import { Injectable } from '@angular/core';
import { ChainType, TokenSummary } from '../../types/multichain';
import { environment } from '../../../environments/environment';
import axios from 'axios';
import { CertificateSummary } from '@arianee/arianeejs/dist/src/core/wallet/certificateSummary';
import { NETWORK } from '@arianee/arianeejs';
import { ethers } from 'ethers';

interface IssuerIdentities {
  [issuer: string] : Partial<Record<NETWORK, any>>
}

type UnixTimestamp = number;

export type MultichainCertificateSummary = CertificateSummary & { network: NETWORK };

@Injectable({
  providedIn: 'root'
})
export class ArianeeBlockchainProxyService {
  private issuerIdentitiesCache: IssuerIdentities = {};
  private identitiesCacheExpiration: UnixTimestamp = 0;
  /**
   * Retrieves and put in cache all existing identities of the passed address
   * @param identityAddress address to retrieve identities of
   */
  private async fetchAndCacheIdentitiesOf (identityAddress: string) {
    const { data: identityByNetwork } = await axios.get<Partial<Record<NETWORK, any>>>(
    `${environment.blockchainProxy.base}/identity/${identityAddress}`
    );

    this.issuerIdentitiesCache[identityAddress] = {};

    const setNetworkIdentityPromises: Promise<void>[] = [];

    for (const network in identityByNetwork) {
      this.issuerIdentitiesCache[identityAddress][network] = {};

      setNetworkIdentityPromises.push(
        (async () => {
          const res = await axios.get(
          `${environment.blockchainProxy.base}/identity/${network}/${identityAddress}`
          );
          this.issuerIdentitiesCache[identityAddress][network] = res.data;
        })()
      );
    }

    try {
      await Promise.all(setNetworkIdentityPromises);
    } catch (e) {
      console.error('Could not retrieve one or more identity for issuer ' + identityAddress, e);
    }
  }

  /**
 * Returns the identity of the passed address. If there exists an identity
 * for this address on polygon, it will be returned, otherwise, the first
 * identity found will be returned.
 * @param identityAddress address to retrieve identity
 * @returns the polygon identity if exists, the first identity found otherwise
 */
  public async getIdentity (identityAddress: string) {
    this.checkIdentitiesCacheExpiration();

    if (!this.issuerIdentitiesCache[identityAddress]) {
      await this.fetchAndCacheIdentitiesOf(identityAddress);
    }

    const identities = this.issuerIdentitiesCache[identityAddress];
    const identitiesValues = Object.values(identities);

    if (identitiesValues.length === 0) {
      throw new Error('Identity not found');
    }

    if (identitiesValues.length > 1) {
      if (identities[NETWORK.polygon]) {
        return identities[NETWORK.polygon];
      }
    }

    return identitiesValues[0];
  }

  /**
   * Retrieves identity for each couple of (issuer, network) in the certificates
   * passed as parameter.
   * @param certificates the certificates to retrieve identities of
   * @returns an object with each unique issuer as a property, the value of each issuer is a record of network <-> identity on the network
   */
  private async retrieveIdentities (certificates: TokenSummary[]) : Promise<IssuerIdentities> {
    const issuerNetworkCouples = new Set(
      certificates.map(certificate => certificate.issuer + ':' + certificate.network)
    );

    const fetchIdentityPromises: Promise<void>[] = [];

    this.checkIdentitiesCacheExpiration();

    for (const issuerNetworkCouple of Array.from(issuerNetworkCouples)) {
      let [issuer, network] = issuerNetworkCouple.split(':') as [string, NETWORK];
      issuer = ethers.utils.getAddress(issuer);
      if (!(issuer in this.issuerIdentitiesCache)) {
        this.issuerIdentitiesCache[issuer] = {};
      }

      if (this.issuerIdentitiesCache[issuer][network]) {
        continue;
      }

      fetchIdentityPromises.push(new Promise((resolve, reject) => {
        axios.get(`${environment.blockchainProxy.base}/identity/${network}/${issuer}`)
          .then(res => {
            this.issuerIdentitiesCache[issuer][network] = res.data;
            resolve();
          }).catch(err => {
            resolve();
          });
      }));
    }

    await Promise.all(fetchIdentityPromises);

    for (const issuerNetworkCouple of Array.from(issuerNetworkCouples)) {
      let [issuer] = issuerNetworkCouple.split(':') as [string, NETWORK];
      issuer = ethers.utils.getAddress(issuer);
      const networks = Object.keys(NETWORK);
      networks.forEach((network) => {
        if (this.issuerIdentitiesCache[issuer][network] && this.issuerIdentitiesCache[issuer][NETWORK.polygon]) {
          this.issuerIdentitiesCache[issuer][network] = this.issuerIdentitiesCache[issuer][NETWORK.polygon];
        }
      });
    }

    return this.issuerIdentitiesCache;
  }

  private checkIdentitiesCacheExpiration () {
    const nowUnixTimestamp = Date.now() / 1000;

    if (this.identitiesCacheExpiration < nowUnixTimestamp) {
      this.issuerIdentitiesCache = {};
      this.identitiesCacheExpiration = nowUnixTimestamp + 90;
    }
  }

  private filterValidCertificates (certificates: TokenSummary[]) : TokenSummary[] {
    const certificatesLength = certificates.length;
    const validCertificates = certificates.filter(certificate => !!certificate.issuer);
    const filteredLength = validCertificates.length;

    if (certificatesLength !== filteredLength) {
      console.warn('Some certificates were filtered out because they are not supported by the multichain wallet (lack `issuer` property)');
    }

    return validCertificates;
  }

  /**
   * Get all the certificates owned by `address` on chain type `chainType`
   * @param chainType chain type to get the certificates from
   * @param address owner of certificates to get
   * @returns an array of MultichainCertificateSummary
   */
  public async getCertificates (chainType: ChainType, address: string) : Promise<MultichainCertificateSummary[]> {
    const allCertificates = (await axios.get(`${environment.blockchainProxy.base}/multichain/${chainType}/nft/${address}/list`))
      .data as TokenSummary[];

    const validCertificates = this.filterValidCertificates(allCertificates);
    const issuerIdentities = await this.retrieveIdentities(validCertificates);

    return validCertificates.map(certificate => {
      const checksummedAddress = ethers.utils.getAddress(certificate.issuer);
      const certificateSummary = this.certificateSummaryFromTokenSummary(certificate, issuerIdentities[checksummedAddress][certificate.network]);

      return {
        ...certificateSummary,
        network: certificate.network
      };
    });
  }

  /**
   * Get all the certificates owned by `address` on chain type `chainType`, grouped by issuer
   * @param chainType chain type to get the certificates from
   * @param address owner of certificates to get
   * @returns a record of issuer <-> MultichainCertificateSummary[]
   */
  public async getCertificatesGroupedByIssuer (chainType: ChainType, address: string) : Promise<Record<string, MultichainCertificateSummary[]>> {
    const certificates = await this.getCertificates(chainType, address);

    return certificates.reduce((previous, current) => {
      const issuer = current.issuer.identity.address;

      if (!(issuer in previous)) {
        previous[issuer] = [];
      }

      previous[issuer].push(current);

      return previous;
    }, {});
  }

  /**
   * Get messages ids for of `address` on chain type `chainType`
   * @param chainType chain type to get message ids from
   * @param address address to get messages ids for
   * @returns list of message ids for `address` on chain type `chainType`
   */
  public async getMessages (chainType: ChainType, address: string) {
    return (await axios.get(`${environment.blockchainProxy.base}/multichain/${chainType}/dmessage/${address}/list`))
      .data;
  }

  /**
   * Creates a CertificateSummary from a TokenSummary
   * @param tokenSummary token summary to construct a CertificateSummary from
   * @param identityData data of the TokenSummary's identity
   * @returns a new CertificateSummary object created from the passed TokenSummary and identity data
   */
  private certificateSummaryFromTokenSummary (tokenSummary: TokenSummary, identityData: any) : CertificateSummary {
    return {
      certificateId: tokenSummary.tokenId,
      issuer: {
        imprint: tokenSummary.imprint,
        isIdentityAuthentic: true,
        isIdentityVerified: false,
        identity: {
          address: ethers.utils.getAddress(tokenSummary.issuer),
          data: identityData,
          imprint: tokenSummary.imprint,
          isApproved: true,
          isAuthentic: true
        }
      }
    };
  }

  public async getEvents (chainType: ChainType, contractName: string, eventName: string, filters?: string) {
    const queryParams = filters ? '?' + filters : '';
    const events = (await axios.get(`${environment.blockchainProxy.base}/multichain/${chainType}/contract/${contractName}/${eventName}${queryParams}`)).data;

    return events;
  }
}
