import CryptoJS from 'crypto-js';
import { environment } from '../../../../../environments/environment';

export const decryptLink = (link:string):string => {
  const splitedLink = link.split(',');
  let passphrase = splitedLink[splitedLink.length - 1];

  if (passphrase.startsWith('enc:')) {
    passphrase = passphrase.replace('enc:', '');
    splitedLink[splitedLink.length - 1] = CryptoJS.AES.decrypt(
      passphrase,
      environment.passphraseSecret,
      {}).toString(CryptoJS.enc.Utf8);
    return splitedLink.join(',');
  } else {
    return link;
  }
};
