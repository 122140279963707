import { Component, Input } from '@angular/core';

export interface IModalHeaderConfig {
  logo?: string;
  title?: string;
  subtitle?: {
    label: string;
    verified?: boolean;
    logo?: string;
  };
  description?: string;
}

@Component({
  selector: 'app-modal-header',
  templateUrl: './modal-header.component.html',
  styleUrls: ['./modal-header.component.scss']
})
export class ModalHeaderComponent {
  @Input() config!: IModalHeaderConfig;
  @Input() columnDisplay = true; /* Display Header Vertically or not */
}
