import { Injectable } from '@angular/core';
import { BackupService } from '../backup-service/backup.service';

@Injectable({
  providedIn: 'root'
})
export class SessionService {
  public isAuth = false;
  public hasSeenBackupWarnModal=false;

  constructor (private backupService:BackupService) {
    setInterval(() => { this.resetSession(); }, 5 * 60 * 1000);
  }

  public resetSession () {
    this.isAuth = false;
    this.backupService.logout();
  }
}
