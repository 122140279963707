import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';

import { UserService } from '../providers/user-service/user.service';
import { SessionService } from '../providers/session-service/session.service';
import { ModalController } from '@ionic/angular';
import { AuthPage } from '../pages/auth/auth.page';

@Injectable({
  providedIn: 'root'
})
export class IsAuthGuard implements CanActivate {
  private pinCodeModal;

  constructor (private userService: UserService, private router: Router,
               private sessionService: SessionService,
               private modal:ModalController) {
  }

  private async presentModalAndWaitAuth () {
    if (this.pinCodeModal === undefined) {
      this.pinCodeModal = await this.modal.create({
        component: AuthPage,
        cssClass: 'modal--full_screen'
      });
      await this.pinCodeModal.present();
      await this.pinCodeModal.onDidDismiss();
      this.pinCodeModal = undefined;
    }

    return true;
  }

  public async isAuth (): Promise<boolean> {
    if (!this.sessionService.isAuth) {
      await this.presentModalAndWaitAuth();
    };
    return this.sessionService.isAuth;
  }

  public canActivate (
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.isAuth();
  }
}
