import { Injectable } from '@angular/core';
import { NETWORK } from '@arianee/arianeejs';
import {
	LoaderService,
	ProductInfoYoutubeComponent,
	ToasterService,
} from '@arianeeprivate/wallet-shared-components';
import { PreviewAnyFile } from '@ionic-native/preview-any-file/ngx';
import { ModalController } from '@ionic/angular';

import { ArianeeBlockchainActionService } from '../../../../providers/arianee-blockchain-action-service/arianee-blockchain-action.service';
import { ArianeeService } from '../../../../providers/arianee-service/arianee.service';
import { EventLoggerService } from '../../../../providers/event-logger/event-logger-service';
import { BrowserService } from '../../../../providers/inapp-browser/inapp-browser-service';
import { SPKZService } from '../../../../providers/spkz-service.ts/SPKZService';

export interface NftLinkInfo {
	isOwner: boolean;
	isPending: boolean;
	certificateId: string;
	network: NETWORK;
	claimable: boolean;
}

@Injectable({
	providedIn: 'root',
})
export class ExternalContentService {
	constructor(
		private toasterService: ToasterService,
		private modalCtrl: ModalController,
		private eventLogger: EventLoggerService,
		private loaderService: LoaderService,
		private browserService: BrowserService,
		private previewAnyFile: PreviewAnyFile,
		private spkzService: SPKZService,
		private arianeeBlockchainActionService: ArianeeBlockchainActionService,
		private arianeeService: ArianeeService,
	) {}

	actionProofLink = async (
		link: {
			type: string;
			url: string;
			title: string;
		},
		nftInfos: NftLinkInfo,
	) => {
		if (nftInfos.isOwner) {
			this.loaderService.showLoader();

			try {
				const wallet = await this.arianeeService.getWalletInstance(
					nftInfos.network,
				);
				const proofLink = await wallet.methods.createAuthURL({
					url: link.url,
					certificateId: +nftInfos.certificateId,
					type:
						link.type === 'proofLinkAction' ? 'proof' : 'arianeeAccessToken',
				});

				this.browserService.openBrowser(proofLink);
			} catch (e) {
				console.error(e);
				this.toasterService.showRedesigned({
					title: 'Oh noo',
					color: 'danger',
					icon: 'cancel',
					duration: 2500,
					position: 'top',
				});
			} finally {
				this.loaderService.dismiss();
			}
		} else {
			this.toasterService.showRedesigned({
				title: 'CertificateInfos.actionLink.notTheOwner',
				color: 'danger',
				icon: 'cancel',
				duration: 2500,
				position: 'top',
			});
		}
	};

	async onLinkClick(
		link: { type: string; url: string; title: string },
		nftInfos: NftLinkInfo,
	) {
		if (
			link.type === 'proofLinkAction' ||
			link.type === 'arianeeAccessTokenAuthLink'
		) {
			if (!nftInfos.isOwner && nftInfos.isPending) {
				return this.toasterService.showRedesigned({
					title: 'CertificateInfos.actionLink.notTheOwner',
					color: 'danger',
					icon: 'cancel',
					duration: 2500,
					position: 'top',
				});
			}

			return this.actionProofLink(link, nftInfos);
		} else if (link.type === 'youtube') {
			const historyModal = await this.modalCtrl.create({
				component: ProductInfoYoutubeComponent,
				cssClass: 'auto-height middle light-round',
				componentProps: {
					link: link,
					logScreen: this.eventLogger.logScreen,
				},
			});
			await historyModal.present();
		} else if (link.type === 'system') {
			this.loaderService.showLoader();
			this.previewAnyFile.preview(link.url).then(() => {
				setTimeout(() => {
					this.loaderService.dismiss();
				}, 5000);
			});
		} else if (link.type === 'external') {
			this.browserService.openBrowserSystem(link.url);
		} else if (link.type === 'spkzLink') {
			this.spkzService.navigateToSPKZLink(link.url);
		} else {
			if (link.url.startsWith('arianee:')) {
				if (nftInfos.isOwner) {
					link.url = link.url.replace(
						'%certificateId%',
						nftInfos.certificateId,
					);
					this.arianeeBlockchainActionService.handleLink(
						link.url,
						nftInfos.network,
					);
				} else {
					this.toasterService.showRedesigned({
						title: 'CertificateInfos.actionLink.notTheOwner',
						color: 'danger',
						icon: 'cancel',
						duration: 2500,
						position: 'top',
					});
				}
			} else {
				this.browserService.openBrowser(link.url);
			}
		}
	}
}
