import { Injectable } from '@angular/core';
import { ArianeeService } from '../arianee-service/arianee.service';
import { mergeMap, take } from 'rxjs/operators';
import { CertificateSummary } from '@arianee/arianeejs/dist/src/core/wallet/certificateSummary';
import {
  ArianeeBlockchainProxyService,
  MultichainCertificateSummary
} from '../arianee-blockchain-proxy-service/arianee-blockchain-proxy-service';
import { UserService } from '../user-service/user.service';
import {
  ConsolidatedCertificateRequest
} from '@arianee/arianeejs/dist/src/core/wallet/certificateSummary/certificateSummary';
import { NETWORK } from '@arianee/arianeejs/dist/src';
import { from } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ArianeeBlockchainSyncService {
  constructor (private arianeeService: ArianeeService, private userService: UserService, private arianeeBlockchainProxyService: ArianeeBlockchainProxyService) {
  }

  private hasAlreadySynced = false;

  /**
   * Return true if certificate content AND identity are identical
   * @param certificate1
   * @param certificate2
   */
  private areCertificateIdentical (certificate1: CertificateSummary, certificate2: CertificateSummary): boolean {
    const hasSameCertificateId = certificate1.certificateId.toString() === certificate2.certificateId.toString();
    const hasIssuerCertificateChanged = certificate1.issuer.imprint === certificate2.issuer.imprint;
    let hasContentCertificateChanged = false;
    if (certificate2.content !== undefined && certificate1.content !== undefined) {
      hasContentCertificateChanged = certificate1.content.imprint === certificate2.content.imprint;
    }

    return hasSameCertificateId && hasIssuerCertificateChanged && hasContentCertificateChanged;
  }

  /**
   *
   * Sync with blockchain only if it has not been made before or force =true
   * It will sync identities && content of all owner's certificates
   * @param force
   */
  public syncWithBlockchain = (force = false) => {
    if (this.hasAlreadySynced === false || force) {
      this.hasAlreadySynced = true;
      return this.syncAllCertificate();
    }
  };

  private async getCurrentChainType () {
    return await this.userService.$chainType.getOnce().toPromise();
  }

  public syncAllCertificate (query: ConsolidatedCertificateRequest =
  {
    issuer: { forceRefresh: true },
    content: { forceRefresh: true }
  }
  ): void {
    this.arianeeService.$walletInitialize.subscribe((network: NETWORK) => {
      from(this.arianeeService.getWalletInstance(network)).pipe(
        take(1),
        mergeMap(async wallet => {
          const $certificates: Promise<MultichainCertificateSummary[]> = this.arianeeBlockchainProxyService.getCertificates(await this.getCurrentChainType(), wallet.address);
          $certificates.then((certificates: MultichainCertificateSummary[]) => {
            certificates.map(async (certificate: MultichainCertificateSummary) => {
              const walletInstance = await this.arianeeService.getWalletInstance(certificate.network);
              await walletInstance.methods.getCertificate(certificate.certificateId, '', query);
            });
          });
        })
      ).subscribe();
    });
  }
}
