import {
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	ElementRef,
	EventEmitter,
	Input,
	OnChanges,
	OnInit,
	Output,
	QueryList,
	SimpleChanges,
	ViewChildren,
} from '@angular/core';

import { Icon } from '../../../icon/models/icon.model';
import { MediaItemCarousel } from '../../models/media-item-carousel.model';

@Component({
	selector: 'app-media-carousel-fullscreen',
	templateUrl: './media-carousel-fullscreen.component.html',
	styleUrls: ['./media-carousel-fullscreen.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MediaCarouselFullscreenComponent implements OnInit, OnChanges {
	@Input() carouselItems: MediaItemCarousel[];
	@Input() currentMediaIndex: number = 0;
	@Input() itemBackgroundPicture: string;
	@Output() closeFullscreen: EventEmitter<number> = new EventEmitter<number>();
	@Output() mediaClick: EventEmitter<number> = new EventEmitter<number>();

	@ViewChildren('card') cards: QueryList<ElementRef>;

	closeIcon: Icon = {
		name: 'icon-cancel',
		fontSize: 24,
		borderRadius: '12px',
		height: '40px',
		width: '40px',
		backgroundColor: '#0E0F11',
		color: '#FFFFFF',
	};

	totalMedias = 0;

	constructor(private cdr: ChangeDetectorRef) {}

	ngOnInit() {}

	ngOnChanges(changes: SimpleChanges) {
		this.totalMedias = this.carouselItems.filter(
			(item) => item.type !== 'ARPrimaryAsset' && !!item.url,
		).length;
	}

	onCloseFullscreen() {
		this.closeFullscreen.emit(this.currentMediaIndex);
	}

	setCurrentImage(index: number) {
		this.currentMediaIndex = index;
		this.cdr.detectChanges();
		if (index) {
			this.mediaClick.emit(index);
		}
	}

	onCurrentMediaIndexChange(currentMediaIndex: number) {
		this.currentMediaIndex = currentMediaIndex;
		this.scrollToActiveCard(currentMediaIndex);
		this.cdr.detectChanges();
	}

	private scrollToActiveCard(currentMediaIndex: number) {
		const currentCard: ElementRef = (this.cards || []).find((card) => {
			return card.nativeElement.id === 'active';
		});

		if (!currentCard) {
			return;
		}

		let inline: 'start' | 'end';
		if (currentMediaIndex === 3) {
			inline = 'end';
		} else if (currentMediaIndex === this.totalMedias / 2) {
			inline = 'start';
		}

		currentCard.nativeElement.scrollIntoView({
			behavior: 'smooth',
			block: 'nearest',
			inline,
		});
	}

	isInViewport(element: HTMLElement): boolean {
		const rect = element.getBoundingClientRect();
		return (
			rect.top >= 0 &&
			rect.left >= 0 &&
			rect.bottom <=
				(window.innerHeight || document.documentElement.clientHeight) &&
			rect.right <= (window.innerWidth || document.documentElement.clientWidth)
		);
	}
}
