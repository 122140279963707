import { IdentityBase } from '@arianee/arianeejs/dist/src/models';
import { get } from 'lodash';

export const brandImageLogo = (identity: IdentityBase, picturesOrder: string[]): string => {
  if (get(identity, 'data') === undefined || get(identity, 'data') === null) {
    return '';
  }

  const { pictures } = identity.data;

  const isPicture = (pictureName): boolean => {
    const picture = pictures
      .find(pic => pic.type === pictureName);

    return picture !== undefined;
  };

  const pictureUrl = (pictureName): string => {
    return pictures
      .find(pic => pic.type === pictureName).url;
  };

  const findPicture = (picturesNames: string[]): string => {
    for (const pictureName of picturesNames) {
      if (isPicture(pictureName)) {
        return pictureUrl(pictureName);
      }
    }
    return '';
  };

  if (pictures) {
    return findPicture(picturesOrder);
  }

  return '';
};
