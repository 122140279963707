import { Component, Input, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToasterService } from '@arianeeprivate/wallet-shared-components';
import {
	ModalController,
	NavController,
	ToastController,
} from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { Subject, Subscription, from, iif, of } from 'rxjs';
import { delay, filter, map, mergeMap, tap } from 'rxjs/operators';
import { EventLoggerService } from 'src/app/providers/event-logger/event-logger-service';

import { BiometricService } from '../../../../providers/fingerprint-service/biometric.service';
import { SessionService } from '../../../../providers/session-service/session.service';
import { UserService } from '../../../../providers/user-service/user.service';

@Component({
	selector: 'app-auth-legacy',
	templateUrl: './auth-legacy.component.html',
	styleUrls: ['./auth-legacy.component.scss'],
})
export class AuthLegacyComponent implements OnInit, OnDestroy {
	public displayedPadPinCode: number[];
	public displayedPinCode: number[];
	private subscriptions: Array<Subscription> = [];

	@Input() changePinCode = false;
	displayErrorMsg = false;
	constructor(
		public userService: UserService,
		private router: Router,
		private toastController: ToastController,
		private translate: TranslateService,
		private activatedRoute: ActivatedRoute,
		private sessionService: SessionService,
		private fingerPrint: BiometricService,
		private modalCtrl: ModalController,
		private renderer: Renderer2,
		private eventLogger: EventLoggerService,
		private toasterService: ToasterService,
	) {}

	public pad = [[1, 2, 3], [4, 5, 6], [7, 8, 9], [0]];

	public pinCode = '';
	public hasPinCode;
	public confirmedPinCode = '';
	public clickEvent = new Subject<number>();

	public reset() {
		this.displayedPadPinCode = [undefined, undefined, undefined, undefined];
		this.displayedPinCode = [];
		this.pinCode = '';
	}

	ngOnInit() {
		this.eventLogger.logScreen('auth', {});

		this.renderer.addClass(document.body, 'body--without--app-loader');
		this.reset();

		const sub = this.userService.hasPinCode
			.pipe(
				tap((haspinCode) => {
					this.hasPinCode = haspinCode;
				}),
				mergeMap((hasPinCode) =>
					iif(
						() => !hasPinCode || this.changePinCode,
						this.recordPin(),
						this.biometricAuth(),
					),
				),
			)
			.subscribe();

		this.subscriptions.push(sub);
	}

	clickOnNumber(pinNumber: number) {
		this.clickEvent.next(pinNumber);
	}

	biometricAuth() {
		return this.fingerPrint.showAuth().pipe(
			mergeMap((isAvailable) => {
				if (isAvailable) {
					return this.redirect();
				} else {
					return this.validPin();
				}
			}),
		);
	}

	validPin() {
		return this.clickEvent.pipe(
			tap((pinNb) => this.displayedPinCode.push(pinNb)),
			tap((pinNb) => {
				this.pinCode += pinNb.toString();
			}),
			filter(() => this.displayedPinCode.length === 4),
			mergeMap(() => this.validPinCode()),
		);
	}

	validPinCode() {
		return this.userService.isPinCodeValid(this.pinCode).pipe(
			mergeMap((isValid) => {
				if (isValid) {
					return this.redirect();
				} else {
					return this.restart();
				}
			}),
		);
	}

	redirect = async () => {
		const i = async () => {
			this.sessionService.isAuth = true;
			this.renderer.removeClass(document.body, 'body--without--app-loader');
			await this.modalCtrl.dismiss();
		};

		return from(i());
	};

	restart() {
		return this.translate.get('Pin.wrong_pin').pipe(
			delay(350),
			tap(async (text) => {
				this.displayedPinCode = [];
				this.pinCode = '';
				this.confirmedPinCode = '';
				this.toasterService.showRedesigned({
					title: text,
					color: 'danger',
					icon: 'cancel',
					duration: 2000,
					position: 'top',
				});
			}),
		);
	}

	recordPin() {
		return this.clickEvent.pipe(
			tap((pinNb) => this.displayedPinCode.push(pinNb)),
			mergeMap((pinNb) => {
				this.displayErrorMsg = false;

				if (this.pinCode.length < 4) {
					this.pinCode += pinNb;
					if (this.pinCode.length === 4) {
						return of('').pipe(
							delay(350),
							tap(() => {
								this.displayedPinCode = [];
							}),
							map(() => this.pinCode),
						);
					}
				} else {
					this.confirmedPinCode += pinNb;
				}

				return of(this.pinCode);
			}),
			filter((pincode) => this.confirmedPinCode.length === 4),
			mergeMap(() =>
				iif(
					() => this.pinCode === this.confirmedPinCode,
					this.registerPin(),
					this.restartAfterPinNotMatch(),
				),
			),
		);
	}

	restartAfterPinNotMatch() {
		this.displayErrorMsg = true;
		return this.restart();
	}

	registerPin() {
		this.displayErrorMsg = false;
		return this.userService
			.setPinCode(this.pinCode)
			.pipe(mergeMap(() => this.redirect()));
	}

	ngOnDestroy() {
		this.subscriptions.forEach((sub) => sub.unsubscribe());
	}

	onClickNavBack() {
		this.modalCtrl.dismiss();
	}
}
