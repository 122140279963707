import { Injectable } from '@angular/core';
import { InAppBrowser, InAppBrowserOptions } from '@ionic-native/in-app-browser/ngx';
import { Platform } from '@ionic/angular';
import { AppAvailability } from '@ionic-native/app-availability/ngx';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class BrowserService {
  private iabOptions: InAppBrowserOptions = {
    zoom: 'no',
    toolbartranslucent: 'no',
    toolbarposition: 'bottom',
    closebuttoncaption: 'close',
    navigationbuttoncolor: '#FFFFFF',
    location: 'no',
    EnableViewPortScale: 'yes',
    clearcache: 'yes',
    cleardata: 'yes',
    closebuttoncolor: '#cd9a59',
    // presentationstyle: 'pagesheet'
    presentationstyle: 'fullscreen'

  };

  constructor (
    private iab: InAppBrowser,
    private platform:Platform,
    private appAvailability: AppAvailability,
    private translateService: TranslateService
  ) {
    this.platform.ready().then(() => {
      this.checkTwitterAvailability();
      this.checkFacebookAvailability();
      this.checkInstagramAvailability();
      this.loadTranslation();
    });
  }

  private isTwitterAvailable = false;
  private isFacebookAvailable = false;
  private isInstagramAvailable = false;

  async loadTranslation () {
    const closeTranslation = await this.translateService.instant('Generic.browserClose');
    this.iabOptions.closebuttoncaption = closeTranslation;
  }

  public openBrowser = (url) => {
    return this.openBrowserSystem(url);
  }

  public openBrowserSystem = (url) => {
    this.iab.create(url, '_system');
  }

  public openTwitter (pageName) {
    if (this.isTwitterAvailable) {
      this.iab.create('twitter://user?screen_name=' + pageName, '_system');
    } else {
      this.iab.create('https://twitter.com/' + pageName, '_blank', this.iabOptions);
    }
  }

  public openFacebook (pageName) {
    if (this.isFacebookAvailable) {
      this.iab.create('fb://page/' + pageName, '_system');
    } else {
      this.iab.create('https://facebook.com/' + pageName, '_blank', this.iabOptions);
    }
  }

  public openInstagram (pageName) {
    if (this.isInstagramAvailable) {
      this.iab.create('instagram://user?username=' + pageName, '_system');
    } else {
      this.iab.create('https://instagram.com/' + pageName, '_blank', this.iabOptions);
    }
  }

  public openYoutube (pageName) {
    const isLegacy = pageName.match(/^(https:\/\/www.youtube.com)/g).length === 0;
    let youtubeUrl;
    if (isLegacy) {
      youtubeUrl = `https://youtube.com/channel/${pageName}`;
    } else {
      youtubeUrl = pageName;
    }

    this.iab.create(youtubeUrl, '_system');
  }

  public openMail (mail) {
    this.iab.create('mailto:' + mail, '_system');
  }

  private checkTwitterAvailability () {
    let app;
    if (this.platform.is('ios')) {
      app = 'twitter://';
    } else if (this.platform.is('android')) {
      app = 'com.twitter.android';
    } else {
      app = 'twitter';
    }

    this.appAvailability.check(app)
      .then(
        () => { this.isTwitterAvailable = true; },
        () => { this.isTwitterAvailable = false; }
      );
  }

  private checkFacebookAvailability () {
    let app;
    if (this.platform.is('ios')) {
      app = 'facebook://';
    } else if (this.platform.is('android')) {
      app = 'com.facebook.katana';
    } else {
      app = 'facebook';
    }

    this.appAvailability.check(app)
      .then(
        () => { this.isFacebookAvailable = true; },
        () => { this.isFacebookAvailable = false; }
      );
  }

  private checkInstagramAvailability () {
    let app;
    if (this.platform.is('ios')) {
      app = 'instagram://';
    } else if (this.platform.is('android')) {
      app = 'com.instagram.android';
    } else {
      app = 'instagram';
    }

    this.appAvailability.check(app)
      .then(
        () => { this.isInstagramAvailable = true; },
        () => { this.isInstagramAvailable = false; }
      );
  }
}
