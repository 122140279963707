import { MediaItemCarousel } from '../../../../../../../components/redesign/media-carousel/models/media-item-carousel.model';
import { getYoutubeMedia } from '../../../youtube/youtube.helper';

export function getMediaCarouselItemFromEvent(event): MediaItemCarousel[] {
	let mediaList = [];
	if (event && event.medias) {
		let previewUrl = '';
		let ytMedia: { ytId: string; thumbnail: string; embedURL: string } = null;
		mediaList = event.medias
			.filter((media) => media.url)
			.map((media) => {
				switch (media.mediaType) {
					case 'youtube':
						ytMedia = getYoutubeMedia(media);
						return {
							type: media.mediaType,
							url: ytMedia.embedURL,
							preview: ytMedia.thumbnail,
							order: media.order,
						};
					case 'picture':
						previewUrl = media.preview;
						return media.type === 'product'
							? {
									type: media.mediaType,
									url: media.url,
									preview: previewUrl,
									order: media.order,
							  }
							: undefined;
					default:
						console.warn('Invalid media type:', media.type);
						return null;
				}
			})
			.filter((media) => media);

		mediaList = [...mediaList];
	}

	return mediaList;
}
