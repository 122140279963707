import {
	AfterViewInit,
	ChangeDetectionStrategy,
	Component,
	ElementRef,
	Input,
	OnInit,
	ViewChild,
} from '@angular/core';

import { MediaItemCarousel } from '../../models/media-item-carousel.model';

type ModelViewerWebComponent = HTMLElement & {
	play: () => void;
};

@Component({
	selector: 'app-three-dimensions-media',
	templateUrl: './three-dimensions-media.component.html',
	styleUrls: ['./three-dimensions-media.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ThreeDimensionsMediaComponent implements OnInit, AfterViewInit {
	@Input() media: MediaItemCarousel;

	@ViewChild('modelViewerLoader', { static: false })
	public modelViewerLoader: ElementRef<HTMLElement>;

	@ViewChild('modelViewer', { static: false })
	public modelViewer: ElementRef<HTMLElement>;

	ngOnInit() {}

	ngAfterViewInit() {
		this.modelViewer.nativeElement.addEventListener('progress', (event) => {
			this.onModelProgress(event);
		});
	}

	private onModelProgress(event) {
		const currentProgress = event.detail.totalProgress;
		this.modelViewerLoader.nativeElement.style.display =
			currentProgress >= 1 ? 'none' : 'block';

		if (currentProgress >= 1) {
			(this.modelViewer.nativeElement as ModelViewerWebComponent).play();
		}
	}
}
