import { Injectable } from '@angular/core';
import { createOrRetrieveWallet } from '@arianee/spkz-sdk';
import { take } from 'rxjs/operators';
import { ArianeeService } from '../arianee-service/arianee.service';
import { BrowserService } from '../inapp-browser/inapp-browser-service';
import { from } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SPKZService {
  constructor (private arianeeService: ArianeeService, private browserService:BrowserService) {

  }

  /**
   * Pass a link to spkz room or section and it will open browser with the right query params
   * @param url
   */
  async navigateToSPKZLink (url:string) {
    const network = await this.arianeeService.$walletInitialize.pipe(take(1)).toPromise();
    const proxyWallet = createOrRetrieveWallet();
    const arianeeWallet = await from(this.arianeeService.getWalletInstance(network)).pipe(take(1)).toPromise();
    const pk = arianeeWallet.privateKey;
    await proxyWallet.wallets.addWalletFromPrivateKey(pk);

    const urlToRedirectTo = proxyWallet.wallets.createAuthLink(url);
    this.browserService.openBrowser(urlToRedirectTo);
  }
}
