import { Injectable } from '@angular/core';
import Arianee, { NETWORK } from '@arianee/arianeejs';
import ArianeeWallet from '@arianee/arianeejs/dist/src/core/wallet';
import { CertificateSummary } from '@arianee/arianeejs/dist/src/core/wallet/certificateSummary';
import axios from 'axios';
import { entropyToMnemonic } from 'ethers/lib/utils';
import get from 'lodash/get';

export type ClaimParams = {
	tokenId: number;
	passphrase: string;
};

@Injectable({
	providedIn: 'root',
})
export default class LandingClaimService {
	public async relayedClaim(
		network: NETWORK,
		claimerAddress: string,
		{ tokenId, passphrase }: ClaimParams,
	): Promise<boolean> {
		if (!tokenId || !passphrase)
			throw new Error('missing tokenId or passphrase');

		const arianee = new Arianee();
		const wallet = (await arianee.init(network)).fromPassPhrase(passphrase);

		const storeContract = wallet.contracts.storeContract;

		const { messageHash, signature } =
			await wallet.utils.signProofForRequestToken(
				tokenId,
				claimerAddress,
				wallet.privateKey,
			);

		try {
			await storeContract.methods
				.requestToken(
					tokenId,
					messageHash,
					false,
					wallet.configuration.brandDataHubReward.address,
					signature,
					claimerAddress,
				)
				.send();

			return true;
		} catch (e) {
			console.error('Error while sending transaction', e);
		}

		return false;
	}

	/**
	 * Retrieves the claim params behind a NFT. The claim params may vary based on the content
	 * of the NFT (classical nft, nft with authRedirectTo)
	 * @param claimerAddress public address that will claim the nft, used as seed to generate the wallet that will create the arianeeAccessToken to call one2many apis
	 * @param nft The nft to retrieve the claim params from
	 * @param nftClaimParams The claim params of the passed nft, will be returned if no claim params could be retrieved from the nft
	 * @returns The claim params or null if no claim params could be retrieved from both the nft and the route
	 */
	public async retrieveClaimParamsBehindNft(
		claimerAddress: string,
		nft: CertificateSummary,
		nftClaimParams?: ClaimParams,
	): Promise<ClaimParams | null> {
		const authRedirectToUrl = this.getAuthRedirectToUrl(nft);

		if (authRedirectToUrl) {
			return this.getClaimParamsFromAuthRedirectToUrl(
				claimerAddress,
				authRedirectToUrl,
			);
		}

		return nftClaimParams || null;
	}

	public async isNftClaimable(network: NETWORK, nftClaimParams: ClaimParams) {
		const arianee = new Arianee();
		const wallet = (await arianee.init(network)).fromPassPhrase(
			nftClaimParams.passphrase,
		);

		try {
			return wallet.methods.isCertificateOwnershipRequestable(
				nftClaimParams.tokenId,
				nftClaimParams.passphrase,
			);
		} catch (e) {
			return false;
		}
	}

	private getAuthRedirectToUrl(nft: CertificateSummary): string | null {
		const externalContents: { type: string; url: string }[] = get(
			nft,
			'content.data.externalContents',
			[],
		);

		const authRedirectTo = externalContents.find(
			(externalContent) => externalContent.type === 'authRedirectTo',
		);

		return authRedirectTo ? authRedirectTo.url : null;
	}

	private async getClaimParamsFromAuthRedirectToUrl(
		claimerAddress: string,
		url: string,
	): Promise<ClaimParams> {
		const one2ManyEndpoint = this.getOne2ManyEndpointFromAuthRedirectToUrl(url);

		const nftLink = await this.getNftLinkFromOne2ManyEndpoint(
			claimerAddress,
			one2ManyEndpoint,
		);

		const [tokenId, passphrase] = new URL(nftLink).pathname.slice(1).split(',');

		return {
			tokenId: +tokenId,
			passphrase,
		};
	}

	/**
	 * The value of authRedirectTo can either be an url to a one2many endpoint or
	 * an url to an inapp link where the one2many endpoint is an url encoded parameter.
	 * In the first case, the url is already usable but in the second case,
	 * the nmp api must be extracted and decoded from the inapp link.
	 * @param url the authRedirectTo url to extract the api endpoint from
	 * @returns the decoded url
	 */
	private getOne2ManyEndpointFromAuthRedirectToUrl(url: string): string {
		if (url.indexOf('/tab/pending/') === -1) return url;

		const urlEncodedOne2ManyEndpoint = url.split('/tab/pending/')[1];
		const one2ManyEndpoint = decodeURIComponent(urlEncodedOne2ManyEndpoint);

		return one2ManyEndpoint;
	}

	private async getNftLinkFromOne2ManyEndpoint(
		claimerAddress: string,
		endpoint: string,
	): Promise<string> {
		const arianeeWallet = await this.createWalletFromSeed(claimerAddress);

		const arianeeAccessToken =
			await arianeeWallet.methods.createWalletAccessToken();

		const url = new URL(endpoint);
		url.searchParams.set('arianeeAccessToken', arianeeAccessToken);
		url.searchParams.set('format', 'link');

		const response = await axios.get(url.toString());
		return response.data;
	}

	/**
	 * Creates a wallet using mnemonic generated from the seed passed in parameter.
	 * This allows for deterministic wallet creation.
	 * @param seed the seed to generate the mnemonic from
	 * @returns a wallet created from the mnemonic generated from the seed
	 */
	private async createWalletFromSeed(seed: string): Promise<ArianeeWallet> {
		const mnemonic = entropyToMnemonic(seed);
		const arianee = new Arianee();
		return (await arianee.init()).fromMnemonic(mnemonic);
	}
}
