import { Injectable } from '@angular/core';
import { ResolveEnd, Router } from '@angular/router';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { Platform } from '@ionic/angular';
import { combineLatest } from 'rxjs';
import { filter } from 'rxjs/operators';

import { FeatureFlipService } from '../feature-flip-service/feature-flip.service';

interface ISpecificRoute {
	url: string;
	statusBarType: string;
}

@Injectable({
	providedIn: 'root',
})
export class StatusBarService {
	private alreadyInit = false;

	private specificRoutes: ISpecificRoute[] = [
		{ url: '/tab/scan', statusBarType: 'blackBackground' },
		{ url: '/tab/scan-full-page', statusBarType: 'blackBackground' },
		{ url: '/tab/scan-edgyn', statusBarType: 'blackBackground' },
		{ url: '/tab/noti', statusBarType: 'blackText' },
		{ url: '/chat', statusBarType: 'blackBackground' },
		{ url: '/notification', statusBarType: 'blackText' },
		{
			url: '/tab/brand-list/product-detail',
			statusBarType: 'transparentBackground',
		},
		{ url: '/brand-page', statusBarType: 'blackBackground' },
		{ url: '/tab/brand-list/pending/', statusBarType: 'blackBackground' },
	];

	private currentStatusBarStyle: string | undefined;

	constructor(
		private statusBar: StatusBar,
		private router: Router,
		private platform: Platform,
		private ffService: FeatureFlipService,
	) {}

	public init() {
		if (!this.alreadyInit) {
			this.alreadyInit = true;

			if (this.platform.is('android')) {
				this.statusBar.overlaysWebView(false);
				this.statusBar.backgroundColorByHexString('#000000');
				this.statusBar.styleBlackOpaque();
			} else {
				const routeChangeEvent = this.router.events.pipe(
					filter((event) => event instanceof ResolveEnd),
				);
				const darkModeChangeEvent = this.ffService.$isFeatureFlip('darkMode');

				combineLatest([routeChangeEvent, darkModeChangeEvent]).subscribe(
					([route, darkMode]: [ResolveEnd, boolean]) => {
						const urlMatch: ISpecificRoute = this.specificRoutes.find(
							(item: ISpecificRoute) => route.url.includes(item.url),
						);
						if (darkMode) {
							this.changeStatusBar('blackBackground');
						} else if (urlMatch) {
							this.changeStatusBar(urlMatch.statusBarType);
						} else {
							this.changeStatusBar('blackText');
						}
					},
				);
			}
		}
	}

	public changeStatusBar(template?: string) {
		if (this.currentStatusBarStyle !== template) {
			switch (template) {
				case 'transparentBackground':
					this.statusBar.styleBlackTranslucent();
					this.currentStatusBarStyle = 'transparentBackground';
					break;

				case 'blackBackground':
					this.statusBar.styleBlackOpaque();
					this.statusBar.backgroundColorByHexString('#000000');
					this.currentStatusBarStyle = 'blackBackground';
					break;

				case 'blackText':
				default:
					this.statusBar.backgroundColorByHexString('#ffffff');
					this.statusBar.styleDefault();
					this.currentStatusBarStyle = 'blackText';
					break;
			}
		}
	}
}
