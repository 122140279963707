import {ChangeDetectionStrategy, Component, Input, NgZone, OnInit,} from '@angular/core';
import {ModalController} from '@ionic/angular';
import {TranslateService} from '@ngx-translate/core';
import {AnimationItem} from 'lottie-web';
import {AnimationOptions} from 'ngx-lottie';

import {Button} from '../../../../../components/redesign/button/models/button.model';
import {EventLoggerService} from '../../../../../providers/event-logger/event-logger-service';
import { Router } from '@angular/router';

@Component({
	selector: 'app-claim-congrats-modal',
	templateUrl: './claim-congrats-modal.component.html',
	styleUrls: ['./claim-congrats-modal.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ClaimCongratsModalComponent implements OnInit {
	@Input() title: string;
	@Input() description: string;
	@Input() item: { url: string; legend: string };
	buttons: {
		continue: Button;
		manageNotification: Button;
	}

	styles: Partial<CSSStyleDeclaration> = {
		position: 'fixed',
		top: '0',
		left: '50%',
		transform: 'translateX(-50%)',
		zIndex: '1',
		pointerEvents: 'none',
	};

	options: AnimationOptions = {};
	private animationItem: AnimationItem;
	constructor(
		private modalCtrl: ModalController,
		private translate: TranslateService,
		private router: Router,
		private ngZone: NgZone,
		private eventLogger: EventLoggerService,
	) {}

	ngOnInit() {
		this.eventLogger.logScreen('CongratOnClaimModal');
		this.buttons = {
			continue: {
				title: {
					text: this.translate.instant(
						'CertificateInfos.claimCongrat.buttonLabel',
					),
				},
				buttonType: 'primary',
			},
			manageNotification: {
				title: {
					text: this.translate.instant(
						'CertificateInfos.claimCongrat.manageNotifications',
					),
				},
				buttonType: 'text',
			}
		};

		this.options = {
			...this.options,
			path: '/assets/lotties/confetti.json',
		};
	}

	animationCreate(animationItem: AnimationItem) {
		this.animationItem = animationItem;
		this.animationItem.autoplay = false;
		this.animationItem.loop = false;
		this.playAnimation();
	}

	playAnimation() {
		this.ngZone.runOutsideAngular(() => {
			this.animationItem.stop();
			this.animationItem.play();
		});
	}

	async onCloseModal(showOptInOutModal?: boolean): Promise<void> {
		this.eventLogger.logEvent('CongratOnClaimModal_close');
		await this.modalCtrl.dismiss({
			dismissed: true,
			showOptInOutModal
		});
	}
}
