import { ListItem } from '../../../../../../components/redesign/list/models/list-item.model';
import { MappedCertificate } from '../../../models/mapped-certificate.model';
import { dateFormatter } from '../../date/date-formatter';

export function arianeeEventsMapper(
	certificate: MappedCertificate,
	events,
): ListItem[] {
	return (events || []).map((event) => {
		return {
			icon: {
				image: {
					url: certificate.identity.brandLogoSquare,
					height: '48px',
					width: '48px',
					borderRadius: '24px',
				},
			},
			title: event.content.data.title,
			highlightText:
				event.content.data.highlightText === 'none'
					? ''
					: event.issuer.identity.data.name,
			subtitle: event.timestamp
				? dateFormatter(new Date(event.timestamp * 1000))
				: undefined,
			actionIcon: 'link',
			original: event,
			action: () => null,
			badge: {
				count: undefined,
				enableBadge: event.pending,
			},
			e2eTag: 'arianee-event-history-item',
		};
	});
}
