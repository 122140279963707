import { Component } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { CertificateSummary } from '@arianee/arianeejs/dist/src/core/wallet/certificateSummary';
import { LoaderService } from '@arianeeprivate/wallet-shared-components';
import { GenericCustodialService } from '../../providers/generic-custodial-service/generic-custodial.service';
import { BrowserService } from '../../providers/inapp-browser/inapp-browser-service';
import { get } from 'lodash';
import { ArianeeService } from '../../providers/arianee-service/arianee.service';
import { take } from 'rxjs/operators';

@Component({
  templateUrl: './export-mnemonic.component.html',
  styleUrls: ['./export-mnemonic.component.scss']
})
export class ExportMnemonicComponent {
  public certificate:CertificateSummary;
  constructor (
      private modalCtrl: ModalController,
      private loaderService:LoaderService,
      private genericCustodialService:GenericCustodialService,
      private browserService:BrowserService,
    private arianeeService: ArianeeService
  ) {
  }

  public get brandName ():string {
    return get(this.certificate, 'issuer.identity.data.name') || 'unknown brand';
  }

  async onCancel () {
    await this.modalCtrl.dismiss();
  }

  async onConfirm () {
    const network = await this.arianeeService.$walletInitialize.pipe(take(1)).toPromise();

    this.loaderService.showLoaderWithBlockchain();

    const url = await this.genericCustodialService.generateLinkToCustodialWalletLandingPage(this.certificate.issuer.identity.address, network);
    this.loaderService.dismiss();

    this.browserService.openBrowser(url);
    await this.modalCtrl.dismiss();
  }
}
