import {
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	Input,
} from '@angular/core';

import { ListItem } from '../models/list-item.model';

@Component({
	selector: 'app-list-item',
	templateUrl: './list-item.component.html',
	styleUrls: ['./list-item.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ListItemComponent {
	@Input() item: ListItem;
	@Input() isLastItem?: boolean;

	constructor(private cd: ChangeDetectorRef) {}

	onItemClicked(item, event?) {
		if (item) {
			item.checked = event.detail.checked;
			if (item.action) {
				item.action();
			}
		}
	}
}
