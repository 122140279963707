import { Injectable } from '@angular/core';
import { Vibration } from '@ionic-native/vibration/ngx';

@Injectable({
  providedIn: 'root'
})
export class VibrationService {
  constructor (private vibration: Vibration) {
  }

  public vibrate () {
    this.vibration.vibrate(1000);
  }

  public async vibrateRepeat () {
    await this.vibration.vibrate(600);
    await this.delay(600);
    await this.vibration.vibrate(600);
  }

  public delay (ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }
}
