import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { IModalHeaderConfig } from '../wallet-connect-modals/components/modal-header/modal-header.component';

@Component({
  selector: 'app-poap-error-modal',
  templateUrl: './poap-error-modal.component.html',
  styleUrls: ['./poap-error-modal.component.scss']
})
export class PoapErrorModalComponent implements OnInit {
  @Input() title: string;
  @Input() message: string;
  headerConfig: IModalHeaderConfig = {
    logo: 'assets/imgs/error-icon.png',
    title: 'POAP',
    subtitle: {
      label: '',
      verified: false
    }
  }

  constructor (public modalController: ModalController) { }

  ngOnInit () {
    this.headerConfig.title = this.title;
    this.headerConfig.subtitle.label = this.message;
  }
}
