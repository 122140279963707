import { Injectable } from '@angular/core';
import { backupType } from '@arianeeprivate/wallet-shared-components';
import { NativeStorage } from '@ionic-native/native-storage/ngx';
import {
	SecureStorage,
	SecureStorageObject,
} from '@ionic-native/secure-storage/ngx';
import { Platform } from '@ionic/angular';
import CryptoJS from 'crypto-js';
import { ethers } from 'ethers';
import { BehaviorSubject, Observable, ReplaySubject, from, of } from 'rxjs';
import { map, mergeMap, take } from 'rxjs/operators';

import { storageKeys } from '../../../config/storageKeys';
import { environment } from '../../../environments/environment';
import { NativeStorageObservableCache } from '../../helpers/observable-cache-storage/native-storage-observable-cache';
import { SecureStorageObservableCache } from '../../helpers/observable-cache-storage/secure-storage-observable-cache';
import { ChainType } from '../../types/multichain';
import { StorageService } from '../storage-service/storage.service';
import { getChainTypeFromNetwork } from '../wallet-connect-service/chain-utils/chain-utils';

@Injectable({
	providedIn: 'root',
})
export class UserService {
	private secureStorageObservableCacheFactory = (
		key: string,
		defaultValue?: any,
	) => {
		return this.$secureStorage.pipe(
			take(1),
			map(
				(secuStorage) =>
					new SecureStorageObservableCache(secuStorage, key, defaultValue),
			),
		);
	};

	public $secureStorage = new ReplaySubject<SecureStorageObject>(1);

	public hasFakeLoggedIn = false;
	private mnemonicKey = storageKeys.mnemonic;
	private secureStorage: SecureStorage;
	private secureStorageObject: SecureStorageObject;
	private nativeStorage: NativeStorage;

	public $backup: NativeStorageObservableCache;
	public $chainType: NativeStorageObservableCache<ChainType>;
	public hasAgreePrivacyPolicy: NativeStorageObservableCache;
	public $devToolStorage: NativeStorageObservableCache;
	public $advancedToolStorage: NativeStorageObservableCache;
	public $numberOfAppLaunch: NativeStorageObservableCache;
	public $pinCodeStorage: Observable<SecureStorageObservableCache> =
		this.secureStorageObservableCacheFactory(storageKeys.pinCode);

	public $hasOnBoardedStorage: Observable<SecureStorageObservableCache> =
		this.secureStorageObservableCacheFactory(storageKeys.onBoardingStatus);

	public $hasBackup: ReplaySubject<string> = new ReplaySubject(1);

	public hasPinCode: Observable<boolean> = this.$pinCodeStorage
		.pipe(mergeMap((pinCodeStorage) => pinCodeStorage.get()))
		.pipe(
			map((d) => {
				return d !== null && d !== undefined;
			}),
		);

	constructor(
		private storageService: StorageService,
		private platform: Platform,
	) {
		this.init();
	}

	public incrementNumberOfAppLaunch = () => {
		this.$numberOfAppLaunch
			.getOnce()
			.pipe(
				mergeMap((value) => {
					return this.$numberOfAppLaunch.set(+value + 1);
				}),
				take(1),
			)
			.subscribe();
	};

	public init() {
		return this.platform.ready().then(() => {
			this.secureStorage = this.storageService.secureStorage;
			this.nativeStorage = this.storageService.nativeStorage;
			this.$devToolStorage = new NativeStorageObservableCache(
				this.nativeStorage,
				storageKeys.hasDevTools,
				false,
			);
			this.$advancedToolStorage = new NativeStorageObservableCache(
				this.nativeStorage,
				storageKeys.hasAdvancedTools,
				false,
			);

			this.$chainType = new NativeStorageObservableCache(
				this.nativeStorage,
				storageKeys.userChainType,
				getChainTypeFromNetwork(environment.network),
			);

			this.hasAgreePrivacyPolicy = new NativeStorageObservableCache(
				this.nativeStorage,
				storageKeys.privacyPolicyStatus,
				false,
			);
			this.$numberOfAppLaunch = new NativeStorageObservableCache(
				this.nativeStorage,
				storageKeys.numberOfAppLaunch,
				0,
			);
			this.$backup = new NativeStorageObservableCache(
				this.nativeStorage,
				storageKeys.hasBackupStatusKey,
				[],
			);

			this.initHasBackup();
		});
	}

	private initHasBackup() {
		this.$secureStorage
			.pipe(
				mergeMap((secureStorage) =>
					secureStorage.get(storageKeys.hasBackup).catch(() => 'false'),
				),
				map((data) => {
					this.$hasBackup.next(data);
				}),
				take(1),
			)
			.subscribe();
	}

	public setPinCode = (value: string): Observable<any> => {
		return this.$pinCodeStorage.pipe(mergeMap((d) => d.set(value)));
	};

	public isPinCodeValid(pinCode: string): Observable<boolean> {
		return this.$pinCodeStorage.pipe(
			mergeMap((pinCodeStorage) => pinCodeStorage.getOnce()),
			map((pinCodeStored) => pinCodeStored === pinCode),
		);
	}

	public getHasBackup(): Observable<boolean> {
		return this.$hasBackup.pipe(map((data) => data === 'true'));
	}

	public setHasBackup(bool = true) {
		return this.$secureStorage
			.pipe(
				mergeMap((storage) => storage.set(storageKeys.hasBackup, String(bool))),
				map(() => {
					this.$hasBackup.next(String(bool));
				}),
				take(1),
			)
			.subscribe();
	}

	public hasDevTools(): Observable<boolean> {
		return this.$devToolStorage
			.getOnce()
			.pipe(map((value) => JSON.parse(value)));
	}

	public setHaveDevTools(hasDevTools = true) {
		return this.$devToolStorage.set(hasDevTools);
	}

	public hasAdvancedTools(): Observable<boolean> {
		return this.$advancedToolStorage
			.getOnce()
			.pipe(map((value) => JSON.parse(value)));
	}

	public setAdvancedTools(hasAdvancedTools = true) {
		return this.$advancedToolStorage.set(hasAdvancedTools);
	}

	public initSecureStorage() {
		return this.secureStorage
			.create(environment.secureStorageKey)
			.then((secuStore) => {
				return this.$secureStorage.next(secuStore);
			});
	}

	public hasOnboarded(): Observable<boolean> {
		return this.$hasOnBoardedStorage.pipe(
			mergeMap((storage) => storage.get()),
			map((data) => data !== null && data !== undefined),
		);
	}

	public setOnBoardingStatus() {
		return this.$hasOnBoardedStorage
			.pipe(
				mergeMap((storage) => storage.set('true')),
				take(1),
			)
			.subscribe();
	}

	public getMnemonicV1(): Observable<string> {
		return this.$secureStorage.pipe(
			mergeMap((secureStorageObject) =>
				from<Promise<string>>(
					secureStorageObject
						.get(storageKeys.mnemonicV1)
						.then((value) => JSON.parse(value))
						.then((value) => value.signingKey.mnemonic)
						.catch((err) => undefined),
				),
			),

			mergeMap((mnemonic) => {
				if (mnemonic) {
					return this.setMnemonic(mnemonic).pipe(
						map(() => {
							return mnemonic;
						}),
					);
				} else {
					return of(undefined);
				}
			}),
		);
	}

	public getMnemonicV2(): Observable<string> {
		return this.$secureStorage.pipe(
			mergeMap((secureStorageObject) =>
				from<Promise<string>>(
					secureStorageObject
						.get(this.mnemonicKey)
						.then((value) => value)
						.catch(() => undefined),
				),
			),
		);
	}

	public getEncryptedMnemonic(): Observable<string> {
		return this.getMnemonic().pipe(
			map((mnemonic) =>
				CryptoJS.AES.encrypt(mnemonic, environment.backupSecret).toString(),
			),
		);
	}

	public decryptMnemonic(encryptedMnenmonic): string {
		return CryptoJS.AES.decrypt(
			encryptedMnenmonic,
			environment.backupSecret,
			{},
		).toString(CryptoJS.enc.Utf8);
	}

	public getMnemonic(): Observable<string> {
		return this.getMnemonicV2().pipe(
			mergeMap((mnemonic) => {
				if (!mnemonic) {
					return this.getMnemonicV1();
				} else {
					return new BehaviorSubject(mnemonic);
				}
			}),
		);
	}

	public setMnemonic(mnemonic: string): Observable<boolean> {
		const isValidMnemonic = ethers.utils.isValidMnemonic(mnemonic);
		if (!isValidMnemonic) {
			throw new Error('no possible to save mnemonic not valid');
		}

		return this.$secureStorage.pipe(
			mergeMap((secureStorageObject) =>
				from<Promise<boolean>>(
					secureStorageObject
						.set(this.mnemonicKey, mnemonic)
						.catch(() => false)
						.then(() => true),
				),
			),
		);
	}

	public clearSecureStorage() {
		return this.$secureStorage.pipe(
			mergeMap((secureStorage) => {
				return from<Promise<any>>(secureStorage.clear());
			}),
		);
	}

	public getBackups(): Observable<
		{
			address: string;
			type: backupType;
			email?: string;
			date: number;
			isBackedUp: boolean;
		}[]
	> {
		return this.$backup.getOnce();
	}

	public setBackup(
		date: number,
		address: string,
		isBackedUp: boolean,
		type: backupType,
		email?: string,
	): Observable<any> {
		return this.getBackups().pipe(
			map((backups) => {
				const existingBackupIndex = backups.findIndex(
					(item) => item.address === address && item.type === type,
				);
				const backupToSave = {
					address,
					type,
					email: email || undefined,
					date,
					isBackedUp,
				};

				if (existingBackupIndex > -1) {
					backups[existingBackupIndex] = backupToSave;
				} else {
					backups.push(backupToSave);
				}

				return backups;
			}),
			mergeMap((backups) => this.$backup.set(backups)),
			take(1),
		);
	}
}
