import { Observable, ReplaySubject } from 'rxjs';
import { NativeStorage } from '@ionic-native/native-storage/ngx';
import { take } from 'rxjs/operators';

export class NativeStorageObservableCache<ValueType=any> {
    private _$store:ReplaySubject<ValueType>=new ReplaySubject(1);
    private fetched=false;
    private lastValue:ValueType;

    constructor (private storage:NativeStorage, private key:string, private defaultValue = undefined) {
      this.$store
        .subscribe(lastValue => {
          this.lastValue = lastValue;
        });
    }

    get $store () {
      return this._$store;
    }

    get ():Observable<ValueType> {
      if (!this.fetched) {
        this.fetched = true;
        this.storage.getItem(this.key)
          .catch(i => this.defaultValue)
          .then(value => this.$store.next(value));
      }

      return this.$store;
    }

    getOnce ():Observable<ValueType> {
      return this.get().pipe(take(1));
    }

    getValue () {
      if (!this.fetched) {
        throw new Error('this value has never be get before');
      }

      return this.lastValue;
    }

    set (value:ValueType):Observable<ValueType> {
      this.fetched = true;
      this.storage.setItem(this.key, value);
      this.$store.next(value);
      return this.$store;
    }
}
