import { Injectable } from '@angular/core';
import { Arianee, NETWORK } from '@arianee/arianeejs';
import { ArianeeWallet } from '@arianee/arianeejs/dist/src/core/wallet';
import { ArianeeWalletBuilder } from '@arianee/arianeejs/dist/src/core/wallet/walletBuilder';
import { Platform } from '@ionic/angular';
import { Observable, ReplaySubject, from, of } from 'rxjs';
import { map, mergeMap, take, tap } from 'rxjs/operators';

import { environment } from '../../../environments/environment';
import { ChainType } from '../../types/multichain';
import { AuthJWTService } from '../authJWT-service/authJWT.service';
import { ProxyService } from '../proxy-service/proxy-service';
import { StorageService } from '../storage-service/storage.service';
import { UserService } from '../user-service/user.service';
import { getNetworkOfType } from '../wallet-connect-service/chain-utils/chain-utils';

type MapToObservable<
	T extends Record<string, (...args: any[]) => Promise<any>>,
> = {
	[P in keyof T]: T[P] extends (...args: infer P) => Promise<infer R>
		? (...args: P) => Observable<R>
		: never;
};

@Injectable({
	providedIn: 'root',
})
export class ArianeeService {
	private connectedArianee: ArianeeWalletBuilder;

	public $address: ReplaySubject<string> = new ReplaySubject<string>(1);
	// is emited when after a wallet with a new network is initialized
	public $walletInitialize: ReplaySubject<NETWORK> =
		new ReplaySubject<NETWORK>();

	private wallets: Partial<Record<NETWORK, Promise<ArianeeWallet>>> = {};

	constructor(
		private userService: UserService,
		private storageService: StorageService,
		private platform: Platform,
		private arianeeHttpInterceptor: ProxyService,
		private authJWT: AuthJWTService,
	) {
		this.platform.ready().then(() => {
			this.initialize().subscribe();
		});
	}

	public async getWalletInstance(network: NETWORK): Promise<ArianeeWallet> {
		if (!this.wallets[network]) {
			this.wallets[network] = new Promise(async (resolve, reject) => {
				const arianee = await new Arianee().init(network, {
					transactionOptions: environment.transactionOptions,
					httpProvider: environment.blockchainProvider[network],
					blockchainProxy: {
						...environment.blockchainProxy,
					},
					httpInterceptor: {
						httpRequestInterceptor:
							this.arianeeHttpInterceptor.requestInterceptor,
					},
				});

				const mnemonic = await this.userService
					.getMnemonic()
					.pipe(take(1))
					.toPromise();
				const wallet = mnemonic
					? arianee.fromMnemonic(mnemonic)
					: arianee.readOnlyWallet();

				const currConfiguration = wallet.globalConfiguration.defaultQuery;

				currConfiguration.advanced = { languages: [...navigator.languages] };
				currConfiguration.issuer = {
					waitingIdentity: environment.features.waitingIdentity,
					forceRefresh: false,
				};

				wallet.globalConfiguration.setDefaultQuery(currConfiguration);
				resolve(wallet);
			});
		}

		const wallet = await this.wallets[network];

		if (environment.appType !== 'web') {
			if (
				wallet.mnemnonic !==
				(await this.userService.getMnemonic().pipe(take(1)).toPromise())
			) {
				this.wallets = {};
				return this.getWalletInstance(network);
			}
		}

		return wallet;
	}

	public switchChainType(chainType: ChainType): void {
		this.userService.$chainType.set(chainType);

		this.$walletInitialize.next(getNetworkOfType(chainType));
	}

	public switchWallet(mnemonic: string) {
		return this.userService
			.setMnemonic(mnemonic)
			.pipe(mergeMap(() => this.initializeWallet()));
	}

	public switchWaitingIdentity(isWaitingIdentity: boolean): Observable<any> {
		return this.$walletInitialize.pipe(
			take(1),
			mergeMap((network) => {
				Object.entries(this.wallets).forEach(
					async ([network, walletPromise]) => {
						const wallet = await walletPromise;
						const currConfiguration = wallet.globalConfiguration.defaultQuery;
						currConfiguration.issuer = { waitingIdentity: isWaitingIdentity };
						wallet.globalConfiguration.setDefaultQuery(currConfiguration);
					},
				);
				return of(true);
			}),
		);
	}

	initialize = () => {
		return this.initializeArianee().pipe(
			mergeMap(() => this.initializeWallet()),
		);
	};

	private initializeWallet = (): Observable<any> => {
		return this.userService.getMnemonic().pipe(
			mergeMap((mnemonic) => {
				if (environment.appType === 'web') {
					return of(this.connectedArianee.readOnlyWallet());
				} else if (mnemonic) {
					return from(this.getWalletInstance(NETWORK.mainnet));
				} else {
					const wallet = this.connectedArianee.fromRandomMnemonic();
					return this.userService
						.setMnemonic(wallet.mnemnonic)
						.pipe(map((success) => wallet));
				}
			}),
			tap((wallet) => {
				const currConfiguration = wallet.globalConfiguration.defaultQuery;

				currConfiguration.advanced = {
					languages: [...navigator.languages],
				};

				currConfiguration.issuer = {
					waitingIdentity: environment.features.waitingIdentity,
					forceRefresh: false,
				};
				wallet.globalConfiguration.setDefaultQuery(currConfiguration);
			}),
			tap((wallet) => {
				this.$address.next(wallet.address);
				this.$walletInitialize.next(environment.network);
			}),
			take(1),
		);
	};

	private initializeArianee = (): Observable<any> => {
		const arianee = new Arianee();
		if (environment.appType !== 'web') {
			arianee.setStore({
				getStoreItem: (storeKey: string) =>
					this.storageService.nativeStorage.getItem(storeKey).then((result) => {
						return result;
					}),
				hasItem: (storeKey: string): Promise<boolean> => {
					return this.storageService.nativeStorage
						.getItem(storeKey)
						.then((result) => {
							return true;
						})
						.catch((e) => {
							return false;
						});
				},
				setStoreItem: (key, value) => {
					return this.storageService.nativeStorage.setItem(key, value);
				},
			});
		}
		return this.userService.$chainType.get().pipe(
			mergeMap((chain) =>
				from(
					arianee.init(getNetworkOfType(chain), {
						transactionOptions: environment.transactionOptions,
						httpProvider:
							environment.blockchainProvider[getNetworkOfType(chain)],
						blockchainProxy: {
							host: environment.blockchainProxy.host,
							enable: environment.blockchainProxy.enable,
						},
						httpInterceptor: {
							httpRequestInterceptor:
								this.arianeeHttpInterceptor.requestInterceptor,
						},
					}),
				),
			),
			tap((connectedArianee) => {
				this.connectedArianee = connectedArianee;
			}),
		);
	};

	private mapArianeeMethodPromiseToObservable = <
		T extends Record<string, (...args: any[]) => any>,
	>(
		methods: T,
	) => {
		return Object.keys(methods).reduce((acc, key) => {
			acc[key] = function (...args: any[]) {
				return from(methods[key].call(this, ...args));
			};

			if (key === 'createActionJWTProofLink') {
				acc[key] = function (...args: any[]) {
					return of(methods[key].call(this, ...args));
				};
			}
			return acc;
		}, {} as Record<string, (...args: any[]) => Observable<any>>) as any as MapToObservable<T>;
	};
}
