export const getYoutubeMedia = (media) => {
	media.ytId = extractYoutubeId(media.url);
	media.thumbnail = getYoutubeThumbnail(media.ytId);
	media.embedURL = getYoutubeEmbedUrl(media.ytId);

	return media;
};

export const extractYoutubeId = (url: string) => {
	const match = url.match(
		/(?:youtu\.be\/|youtube\.com(?:\/embed\/|\/v\/|\/watch\?(?:app=desktop&)?v=|\/user\/\S+|\/ytscreeningroom\?v=|\/sandalsResorts#\w\/\w\/.*\/))([^/&?]{10,12})/,
	);
	return match[1];
};

export const getYoutubeThumbnail = (ytId: string) => {
	return `https://img.youtube.com/vi/${ytId}/0.jpg`;
};

export const getYoutubeEmbedUrl = (ytId: string) => {
	return `https://www.youtube.com/embed/${ytId}`;
};
