import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';

import { Icon } from '../icon/models/icon.model';

@Component({
	selector: 'app-modal-nft-properties',
	templateUrl: './modal-nft-properties.component.html',
	styleUrls: ['./modal-nft-properties.component.scss'],
})
export class ModalNftPropertiesComponent implements OnInit {
	@Input() header: { title: string; icon: Icon };
	@Input() isWeb: boolean;
	@Input() properties: any;

	public modal: HTMLIonModalElement;
	constructor(private modalCtrl: ModalController) {}

	async ngOnInit() {
		this.modal = await this.modalCtrl.getTop();
	}

	onCloseModal() {
		this.modalCtrl.dismiss({
			dismissed: true,
		});
	}
}
