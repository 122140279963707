import {
	AfterViewInit,
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	EventEmitter,
	Input,
	OnChanges,
	OnInit,
	Output,
	SimpleChanges,
	ViewChild,
	ViewEncapsulation,
} from '@angular/core';
import { LoaderService } from '@arianeeprivate/wallet-shared-components';
import { PreviewAnyFile } from '@ionic-native/preview-any-file/ngx';
import { BehaviorSubject } from 'rxjs';
import SwiperCore, { Navigation, Pagination } from 'swiper';
import { SwiperComponent } from 'swiper/angular';
import { PaginationOptions } from 'swiper/types';

import { MediaItemCarousel } from './models/media-item-carousel.model';

// install Swiper components
SwiperCore.use([Navigation, Pagination]);

@Component({
	selector: 'app-media-carousel',
	templateUrl: 'media-carousel.component.html',
	styleUrls: ['media-carousel.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	encapsulation: ViewEncapsulation.None,
})
export class MediaCarouselComponent
	implements OnInit, OnChanges, AfterViewInit
{
	@Input() carouselItems: MediaItemCarousel[];
	@Input() isFullscreen = false;
	@Input() currentMediaIndex: number = 0;
	@Input() itemBackgroundPicture: string;

	@Output() openFullscreen: EventEmitter<{
		isFullscreen: boolean;
		currentMediaIndex: number;
	}> = new EventEmitter<{ isFullscreen: boolean; currentMediaIndex: number }>();

	@Output() currentMediaIndexChange: EventEmitter<number> =
		new EventEmitter<number>();

	@ViewChild('swiper', { static: false }) swiper?: SwiperComponent;
	currentMedia: MediaItemCarousel;

	primaryARUrl: string = undefined;

	slides$ = new BehaviorSubject<MediaItemCarousel[]>([]);

	paginationOptions: PaginationOptions;

	constructor(
		private cdr: ChangeDetectorRef,
		private previewAnyFile: PreviewAnyFile,
		private loaderService: LoaderService,
	) {}

	ngOnInit(): void {
		if (this.carouselItems.length === 0) {
			return;
		}
		this.paginationOptions = {
			el: '.bullets',
			dynamicBullets: true,
		};
		this.currentMedia = this.carouselItems[0];
		this.primaryARUrl = this.handlePrimaryArAsset();
	}

	ngOnChanges(changes: SimpleChanges) {
		if (changes.carouselItems) {
			changes.carouselItems.currentValue =
				changes.carouselItems.currentValue.filter(
					(item) => item.type !== 'ARPrimaryAsset' && !!item.url,
				);
			this.slides$.next(changes.carouselItems.currentValue);
		}

		if (
			changes.currentMediaIndex &&
			changes.currentMediaIndex.currentValue !== undefined
		) {
			if (this.swiper && this.swiper.swiperRef) {
				this.swiper.swiperRef.slideTo(changes.currentMediaIndex.currentValue);
			}
		}
	}

	ngAfterViewInit() {
		if (this.swiper.swiperRef) {
			this.slides$.next(this.carouselItems);
			this.cdr.detectChanges();

			this.swiper.swiperRef.slideTo(this.currentMediaIndex);
			if (
				this.carouselItems.length > 0 &&
				(this.carouselItems[0].type === '3dModel' ||
					this.carouselItems.length === 1)
			) {
				this.swiper.swiperRef.allowTouchMove = false;
			}
		}
	}

	onSlideChange(swiper) {
		this.currentMedia = this.carouselItems[swiper.activeIndex];
		this.currentMediaIndex = swiper.activeIndex;
		if (this.swiper.swiperRef) {
			this.swiper.swiperRef.allowTouchMove = false;
		}
		if (
			this.swiper.swiperRef &&
			this.currentMedia &&
			this.currentMedia.type !== '3dModel'
		) {
			this.swiper.swiperRef.allowTouchMove = true;
		}

		this.currentMediaIndexChange.emit(this.currentMediaIndex);

		this.cdr.detectChanges();
	}

	goNext() {
		this.swiper.swiperRef.slideNext();
	}

	goPrev() {
		this.swiper.swiperRef.slidePrev();
	}

	onArClick() {
		const ARUrl =
			this.currentMedia.type === 'AR'
				? this.currentMedia.url
				: this.primaryARUrl;

		this.loaderService.showLoader();
		this.previewAnyFile
			.preview(ARUrl)
			.then(() => {
				setTimeout(() => {
					this.loaderService.dismiss();
				}, 5000);
			})
			.catch((error) => {
				console.warn('error while opening AR ', error);
				this.loaderService.dismiss();
			});
	}

	onOpenFullscreen(payload: {
		isFullscreen: boolean;
		currentMediaIndex: number;
	}) {
		this.openFullscreen.emit({
			isFullscreen: payload.isFullscreen,
			currentMediaIndex: payload.currentMediaIndex,
		});
	}

	private handlePrimaryArAsset() {
		if (!this.carouselItems.find((item) => item.type === 'ARPrimaryAsset')) {
			return undefined;
		}
		let url;
		this.carouselItems = this.carouselItems.filter((item) => {
			if (item.type === 'ARPrimaryAsset') {
				url = item.url;
				return false;
			}
			return true;
		});
		return url;
	}
}
