import { IdentityBase } from '@arianee/arianeejs/dist/src/models';

export interface CollectionItem {
	brandAddress: string;
	brandName: string;
	brandHomePicture: string;
	brandLogoHeader: string;
	brandLogoSquare: string;
	count: number;
}

/**
 * Maps an array of issuers and their respective certificates count to an array of collection items.
 * @param issuers An array of issuers and their respective certificates count.
 * @returns An array of collection items.
 */
export function collectionListMapper(
	issuers: { issuer: IdentityBase; certificatesCount: number }[],
): CollectionItem[] {
	return (issuers || [])
		.filter((issuer) => issuer && issuer.issuer !== undefined)
		.map(mapIssuerToCollectionItem);
}

/**
 * Maps an issuer object to a collection item object.
 * @param issuer An issuer object containing an IdentityBase object and a certificates count.
 * @returns A collection item object.
 */
function mapIssuerToCollectionItem(issuer: {
	issuer: IdentityBase;
	certificatesCount: number;
}): CollectionItem {
	if (issuer.issuer.data === undefined) {
		return undefined;
	}
	return {
		brandAddress: issuer.issuer.address,
		brandName: issuer.issuer.data.name,
		brandHomePicture: findPictureByType(
			issuer.issuer.data.pictures,
			'brandHomePicture',
		),
		brandLogoHeader: findPictureByType(
			issuer.issuer.data.pictures,
			'brandLogoHeader',
		),
		brandLogoSquare: findPictureByType(
			issuer.issuer.data.pictures,
			'brandLogoSquare',
		),
		count: issuer.certificatesCount,
	};
}

/**
 * Finds a picture object in an array of picture objects by its type property.
 * @param pictures An array of picture objects.
 * @param type The type of the picture to find.
 * @returns The URL of the picture, or undefined if the picture was not found.
 */
function findPictureByType(
	pictures: { type: string; url: string }[],
	type: string,
): string | undefined {
	if (!pictures) {
		return undefined;
	}
	const picture = pictures.find((picture) => picture.type === type);
	return picture ? picture.url : undefined;
}
