import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { take } from 'rxjs/operators';
import { AppVersion } from '@ionic-native/app-version/ngx';
import { Platform } from '@ionic/angular';
import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs';
import { BrowserService } from '../inapp-browser/inapp-browser-service';
import { TranslateService } from '@ngx-translate/core';
import { ToasterService } from '@arianeeprivate/wallet-shared-components';

@Injectable({
  providedIn: 'root'
})
export class WalletVersionService {
  constructor (private http:HttpClient,
               private platform:Platform,
               private appVersion: AppVersion,
               private toasterService:ToasterService,
               private inAppBrowser:BrowserService,
               private translateService:TranslateService
  ) {

  }

  public async check ():Promise<any> {
    await this.platform.ready();

    return new Promise((resolve, reject) => {
      this.walletVersionRequirements()
        .pipe(take(1))
        .subscribe(async (response) => {
          const versionsToCompare = this.platform.is('ios') ? response.ios : response.android;

          const isOlderThanRequired = await this.isVersionOlderThan(versionsToCompare.version.required);
          const isOlderThanrecommended = await this.isVersionOlderThan(versionsToCompare.version.recommended);

          const openStoreHandler = () => {
            const appStoreUrl = this.platform.is('ios')
              ? environment.appURL.appleStore
              : environment.appURL.playStore;

            this.inAppBrowser.openBrowserSystem(appStoreUrl);
            return false;
          };

          if (isOlderThanRequired) {
            this.toasterService.alert({
              backdropDismiss: false,
              message: 'VersionApp.olderThanRequired',
              buttons: [
                {
                  text: this.translateService.instant('VersionApp.ok'),
                  role: 'cancel',
                  cssClass: 'secondary',
                  handler: openStoreHandler
                }]
            })
              .pipe(take(1))
              .subscribe(d => resolve('isOlderThanRequired'));
          } else if (isOlderThanrecommended) {
            this.toasterService.alert({
              message: 'VersionApp.olderThanrecommended',
              buttons: [
                {
                  text: this.translateService.instant('VersionApp.ok'),
                  role: 'cancel',
                  cssClass: 'secondary',
                  handler: openStoreHandler
                },
                {
                  text: this.translateService.instant('VersionApp.later'),
                  role: 'cancel',
                  cssClass: 'secondary'
                }
              ]
            })
              .pipe(take(1))
              .subscribe(d => resolve('isOlderThanRecommended'));
          } else {
            resolve('ok');
          }
        });
    });
  }

  public walletVersionRequirements ():Observable<any> {
    return this.http.get(environment.walletVersionUrl);
  }

  public async isVersionOlderThan (versionToCompare:string):Promise<boolean> {
    const currVersion = await this.appVersion.getVersionNumber();
    return this.versionCompare(currVersion, versionToCompare) === 'older';
  }

  public versionCompare (v1, v2, options?):string {
    var lexicographical = options && options.lexicographical;
    var zeroExtend = options && options.zeroExtend;
    var v1parts = v1.split('.');
    var v2parts = v2.split('.');

    function isValidPart (x) {
      return (lexicographical ? /^\d+[A-Za-z]*$/ : /^\d+$/).test(x);
    }

    if (!v1parts.every(isValidPart) || !v2parts.every(isValidPart)) {
      return 'notValid';
    }

    if (zeroExtend) {
      while (v1parts.length < v2parts.length) v1parts.push('0');
      while (v2parts.length < v1parts.length) v2parts.push('0');
    }

    if (!lexicographical) {
      v1parts = v1parts.map(Number);
      v2parts = v2parts.map(Number);
    }

    for (var i = 0; i < v1parts.length; ++i) {
      if (v2parts.length === i) {
        return 'newer';
      }

      if (v1parts[i] === v2parts[i]) {
        continue;
      } else if (v1parts[i] > v2parts[i]) {
        return 'newer';
      } else {
        return 'older';
      }
    }

    if (v1parts.length !== v2parts.length) {
      return 'older';
    }

    return 'same';
  }
}
