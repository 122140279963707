export enum NftStatusType {
	SUCCESS = 'success',
	ERROR = 'error',
}
export interface NftStatus {
	type: NftStatusType;
	message: string;
	label: string;
	icon: string;
}
