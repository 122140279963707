import { Injectable } from '@angular/core';
import { Lens } from 'lens-protocol';

@Injectable({
	providedIn: 'root',
})
export class LensService {
	public async getProfilesByHandle(handle: string): Promise<string | null> {
		if (!handle.endsWith('.lens')) {
			return null;
		}

		try {
			const profile = (await Lens.profileByHandle(handle)) as any;
			return profile.data.profile.ownedBy;
		} catch (e) {
			return null;
		}
	}
}
