import {
	Component,
	EventEmitter,
	Input,
	OnDestroy,
	OnInit,
	Output,
} from '@angular/core';
import { ProductListItem } from '@arianeeprivate/wallet-shared-components';
import { Subscription } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';

import { PendingNftService } from '../../providers/pending-nft-service/pending-nft.service';
import { UserService } from '../../providers/user-service/user.service';
import { ChainType } from '../../types/multichain';

@Component({
	selector: 'app-brand-nft-list',
	templateUrl: './brand-nft-list.component.html',
	styleUrls: ['./brand-nft-list.component.scss'],
})
export class BrandNftListComponent implements OnInit, OnDestroy {
	@Input() isLoading: boolean;
	@Input() brandId: string;
	@Input() brandsCount = 0;
	@Input() brandName: string;
	@Input() brandLogo: string;
	@Input() subProducts: Array<ProductListItem> = [undefined, undefined];

	@Output() brandClick = new EventEmitter();
	@Output() productClick = new EventEmitter<string>();
	chainType: ChainType;
	private subscriptions: Subscription[] = [];

	constructor(
		public pendingNftService: PendingNftService,
		private userService: UserService,
	) {}

	ngOnInit() {
		this.setChainType();
	}

	public get scannedProduct() {
		return this.subProducts.filter(
			(product) => product !== undefined && product.scanned,
		);
	}

	public get notScannedProduct() {
		return this.subProducts.filter(
			(product) => product !== undefined && !product.scanned,
		);
	}

	private setChainType() {
		const chainTypeSub = this.userService.$chainType
			.get()
			.pipe(distinctUntilChanged())
			.subscribe((chainType) => {
				this.chainType = chainType;
			});

		this.subscriptions.push(chainTypeSub);
	}

	ngOnDestroy() {
		this.subscriptions.forEach((sub) => sub.unsubscribe());
	}
}
