import { ErrorHandler, Injectable } from '@angular/core';
import { AppVersion } from '@ionic-native/app-version/ngx';
import { Platform } from '@ionic/angular';
import * as Sentry from '@sentry/browser';
import { BrowserTracing } from '@sentry/tracing';

import { environment } from '../../../environments/environment';
import { ProfileService } from '../profile-service/profile.service';
import { SlackService } from '../slack-service/slack-service';

@Injectable({
	providedIn: 'root',
})
export class SentryErrorHandler implements ErrorHandler {
	isInit: Promise<any>;

	constructor(
		private slackService: SlackService,
		private appVersion: AppVersion,
		private profileService: ProfileService,
		private platform: Platform,
	) {
		this.isInit = this.init();
	}

	public sentry = Sentry;
	async init(): Promise<void> {
		await this.platform.ready();

		return new Promise((resolve) => {
			this.profileService.getProfile().subscribe((profile) => {
				Sentry.init({
					dsn: environment.sentry.dsn,
					release: profile.buildNb as string,
					environment: environment.environment,
					integrations: [new BrowserTracing()],
				});
				Sentry.configureScope(async (scope) => {
					Object.keys(profile).forEach((key) => {
						return scope.setTag(key, profile[key]);
					});
				});
			});
			resolve();
		});
	}

	async handleError(error) {
		if (error && error.status !== 404 && environment.environment === 'prod') {
			await this.isInit;

			const isAndroid = this.platform.is('android') ? 'android' : null;
			const isIos = this.platform.is('ios') ? 'ios' : null;

			const platform = isAndroid || isIos || '?web?';
			const eventId = Sentry.captureException(error.originalError || error);
		} else {
			console.error(error);
		}
	}
}
