import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'backgroundStyle'
})
export class BackgroundStylePipe implements PipeTransform {
  transform (item): { [key: string]: string } {
    if (item.type === 'video' || item.type === 'youtube') {
      return { background: '#000' };
    } else {
      return { background: '#EEEFF2' };
    }
  }
}
