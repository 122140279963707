import { Component, Input } from '@angular/core';
import { Subject } from 'rxjs/internal/Subject';

type Suggestion = string;

type MnemonicSuggestionsProps = {
	dictionary: string[];
	showSuggestionsFor: string;
	$suggestion: Subject<Suggestion>;
};

@Component({
	selector: 'app-mnemonic-suggestions',
	templateUrl: './mnemonic-suggestions.component.html',
	styleUrls: ['./mnemonic-suggestions.component.scss'],
})
export class MnemonicSuggestionsComponent implements MnemonicSuggestionsProps {
	@Input() dictionary: string[];
	@Input() showSuggestionsFor: string;
	@Input() $suggestion: Subject<string>;

	/**
	 * Returns the first 20 suggestions that start with the current input
	 */
	public get suggestions(): string[] {
		return this.dictionary
			.filter((word) => word.startsWith(this.showSuggestionsFor))
			.slice(0, 20);
	}

	public onClickSuggestion(suggestion: Suggestion) {
		this.$suggestion.next(suggestion);
	}
}
