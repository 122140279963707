import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  Renderer2,
  ViewChild
} from '@angular/core';

@Component({
  selector: 'claim-slider',
  templateUrl: './claim-slider.component.html',
  styleUrls: ['./claim-slider.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ClaimSliderComponent implements OnInit, AfterViewInit, OnDestroy {
    @Input() label: string;
    @Input() loading: { label: string, isLoading: boolean };
    @Output() onSwipeComplete: EventEmitter<boolean> = new EventEmitter();

    @ViewChild('swiper', { static: false }) swiper: ElementRef;
    @ViewChild('swiperContainer', { static: false }) swiperContainer: ElementRef;

    private hasStarted = false;
    private originFinger;

    constructor (
        private renderer2: Renderer2
    ) {
    }

    ngOnInit () {
    }

    ngAfterViewInit () {
    }

    ngOnDestroy () {
    }

    onSlide = (event) => {
      if (this.hasStarted) {
        const sliderSize = this.swiper.nativeElement.getBoundingClientRect().width;
        const newValue = (event.clientX || event.touches[0].clientX) - this.originFinger;
        const maxValue = this.swiperContainer.nativeElement.getBoundingClientRect().width - sliderSize - 16;

        if (newValue >= maxValue) {
          this.onSwipeComplete.emit(true);
          this.reset();
          return;
        }

        if (newValue > 0 && newValue < maxValue) {
          this.swiper.nativeElement.style.transform = `translateX(${newValue}px)`;
        }
      }
    };

    start = (event) => {
      this.renderer2.removeClass(this.swiper.nativeElement, 'slider-reset');
      this.hasStarted = true;
      this.originFinger = event.clientX || event.touches[0].clientX;
    };

    reset = () => {
      this.hasStarted = false;
      this.renderer2.addClass(this.swiper.nativeElement, 'slider-reset');
      this.swiper.nativeElement.style.transform = `translateX(${0}px)`;
    };
}
