import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import {
	BackCertComponent,
	ToasterService,
} from '@arianeeprivate/wallet-shared-components';
import { ModalController, NavController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { map, take, tap } from 'rxjs/operators';

import { BackupService } from '../../../../../providers/backup-service/backup.service';
import { EventLoggerService } from '../../../../../providers/event-logger/event-logger-service';
import { UserService } from '../../../../../providers/user-service/user.service';

@Component({
	selector: 'app-backup-viewer2-legacy',
	templateUrl: './backup-viewer2-legacy.component.html',
	styleUrls: ['./backup-viewer2-legacy.component.scss'],
})
export class BackupViewer2LegacyComponent implements OnInit {
	mnemonic: string;
	words = [];
	selectedWords = [];
	notGoodMnemonic = false;

	wordsCount = 12;

	constructor(
		private navCtrl: NavController,
		private location: Location,
		private userService: UserService,
		private modalCtrl: ModalController,
		private toasterService: ToasterService,
		private translate: TranslateService,
		private eventLogger: EventLoggerService,
		private backupService: BackupService,
	) {}

	ngOnInit() {
		this.eventLogger.logScreen('backup-viewer2-legacy', {});

		this.userService
			.getMnemonic()
			.pipe(
				tap((mnemonic) => {
					this.mnemonic = mnemonic;
				}),
				map((mnemonics) => mnemonics.split(' ')),
				map((words) => this.shuffleWords(words)),
				tap((shuffleArray) => {
					this.words = shuffleArray;
				}),
			)
			.subscribe();
	}

	shuffleWords(words) {
		let word;

		for (let i = words.length - 1; i > 0; i--) {
			const j = Math.floor(Math.random() * i);
			const temp = words[i];
			words[i] = words[j];
			words[j] = temp;
		}

		return words;
	}

	addSelectedWords(word) {
		this.selectedWords.push(word);

		const wordIndex = this.words.indexOf(word);

		this.words.splice(wordIndex, 1);

		if (this.selectedWords.length === 12) {
			this.checkMnemonic();
		}
	}

	delete(word) {
		this.words.push(word);

		const wordIndex = this.selectedWords.indexOf(word);

		this.selectedWords.splice(wordIndex, 1);
	}

	checkMnemonic() {
		if (this.selectedWords.join(' ') === this.mnemonic) {
			this.notGoodMnemonic = false;
			this.showBackUpCertModal();
		} else {
			this.showErrorToaser();
		}
	}

	async showBackUpCertModal() {
		const certModal = await this.modalCtrl.create({
			component: BackCertComponent,
			cssClass: 'auto-height bottom half-round',
		});
		await certModal.present();
		this.backupService
			.setBackup(Date.now(), true, 'manual')
			.pipe(take(1))
			.subscribe();
	}

	showErrorToaser() {
		this.toasterService.showRedesigned({
			title: 'Backup.notGoodMnemonic',
			color: 'danger',
			icon: 'cancel',
			position: 'top',
			duration: 2500,
		});
	}

	onClickConfirmSort() {
		this.navCtrl.navigateForward('/import-wallet');
	}

	onClickNavBack() {
		this.location.back();
	}
}
