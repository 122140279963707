import {
	AfterViewInit,
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	ElementRef,
	Input,
	OnChanges,
	OnDestroy,
	SimpleChanges,
	ViewChild,
} from '@angular/core';

import { MediaItemCarousel } from '../../models/media-item-carousel.model';

@Component({
	selector: 'app-video-media',
	templateUrl: './video-media.component.html',
	styleUrls: ['./video-media.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VideoMediaComponent
	implements OnChanges, AfterViewInit, OnDestroy
{
	@Input() media: MediaItemCarousel;
	@Input() isCurrentVideo: boolean;
	@Input() crop: boolean = true;

	@ViewChild('video', { static: false }) video: ElementRef<HTMLVideoElement>;

	croppedStyle: {
		height?: string;
		'max-height'?: string;
		width?: string;
		'max-width'?: string;
	} = {};

	nonCroppedStyle = {
		width: '100%',
		height: '100%',
		'max-width': '100%',
		'max-height': '100%',
	};

	loadedMetadataListener: EventListener;

	constructor(private changeDetectorRef: ChangeDetectorRef) {}

	ngOnChanges(changes: SimpleChanges) {
		setTimeout(() => {
			const video = document.getElementById(
				'video-' + this.media.url,
			) as HTMLVideoElement;
			if (this.isCurrentVideo) {
				video.play();
			} else {
				video.pause();
			}
		}, 200);
	}

	ngAfterViewInit() {
		this.updateSwiper();
		this.setVideoStyle();
	}

	ngOnDestroy(): void {
		if (this.loadedMetadataListener) {
			this.video.nativeElement.removeEventListener(
				'loadedmetadata',
				this.loadedMetadataListener,
			);
		}
	}

	private setVideoStyle() {
		this.loadedMetadataListener = (e) => {
			const { videoWidth, videoHeight } = this.video.nativeElement;
			const aspectRatio = videoWidth / videoHeight;

			if (aspectRatio < 1) {
				this.croppedStyle = {
					width: '100%',
					'max-width': '100%',
					height: 'auto',
					'max-height': 'none',
				};
			} else {
				this.croppedStyle = {
					height: '100%',
					'max-height': '100%',
					width: 'auto',
					'max-width': 'none',
				};
			}

			this.changeDetectorRef.detectChanges();
		};

		this.video.nativeElement.addEventListener(
			'loadedmetadata',
			this.loadedMetadataListener,
			false,
		);
	}

	private updateSwiper() {
		setTimeout(() => {
			const swiperWrapper = document.querySelector(
				'.swiper-container',
			) as HTMLElement;
			swiperWrapper.style.backgroundColor = '#000';
		});
	}
}
