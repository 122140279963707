import { IdentityBase } from '@arianee/arianeejs/dist/src/models';
import { POAPNFT } from '@arianeeprivate/wallet-shared-components';
import { get } from 'lodash';

import { MappedCertificate } from '../../product-detail/models/mapped-certificate.model';
import { brandImageLogo } from '../utils/poap-utils';

export function poapCertificateSummaryMapper(certificate: {
	poapNft: POAPNFT;
	identity: IdentityBase;
}): MappedCertificate {
	return {
		id: certificate.poapNft.tokenId,
		name: certificate.poapNft.event.name,
		description: certificate.poapNft.event.description,
		model: null,
		pictures: [
			{
				type: 'picture',
				mediaType: 'picture',
				url: certificate.poapNft.event.image_url,
			},
		],
		externalContents: [
			{
				title: 'Website',
				url: certificate.poapNft.event.event_url,
				type: 'website',
				order: 0,
			},
		],
		poapEvent: certificate.poapNft.event,
		transparencyItems: [],
		isRequestable: !certificate.poapNft.claimed,
		isContentAuthentic: true,
		identity: {
			brandName: certificate.identity.data.name,
			brandDescription: certificate.identity.data.description,
			brandLogoHeaderReversed: brandImageLogo(certificate.identity, [
				'brandLogoHeaderReversed',
				'brandLogoHeader',
			]),
			brandLogoHeader: brandImageLogo(certificate.identity, [
				'brandLogoHeader',
			]),
			certificateBackgroundPicture: brandImageLogo(certificate.identity, [
				'certificateBackgroundPicture',
			]),
			brandLogoSquare: brandImageLogo(certificate.identity, [
				'brandLogoSquare',
			]),
			externalContents: get(certificate.identity.data, 'externalContents', []),
			socialMedias: get(certificate.identity.data, 'socialmedia', []),
			/* POAP identity is fetched from our backend, so we can assume it's authentic and verified */
			isIdentityAuthentic: true,
			isIdentityVerified: true,
		},
	};
}
