import { Injectable } from '@angular/core';
import WalletConnectV2AuthEventManager from './event-manager/auth-event-manager';
import WalletConnectV2SignEventManager from './event-manager/sign-event-manager';
import WalletConnectV2Utils from './utils';

@Injectable({
  providedIn: 'root'
})
export default class WalletConnectV2Init {
  private initialized = false;

  constructor (
    private utils: WalletConnectV2Utils,
    private signEventManager: WalletConnectV2SignEventManager,
    private authEventManager: WalletConnectV2AuthEventManager
  ) {}

  public async init () {
    if (this.initialized) {
      console.warn('WalletConnectV2Service is already initialized');
      return;
    }

    await this.initClient();

    this.initialized = true;
  }

  private async initClient () {
    const client = await this.utils.getClient();

    client.on('session_proposal', (proposal) => {
      this.signEventManager.handleEvent('session_proposal', proposal);
    });
    client.on('session_request', (sessionRequest) => {
      this.signEventManager.handleEvent('session_request', sessionRequest);
    });
    client.on('auth_request', (authRequest) => {
      this.authEventManager.handleEvent('auth_request', authRequest);
    });
  }
}
