import { Injectable } from '@angular/core';
import { NativeStorage } from '@ionic-native/native-storage/ngx';
import {
	SecureStorage,
	SecureStorageObject,
} from '@ionic-native/secure-storage/ngx';
import { Platform } from '@ionic/angular';

import { MockBrowserNativeStorage } from './mockBrowserNativeStorage';
import { MockBrowserSecureStorage } from './mockBrowserSecureStorage';

@Injectable({
	providedIn: 'root',
})
export class StorageService {
	constructor(
		private originalNativeStorage: NativeStorage,
		private originalSecureStorage: SecureStorage,
		private platform: Platform,
	) {}

	private shouldBeMock() {
		return !this.platform.is('cordova');
	}

	public get nativeStorage(): NativeStorage {
		if (!this.shouldBeMock()) {
			return this.originalNativeStorage;
		} else {
			return new MockBrowserNativeStorage() as NativeStorage;
		}
	}

	public clearNativeStorage() {
		return this.nativeStorage.clear();
	}

	public get secureStorage(): SecureStorage {
		if (!this.shouldBeMock()) {
			return this.originalSecureStorage;
		} else {
			return {
				create(store: string): Promise<SecureStorageObject> {
					const mock = new MockBrowserSecureStorage() as unknown;
					return Promise.resolve(mock as SecureStorageObject);
				},
			};
		}
	}
}
