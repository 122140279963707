import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { NETWORK } from '@arianee/arianeejs';
import { ToasterService } from '@arianeeprivate/wallet-shared-components';
import { AndroidPermissions } from '@ionic-native/android-permissions/ngx';
import { CameraPreview } from '@ionic-native/camera-preview/ngx';
import { NFC } from '@ionic-native/nfc/ngx';
import { QRScanner, QRScannerStatus } from '@ionic-native/qr-scanner/ngx';
import { Platform } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { Observable, Subject, Subscription } from 'rxjs';
import { distinctUntilChanged, map, mergeMap, tap } from 'rxjs/operators';

import { EventLoggerService } from '../event-logger/event-logger-service';
import { UserService } from '../user-service/user.service';
import { CameraService } from './camera.service';
import { HandleLinkService } from './handle-link.service/handle-link.service';
import { QrCodeService } from './qr-code-service';

@Injectable({
	providedIn: 'root',
})
export class ScanService {
	public $scanEvents = new Subject();
	public $scanActivated = new Subject();

	private cameraPreview: CameraPreview;
	private qrScanner: QRScanner;
	private qrScannerStatus: QRScannerStatus;
	private qrScanSubscription: Subscription;

	public beginSession;
	private OCRInProgress = false;
	public isQrScannerEnable = true;

	constructor(
		private cameraService: CameraService,
		private router: Router,
		private platform: Platform,
		private androidPermissions: AndroidPermissions,
		private translateService: TranslateService,
		private toasterService: ToasterService,
		private nfc: NFC,
		private handleLinkService: HandleLinkService,
		private qrCodeService: QrCodeService,
		private eventLogger: EventLoggerService,
		private httpClient: HttpClient,
		private userService: UserService,
	) {
		this.cameraPreview = this.cameraService.cameraPreview;
		this.qrScanner = this.qrCodeService.qrScanner;
	}

	public init = async () => {
		this.initNfc();
	};

	private initNfc() {
		if (this.platform.is('cordova')) {
			this.nfc
				.addNdefListener()
				.pipe(
					map((event) => {
						this.eventLogger.logEvent('scan_nft');
						const nfcId = event.tag.ndefMessage[0].payload.shift();
						if (this.beginSession) {
							this.beginSession.unsubscribe();
						}
						return (
							this.nfcPrefix(nfcId) +
							new Buffer(event.tag.ndefMessage[0].payload).toString().trim()
						);
					}),
					mergeMap((link) => this.handleLinkService.handleLink(link)),
					mergeMap(async (objectLink) => {
						const network = objectLink.network || NETWORK.mainnet;
						return this.handleLinkService.redirectWithFirstNavigation(
							`/tab/brand-list/product-detail/${network}/${objectLink.certificateId}/${objectLink.passphrase}/${objectLink.method}`,
						);
					}),
				)
				.subscribe();
		}
	}

	public async isNFCEnabled(): Promise<boolean | Promise<any>> {
		try {
			const NFCEnabled = await this.nfc.enabled();
			return NFCEnabled;
		} catch {
			return false;
		}
	}

	public async scanNFC() {
		this.beginSession = this.nfc
			.beginSession()
			.pipe(map((event) => {}))
			.subscribe();
	}

	private nfcPrefix(id) {
		const TNFPrefix = [
			'',
			'http://www.',
			'https://www.',
			'http://',
			'https://',
			'tel:',
			'mailto:',
			'file://',
		];
		return TNFPrefix[id].trim();
	}

	public enableQrScanner = async (isPrepare = true) => {
		this.isQrScannerEnable = true;
		await this.disableOcrScanner();

		try {
			if (this.platform.is('android')) {
				const permResult = await this.androidPermissions.checkPermission(
					this.androidPermissions.PERMISSION.CAMERA,
				);
				if (!permResult.hasPermission) {
					const perm = await this.androidPermissions.requestPermission(
						this.androidPermissions.PERMISSION.CAMERA,
					);
					if (!perm.hasPermission) {
						return this.displayModalExplaining();
					}
				}
			}
		} catch {}

		if (isPrepare) {
			this.qrScannerStatus = await this.qrScanner.prepare();
		}

		const enableScan = () => {
			this.qrScanSubscription = this.qrScanner
				.scan()
				.subscribe((text: string) => {
					this.$scanEvents.next(text.trim());
					this.qrScanSubscription.unsubscribe();
					enableScan();
				});
		};

		enableScan();
		this.qrScanner.show();
	};

	private b64toBlob = (b64Data, contentType?, sliceSize?) => {
		contentType = contentType || '';
		sliceSize = sliceSize || 512;

		var byteCharacters = atob(b64Data);
		var byteArrays = [];

		for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
			var slice = byteCharacters.slice(offset, offset + sliceSize);

			var byteNumbers = new Array(slice.length);
			for (var i = 0; i < slice.length; i++) {
				byteNumbers[i] = slice.charCodeAt(i);
			}

			var byteArray = new Uint8Array(byteNumbers);

			byteArrays.push(byteArray);
		}

		var blob = new Blob(byteArrays, { type: contentType });
		return blob;
	};

	public disableOcrScanner = async () => {
		this.cameraPreview.stopCamera();
		this.OCRInProgress = false;
	};

	public enableOcrScanner = async () => {
		this.disableQrScanner();

		await this.cameraPreview
			.startCamera({
				camera: 'BACK',
				toBack: true,
			})
			.catch((e) => {
				if (e !== 'Camera already started') {
					console.error(e);
				}
			});

		const scan = async () => {
			const picture = await this.cameraPreview.takeSnapshot({ quality: 100 });

			const data = this.b64toBlob(picture, 'image/jpeg');
			var fd = new FormData();
			fd.append('file', data);

			const answer: any = await this.httpClient
				.request(
					'post',
					'https://us-central1-arianeesharetestnet.cloudfunctions.net/ocrSerialNumber',
					{
						body: fd,
					},
				)
				.toPromise()
				.catch((e) => null);
			if (answer && answer.deeplink) {
				this.eventLogger.logEvent('scan_ocr');

				this.$scanEvents.next(answer.deeplink);
			} else {
				if (this.OCRInProgress) {
					scan();
				}
			}
		};

		if (!this.OCRInProgress) {
			this.OCRInProgress = true;
			scan();
		}
	};

	public disableQrScanner = async () => {
		// avoid disable qr scanner is called multiple time
		if (this.isQrScannerEnable) {
			this.isQrScannerEnable = false;
			await this.qrScanner.hide();
			await this.qrScanner.destroy();
		}
	};

	public hasLight = async () => {
		const status = await this.qrScanner.getStatus();
		return status.canEnableLight;
	};

	public enableLight = () => {
		this.qrScanner.enableLight();
	};

	public disableLight = () => {
		this.qrScanner.disableLight();
	};

	private displayModalExplaining = async () => {
		const okButton = await this.translateService
			.get('Error.androidCameraPermissionNeeded.ok')
			.toPromise();
		const cancelButton = await this.translateService
			.get('Error.androidCameraPermissionNeeded.cancel')
			.toPromise();

		return this.toasterService.alert({
			backdropDismiss: false,
			message: 'Error.androidCameraPermissionNeeded.message',
			buttons: [
				{
					text: cancelButton,
					cssClass: 'secondary',
					handler: () => this.router.navigate(['tab/brand-list']),
				},
				{
					text: okButton,
					handler: () => this.enableQrScanner(),
				},
			],
		});
	};
}
