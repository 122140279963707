import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

import { DeferDeeplinkGuard } from './guards/deferDeeplink.guard';
import { FakeLoginGuard } from './guards/fakeLogin.guard';
import { HasOnboardedGuard } from './guards/has-onboarded-guard.service';
import { IsNotPWAGuard } from './guards/is-not-pwaguard.service';
import { IsPWAAndSwitchNetworkGuard } from './guards/is-pwa-guard.service-and-switch-network';
import { IsAuthGuard } from './guards/isAuth.guard';
import { LandingPathIsNotEmptyGuard } from './guards/landingPathIsNotEmpty.guard';
import { PlatformReadyGuard } from './guards/platformReady.guard';
import { SkipPWAGuard } from './guards/skipPWA.guard';
import { UserAgreedAndOnboardedGuard } from './guards/user-agreed-and-onboarded.guard';

const routes: Routes = [
	{
		path: '',
		canActivate: [PlatformReadyGuard, LandingPathIsNotEmptyGuard],
		children: [
			{
				path: 'intro',
				loadChildren: './pages/intro/intro.module#IntroPageModule',
				canActivate: [IsNotPWAGuard],
			},
			{
				path: 'auth',
				loadChildren: './pages/auth/auth.module#AuthPageModule',
				canActivate: [IsNotPWAGuard, HasOnboardedGuard],
			},
			{
				path: 'export-mnemonic',
				canLoad: [DeferDeeplinkGuard],
				canActivate: [IsAuthGuard],
				data: {
					description: 'ExportMnemonics.deepLink.description',
					title: 'ExportMnemonics.deepLink.title',
				},
				loadChildren:
					'./pages/main/export-mnemonic/export-mnemonic.module#ExportMnemonicModule',
			},
			{
				path: 'import-custodial-mnemonic',
				canLoad: [DeferDeeplinkGuard],
				canActivate: [IsAuthGuard],
				data: {
					description: 'ImportCustodialMmnemonic.deepLink.description',
					title: 'ImportCustodialMmnemonic.deepLink.title',
				},
				loadChildren:
					'./pages/import-custodial-mnemonic/import-custodial-mnemonic.module#ImportCustodialMnemonicModule',
			},
			{
				path: '',
				canActivate: [
					IsNotPWAGuard,
					FakeLoginGuard,
					UserAgreedAndOnboardedGuard,
				],
				children: [
					{
						path: '',
						pathMatch: 'full',
						redirectTo: 'tab/brand-list',
					},
					{
						path: 'digit-print',
						loadChildren:
							'./pages/digit-print/digit-print.module#DigitPrintPageModule',
					},
					{
						path: 'face-id',
						loadChildren: './pages/face-id/face-id.module#FaceIdPageModule',
					},
					{
						path: 'tab',
						loadChildren: './pages/main/tab/tab.module#TabPageModule',
					},
					{
						path: 'backup-viewer',
						canActivate: [IsAuthGuard],
						loadChildren:
							'./pages/main/backup-viewer/backup-viewer.module#BackupViewerPageModule',
					},
					{
						path: 'backup-words',
						canActivate: [IsAuthGuard],
						loadChildren:
							'./pages/main/backup-viewer1/backup-viewer1.module#BackupViewer1PageModule',
					},
					{
						path: 'backup-sort',
						canActivate: [IsAuthGuard],
						loadChildren:
							'./pages/main/backup-viewer2/backup-viewer2.module#BackupViewer2PageModule',
					},
					{
						path: 'backup-import',
						canActivate: [IsAuthGuard],
						loadChildren:
							'./pages/main/backup-import/backup-import.module#BackupImportPageModule',
					},
					{
						path: 'backup-import-success',
						canActivate: [IsAuthGuard],
						loadChildren:
							'./pages/main/backup-import-success/backup-import-success.module#BackupImportSuccessPageModule',
					},
					{
						path: 'backup-manually-success',
						canActivate: [IsAuthGuard],
						loadChildren:
							'./pages/main/backup-manually-success/backup-manually-success.module#BackupManuallySuccessPageModule',
					},
					{
						path: 'cloud-import',
						canActivate: [IsAuthGuard],
						loadChildren:
							'./pages/main/cloud-import/cloud-import.module#CloudImportPageModule',
					},
					{
						path: 'home-backup',
						canActivate: [IsAuthGuard],
						loadChildren:
							'./pages/main/home-backup/home-backup.module#HomeBackupPageModule',
					},
					{
						path: 'home-import',
						canActivate: [IsAuthGuard],
						loadChildren:
							'./pages/main/home-import/home-import.module#HomeImportPageModule',
					},
					{
						path: 'notification/:network/:certificateId',
						loadChildren: './pages/main/chat/chat.module#ChatPageModule',
					},
					{
						path: 'chat',
						loadChildren: './pages/main/chat/chat.module#ChatPageModule',
					},

					{
						path: 'faq',
						loadChildren: './pages/main/faq/faq.module#FaqPageModule',
					},
					{
						path: 'setting',
						loadChildren:
							'./pages/main/setting/setting.module#SettingPageModule',
					},
					{
						path: 'brand-page/:brandId',
						loadChildren:
							'./pages/main/brand-page/brand-page.module#BrandPageModule',
					},
					{
						path: 'share-photo',
						loadChildren:
							'./pages/main/share-photo/share-photo.module#SharePhotoPageModule',
					},
					{
						path: 'test/import-encrypted-mnemonic',
						loadChildren:
							'./pages/import-encrypted-mnemonic/import-encrypted-mnemonic.module#ImportEncryptedMnemonicModule',
					},
					{
						path: 'demo',
						loadChildren: './pages/demo/demo.module#DemoModule',
					},
				],
			},
			{
				path: '',
				canActivate: [IsPWAAndSwitchNetworkGuard],
				children: [
					{
						path: ':certificateIdAndpassphrase',
						canActivate: [SkipPWAGuard],
						loadChildren:
							'./pages/main/product-detail/product-detail.module#ProductDetailPageModule',
					},
					{
						path: ':certificateIdAndpassphrase',
						canActivate: [SkipPWAGuard],
						loadChildren:
							'./pages/main/product-detail/product-detail.module#ProductDetailPageModule',
					},
					{
						path: ':method/:certificateIdAndpassphrase',
						canActivate: [SkipPWAGuard],
						loadChildren:
							'./pages/main/product-detail/product-detail.module#ProductDetailPageModule',
					},
				],
			},
		],
	},
];

@NgModule({
	imports: [
		RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules }),
	],
	exports: [RouterModule],
})
export class AppRoutingModule {}
