import {
	ChangeDetectionStrategy,
	Component,
	EventEmitter,
	Input,
	OnInit,
	Output,
	ViewEncapsulation,
} from '@angular/core';
import { NETWORK } from '@arianee/arianeejs';
import { CertificateSummary } from '@arianee/arianeejs/dist/src/core/wallet/certificateSummary';
import { get } from 'lodash';
import { take } from 'rxjs/operators';

import { MessageItem } from '../../../pages/main/chat/models/message-item.model';
import {
	ExternalContentService,
	NftLinkInfo,
} from '../../../pages/main/product-detail/utils/externalContentsHandler';
import { ArianeeService } from '../../../providers/arianee-service/arianee.service';
import { EventLoggerService } from '../../../providers/event-logger/event-logger-service';
import { BrowserService } from '../../../providers/inapp-browser/inapp-browser-service';
import { Button } from '../button/models/button.model';

@Component({
	selector: 'app-message-item',
	templateUrl: './message-item.component.html',
	styleUrls: ['./message-item.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	encapsulation: ViewEncapsulation.None,
})
export class MessageItemComponent implements OnInit {
	@Input() message: MessageItem;
	@Input() certificate: CertificateSummary;
	@Input() network: NETWORK;
	@Input() isFirstUnreadMessage: boolean;
	@Output() openFullscreen: EventEmitter<{
		isFullscreen: boolean;
		currentMediaIndex: number;
		messageId: string;
	}> = new EventEmitter<{
		isFullscreen: boolean;
		currentMediaIndex: number;
		messageId: string;
	}>();

	buttonList: Button[] = undefined;

	constructor(
		private browserService: BrowserService,
		private externalContentService: ExternalContentService,
		private eventLogger: EventLoggerService,
		private arianeeService: ArianeeService,
	) {}

	ngOnInit() {
		this.initButtons();
	}

	async openUrl(index: number) {
		const nftInfos: NftLinkInfo = {
			isOwner: this.certificate.owner.isOwner,
			isPending: false,
			certificateId: this.certificate.certificateId,
			network: this.network,
			claimable: false,
		};

		this.eventLogger.logEvent('chat_ctaClicked', {
			certificateId: this.certificate.certificateId,
			messageId: this.message.id,
			issuer: get(this.message.issuer, 'identity.address'),
			network: this.network,
		});

		await this.externalContentService.onLinkClick(
			this.message.content.externalContents[index],
			nftInfos,
		);
	}

	private initButtons() {
		this.buttonList =
			this.message.content.externalContents &&
			this.message.content.externalContents.map((externalContent) => {
				return {
					title: {
						text: externalContent.title,
					},
					buttonType: 'primary',
				};
			});
	}

	onOpenFullscreen(payload: {
		isFullscreen: boolean;
		currentMediaIndex: number;
		messageId: string;
	}) {
		this.openFullscreen.emit({
			isFullscreen: payload.isFullscreen,
			currentMediaIndex: payload.currentMediaIndex,
			messageId: payload.messageId,
		});
	}
}
