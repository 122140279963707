import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { ModalController } from '@ionic/angular';

import { Icon } from '../../../../../../../components/redesign/icon/models/icon.model';
import { BrowserService } from '../../../../../../../providers/inapp-browser/inapp-browser-service';
import {
	ExternalContent,
	TransparencyV1Item,
	TransparencyV2Item,
} from '../../../../models/mapped-certificate.model';
import {
	ExternalContentService,
	NftLinkInfo,
} from '../../../../utils/externalContentsHandler';
import { mapExternalContentsToLinksListItems } from '../../../../utils/mappers/list-items/list-items-mappers';
import { getYoutubeMedia } from '../../../../utils/youtube/youtube.helper';

@Component({
	selector: 'app-transparency-details-modal',
	templateUrl: './transparency-details-modal.component.html',
	styleUrls: ['./transparency-details-modal.component.scss'],
	encapsulation: ViewEncapsulation.None,
})
export class TransparencyDetailsModalComponent implements OnInit {
	@Input() icon: Icon;
	@Input() transparencyItem: TransparencyV1Item | TransparencyV2Item;
	@Input() isWeb: boolean;

	@Input() nftLinkInfo: NftLinkInfo;

	public modal: HTMLIonModalElement;

	formattedTransparencyItem;

	constructor(
		private modalCtrl: ModalController,
		private browserService: BrowserService,
		private externalContentService: ExternalContentService,
	) {}

	async ngOnInit() {
		this.modal = await this.modalCtrl.getTop();
		this.initFormattedTransparencyItemV2();
	}

	private initFormattedTransparencyItemV2() {
		if (this.transparencyItem.transparencyType === 'v2') {
			this.formattedTransparencyItem = {
				title: (this.transparencyItem as TransparencyV2Item).title,
				subtitle: (this.transparencyItem as TransparencyV2Item).subtitle,
				description: (this.transparencyItem as TransparencyV2Item).description,
				externalContents: mapExternalContentsToLinksListItems(
					(this.transparencyItem as TransparencyV2Item)
						.externalContents as ExternalContent[],
					this.browserService,
					this.nftLinkInfo,
					this.externalContentService,
				),
				medias: (this.transparencyItem as TransparencyV2Item).medias
					? (this.transparencyItem as TransparencyV2Item).medias.map(
							(media) => {
								if (media.mediaType === 'youtube') {
									media = getYoutubeMedia(media);
								}
								return media;
							},
					  )
					: [],
			};
		}
	}

	onCloseModal(): void {
		this.modalCtrl.dismiss({
			dismissed: true,
		});
	}
}
