import { Component } from '@angular/core';
import { NETWORK } from '@arianee/arianeejs/dist/src';
import { UserService } from '../../providers/user-service/user.service';
import { combineLatest, Subscription } from 'rxjs';
import { Platform } from '@ionic/angular';
import { FeatureFlipService } from '../../providers/feature-flip-service/feature-flip.service';
import { ChainType } from '../../types/multichain';
import { ArianeeService } from '../../providers/arianee-service/arianee.service';

@Component({
  selector: 'network-watemark',
  templateUrl: './network-watemark.component.html',
  styleUrls: ['./network-watermark.component.scss']
})
export class NetworkWatemarkComponent {
    private subscriptions:Array<Subscription>=[];

    public isTestNetMode=false;
    public chainType:ChainType;

    constructor (private userService:UserService, private platform:Platform, private featureFlipService:FeatureFlipService, private arianeeService: ArianeeService) {}

    ngOnInit () {
      this.platform.ready().then(async () => {
        const sub = combineLatest([
          this.featureFlipService.$isFeatureFlip('hideNetworkBanner'),
          this.arianeeService.$walletInitialize,
          this.userService.$chainType.get()
        ])
          .subscribe(([hideNetworkBanner, chain, chainType]) => {
            this.chainType = chainType;
            this.isTestNetMode = hideNetworkBanner ? false : chainType === ChainType.testnet;
          });

        this.subscriptions.push(sub);
      });
    }

    ngOnDestroy () {
      this.subscriptions.forEach(sub => sub.unsubscribe());
    }
}
