import { Icon } from '../../icon/models/icon.model';
import { OnBoardingStep } from '../models/on-boarding-step';

export const onBoardingSteps: OnBoardingStep[] = [
	{
		icon: { name: 'icon-wallet' } as Partial<Icon>,
		title: 'OnBoardingInPassport.digitalAsset.slide1.title',
		description: 'OnBoardingInPassport.digitalAsset.slide1.description',
	},
	{
		icon: { name: 'icon-scarce' } as Partial<Icon>,
		title: 'OnBoardingInPassport.digitalAsset.slide2.title',
		description: 'OnBoardingInPassport.digitalAsset.slide2.description',
	},
	{
		icon: { name: 'icon-owner' } as Partial<Icon>,
		title: 'OnBoardingInPassport.digitalAsset.slide3.title',
		description: 'OnBoardingInPassport.digitalAsset.slide3.description',
	},
	{
		icon: { name: 'icon-connect-wallet' } as Partial<Icon>,
		title: 'OnBoardingInPassport.digitalAsset.slide4.title',
		description: 'OnBoardingInPassport.digitalAsset.slide4.description',
	},
];
