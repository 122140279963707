import { TranslateService } from '@ngx-translate/core';

import { ListItem } from '../../../../../../components/redesign/list/models/list-item.model';
import { MappedCertificate } from '../../../models/mapped-certificate.model';
import { dateFormatter } from '../../date/date-formatter';

export function poapEventsMapper(
	certificate: MappedCertificate,
	translate: TranslateService,
): ListItem[] {
	return [
		{
			title: translate.instant('History.poapStart'),
			subtitle: dateFormatter(new Date(certificate.poapEvent.start_date)),
			icon: {
				image: {
					url: certificate.identity.brandLogoSquare,
					height: '48px',
					width: '48px',
					borderRadius: '24px',
				},
			},
			actionIcon: 'none',
		},
		{
			title: translate.instant('History.poapEnd'),
			subtitle: dateFormatter(new Date(certificate.poapEvent.end_date)),
			icon: {
				image: {
					url: certificate.identity.brandLogoSquare,
					height: '48px',
					width: '48px',
					borderRadius: '24px',
				},
			},
			actionIcon: 'none',
		},
	];
}
