import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
	selector: 'app-modal-cancel-button',
	template: `<app-icon
		[icon]="{
			name: 'icon-cancel',
			fontSize: 32,
			height: '48px',
			width: '48px',
			borderRadius: '16px'
		}"
	>
	</app-icon>`,
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ModalCancelButtonComponent {}
