import { ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FeatureFlipService } from '../../../providers/feature-flip-service/feature-flip.service';
import { Subscription } from 'rxjs';

@Component({
	selector: 'app-empty-state',
	templateUrl: './empty-state.component.html',
	styleUrls: ['./empty-state.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EmptyStateComponent {
	@Input() imageUrl: string;
	@Input() darkImageUrl: string;
	@Input() description: string;
}
