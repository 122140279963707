import { Injectable } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { AuthClientTypes } from '@walletconnect/auth-client';
import { ProposalTypes, SignClientTypes } from '@walletconnect/types';

import { SessionProposalModalComponent } from '../../../components/wallet-connect-modals/session-proposal-modal/session-proposal-modal.component';
import {
	SessionProposalModalProps,
	SignMessageModalProps,
	SignTransactionModalProps,
	WalletConnectModalProps,
} from '../../../components/wallet-connect-modals/types/wallet-connect-modal';
import { WalletSignMessageModalComponent } from '../../../components/wallet-connect-modals/wallet-sign-message-modal/wallet-sign-message-modal.component';
import { WalletTransactionModalComponent } from '../../../components/wallet-connect-modals/wallet-transaction-modal/wallet-transaction-modal.component';
import { GasStationService } from '../../gas-station-service/gas-station.service';
import { getCurrencySymbol } from '../../wallet-connect-service/chain-utils/chain-utils';
import WalletConnectV2Utils from '../utils';
import {
	SignOrAuthEvent,
	SignOrAuthEventArguments,
	WalletConnectModal,
} from './types';

@Injectable({
	providedIn: 'root',
})
export default class WalletConnectV2ModalService {
	constructor(
		private modalController: ModalController,
		private utils: WalletConnectV2Utils,
		private gasStation: GasStationService,
	) {}

	public async open<Event extends SignOrAuthEvent>(
		event: Event,
		modalComponent: WalletConnectModal,
		args: SignOrAuthEventArguments<Event>,
	): Promise<{ approved: boolean }> {
		let props: WalletConnectModalProps;

		switch (modalComponent) {
			case SessionProposalModalComponent:
				props = await this.getPropsForSessionProposalModal(
					args as SignClientTypes.EventArguments['session_proposal'],
				);
				break;
			case WalletSignMessageModalComponent:
				if (event === 'auth_request') {
					props = await this.getPropsForAuthenticationRequestModal(
						args as AuthClientTypes.EventArguments['auth_request'],
					);
				} else {
					props = await this.getPropsForWalletSignMessageModal(
						args as SignClientTypes.EventArguments['session_request'],
					);
				}
				break;
			case WalletTransactionModalComponent:
				props = await this.getPropsForWalletTransactionModal(
					args as SignClientTypes.EventArguments['session_request'],
				);
				break;
			default:
				throw new Error(`Unhandled modal component: ${modalComponent.name}`);
		}

		const modalInstance = await this.modalController.create({
			component: modalComponent,
			cssClass: 'modal--bottom',
			backdropDismiss: false,
			componentProps: {
				...props,
			},
		});

		await modalInstance.present();

		const { data } = await modalInstance.onWillDismiss();

		return {
			approved: data,
		};
	}

	private async getPropsForSessionProposalModal(
		args: SignClientTypes.EventArguments['session_proposal'],
	): Promise<SessionProposalModalProps> {
		const { name, icons } = args.params.proposer.metadata;
		const { requiredNamespaces } = args.params;

		const requiredNamespacesValues = Object.values(requiredNamespaces);

		const methods = requiredNamespacesValues.reduce<string[]>(
			(_methods: string[], namespace: ProposalTypes.RequiredNamespace) => [
				..._methods,
				...namespace.methods,
			],
			[],
		);

		const chains = requiredNamespacesValues.reduce<string[]>(
			(_chains: string[], namespace: ProposalTypes.RequiredNamespace) => [
				..._chains,
				...namespace.chains,
			],
			[],
		);

		const supportedMethods = this.utils.getSupportedMethods();
		const supportedChains = this.utils.getSupportedChains();

		const unsupportedMethods = methods.filter(
			(method) => !supportedMethods.includes(method),
		);
		const unsupportedChains = chains.filter(
			(chain) => !supportedChains.includes(chain),
		);

		const canApprove =
			unsupportedMethods.length === 0 && unsupportedChains.length === 0;

		const isVerified = await this.utils.isVerifiedPeer(
			args.params.proposer.metadata,
		);

		return {
			isVerified,
			logo: icons[0],
			website: name,
			methods,
			chains,
			unsupportedMethods,
			unsupportedChains,
			canApprove,
		};
	}

	private async getPropsForAuthenticationRequestModal(
		args: AuthClientTypes.EventArguments['auth_request'],
	): Promise<SignMessageModalProps> {
		const { name, icons } = args.params.requester.metadata;

		const client = await this.utils.getClient();
		const iss = await this.utils.getIss();

		const message = client.formatMessage(args.params.cacaoPayload, iss);

		const isVerified = await this.utils.isVerifiedPeer(
			args.params.requester.metadata,
		);

		return {
			website: name,
			logo: icons[0],
			isVerified,
			message,
			modalTitle: 'WalletConnect.v2.titles.authenticationRequest',
		};
	}

	private async getPropsForWalletSignMessageModal(
		args: SignClientTypes.EventArguments['session_request'],
	): Promise<SignMessageModalProps> {
		const { topic, params } = args;
		const { request } = params;

		const client = await this.utils.getClient();

		const requestSession = client.engine.signClient.session.get(topic);
		const { name, icons } = requestSession.peer.metadata;

		const plainMessage = this.utils.getSignParamsMessage(args);

		const isVerified = await this.utils.isVerifiedPeer(
			requestSession.peer.metadata,
		);

		return {
			website: name,
			logo: icons[0],
			isVerified,
			message: plainMessage,
		};
	}

	private async getPropsForWalletTransactionModal(
		args: SignClientTypes.EventArguments['session_request'],
	): Promise<SignTransactionModalProps> {
		const { topic, params } = args;
		const { request } = params;

		const client = await this.utils.getClient();

		const requestSession = client.engine.signClient.session.get(topic);
		const { name, icons } = requestSession.peer.metadata;

		const chainId = args.params.chainId.split(':')[1];
		const gasStation = await this.gasStation.fetchGasStation(chainId);

		const symbol = getCurrencySymbol(parseInt(chainId));

		const isVerified = await this.utils.isVerifiedPeer(
			requestSession.peer.metadata,
		);

		return {
			website: name,
			logo: icons[0],
			isVerified,
			gasPrice: gasStation,
			payload: request,
			signOnly: request.method === 'eth_signTransaction',
			symbol,
		};
	}
}
