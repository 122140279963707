import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ChinaService {
  private _getCountryCache;
  constructor (private http:HttpClient) {

  }

  public showChinaFooter = async (): Promise<boolean> => {
    if (environment.appType === 'web' && await this.isChina()) {
      return true;
    } else {
      return false;
    }
  };

  public isChina = async (): Promise<boolean> => {
    const country = await this.getCountry();

    return country.country.toUpperCase() === 'CN';
  };

  public getCountry = async (): Promise<{country:string}> => {
    if (this._getCountryCache === undefined) {
      const chinaUrl = environment.countryUrl;
      this._getCountryCache = this.http.get(chinaUrl).toPromise();
    }

    return this._getCountryCache;
  };
}
