import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';

import { ChainType } from '../../../types/multichain';
import { Icon } from '../icon/models/icon.model';

@Component({
	selector: 'app-network-switch-modal',
	templateUrl: './network-switch-modal.component.html',
	styleUrls: ['./network-switch-modal.component.scss'],
})
export class NetworkSwitchModalComponent implements OnInit {
	@Input() header: { title: string; icon: Icon };
	@Input() currentChainType: ChainType;
	networks = [ChainType.mainnet, ChainType.testnet];
	constructor(private modalCtrl: ModalController) {}

	ngOnInit() {}

	onCloseModal() {
		this.modalCtrl.dismiss();
	}

	onNetworkChange(chainType: ChainType) {
		this.currentChainType = chainType;
		this.modalCtrl.dismiss({ chainType });
	}
}
