import { Injectable } from '@angular/core';
import { ArianeeService } from '../../arianee-service/arianee.service';
import { HttpClient } from '@angular/common/http';
import { from, Observable, of } from 'rxjs';
import { DeepLinkObject } from '../../../models/deepLinkObject';
import { catchError, first, map, mergeMap, take, withLatestFrom } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { isLinkValid } from './helpers/isLinkValid';
import { NETWORK } from '@arianee/arianeejs';
import { UserService } from '../../user-service/user.service';
import { getChainTypeFromNetwork } from '../../wallet-connect-service/chain-utils/chain-utils';
import { AuthJWTService } from '../../authJWT-service/authJWT.service';
import { getChainFromWhitelabelHostname } from './helpers/getChainFromWhitelabelHostname';
import { ChainType } from '../../../types/multichain';

@Injectable({
  providedIn: 'root'
})
export class HandleCertificateLinkService {
  private network: NETWORK;
  constructor (private arianeeService: ArianeeService,
                private httpClient: HttpClient,
                private userService: UserService,
                private authJWTService: AuthJWTService
  ) {
    this.initializeWallet();
  }

    public transformAnyLinkIntoDeepLinkObject = (link: string): Observable<DeepLinkObject> => {
      return from(this.arianeeService.getWalletInstance(this.network))
        .pipe(
          take(1),
          map((wallet) => {
            const deeplinkObject = wallet.utils.readLink(link, false);

            if (!isLinkValid(deeplinkObject)) {
              throw new Error('this is not an arianee link');
            }
            return { ...deeplinkObject, deeplink: link };
          }),
          catchError(e => this.tryRedirectToNFT(link)),
          catchError(err => {
            console.info('handleLink ## not a arianee link');
            return this.tryHandlePartnerLink(link);
          })
        );
    };

    /**
     * Try to read an arianee link from any url: https://www.breitling.com/1243,eever
     * @param link
     */
    public tryReadCertificateFromURLShape (link: string): Observable<DeepLinkObject> {
      return from(this.arianeeService.getWalletInstance(this.network))
        .pipe(
          first(),
          mergeMap(wallet => {
            try {
              const linkObject = wallet.utils.readLink(link, false);
              if (linkObject.passphrase && linkObject.certificateId) {
                console.info('handleLink ##  trying fetching certificate blindly');
                return of(linkObject);
              }
            } catch (e) {
              throw new Error('this is not an arianee link');
            }
          }));
    }

    /**
     * Try get link from dispatcher
     * @param url
    */
    public tryDispatchLink (url: string): Observable<any> {
      return from(this.arianeeService.getWalletInstance(this.network))
        .pipe(take(1))
        .pipe(
          mergeMap(wallet => wallet.methods.createWalletAccessToken()),
          mergeMap(async (aat) => {
            return await this.authJWTService.authJwtGetter(aat, this.network);
          }),
          mergeMap(jwt => {
            return this.httpClient.get(url,
              {
                headers: {
                  Authorization: `${jwt}`
                }
              });
          }
          )
        );
    }

    /**
     * Try to fetch redirectToNFT
     * @param url
     */
    public tryRedirectToNFT (url:string):Observable<DeepLinkObject> {
      return from(this.arianeeService.getWalletInstance(this.network))
        .pipe(take(1))
        .pipe(
          mergeMap(wallet => wallet.methods.createActionArianeeAccessTokenLink(url, -1)),
          mergeMap(urlToFetch => {
            return this.httpClient.get(urlToFetch,
              { responseType: 'text' });
          }
          ),
          withLatestFrom(from(this.arianeeService.getWalletInstance(this.network))),
          map(([link, wallet]) => ({
            ...wallet.utils.readLink(link, false),
            deeplink: link
          })
          )
        );
    }

    /**
     * Check if link is a partner link. Ex Bretiling Warranty card
     * @param link
     */
    public tryHandlePartnerLink (link: string): Observable<DeepLinkObject> {
      return this.arianeeService.$walletInitialize
        .pipe(
          take(1),
          mergeMap(network => this.arianeeService.getWalletInstance(network)),
          mergeMap(wallet => {
            return wallet.methods.createWalletAccessToken();
          }),
          mergeMap((accessToken) => {
            return this.httpClient.post<DeepLinkObject>(
              environment.partnersLinks,
              { link },
              {
                headers: {
                  Authorization: `Bearer ${accessToken}`
                }
              }
            );
          }),
          map(deepLinkObject => {
            if (isLinkValid(deepLinkObject)) {
              return deepLinkObject;
            } else {
              throw new Error('not a a partner link');
            }
          })
        );
    }

    /**
     * Simple read link from: https://arian.ee/123,234.
     * Auto switch to the right network if it can
     * @param link
     */
     public tryReadLink = (link: string): Observable<DeepLinkObject & { network?: NETWORK }> => {
       return from(this.arianeeService.getWalletInstance(this.network))
         .pipe(
           first(),
           mergeMap(async (wallet) => {
             let deepLinkObject : DeepLinkObject | null = null;

             try {
               deepLinkObject = wallet.utils.readLink(link, false);
             } catch {
               deepLinkObject = await this.tryReadCertificateFromURLShape(link).pipe(take(1)).toPromise();
             }

             if (!deepLinkObject) throw new Error('no deeplink object retrieved');
             let network: NETWORK;

             try {
               const url = new URL(link);
               network = wallet.utils.findChainFromHostname(url.hostname) || getChainFromWhitelabelHostname(url.hostname);
               if (!network) console.error(`could not retrieve chain for ${url.hostname}`);
               this.userService.$chainType.set(network ? getChainTypeFromNetwork(network) : ChainType.mainnet);
             } catch { }

             return {
               ...deepLinkObject,
               network
             };
           })
         );
     }

     private initializeWallet () {
       this.arianeeService
         .$walletInitialize
         .pipe(take(1))
         .subscribe((network) => {
           this.network = network;
         });
     }
}
