import { Location } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BackConfirmComponent } from '@arianeeprivate/wallet-shared-components';
import { ModalController, NavController } from '@ionic/angular';
import { ethers } from 'ethers';
import { Observable, from, of } from 'rxjs';
import { mergeMap, take, tap } from 'rxjs/operators';
import { ArianeeService } from 'src/app/providers/arianee-service/arianee.service';

import { BackupService } from '../../../../../providers/backup-service/backup.service';
import { EventLoggerService } from '../../../../../providers/event-logger/event-logger-service';
import { UserService } from '../../../../../providers/user-service/user.service';

@Component({
	selector: 'app-backup-import-legacy',
	templateUrl: './backup-import-legacy.component.html',
	styleUrls: ['./backup-import-legacy.component.scss'],
})
export class BackupImportLegacyComponent implements OnInit, OnDestroy {
	public seed = [];
	public validMnemonic: boolean;
	public wordsList: string[];
	public query: string;

	constructor(
		private navCtrl: NavController,
		private location: Location,
		private modalCtrl: ModalController,
		private userService: UserService,
		private arianeeService: ArianeeService,
		private router: Router,
		private eventLogger: EventLoggerService,
		private backupService: BackupService,
	) {}

	public wordsCount = 12;

	ngOnInit() {
		this.eventLogger.logScreen('backup-import', {});
	}

	ngOnDestroy() {
		this.seed = [];
	}

	itemSelected(word) {
		this.seed.push(word);

		this.validMnemonic = false;

		this.query = '';

		if (this.seed.length > 11) {
			if (ethers.utils.isValidMnemonic(this.seed.join(' '))) {
				this.validMnemonic = true;
			}
		}

		document.querySelector('ion-searchbar').setFocus();
	}

	private reinitiliazeWallet(): Observable<any> {
		const mnemonic = this.seed.join(' ');
		return this.arianeeService.switchWallet(mnemonic).pipe(
			tap(() =>
				this.eventLogger.logEvent('backup-import-legacy_switchWallet_success'),
			),
			mergeMap(() => from(this.router.navigate(['tab/brand-list']))),
		);
	}

	async onClickConfirmSort() {
		const confirmModal = await this.modalCtrl.create({
			component: BackConfirmComponent,
			cssClass: 'auto-height @',
		});
		await confirmModal.present();

		from(confirmModal.onDidDismiss())
			.pipe(
				mergeMap((data) => {
					if (data.data === 'confirm') {
						return this.backupService
							.setBackup(Date.now(), true, 'manual')
							.pipe(
								take(1),
								mergeMap(() => this.reinitiliazeWallet()),
							);
					} else {
						return of('');
					}
				}),
			)
			.subscribe();
	}

	onClickNavBack() {
		this.location.back();
	}

	delete() {
		this.seed.pop();
	}

	updateWordList() {
		this.wordsList = this.getList(this.query);
	}

	getList(keyword: string) {
		const list = [];

		for (let i = 0; i < 2048; i++) {
			const actual = ethers.wordlists.en.getWord(i);
			if (keyword) {
				if (actual.toLowerCase().startsWith(keyword.toLowerCase())) {
					list.push(actual);
				}
			}
		}

		return list;
	}
}
