import { Injectable } from '@angular/core';
import { LoaderService } from '@arianeeprivate/wallet-shared-components';
import {
	AlertController,
	ModalController,
	NavController,
	Platform,
	PopoverController,
} from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { take } from 'rxjs/operators';
import { ImportPopoverComponent } from 'src/app/components/import-popover/import-popover.component';

import { BackUpWarnModalComponent } from '../../components/redesign/backup-warn-modal/backup-warn-modal.component';
import { ArianeeService } from '../arianee-service/arianee.service';
import { BackupService } from '../backup-service/backup.service';
import { EventLoggerService } from '../event-logger/event-logger-service';
import { GoogleDriveService } from '../google-drive-service/google-drive.service';
import { CloudSettingsService } from '../keychain-service/cloud-settings.service';
import { MyCertificatesService } from '../my-certificates/my-certificates.service';
import { SessionService } from '../session-service/session.service';
import { UserService } from '../user-service/user.service';

@Injectable({
	providedIn: 'root',
})
export class BackupCheckService {
	constructor(
		private arianeeService: ArianeeService,
		private userService: UserService,
		private googleDriveService: GoogleDriveService,
		private cloudSettingsService: CloudSettingsService,
		private platform: Platform,
		private alertController: AlertController,
		private translateService: TranslateService,
		private loaderService: LoaderService,
		private popOverCtrl: PopoverController,
		private sessionService: SessionService,
		private modalCtrl: ModalController,
		private navCtrl: NavController,
		private backupService: BackupService,
		private eventLogger: EventLoggerService,
		private myCertificatesService: MyCertificatesService,
	) {}

	// Flag backup as secured if current wallet exist in backup list (if getBackupIds available - google drive ?)
	checkSecuredBackupAndFlag = async () => {
		const hasBackupSecured = await this.userService.getHasBackup().toPromise();
		if (!hasBackupSecured) {
			const backups = await this.backupService.getBackupsId();
			const myAddress = await this.arianeeService.$address
				.pipe(take(1))
				.toPromise();
			if (backups.length > 0) {
				let backupDetail;
				for (const backup of backups) {
					backupDetail = backup.id.split('-');
					if (backupDetail[1] === myAddress) {
						await this.userService.setHasBackup(true);
					}
				}
			}
		}
	};

	// present backup list if backup exist and current wallet not in backup list
	async openImportWallet() {
		this.loaderService.showLoader();

		await this.checkSecuredBackupAndFlag();

		const hasBackupSecured = await this.userService.getHasBackup().toPromise();
		if (!hasBackupSecured) {
			const backups = await this.backupService.getBackupsId();
			const myAddress = await this.arianeeService.$address
				.pipe(take(1))
				.toPromise();

			let backupFound = false;

			if (backups.length > 0) {
				let backupDetail;
				for (const backup of backups) {
					backupDetail = backup.id.split('-');
					if (backupDetail[1] === myAddress) {
						backupFound = true;
					}
				}

				if (!backupFound) {
					const prodPopover = await this.popOverCtrl.create({
						component: ImportPopoverComponent,
						translucent: true,
						cssClass: 'backup-popover',
						componentProps: {
							backups,
						},
					});
					await prodPopover.present();
				}
			}
		}

		this.loaderService.dismiss();
	}

	displayIfNeededBackUpModal = async () => {
		const hasNft = await this.myCertificatesService
			.getHasNft()
			.pipe(take(1))
			.toPromise();
		const hasSeenBackupWarnModal = this.sessionService.hasSeenBackupWarnModal;
		const hasBackupSecuredStorage = await this.userService
			.getHasBackup()
			.pipe(take(1))
			.toPromise();

		if (
			hasNft === true &&
			hasSeenBackupWarnModal === false &&
			hasBackupSecuredStorage === false
		) {
			try {
				this.sessionService.hasSeenBackupWarnModal = true;
				const detailModal = await this.modalCtrl.create({
					component: BackUpWarnModalComponent,
					cssClass: 'auto-height middle hard-round',
					componentProps: {
						logScreen: this.eventLogger.logScreen,
						logEvent: this.eventLogger.logEvent,
					},
				});

				await detailModal.present();
			} catch (e) {}
		}
	};
}
