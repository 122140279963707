import { Component, Input, OnInit } from '@angular/core';
import { NETWORK } from '@arianee/arianeejs';

import { Icon } from '../icon/models/icon.model';

interface ChainIconProps {
	network: NETWORK;
}

@Component({
	selector: 'app-chain-icon',
	templateUrl: './chain-icon.component.html',
	styleUrls: ['./chain-icon.component.scss'],
})
export class ChainIconComponent implements ChainIconProps, OnInit {
	@Input() network: NETWORK;

	public icon: Icon | null = null;

	ngOnInit() {
		this.setupIcon();
	}

	setupIcon() {
		const networkIsNotPolygon = [
			NETWORK.arianeeTestnet,
			NETWORK.mainnet,
			NETWORK.testnet,
			NETWORK.arialabs,
		].includes(this.network);

		if (networkIsNotPolygon) {
			this.icon = null;
		} else {
			this.icon = {
				name: 'icon-polygon',
				fontSize: 20,
				borderRadius: '13px',
				backgroundColor: 'rgba(var(--neutral-100), 0.5)',
			};
		}
	}
}
