import { Injectable } from '@angular/core';
import { AuthClientTypes } from '@walletconnect/auth-client';
import { getSdkError } from '@walletconnect/utils';
import { WalletSignMessageModalComponent } from '../../../components/wallet-connect-modals/wallet-sign-message-modal/wallet-sign-message-modal.component';
import { SupportedChain } from '../utils';
import WalletConnectV2EventManager from './event-manager';

@Injectable({
  providedIn: 'root'
})
export default class WalletConnectV2AuthEventManager extends WalletConnectV2EventManager<AuthClientTypes.Event> {
  public async handleEvent (
    event: AuthClientTypes.Event,
    args: AuthClientTypes.EventArguments[typeof event]
  ) {
    switch (event) {
      case 'auth_request':
        await this.handleAuthRequestEvent(
          args as AuthClientTypes.EventArguments['auth_request']
        );
        break;
      default:
        console.warn('[WCV2] Unhandled auth event', event, args);
        break;
    }
  }

  private async handleAuthRequestEvent (
    args: AuthClientTypes.EventArguments['auth_request']
  ) {
    const { approved } = await this.modalService.open(
      'auth_request',
      WalletSignMessageModalComponent,
      args
    );

    const { id, params } = args;
    const client = await this.utils.getClient();
    const iss = await this.utils.getIss();

    if (approved) {
      const messageToSign = client.formatMessage(params.cacaoPayload, iss);

      await this.prepareRequestHandler(SupportedChain.ETHEREUM);
      const signature = await this.requestHandler.signPersonalMessage(messageToSign);

      await client.respondAuthRequest(
        {
          id,
          signature: {
            s: signature,
            t: 'eip191'
          }
        },
        iss
      );
    } else {
      await client.respondAuthRequest(
        {
          id: id,
          error: getSdkError('USER_REJECTED')
        },
        iss
      );
    }
  }
}
