import {
	AfterViewInit,
	Component,
	ElementRef,
	OnInit,
	ViewChild,
} from '@angular/core';
import { NETWORK } from '@arianee/arianeejs';
import { IdentityBase } from '@arianee/arianeejs/dist/src/models';
import {
	DisplayMapperService,
	POAPNFT,
	ProductListItem,
} from '@arianeeprivate/wallet-shared-components';
import { NavController } from '@ionic/angular';
import { get } from 'lodash';
import { Subscription, combineLatest, forkJoin } from 'rxjs';

import { NFTCardProps } from '../../../../../components/redesign/nft-card/nft-card.component';
import { EventLoggerService } from '../../../../../providers/event-logger/event-logger-service';
import { MyCertificatesService } from '../../../../../providers/my-certificates/my-certificates.service';
import { PoapService } from '../../../../../providers/poap/poap.service';
import { poapCertificateSummaryMapper } from '../../../poap-detail/mapper/poap-certificate-summary-mapper';

type AugmentedNFTCardProps = NFTCardProps & {
	poapId: string;
};

@Component({
	selector: 'app-poap-nft-list-new',
	templateUrl: './poap-nft-list-new.component.html',
	styleUrls: ['./poap-nft-list-new.component.scss'],
})
export class PoapNftListNewComponent implements OnInit, AfterViewInit {
	@ViewChild('header', {
		read: ElementRef,
		static: false,
	})
	header: ElementRef<HTMLElement>;

	brandId: string;

	brandsCount = 0;
	brandName: string;
	brandLogo: string;
	isLoading = true;
	subProducts: Array<ProductListItem> = [undefined, undefined];
	poapSubscription = Subscription.EMPTY;

	public nftCards: AugmentedNFTCardProps[] = [];
	public headerHeight: number = 0;
	public onlyHavePoap = false;

	constructor(
		private navCtrl: NavController,
		private eventLogger: EventLoggerService,
		private poapService: PoapService,
		private displayMapperService: DisplayMapperService,
		private certificateService: MyCertificatesService,
	) {}

	ngOnInit() {
		this.getPoapNfts();
	}

	ngAfterViewInit() {
		this.setHeaderHeight();

		this.setOnlyHavePoap();
	}

	setOnlyHavePoap() {
		this.certificateService.getHasNft().subscribe((data) => {
			this.onlyHavePoap = !data;
		});
	}

	private setHeaderHeight() {
		this.headerHeight = (
			this.header.nativeElement.firstElementChild as HTMLElement
		).offsetHeight;

		// Retry if css not yet loaded
		if (this.headerHeight === 0)
			setTimeout(this.ngAfterViewInit.bind(this), 10);
	}

	async ionViewDidEnter() {
		this.eventLogger.logScreen('poap-nft-list', { brandId: this.brandId });
	}

	async ionViewDidLeave() {
		this.poapSubscription.unsubscribe();
	}

	getPoapNfts() {
		this.isLoading = true;
		this.poapSubscription = combineLatest([
			this.poapService.getMyPoapNfts(),
			this.poapService.getPoapIdentity(),
		]).subscribe(([poapList, identity]: [POAPNFT[], IdentityBase]) => {
			this.subProducts = poapList.map((data: POAPNFT) =>
				this.displayMapperService.poapListCertificateDisplayMapper({
					poapNFT: data,
					identity: identity,
				}),
			);

			if (this.subProducts.length > 0) {
				const brandLogoSquare = (
					get(identity, 'data.pictures', []).find(
						(picture) => picture.type === 'brandLogoSquare',
					) || {}
				).url;
				this.brandLogo = brandLogoSquare;
				this.brandName = this.subProducts[0].brandName;
				this.brandId = this.subProducts[0].brandName;
				this.nftCards = this.subProducts.map((product) => ({
					name: product.name,
					imageUrl: product.cornerPicture,
					isPending: false,
					network: NETWORK.mainnet,
					poapId: product.certificateId,
				}));
			}
			this.isLoading = false;
		});
	}

	onClickProduct(tokenId) {
		this.navCtrl.navigateForward(`/tab/brand-list/poap-detail/${tokenId}`);
	}

	onClickNavBack() {
		this.navCtrl.navigateBack('/tab/brand-list');
	}
}
