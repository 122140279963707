import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { Platform } from '@ionic/angular';
import { LoaderService } from '@arianeeprivate/wallet-shared-components';

@Injectable({
  providedIn: 'root'
})
export class PlatformReadyGuard implements CanActivate {
  constructor (private platformService:Platform, private loaderService:LoaderService) {
  }

  public canActivate (
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.platformService.ready().then(() => true);
  }
}
