import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';

import { getChainName } from '../../../providers/wallet-connect-service/chain-utils/chain-utils';
import { Button } from '../../redesign/button/models/button.model';
import { IModalHeaderConfig } from '../components/modal-header/modal-header.component';

@Component({
	selector: 'app-wallet-switch-chain-modal',
	templateUrl: './wallet-switch-chain-modal.component.html',
	styleUrls: ['./wallet-switch-chain-modal.component.scss'],
})
export class WalletSwitchChainModalComponent implements OnInit {
	@Input() logo: string;
	@Input() website: string;
	@Input() isVerified: boolean;
	@Input() chainId: number;

	chainName: string = '';

	headerConfig: IModalHeaderConfig = {
		logo: '',
		title: '',
		subtitle: {
			label: '',
			verified: false,
			logo: '',
		},
		description: '',
	};

	cancelButton: Button;
	switchChainButton: Button;

	constructor(
		public modalController: ModalController,
		private translateService: TranslateService,
	) {}

	async ngOnInit() {
		this.chainName = await getChainName(this.chainId);

		this.headerConfig.title = this.translateService.instant(
			'WalletConnect.switchNetwork.title',
			{ chainName: this.chainName },
		);

		this.headerConfig.logo = this.logo;
		this.headerConfig.subtitle.label = this.website;
		this.headerConfig.subtitle.verified = this.isVerified;
		this.initButtons();
	}

	private initButtons() {
		this.cancelButton = {
			title: {
				text: this.translateService.instant(
					'WalletConnect.switchNetwork.cancel',
				),
			},
			buttonType: 'text',
		};

		this.switchChainButton = {
			title: {
				text: this.translateService.instant(
					'WalletConnect.switchNetwork.switchChain',
				),
			},
			buttonType: 'primary',
		};
	}
}
