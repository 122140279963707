import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { ArianeeService } from '../../providers/arianee-service/arianee.service';
import { Subscription } from 'rxjs';
import { NETWORK } from '@arianee/arianeejs/dist/src';

interface SenderMessage{name:string;address:string, isAllowed:boolean, modifying:boolean}

@Component({
  selector: 'app-noti-option',
  templateUrl: './noti-option.component.html',
  styleUrls: ['./noti-option.component.scss']
})
export class NotiOptionComponent implements OnInit {
  @Input() certificateId: string;
  @Input() network: NETWORK;

  public senders:SenderMessage[]=[];
  public loading=true;

  constructor (
    private arianeeService:ArianeeService,
    private modalCtrl: ModalController
  ) { }

  ngOnInit () {
    this.arianeeService.getWalletInstance(this.network).then(async wallet => {
      await wallet.methods.getCertificate(this.certificateId, '', {
        messageSenders: true,
        issuer: true
      })
        .then(certificateSummary => {
          const issuerAddress = certificateSummary.issuer.identity.address;
          const issuerName = certificateSummary.issuer.identity.data.name;

          this.senders = [
            {
              name: issuerName,
              address: issuerAddress,
              isAllowed: certificateSummary.messageSenders[issuerAddress],
              modifying: false
            }
          ];
          console.info('init!!');
        })
        .finally(() => {
          this.loading = false;
        });
    });
  }

  onClickClose () {
    this.modalCtrl.dismiss();
  }

  async onToggle (senderToModify:SenderMessage) {
    const sender = this.senders.find(sender => sender.address === senderToModify.address);
    if (!sender) throw new Error('Sender not found');

    try {
      sender.modifying = true;

      const wallet = await this.arianeeService.getWalletInstance(this.network);
      await wallet.methods.setMessageAuthorizationFor(this.certificateId, senderToModify.address, !senderToModify.isAllowed);

      sender.isAllowed = !sender.isAllowed;
    } catch (e) {
      console.error('Error while changing message authorization', e);
    } finally {
      sender.modifying = false;
    }
  }
}
