import { Component } from '@angular/core';
import { IdentityBase } from '@arianee/arianeejs/dist/src/models';
import {
	DisplayMapperService,
	LoaderService,
	POAPNFT,
} from '@arianeeprivate/wallet-shared-components';
import { NavController, Platform } from '@ionic/angular';
import { Subscription, combineLatest } from 'rxjs';
import { map, tap } from 'rxjs/operators';

import { ArianeeBlockchainSyncService } from '../../../../../providers/arianee-blockchain-sync-service/arianee-blockchain-sync.service';
import { ArianeeService } from '../../../../../providers/arianee-service/arianee.service';
import { BackupCheckService } from '../../../../../providers/backup-check-service/backup-check.service';
import { BackupService } from '../../../../../providers/backup-service/backup.service';
import { DemoPassportService } from '../../../../../providers/demo-passport-service/demo-passport-service';
import { EventLoggerService } from '../../../../../providers/event-logger/event-logger-service';
import { FeatureFlipService } from '../../../../../providers/feature-flip-service/feature-flip.service';
import { MyCertificatesService } from '../../../../../providers/my-certificates/my-certificates.service';
import { PoapService } from '../../../../../providers/poap/poap.service';
import { UserService } from '../../../../../providers/user-service/user.service';

@Component({
	selector: 'app-brand-list-legacy',
	templateUrl: './brand-list-legacy.component.html',
	styleUrls: ['./brand-list-legacy.component.scss'],
	providers: [BackupCheckService],
})
export class BrandListLegacyComponent {
	groupedCertificates: any[] = [];
	poapIdentity!: IdentityBase;
	poapCount = 0;
	poapIsRefreshing = false;
	arianeeNFTCount = 0;
	public isIos = false;
	public hasBackup = false;
	poapSubscription = Subscription.EMPTY;

	private subscritions: Subscription[] = [];
	constructor(
		private navCtrl: NavController,
		private arianeeService: ArianeeService,
		private arianeeBkchainSync: ArianeeBlockchainSyncService,
		private loaderService: LoaderService,
		private userService: UserService,
		private eventLogger: EventLoggerService,
		private platform: Platform,
		private backupService: BackupService,
		private displayMapperService: DisplayMapperService,
		private backupCheckService: BackupCheckService,
		private demoPassportService: DemoPassportService,
		private featureFlipService: FeatureFlipService,
		private myCertificatesService: MyCertificatesService,
		private poapService: PoapService,
	) {}

	async ngOnInit() {
		this.loaderService.showLoader();
		this.isIos = this.platform.is('ios');

		const sub = this.myCertificatesService
			.getAllMyCertificatesGroupedByIssuer()
			.pipe(
				map((certificatesGroupedByIssuer) => {
					const issuers = Object.keys(certificatesGroupedByIssuer || {});

					return issuers.map((key) => {
						const item = certificatesGroupedByIssuer[key] || [];
						this.arianeeNFTCount = this.arianeeNFTCount + item.length;
						return item;
					});
				}),
				tap(() => {
					this.arianeeBkchainSync.syncWithBlockchain();
				}),
			)
			.subscribe(async (groupedCertificates: any[]) => {
				this.groupedCertificates = groupedCertificates;
				this.loaderService.dismiss();

				if (this.isIos) {
					await this.backupCheckService.openImportWallet();
				}
			});

		this.subscritions.push(sub);

		this.eventLogger.logScreen('brand-list', {});

		this.userService.getHasBackup().subscribe((hasBackup) => {
			this.hasBackup = hasBackup;
		});

		this.initPoap();
	}

	get shouldDisplayRecoverWallet(): boolean {
		return this.poapCount + this.arianeeNFTCount === 0;
	}

	/**
	 * Get Poap NFTs
	 * IF > 0 Get Poap Identity and display it
	 */
	initPoap() {
		this.poapSubscription = combineLatest([
			this.poapService.getMyPoapNfts(),
			this.poapService.getPoapIdentity(),
			this.poapService.$isFetchingPoapNfts,
		]).subscribe(
			([poapList, identity, isFetching]: [
				POAPNFT[],
				IdentityBase,
				boolean,
			]) => {
				this.poapCount = poapList.length;
				this.poapIdentity = identity;
				this.poapIsRefreshing = isFetching;
			},
		);
	}

	onClickScanFirstProduct() {
		this.navCtrl.navigateForward('tab/scan-full-page');
	}

	onClickProfile() {}

	onClickOnBrand(brandKey) {
		this.eventLogger.logEvent('brand-list_clickOnBrand', { address: brandKey });
		return this.navCtrl.navigateForward(
			'/tab/brand-list/brand-product-list/' + brandKey,
		);
	}

	onClickOnPoap() {
		this.eventLogger.logEvent('brand-list_clickOnBrand', {
			address: this.poapService.poapAddress,
		});
		return this.navCtrl.navigateForward('/tab/brand-list/poap-nft-list');
	}

	onClickBackupViewer() {
		this.eventLogger.logEvent('brand-list_clickBackupViewer');
		return this.navCtrl.navigateForward('/home-import');
	}

	ngOnDestroy() {
		this.poapSubscription.unsubscribe();
		this.subscritions.forEach((sub) => sub.unsubscribe());
	}

	backupCurrentWallet() {
		this.eventLogger.logEvent('brand-list_clickBackUpCurrentWallet');
		this.navCtrl.navigateForward('/home-backup');
	}

	requestDemoPassport() {
		this.demoPassportService.getDemoPassport();
	}
}
