import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { EMPTY } from 'rxjs';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CoinGeckoService {
  constructor (private http: HttpClient) { }

  public getCoinsList (includePlatform: boolean = false) {
    return this.http.get(`https://api.coingecko.com/api/v3/coins/list?include_platform=${includePlatform}`).toPromise();
  }

  public async getCoinBySymbol (symbol: string) {
    const coinsList = await this.getCoinsList() as Array<any>;
    return coinsList.find(x => x.symbol == symbol.toLowerCase());
  }

  public getPrices (ids: string[], vsCurrencies: string[] = ['usd']) {
    return this.http.get(`https://api.coingecko.com/api/v3/simple/price?ids=${ids.join(',')}&vs_currencies=${vsCurrencies.join(',')}`).toPromise();
  }
}
