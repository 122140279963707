import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';

import { Button } from '../button/models/button.model';
import { OnBoardingStep } from './models/on-boarding-step';
import { onBoardingSteps } from './steps/onboarding-steps';

@Component({
	selector: 'app-on-boarding',
	templateUrl: './on-boarding-modal.component.html',
	styleUrls: ['./on-boarding-modal.component.scss'],
	encapsulation: ViewEncapsulation.None,
})
export class OnBoardingModalComponent implements OnInit {
	onBoardingSteps: OnBoardingStep[];
	modal: HTMLIonModalElement;
	currentIndex = 0;
	currentStep: OnBoardingStep;
	nextButton: Button;
	backButton: Button;

	constructor(
		private modalCtrl: ModalController,
		private translate: TranslateService,
	) {}

	async ngOnInit() {
		this.modal = await this.modalCtrl.getTop();
		this.onBoardingSteps = onBoardingSteps;
		this.initButtons();
		this.initCurrentStep();
	}

	private initCurrentStep() {
		this.currentStep = {
			...this.onBoardingSteps[this.currentIndex],
			icon: {
				name: this.onBoardingSteps[this.currentIndex].icon.name,
				fontSize: 42,
				height: '72px',
				width: '72px',
				borderRadius: '16px',
			},
		};
	}

	private updateCurrentStep() {
		this.currentStep = {
			...this.onBoardingSteps[this.currentIndex],
			icon: {
				name: this.onBoardingSteps[this.currentIndex].icon.name,
				fontSize: 42,
				height: '72px',
				width: '72px',
				borderRadius: '16px',
			},
		};
	}

	private initButtons() {
		this.nextButton = {
			title: {
				text: this.translate.instant('OnBoardingInPassport.next'),
			},
			buttonType: 'primary',
			disabled: false,
		};

		this.backButton = {
			className: 'back-button',
			title: {
				text: 'Back',
			},
			buttonType: 'text',
			disabled: true,
		};
	}

	onCloseModal(): void {
		this.modalCtrl.dismiss({
			dismissed: true,
		});
	}

	private updateButtons() {
		this.backButton = {
			...this.backButton,
			disabled: this.currentIndex === 0,
		};

		this.nextButton = {
			...this.nextButton,
			title: {
				text:
					this.currentIndex === this.onBoardingSteps.length - 1
						? this.translate.instant('OnBoardingInPassport.gotIt')
						: this.translate.instant('OnBoardingInPassport.next'),
			},
		};
	}

	goToPreviousStep() {
		this.backButton.loading = false;

		if (this.currentIndex > 0) {
			this.currentIndex--;
			this.updateCurrentStep();
		}

		this.updateButtons();
	}

	goToNextStep() {
		this.nextButton.loading = false;

		if (this.currentIndex < this.onBoardingSteps.length - 1) {
			this.currentIndex++;
			this.updateCurrentStep();
		}

		this.updateButtons();
	}
}
