import { from, Observable, ReplaySubject } from 'rxjs';
import { take } from 'rxjs/operators';
import { SecureStorageObject } from '@ionic-native/secure-storage';

export class SecureStorageObservableCache {
    private _$store:ReplaySubject<string>=new ReplaySubject(1);
    private fetched=false;
    private lastValue:string;

    constructor (private storage:SecureStorageObject, private key:string, private defaultValue = undefined) {
      this.$store
        .subscribe(lastValue => {
          this.lastValue = lastValue;
        });
    }

    get $store () {
      return this._$store;
    }

    get ():Observable<string> {
      if (!this.fetched) {
        this.fetched = true;
        this.storage.get(this.key)
          .catch(i => this.defaultValue)
          .then(value => this.$store.next(value));
      }

      return this.$store;
    }

    getOnce ():Observable<string> {
      return this.get().pipe(take(1));
    }

    getValue () {
      if (!this.fetched) {
        throw new Error('this value has never be get before');
      }

      return this.lastValue;
    }

    hasKey ():Observable<boolean> {
      return from(this.storage.get(this.key)
        .then(value => value === undefined));
    }

    set (value:string):Observable<string> {
      this.fetched = true;

      this.storage.set(this.key, value);
      this.$store.next(value);
      return this.$store;
    }
}
