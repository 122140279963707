import { Injectable } from '@angular/core';
import { NETWORK } from '@arianee/arianeejs';
import ArianeeWallet from '@arianee/arianeejs/dist/src/core/wallet';
import { CertificateSummary } from '@arianee/arianeejs/dist/src/core/wallet/certificateSummary';
import axios from 'axios';
import { cloneDeep, get, intersectionBy } from 'lodash';
import { from } from 'rxjs';
import { take } from 'rxjs/operators';

import { CHAIN_TYPE_DETAILED, ChainType } from '../../types/multichain';
import { ArianeeService } from '../arianee-service/arianee.service';

export interface PendingNft extends CertificateSummary {
	addedToPendingAt: string;
	network?: NETWORK;
	chainType?: ChainType;
}

@Injectable({
	providedIn: 'root',
})
export class PendingNftService {
	static readonly LOCAL_STORAGE_KEY: string = 'pendingNfts';
	static readonly LOCAL_STORAGE_WALLET_KEY: string = 'pendingNftsWallet';
	static readonly PENDING_NFT_EXPIRATION: number = 7 * 24 * 60 * 60 * 1000;

	constructor(private arianeeService: ArianeeService) {
		this.arianeeService.$walletInitialize
			.pipe(take(1))
			.subscribe((network: NETWORK) => {
				from(this.arianeeService.getWalletInstance(network)).subscribe(
					(wallet) => {
						this.onNewWallet(wallet);
					},
				);
			});
	}

	private onNewWallet(wallet: ArianeeWallet) {
		const lastWalletAddress = localStorage.getItem(
			PendingNftService.LOCAL_STORAGE_WALLET_KEY,
		);

		if (!lastWalletAddress) {
			localStorage.setItem(
				PendingNftService.LOCAL_STORAGE_WALLET_KEY,
				wallet.address,
			);
		} else if (lastWalletAddress && wallet.address !== lastWalletAddress) {
			localStorage.setItem(
				PendingNftService.LOCAL_STORAGE_WALLET_KEY,
				wallet.address,
			);
			localStorage.setItem(PendingNftService.LOCAL_STORAGE_KEY, '[]');
		}
	}

	private getNetworks(chainType: ChainType): NETWORK[] {
		if (CHAIN_TYPE_DETAILED[chainType] !== undefined) {
			return CHAIN_TYPE_DETAILED[chainType].map(
				(chainDetails) => chainDetails.name,
			);
		}
	}

	/**
	 * Returns the pending nfts
	 * @param chainType
	 * @param certificates optional, if set, pending nfts that intersects
	 * with these certificates will be removed from pending nfts before returning the pending nfts
	 * @param removeExpired
	 * @returns an array of certificates
	 */
	public getPendingNfts(
		chainType: ChainType,
		certificates?: CertificateSummary[],
		removeExpired: boolean = false,
	): PendingNft[] {
		const networks = this.getNetworks(chainType);

		if (networks === undefined) {
			return [];
		}

		if (certificates) {
			this.refreshPendingNfts(chainType, certificates);
		}

		if (removeExpired) {
			this.removeExpiredPendingNfts(chainType);
		}

		try {
			return JSON.parse(
				localStorage.getItem(PendingNftService.LOCAL_STORAGE_KEY) || '[]',
			).filter((certificate: CertificateSummary) =>
				networks.includes(get(certificate, 'network')),
			);
		} catch {
			console.error(
				`Could not parse pending NFTs (value in local storage: ${localStorage.getItem(
					PendingNftService.LOCAL_STORAGE_KEY,
				)})`,
			);
			return [];
		}
	}

	public getPendingNft(
		chainType: ChainType,
		certificateId: number,
	): PendingNft | null {
		const networks = this.getNetworks(chainType);

		return (
			this.getPendingNfts(chainType).find(
				(certificate) =>
					get(certificate, 'certificateId') === certificateId &&
					networks.findIndex(
						(network) => network === get(certificate, 'network'),
					) !== -1,
			) || null
		);
	}

	public addPendingNft(chainType: ChainType, nft: CertificateSummary) {
		const pendingNfts = this.getPendingNfts(chainType);
		const pendingNftToAdd: PendingNft = {
			...nft,
			certificateId: +nft.certificateId,
			chainType,
			addedToPendingAt: new Date().toISOString(),
		};

		const alreadyInPendingNfts = !!pendingNfts.find(
			(pendingNft) =>
				+get(pendingNftToAdd, 'certificateId') ===
				+get(pendingNft, 'certificateId'),
		);

		if (alreadyInPendingNfts) {
			return;
		}

		pendingNfts.push(pendingNftToAdd);
		localStorage.setItem(
			PendingNftService.LOCAL_STORAGE_KEY,
			JSON.stringify(pendingNfts),
		);
	}

	private removePendingNft(chainType: ChainType, certificateId: number): void {
		const pendingNfts = this.getPendingNfts(chainType);
		const newPendingNfts = pendingNfts.filter(
			(pendingNft) =>
				+get(pendingNft, 'certificateId') !== certificateId ||
				(+get(pendingNft, 'certificateId') === certificateId &&
					get(pendingNft, 'chainType') !== chainType),
		);
		localStorage.setItem(
			PendingNftService.LOCAL_STORAGE_KEY,
			JSON.stringify(newPendingNfts),
		);
	}

	/**
	 * Removes from pending nfts the nfts that intersects with the certificates passed in parameter.
	 * (a pending nft is no longer a pending nft if it also is a non-pending nft).
	 * @param chainType
	 * @param certificates certificates to check intersection with
	 */
	private refreshPendingNfts(
		chainType: ChainType,
		certificates: CertificateSummary[],
	): void {
		const cloned = cloneDeep(certificates);
		cloned.forEach((certificate) => {
			Object.assign(certificate, { certificateId: +certificate.certificateId });
		});
		const inter = intersectionBy(
			cloned,
			this.getPendingNfts(chainType),
			'certificateId',
		);

		inter.forEach((certificate) => {
			const certificateId = +get(certificate, 'certificateId');
			this.removePendingNft(chainType, certificateId);
		});
	}

	/**
	 * Removes the pending nfts that have been pending for more than a certain duration
	 */
	private removeExpiredPendingNfts(chainType: ChainType) {
		(this.getPendingNfts(chainType) as PendingNft[]).forEach((pendingNft) => {
			const today = new Date();
			const pendingNftAddedAt = new Date(pendingNft.addedToPendingAt);
			if (
				today.getTime() - pendingNftAddedAt.getTime() >
				PendingNftService.PENDING_NFT_EXPIRATION
			) {
				this.removePendingNft(chainType, +get(pendingNft, 'certificateId'));
			}
		});
	}

	/**
	 * @param chainType
	 * @param certificates optional, if set, pending nfts that intersects
	 * with these certificates will be removed from pending nfts before returning the pending nfts
	 * @returns an array of pending nfts grouped by brand address
	 */
	public getPendingNftsGroupedByBrand(
		chainType: ChainType,
		certificates?: CertificateSummary[],
	): { [brandAddress: string]: CertificateSummary[] } {
		const res = {};

		this.getPendingNfts(chainType, certificates, true).forEach(
			(certificate) => {
				const brandAddress = get(certificate, 'issuer.identity.address', '');
				if (!Array.isArray(res[brandAddress])) {
					res[brandAddress] = [];
				}

				res[brandAddress].push(certificate);
			},
		);

		return res;
	}

	/**
	 * Creates a new array of the certificates passed in parameter merged with the pending certificates of brand `identityAddress`
	 * @param chainType
	 * @param certificates certificates to merge with pending certificates, pending nfts that intersects
	 * with these certificates will be removed from pending nfts before returning the pending nfts
	 * @param identityAddress
	 * @returns a new CertificateSummary array
	 */
	public mergeCertificatesWithPendingOfBrand(
		chainType: ChainType,
		certificates: CertificateSummary[],
		identityAddress: string,
	): CertificateSummary[] {
		return this.getPendingNfts(chainType, certificates, true)
			.filter(
				(pendingNft) =>
					get(pendingNft, 'issuer.identity.address', '').toLowerCase() ===
					identityAddress.toLowerCase(),
			)
			.map((certificate) => {
				delete certificate.network;
				delete certificate.addedToPendingAt;
				return certificate as CertificateSummary;
			})
			.concat(certificates || []);
	}

	public isPendingNft(certificateId: number, chainType: ChainType): boolean {
		return !!this.getPendingNfts(chainType).find(
			(certificate) => +get(certificate, 'certificateId') === +certificateId,
		);
	}

	private getNmpUrlFromDeferredClaimable(
		certificate: CertificateSummary,
	): string | null {
		const customAttributes: { type: string; value: string }[] = get(
			certificate,
			'content.data.customAttributes',
			[],
		);

		const deferredOne2One = customAttributes.find(
			(externalContent) => externalContent.type === 'deferred_claim',
		);

		if (!deferredOne2One) return null;
		return deferredOne2One.value;
	}

	/**
	 * Checks whether the certificate supports the deferred claim mechanism or not
	 * @param certificate the certificate to check support for
	 * @returns true if the certificate supports the deferred claim mechanism, false otherwise
	 */
	public supportsDeferredClaim(certificate: CertificateSummary): boolean {
		return !!this.getNmpUrlFromDeferredClaimable(certificate);
	}

	/**
	 * Claims the passed deferred claimable certificate
	 * @param certificate the certificate to claim
	 * @param passphrase the passphrase (requestKey) of the certificate to claim
	 * @param receiverAddress the address to send the nft to
	 * @returns a promise of the success state of the claim and an httpErrorCode if the claim failed
	 */
	public async deferredClaim(
		certificate: CertificateSummary,
		passphrase: string,
		receiverAddress: string,
	): Promise<
		| { success: true }
		| {
				success: false;
				nmpError:
					| 'nmp.back.deferredTransfer.alreadyOwner'
					| 'nmp.back.deferredTransfer.tokenNotFound'
					| 'nmp.back.deferredTransfer.issuerNotOwner'
					| 'nmp.back.deferredTransfer.passphraseNotRight'
					| 'nmp.back.deferredTransfer.errorFetchRequestKey'
					| 'nmp.back.deferredTransfer.errorTransfer';
		  }
	> {
		if (!this.supportsDeferredClaim(certificate))
			throw new Error('Certificate does not support deferred claim mechanism');

		const nmpUrl = this.getNmpUrlFromDeferredClaimable(certificate);

		if (!nmpUrl) throw new Error('Could not find nmp url in certificate');

		let cleanedNmpUrl: string;
		cleanedNmpUrl = nmpUrl.match(/^http(s)?:\/\//)
			? nmpUrl
			: 'https://' + nmpUrl;
		cleanedNmpUrl = cleanedNmpUrl.endsWith('/')
			? cleanedNmpUrl.slice(0, -1)
			: cleanedNmpUrl;

		try {
			await axios.post(`${cleanedNmpUrl}/deferred/transfer`, {
				tokenId: parseInt(certificate.certificateId),
				passphrase,
				address: receiverAddress,
			});
		} catch (error) {
			if (error.response) {
				if (error.response.data.code === 'nmp.back.deferredTransfer.pending') {
					return { success: true };
				}

				return { success: false, nmpError: error.response.data.code };
			}

			throw new Error('Request failed');
		}

		return { success: true };
	}
}
