import { Component, Input, OnInit } from '@angular/core';
import { CertificateSummary } from '@arianee/arianeejs/dist/src/core/wallet/certificateSummary';
import { Clipboard } from '@ionic-native/clipboard/ngx';
import { ModalController, Platform } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';

import { Icon } from '../../../../../components/redesign/icon/models/icon.model';

interface BrandAuthenticityModalProps {
	isContentAuthentic: boolean;
	identity: {
		brandName: string;
		isAuthentic: boolean;
		isVerified: boolean;
	} | null;
	certificateId: CertificateSummary['certificateId'];
}

type AuthenticityItem = {
	label: string;
	e2e: string;
	icon: Icon;
};

@Component({
	selector: 'app-brand-authenticity-modal',
	templateUrl: './brand-authenticity-modal.component.html',
	styleUrls: ['./brand-authenticity-modal.component.scss'],
})
export class BrandAuthenticityModalComponent
	implements BrandAuthenticityModalProps, OnInit
{
	@Input() certificateId: BrandAuthenticityModalProps['certificateId'];
	@Input() identity: BrandAuthenticityModalProps['identity'];
	@Input()
	isContentAuthentic: BrandAuthenticityModalProps['isContentAuthentic'];

	@Input() isWeb: boolean;

	static readonly LIST_VALID_ICON_COLOR = '#007AFF';
	static readonly LIST_INVALID_ICON_COLOR = '#ff2c55';

	static readonly LIST_VALID_ICON: Icon = {
		name: 'icon-check',
		fontSize: 22,
		fontWeight: 900,
		width: '32px',
		height: '32px',
	};

	static readonly LIST_INVALID_ICON: Icon = {
		...BrandAuthenticityModalComponent.LIST_VALID_ICON,
		name: 'icon-cancel',
	};

	public modal: HTMLIonModalElement;
	public headerIcon: Icon;
	public authenticityItems: AuthenticityItem[];

	constructor(
		private modalCtrl: ModalController,
		private translate: TranslateService,
		private clipboard: Clipboard,
		private platform: Platform,
	) {}

	async ngOnInit() {
		this.modal = await this.modalCtrl.getTop();
		const areAllValid = this.areAllValid();
		this.setupHeaderIcon(areAllValid);
		this.setupAuthenticityBlock(areAllValid);
	}

	private areAllValid(): boolean {
		if (!this.identity) return this.isContentAuthentic;
		return (
			this.isContentAuthentic &&
			this.identity.isAuthentic &&
			this.identity.isVerified
		);
	}

	private setupHeaderIcon(areAllValid: boolean) {
		this.headerIcon = {
			image: {
				url: `/assets/imgs/icons/${
					areAllValid ? 'arianee_blue_720' : 'arianee_red_720'
				}.png`,
				width: '72px',
				height: '72px',
			},
			backgroundColor: 'transparent',
		};
	}

	private setupAuthenticityBlock(areAllValid: boolean) {
		const iconColor = areAllValid
			? BrandAuthenticityModalComponent.LIST_VALID_ICON_COLOR
			: BrandAuthenticityModalComponent.LIST_INVALID_ICON_COLOR;

		this.authenticityItems = [
			{
				label: this.translate.instant(
					'ProductVerifiedDetails.authentic_certificate',
				),
				e2e: 'certificate-authenticity',
				icon: {
					...((this.isContentAuthentic &&
						BrandAuthenticityModalComponent.LIST_VALID_ICON) ||
						BrandAuthenticityModalComponent.LIST_INVALID_ICON),
					color: iconColor,
				},
			},
		];

		if (this.identity) {
			this.authenticityItems.push(
				{
					label: this.translate.instant(
						'ProductVerifiedDetails.verified_brand',
					),
					e2e: 'identity-verification',
					icon: {
						...((this.identity.isVerified &&
							BrandAuthenticityModalComponent.LIST_VALID_ICON) ||
							BrandAuthenticityModalComponent.LIST_INVALID_ICON),
						color: iconColor,
					},
				},
				{
					label: this.translate.instant(
						'ProductVerifiedDetails.authentic_brand',
					),
					e2e: 'identity-authenticity',
					icon: {
						...((this.identity.isAuthentic &&
							BrandAuthenticityModalComponent.LIST_VALID_ICON) ||
							BrandAuthenticityModalComponent.LIST_INVALID_ICON),
						color: iconColor,
					},
				},
			);
		}
	}

	public getValidColor() {
		return BrandAuthenticityModalComponent.LIST_VALID_ICON_COLOR;
	}

	public getInvalidColor() {
		return BrandAuthenticityModalComponent.LIST_INVALID_ICON_COLOR;
	}

	public onCloseModal(): void {
		this.modalCtrl.dismiss({
			dismissed: true,
		});
	}
}
