import {
	AfterViewInit,
	Component,
	ElementRef,
	OnInit,
	ViewChild,
} from '@angular/core';
import { Router } from '@angular/router';
import { NavController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { ReplaySubject } from 'rxjs';
import { take } from 'rxjs/operators';

import { Button } from '../../../../../components/redesign/button/models/button.model';
import { Icon } from '../../../../../components/redesign/icon/models/icon.model';
import { ArianeeService } from '../../../../../providers/arianee-service/arianee.service';
import { EventLoggerService } from '../../../../../providers/event-logger/event-logger-service';
import { MnemonicService } from '../../../../../providers/mnemonic-service/mnemonic.service';
import { UserService } from '../../../../../providers/user-service/user.service';

@Component({
	selector: 'app-backup-viewer-new',
	templateUrl: './backup-viewer-new.component.html',
	styleUrls: ['./backup-viewer-new.component.scss'],
})
export class BackupViewerNewComponent implements OnInit, AfterViewInit {
	@ViewChild('header', {
		read: ElementRef,
		static: false,
	})
	header: ElementRef<HTMLElement>;

	public headerHeight: number = 0;

	public button: Button = {
		title: { text: 'backup' },
	};

	public buttonE2e: string = '';

	public $suggestion: ReplaySubject<string> = new ReplaySubject(1);

	public state: 'mnemonic-hidden' | 'mnemonic-visible' = 'mnemonic-hidden';
	public mnemonic: string = '';

	constructor(
		private eventLogger: EventLoggerService,
		private navController: NavController,
		private router: Router,
		private arianeeService: ArianeeService,
		private userService: UserService,
		private translateService: TranslateService,
	) {}

	ngOnInit() {
		this.updateButton({
			text: this.translateService.instant('Backup.backupManuallyRevealBtn'),
			color: 'default',
			disabled: false,
			e2e: 'reveal-mnemonic',
			loading: false,
			icon: 'key',
		});
	}

	async ngAfterViewInit() {
		this.log('screen', 'backup-viewer-new');
		this.setHeaderHeight();
		await this.setMnemonic();
	}

	private async setMnemonic() {
		this.mnemonic = await this.userService
			.getMnemonic()
			.pipe(take(1))
			.toPromise();
	}

	private setHeaderHeight() {
		this.headerHeight = (
			this.header.nativeElement.firstElementChild as HTMLElement
		).offsetHeight;

		// Retry if css not yet loaded
		if (this.headerHeight === 0)
			setTimeout(this.ngAfterViewInit.bind(this), 10);
	}

	public onClickBack() {
		this.navController.navigateBack(['/tab/setting']);
	}

	public get dictionary() {
		return MnemonicService.WORDS_DICTIONARY;
	}

	private updateButton({
		text,
		color,
		disabled,
		loading = false,
		e2e = 'import',
		icon = null,
	}: {
		text: string;
		color: 'default' | 'invalid';
		disabled: boolean;
		loading: boolean;
		e2e: string;
		icon?: 'key' | null;
	}) {
		const foreground = disabled ? 'black' : undefined;
		const background = disabled ? 'var(--neutral-100)' : 'var(--neutral-1000)';
		const _icon: Icon =
			icon === 'key'
				? {
						backgroundColor: 'transparent',
						fontSize: 20,
						name: 'icon-key',
						width: '20px',
						height: '20px',
						color: 'var(--neutral-0)',
				  }
				: null;

		this.button = {
			...this.button,
			title: {
				text,
				color: foreground,
			},
			buttonType: 'primary',
			customStyle: {
				...this.button.customStyle,
				backgroundColor: background,
			},
			disabled,
			loading,
			icon: _icon,
		};

		this.buttonE2e = e2e;
	}

	private async log(type: 'screen' | 'event', name: string) {
		const address = await this.arianeeService.$address
			.pipe(take(1))
			.toPromise();

		if (type === 'screen') {
			this.eventLogger.logScreen(name, {
				publicKey: address,
			});
		} else {
			await this.eventLogger.logEvent(name, {
				publicKey: address,
			});
		}
	}

	public nextState() {
		switch (this.state) {
			case 'mnemonic-hidden':
				this.state = 'mnemonic-visible';
				this.updateButton({
					text: this.translateService.instant('Backup.backupManuallyTestBtn'),
					color: 'default',
					disabled: false,
					e2e: 'test-yourself',
					loading: false,
				});
				this.log('event', 'backup-import-new_reveal-mnemonic');

				break;
			case 'mnemonic-visible':
				this.router.navigate(['/backup-sort']);
				this.log('event', 'backup-import-new_test-yourself');
				break;
		}
	}
}
