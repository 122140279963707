import {
	AfterViewInit,
	Component,
	ElementRef,
	Input,
	OnInit,
	ViewChild,
	ViewEncapsulation,
} from '@angular/core';
import { ModalController } from '@ionic/angular';

import { Icon } from '../icon/models/icon.model';
import { DescriptionModalComponent } from './description-modal/description-modal.component';

@Component({
	selector: 'app-description',
	templateUrl: './description.component.html',
	styleUrls: ['./description.component.scss'],
	encapsulation: ViewEncapsulation.None,
})
export class DescriptionComponent implements OnInit, AfterViewInit {
	@ViewChild('descriptionContent', { static: true })
	descriptionContent: ElementRef<HTMLElement>;

	@Input() modalHeader: { title: string; icon?: Icon };
	@Input() text: string;
	@Input() isWeb: boolean;
	isTextOverflowing = false;

	constructor(private modalCtrl: ModalController) {}

	ngOnInit() {}

	ngAfterViewInit() {
		setTimeout(() => {
			this.textOverflow();
		}, 200);
	}

	async openModal() {
		const modal = await this.modalCtrl.create({
			component: DescriptionModalComponent,
			cssClass: 'modal--bottom',
			swipeToClose: true,
			componentProps: {
				description: this.text,
				title: this.modalHeader.title,
				icon: this.modalHeader.icon,
				isWeb: this.isWeb,
			},
		});
		return modal.present();
	}

	textOverflow() {
		const descriptionEl = this.descriptionContent.nativeElement;
		this.isTextOverflowing =
			descriptionEl.scrollHeight > descriptionEl.clientHeight;
	}
}
