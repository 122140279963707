import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';

import { TranslateService } from '@ngx-translate/core';

import { EventLoggerService } from '../../providers/event-logger/event-logger-service';
import { UserService } from 'src/app/providers/user-service/user.service';

@Component({
  selector: 'app-privacy-policy-popover',
  templateUrl: './privacy-policy-popover.component.html',
  styleUrls: ['./privacy-policy-popover.component.scss']
})
export class PrivacyPolicyPopover implements OnInit {
  public loader = true;
  public locale;
  public privacyPolicies;
  public termOfUse;

  constructor (
    private modalCtrl: ModalController,
    public translate: TranslateService,
    private eventLogger: EventLoggerService,
    private userService: UserService
  ) {
  }

  ngOnInit () {
    this.eventLogger.logScreen('privacy-policy');
    this.locale = this.translate.currentLang;
  }

  agree () {
    this.userService.hasAgreePrivacyPolicy.set(true);
    this.modalCtrl.dismiss();
  }
}
