import { MediaItemCarousel } from '../../../../components/redesign/media-carousel/models/media-item-carousel.model';
import { getYoutubeMedia } from '../../product-detail/utils/youtube/youtube.helper';

export function messageMapper(notifications) {
	return (notifications || [])
		.map((notification) => {
			return {
				id: notification.id,
				brandLogo: notification.smallBrandImage,
				title: notification.title,
				receptionTime: notification.time,
				isUnread: !notification.isRead,
				content: {
					text: notification.message,
					medias: getMedias(notification.medias),
					externalContents: notification.externalContents,
				},
				issuer: notification.issuer,
			};
		})
		.sort((a, b) => {
			return (
				new Date(a.receptionTime).getTime() -
				new Date(b.receptionTime).getTime()
			);
		});
}

// get medias

export function getMedias(medias): MediaItemCarousel[] {
	return (medias || []).map((media) => {
		if (media.mediaType === 'youtube') {
			const youtubeMedia = getYoutubeMedia(media);
			return {
				url: youtubeMedia.embedURL,
				type: media.mediaType,
				preview: youtubeMedia.thumbnail,
			};
		}

		return {
			url: media.url,
			type: media.mediaType || 'picture',
			preview: undefined,
		};
	});
}
