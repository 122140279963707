import { Injectable } from '@angular/core';
import { NavController, Platform } from '@ionic/angular';
import { QRScanner, QRScannerStatus } from '@ionic-native/qr-scanner/ngx';
import { from } from 'rxjs';
import { delay, take } from 'rxjs/operators';
import { NETWORK } from '@arianee/arianeejs';
import { ArianeeService } from '../arianee-service/arianee.service';

@Injectable({
  providedIn: 'root'
})
export class QrCodeService {
  constructor (private originalQrScanner: QRScanner,
    private platform: Platform,
    private navController: NavController,
    private arianeeService: ArianeeService
  ) {
  }

  private shouldBeMock () {
    let shouldBeMock = true;
    try {
      shouldBeMock = !this.platform.is('cordova');
    } catch {}

    return shouldBeMock;
  }

  private handleArianeeTestnetScan () {
    this.arianeeService.$walletInitialize.subscribe(network => {
      if (network === NETWORK.arianeeTestnet) {
        this.navController.navigateForward('/tab/scan/product-detail/arianeetestnet/28408/6ohswjthzrhl/requestOwnership');
      }
    });
  }

  public get qrScanner (): QRScanner {
    if (!this.shouldBeMock()) {
      return this.originalQrScanner;
    } else {
      console.warn('qr code scanner is mocked for browser');
      return {
        prepare (): Promise<any> {
          return Promise.resolve();
        },
        scan: () => {
          this.handleArianeeTestnetScan();

          return from(['https://test.arian.ee/15280342,r2xutq1kraos'])
            .pipe(
              delay(1000),
              take(1));
        },
        destroy () { },
        show () {

        },
        hide () {

        },
        getStatus (): Promise<QRScannerStatus> {
          return Promise.resolve({
            authorized: true,
            denied: true,
            restricted: true,
            prepared: true,
            showing: true,
            scanning: true,
            previewing: true,
            lightEnabled: true,
            canOpenSettings: true,
            canEnableLight: true,
            canChangeCamera: true,
            currentCamera: 2
          });
        }
      } as QRScanner;
    }
  }
}
