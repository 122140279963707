import { AuthClientTypes } from '@walletconnect/auth-client';
import { SignClientTypes } from '@walletconnect/types';
import { WalletConnectRequestHandlerService } from '../../wallet-connect-service/wallet-connect-request-handler/wallet-connect-requestHandler.service';
import WalletConnectV2ModalService from '../modal-service/modal.service';
import WalletConnectV2Utils, { SupportedChain } from '../utils';

export default abstract class WalletConnectV2EventManager<
  Event extends SignClientTypes.Event | AuthClientTypes.Event,
  EventArgs = Event extends SignClientTypes.Event
    ? SignClientTypes.EventArguments[Event]
    : AuthClientTypes.EventArguments[Exclude<AuthClientTypes.Event, SignClientTypes.Event>]
> {
  constructor (
    protected modalService: WalletConnectV2ModalService,
    protected requestHandler: WalletConnectRequestHandlerService,
    protected utils: WalletConnectV2Utils
  ) {}

  public abstract handleEvent(event: Event, args: EventArgs): void;

  protected async prepareRequestHandler (chain: SupportedChain) {
    if (!this.utils.getSupportedChains().includes(chain)) throw new Error(`Unsupported chain ${chain}`);
    const chainId = parseInt(chain.split(':')[1]);

    await this.requestHandler.init(chainId);
  }
}
