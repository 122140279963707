import {
	ChangeDetectionStrategy,
	Component,
	Input,
	OnInit,
} from '@angular/core';

import { ListItem } from './models/list-item.model';

@Component({
	selector: 'app-list',
	templateUrl: './list.component.html',
	styleUrls: ['./list.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ListComponent implements OnInit {
	@Input() listItems: ListItem[];
	@Input() title: string;
	@Input() clickTitle: () => void;

	ngOnInit() {
		this.listItems = this.listItems.filter((item) => item);
	}
}
