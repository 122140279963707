export const hubspotGlobalParams = {
  region: 'na1',
  target: '#hubspotForm'
};

export const hubspotForms = {
  'fr-FR': {
    ...hubspotGlobalParams,
    portalId: '9335516',
    formId: 'b4925431-37d4-4578-b94b-8af6d927583b',
    locale: 'fr'
  },
  'en-US': {
    ...hubspotGlobalParams,
    portalId: '9335516',
    formId: 'c09151a0-f078-4b00-a1b5-78781fab4e24'
  }
};
