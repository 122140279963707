import { Injectable } from '@angular/core';
import { backupType } from '@arianeeprivate/wallet-shared-components';
import { ethers } from 'ethers';
import { Lens } from 'lens-protocol';
import { take, tap } from 'rxjs/operators';

import { ArianeeService } from '../arianee-service/arianee.service';
import { BackupService } from '../backup-service/backup.service';

@Injectable({
	providedIn: 'root',
})
export class MnemonicService {
	private static _wordsDictionary: string[] = [];

	constructor(
		private arianeeService: ArianeeService,
		private backupService: BackupService,
	) {}

	public static get WORDS_DICTIONARY(): readonly string[] {
		if (MnemonicService._wordsDictionary.length === 0) {
			const arr = [];
			for (let i = 0; i < 2048; i++) {
				arr.push(ethers.wordlists.en.getWord(i));
			}

			MnemonicService._wordsDictionary = arr;
		}

		return MnemonicService._wordsDictionary;
	}

	public async setNewMnemonic(mnemonic: string): Promise<boolean> {
		try {
			await this.arianeeService
				.switchWallet(mnemonic)
				.pipe(take(1))
				.toPromise();

			return true;
		} catch (e) {
			console.error(e);
			return false;
		}
	}

	public async markMnemonicAsBackedUp(
		backUpType: backupType,
	): Promise<boolean> {
		try {
			await this.backupService
				.setBackup(Date.now(), true, backUpType)
				.pipe(take(1))
				.toPromise();

			return true;
		} catch (e) {
			console.error(e);
			return false;
		}
	}
}
