import { Component, Input } from '@angular/core';
import { NETWORK } from '@arianee/arianeejs';

export interface NFTCardProps {
	backgroundUrl?: string;
	imageUrl: string;
	name: string;
	network: NETWORK;
	isPending: boolean;
}

@Component({
	selector: 'app-nft-card',
	templateUrl: './nft-card.component.html',
	styleUrls: ['./nft-card.component.scss'],
})
export class NFTCardComponent implements NFTCardProps {
	@Input() backgroundUrl?: string;
	@Input() imageUrl: string;
	@Input() name: string;
	@Input() network: NETWORK;
	@Input() isPending: boolean;
}
