import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import moment from 'moment/moment';

@Pipe({
	name: 'receptionTime',
})
export class ReceptionTimePipe implements PipeTransform {
	private readonly receivedAtTranslation: string;

	constructor(private translate: TranslateService) {
		this.receivedAtTranslation = this.translate.instant(
			'Notification.receivedAt',
		);
	}

	transform(value: string): string {
		const lang = navigator.language;
		const format = lang.startsWith('fr')
			? ` D MMMM, YYYY [${this.receivedAtTranslation}] h:mm A`
			: `MMMM D, YYYY [${this.receivedAtTranslation}] h:mm A`;

		return moment(value).format(format);
	}
}
