import { Injectable } from '@angular/core';
import {
	LoaderService,
	ToasterService,
} from '@arianeeprivate/wallet-shared-components';
import { take } from 'rxjs/operators';

import { GoogleDriveResponse, googleDriveFile } from '../../models/googleDrive';
import { ArianeeService } from '../arianee-service/arianee.service';
import { UserService } from '../user-service/user.service';
import { GoogleDriveApiService } from './google-drive-api.service';

@Injectable({
	providedIn: 'root',
})
export class GoogleDriveService {
	constructor(
		private userService: UserService,
		private arianeeService: ArianeeService,
		private loaderService: LoaderService,
		private toasterService: ToasterService,
		private googleDriveApiService: GoogleDriveApiService,
	) {}

	private async getLegacyArianeeDriveFolders(
		accessToken,
	): Promise<googleDriveFile[]> {
		const fileList: any =
			await this.googleDriveApiService.requestFilesWithQuery(
				accessToken,
				"trashed=false and name='arianee' and mimeType='application/vnd.google-apps.folder'",
			);
		return fileList.files;
	}

	public async backupMnemonic() {
		try {
			const accessObject = await this.googleDriveApiService.login();

			const arianeeFolder = await this.getArianeeBackupDriveFolder(
				accessObject.accessToken,
			);
			const mnemonic = await this.userService
				.getEncryptedMnemonic()
				.pipe(take(1))
				.toPromise();
			const data = { mnemonic: mnemonic };
			const blob = new Blob([JSON.stringify(data, undefined, 2)], {
				type: 'application/json',
			});
			const name = await this.createBackupName();

			return this.googleDriveApiService.uploadFile(
				blob,
				name,
				arianeeFolder,
				accessObject.accessToken,
			);
		} catch (e) {
			this.loaderService.dismiss();
			this.toasterService.showRedesigned({
				duration: 2000,
				title: 'Backup.failed',
				color: 'danger',
				icon: 'cancel',
				position: 'top',
			});
		}
	}

	public async createBackupName(date?: number) {
		const address = await this.arianeeService.$address
			.pipe(take(1))
			.toPromise();
		const now = date || Date.now();

		return `backup-${address}-${now}`;
	}

	public getDateFromName(file) {
		return new Date(+file.name.split('-').pop());
	}

	private decodeFileName(name: string) {
		return {
			address: name.split('-')[1],
			date: new Date(+name.split('-').pop()),
		};
	}

	public sortByDate(a, b) {
		if (a.date.valueOf() > b.date.valueOf()) {
			return -1;
		} else if (a.date.valueOf() < b.date.valueOf()) {
			return 1;
		} else {
			return 0;
		}
	}

	private async getBackupActualWallet() {
		const accessObject = await this.googleDriveApiService.login();
		const arianeeFolder = await this.getArianeeBackupDriveFolder(
			accessObject.accessToken,
		).catch(() => {});
		if (arianeeFolder === undefined) {
			return false;
		}
		const address = await this.arianeeService.$address
			.pipe(take(1))
			.toPromise();
		const query = "name contains '" + address + "'";
		const fileList: any =
			await this.googleDriveApiService.searchFileInAppFolder(
				accessObject.accessToken,
				query,
			);
		return fileList.files.map((file) => {
			file.date = file.date = this.getDateFromName(file);
			return file;
		});
	}

	/**
	 * Logout from googleplus
	 */

	public logout() {
		return this.googleDriveApiService.logout();
	}

	public async checkBackup() {
		const files = await this.getBackupActualWallet();

		if (files === undefined) {
			return {};
		}

		files.sort(this.sortByDate);
		const email = await this.getDriveMail();
		return { files, email };
	}

	public async getDriveMail() {
		const accessObject = await this.googleDriveApiService.login();
		return accessObject.email;
	}

	public async getBackups(): Promise<{ id: string; date: Date }[]> {
		const accessObject = await this.googleDriveApiService.login();
		const getArianeeBackupDriveFolder = await this.getArianeeBackupDriveFolder(
			accessObject.accessToken,
		);

		const arianeeLegacyFolders = await this.getLegacyArianeeDriveFolders(
			accessObject.accessToken,
		);
		if (arianeeLegacyFolders.length > 0) {
			await this.cleanArianeeFolder(
				arianeeLegacyFolders,
				getArianeeBackupDriveFolder,
				accessObject,
			);
		}

		const query = `trashed=false and '${getArianeeBackupDriveFolder}' in parents`;

		const fileList: any =
			await this.googleDriveApiService.searchFileInAppFolder(
				accessObject.accessToken,
				query,
			);

		fileList.files.map((file) => {
			file.date = this.getDateFromName(file);
			return file;
		});

		return fileList.files;
	}

	isBackupFile(file) {
		const hasRightNameShape = file.name.split('-').length === 3;
		const startWithBackup = file.name.split('-')[0] === 'backup';
		return hasRightNameShape && startWithBackup;
	}

	async cleanArianeeFolder(
		arianeeLegacyFolders: googleDriveFile[],
		newFolder,
		accessObject,
	) {
		const fileList: { [key: string]: GoogleDriveResponse } = {};
		for (const folder of arianeeLegacyFolders) {
			const query = `trashed=false and '${folder.id}' in parents`;
			fileList[folder.id] =
				await this.googleDriveApiService.requestFilesWithQuery(
					accessObject.accessToken,
					query,
				);
		}

		if (Object.keys(fileList).length === 0) {
			return;
		}

		for (const folderId in fileList) {
			const folder = fileList[folderId];
			fileList[folderId].files = folder.files.filter((file) =>
				this.isBackupFile(file),
			);
			if (fileList[folderId].files.length === 0) {
				delete fileList[folderId];
			}
		}

		const filteredBackupName = [];
		for (const folderId in fileList) {
			filteredBackupName.push(...fileList[folderId].files);
		}

		filteredBackupName.forEach(async (file) => {
			await this.googleDriveApiService.copyFile(
				accessObject.accessToken,
				file.id,
				[newFolder],
			);
		});

		const query = `trashed=false and '${newFolder}' in parents`;
		const movedFiles: any =
			await this.googleDriveApiService.searchFileInAppFolder(
				accessObject.accessToken,
				query,
			);

		const addressMovedFiles = movedFiles.files.map((file) => {
			return this.decodeFileName(file.name).address;
		});

		const addressBackupFiles = filteredBackupName.map((file) => {
			return this.decodeFileName(file.name).address;
		});

		const allBackup = addressBackupFiles
			.map((address) => {
				return addressMovedFiles.indexOf(address) === -1;
			})
			.findIndex((isBackuped) => isBackuped === false);

		if (allBackup === -1 && filteredBackupName.length > 0) {
			await this.deleteLegacyFolders(accessObject, fileList);
		}
	}

	private async deleteLegacyFolders(
		accessObject,
		fileList: { [key: string]: GoogleDriveResponse },
	) {
		for (const folderId in fileList) {
			await this.googleDriveApiService.deleteFile(
				accessObject.accessToken,
				folderId,
			);
		}
	}

	private async getArianeeBackupDriveFolder(accessToken): Promise<string> {
		const backupFolderName = '_WalletBackup';
		const query = `trashed=false and name='${backupFolderName}'`;
		const backupDriveFoldersRequest: any =
			await this.googleDriveApiService.searchFileInAppFolder(
				accessToken,
				query,
			);
		let backupDriveFolder: googleDriveFile = backupDriveFoldersRequest.files[0];
		if (!backupDriveFolder) {
			backupDriveFolder = await this.googleDriveApiService.createFolder(
				accessToken,
				backupFolderName,
				['appDataFolder'],
			);
		}
		return backupDriveFolder.id;
	}

	public async getFileContent(fileId): Promise<{ mnemonic: string }> {
		const accessObject = await this.googleDriveApiService.login();
		const fileContent = await this.googleDriveApiService.getFileContent(
			fileId,
			accessObject.accessToken,
		);

		return fileContent as { mnemonic: string };
	}
}
