import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { CoinGeckoService } from 'src/app/providers/coingecko-service/coingecko-service';
import Web3 from 'web3';

import { Button } from '../../redesign/button/models/button.model';
import { IModalHeaderConfig } from '../components/modal-header/modal-header.component';
import {
	SendTransactionModalProps,
	SignTransactionModalProps,
} from '../types/wallet-connect-modal';

@Component({
	selector: 'app-wallet-transaction-modal',
	templateUrl: './wallet-transaction-modal.component.html',
	styleUrls: ['./wallet-transaction-modal.component.scss'],
})
export class WalletTransactionModalComponent
	implements OnInit, SignTransactionModalProps, SendTransactionModalProps
{
	@Input() logo: SignTransactionModalProps['logo'];
	@Input() website: SignTransactionModalProps['website'];
	@Input() isVerified: SignTransactionModalProps['isVerified'];

	@Input() payload: SignTransactionModalProps['payload'];
	@Input() gasPrice: SignTransactionModalProps['gasPrice'];
	@Input() signOnly: SignTransactionModalProps['signOnly'];

	headerConfig: IModalHeaderConfig = {
		logo: '',
		title: '',
		subtitle: {
			label: '',
			verified: false,
		},
		description: '',
	};

	public data: string;
	public symbol: string;

	public value: string;
	public estimatedFees: string;
	public total: string;

	public valueDollars: string = null;
	public estimatedFeesDollars: string = null;
	public totalDollars: string = null;
	acceptButton: Button;
	refuseButton: Button;

	constructor(
		public modalController: ModalController,
		public translateService: TranslateService,
		public coinGeckoService: CoinGeckoService,
	) {}

	async ngOnInit() {
		if (!this.signOnly)
			this.headerConfig.title = this.translateService.instant(
				'WalletConnect.transaction.sendTx',
			);
		else
			this.headerConfig.title = this.translateService.instant(
				'WalletConnect.transaction.signTx',
			);

		this.headerConfig.logo = this.logo;
		this.headerConfig.subtitle.label = this.website;
		this.headerConfig.subtitle.verified = this.isVerified;

		const txParams = this.payload.params[0];
		const web3 = new Web3();

		const gasToWei = web3.utils.toWei(
			web3.utils.toBN(Math.round(this.gasPrice.fastest)),
			'gwei',
		);
		const estimatedFeesBN = web3.utils
			.toBN(txParams.gas || txParams.gasLimit)
			.mul(gasToWei);

		let valueBn = web3.utils.toBN('0');
		if (txParams.value) valueBn = web3.utils.toBN(txParams.value);

		this.value = web3.utils.fromWei(valueBn, 'ether');
		this.estimatedFees = web3.utils.fromWei(estimatedFeesBN, 'ether');
		this.total = web3.utils.fromWei(estimatedFeesBN.add(valueBn), 'ether');

		this.data = web3.utils.hexToAscii(txParams.data);

		const symbolCoin = await this.coinGeckoService.getCoinBySymbol(this.symbol);
		if (!symbolCoin || !symbolCoin.id) return;

		const symbolId = symbolCoin.id;

		const symbolPrice = await this.coinGeckoService.getPrices([symbolId]);
		if (!symbolPrice || !symbolPrice[symbolId].usd) return;

		const symbolUsdPrice = symbolPrice[symbolId].usd;

		this.valueDollars = `${(Number(this.value) * symbolUsdPrice).toFixed(2)}`;
		this.totalDollars = `${(Number(this.total) * symbolUsdPrice).toFixed(2)}`;

		if (!this.signOnly)
			this.estimatedFeesDollars = `${(
				Number(this.estimatedFees) * symbolUsdPrice
			).toFixed(2)}`;

		this.initButtons();
	}

	private initButtons() {
		this.acceptButton = {
			title: {
				text:
					this.signOnly === true
						? this.translateService.instant('WalletConnect.transaction.sign')
						: this.translateService.instant('WalletConnect.transaction.send'),
			},
			buttonType: 'primary',
		};

		this.refuseButton = {
			title: {
				text: this.translateService.instant('WalletConnect.transaction.refuse'),
			},
			buttonType: 'text',
		};
	}
}
