import { Directive, ElementRef, Renderer2 } from '@angular/core';

@Directive({
  selector: '[libImageRatioFullWidth]'
})
export class ImageRatioFullWidthDirective {
  constructor (
        private elementRef: ElementRef<HTMLImageElement>,
        private renderer: Renderer2
  ) {
    this.elementRef.nativeElement.onload = () => {
      const width: number = this.elementRef.nativeElement.clientWidth;
      const height: number = this.elementRef.nativeElement.clientHeight;
      if (width > 0 && height > 0 && width === height) {
        this.renderer.setStyle(this.elementRef.nativeElement, 'width', '100%');
      }
    };
  }
}
