import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';

import { IsAuthGuard } from '../../../guards/isAuth.guard';
import { SharingService } from '../../../providers/sharing-service/sharing.service';
import { Button } from '../button/models/button.model';
import { Icon } from '../icon/models/icon.model';

@Component({
	selector: 'app-qr-code-modal',
	templateUrl: './qr-code-modal.component.html',
	styleUrls: ['./qr-code-modal.component.scss'],
})
export class QrCodeModalComponent implements OnInit {
	@Input() header: { title: string; icon: Icon };
	@Input() linkCodeCreation: () => Promise<
		| {
				certificateId: number;
				link: string;
				passphrase: string;
		  }
		| string
	>;

	@Input() button: Button;
	@Input() isAuth: boolean = false;

	@Input() isWeb: boolean;

	displayLoader = false;
	qrCodeLink: string;

	modal: HTMLIonModalElement;

	constructor(
		private sharingService: SharingService,
		protected modalCtrl: ModalController,
		protected authGuard: IsAuthGuard,
	) {}

	async ngOnInit() {
		this.modal = await this.modalCtrl.getTop();

		if (this.isAuth) {
			const authenticated = await this.authGuard.isAuth();
			if (!authenticated) {
				await this.modal.dismiss();
				return;
			}
		}

		await this.createLinkCode();
	}

	async createLinkCode() {
		this.displayLoader = true;
		const link = await this.linkCodeCreation();
		if (typeof link === 'string') {
			this.qrCodeLink = link as string;
		} else {
			this.qrCodeLink = link.link as string;
		}

		this.displayLoader = false;
	}

	onCloseModal(): void {
		this.modalCtrl.dismiss({
			dismissed: true,
		});
	}

	onShare() {
		this.sharingService.shareLink('', '', this.qrCodeLink);
	}
}
