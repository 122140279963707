import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { NavController } from '@ionic/angular';

import { EventLoggerService } from '../../../../../providers/event-logger/event-logger-service';

@Component({
	selector: 'app-backup-viewer-legacy',
	templateUrl: './backup-viewer-legacy.component.html',
	styleUrls: ['./backup-viewer-legacy.component.scss'],
})
export class BackupViewerLegacyComponent implements OnInit {
	emailAddress = '';

	constructor(
		private navCtrl: NavController,
		private location: Location,
		private eventLogger: EventLoggerService,
	) {}

	ngOnInit() {
		this.eventLogger.logScreen('backup-viewer', {});
	}

	onClickSeeWords() {
		this.navCtrl.navigateForward('/backup-words');
	}

	onClickNavBack() {
		this.location.back();
	}
}
