import { Injectable } from '@angular/core';
import {
	ActivatedRouteSnapshot,
	CanActivate,
	RouterStateSnapshot,
	UrlTree,
} from '@angular/router';
import { first } from 'rxjs/operators';

import { BranchService } from '../providers/branch-service/branch-service';
import { WhatPlatformService } from '../providers/what-platform-service/whatPlatformService';

@Injectable({
	providedIn: 'root',
})
export class LandingPathIsNotEmptyGuard implements CanActivate {
	constructor(
		private whatPlatformService: WhatPlatformService,
		private branchService: BranchService,
	) {}

	public async canActivate() {
		if (!this.whatPlatformService.isPWA()) return true;

		const url = new URL(window.location.href);
		if (url.pathname === '/') {
			const { url: storeUrl } = await this.branchService
				.fetchGenericDeeplink(url.href)
				.pipe(first())
				.toPromise();
			window.location.href = storeUrl;
			return false;
		}

		return true;
	}
}
