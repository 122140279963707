import { HttpClient } from '@angular/common/http';
import { Injectable, NgZone } from '@angular/core';
import { NETWORK } from '@arianee/arianeejs';
import ArianeeWallet from '@arianee/arianeejs/dist/src/core/wallet';
import {
	LoaderService,
	ToasterService,
	toastMessageType,
} from '@arianeeprivate/wallet-shared-components';
import { NavController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { get } from 'lodash';
import { Observable, from, of } from 'rxjs';
import { catchError, map, mergeMap, take, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

import { DeepLinkObject } from '../../../models/deepLinkObject';
import { ArianeeService } from '../../arianee-service/arianee.service';
import { EventLoggerService } from '../../event-logger/event-logger-service';
import { PoapService } from '../../poap/poap.service';
import { WalletConnectService } from '../../wallet-connect-service/wallet-connect.service';
import WalletConnectV2Utils, {
	parseUri,
} from '../../wallet-connect-v2-service/utils';
import { HandleCertificateLinkService } from './handle-certificate-link.service';
import { HandleInappLinkService } from './handle-inapp-link.service';
import { decryptLink } from './helpers/decryptLink';
import { getChainFromWhitelabelHostname } from './helpers/getChainFromWhitelabelHostname';
import { getWcLinkFromDeeplink, isWcDummyLink } from './helpers/getWcLink';
import { isLinkValid } from './helpers/isLinkValid';

@Injectable({
	providedIn: 'root',
})
export class HandleLinkService {
	constructor(
		private arianeeService: ArianeeService,
		private toasterService: ToasterService,
		private loaderService: LoaderService,
		private navCtrl: NavController,
		private httpClient: HttpClient,
		private handleCertificateLinkService: HandleCertificateLinkService,
		private handleInAppLinkService: HandleInappLinkService,
		private translateService: TranslateService,
		private walletConnectService: WalletConnectService,
		private eventLogger: EventLoggerService,
		private poapLink: PoapService,
		private walletConnectV2Utils: WalletConnectV2Utils,
	) {}

	private isWalletConnectLink(link: string): any {
		const regexResult = getWcLinkFromDeeplink(link);
		return regexResult !== null;
	}

	private addNetworkIfPendingUrl = (link: string): string => {
		if (
			/\/tab\/pending\/[a-zA-Z]+\//.test(link) ||
			!/\/tab\/pending\//.test(link)
		) {
			return link;
		}

		const linkArray = link.split('/');
		const hostname = new URL(link).hostname;
		const network = getChainFromWhitelabelHostname(hostname) || NETWORK.mainnet;

		linkArray.splice(linkArray.length - 1, 0, network);
		return linkArray.join('/');
	};

	public handleLink = (
		link: string,
		onScanProcessed: Function = () => {},
	): Observable<any> => {
		link = decryptLink(link);
		const isNativeLinkHandled = this.handleNativeLink(link);
		if (isNativeLinkHandled) {
			this.eventLogger.logEvent('handleLink_nativeLink');
			if (!isNativeLinkHandled) onScanProcessed({ shutdownCamera: false });
			return of(isNativeLinkHandled);
		} else if (this.poapLink.isPOAPLink(link)) {
			this.eventLogger.logEvent('handleLink_poapLink');
			const qrHash = this.poapLink.getQrHashFromLink(link);
			return of(link).pipe(
				tap(() =>
					this.redirectWithFirstNavigation(
						`/tab/brand-list/poap-detail/qr-hash/${qrHash}`,
					),
				),
				tap(() => onScanProcessed({ shutdownCamera: true })),
			);
		} else if (this.handleInAppLinkService.isInAppLink(link)) {
			this.eventLogger.logEvent('handleLink_inAppLink');
			return of(link).pipe(
				map(() => this.addNetworkIfPendingUrl(link)),
				catchError((e) => {
					console.error(e);
					this.toasterService.toastMessage(toastMessageType.notReadable);
					return of(link);
				}),
				tap((newLink) =>
					this.redirectWithFirstNavigation(
						this.handleInAppLinkService.extractInAppLink(newLink),
					),
				),
				tap(() => onScanProcessed({ shutdownCamera: true })),
			);
		} else if (this.isWalletConnectLink(link)) {
			this.eventLogger.logEvent('handleLink_walletConnect');
			const wcLink = getWcLinkFromDeeplink(link);
			const { version } = parseUri(wcLink);

			console.info(
				`Handling WalletConnect URI (version: ${version} - uri: ${wcLink})`,
			);

			if (version === 1) {
				this.walletConnectService.handleLink(wcLink, onScanProcessed);
				return of(false);
			} else {
				try {
					const core = this.walletConnectV2Utils.getCore();
					core.pairing.pair({ uri: wcLink });
					this.loaderService.dismiss();
					return of(false);
				} catch (e) {
					console.error(e);
					this.toasterService.toastMessage(toastMessageType.notReadable);
					return of(false);
				}
			}
		} else {
			console.info('handleLink ## not a native link');

			return this.handleCertificateLinkService
				.transformAnyLinkIntoDeepLinkObject(link)
				.pipe(
					map((linkObject) => {
						const isLink = isLinkValid(linkObject);
						if (!isLink) {
							this.eventLogger.logEvent('handleLink_linkNotReadable');
							this.toasterService.toastMessage(toastMessageType.notReadable);
							throw new Error(toastMessageType.notReadable);
						}
						return linkObject;
					}),
					mergeMap((linkObject) => {
						return this.handleCertificateLinkService.tryReadLink(
							linkObject.deeplink,
						);
					}),
					mergeMap(async (linkObject) => {
						if (linkObject) {
							this.eventLogger.logEvent(
								'handleLink_certificateAndVerifyValidity',
							);
							const network = linkObject.network || NETWORK.mainnet;
							const wallet = await this.arianeeService.getWalletInstance(
								network,
							);
							return this.getCertificateAndVerifyValidity(wallet, linkObject)
								.pipe(take(1))
								.toPromise();
						}
					}),
					catchError((e) => {
						return this.handleCertificateLinkService.tryDispatchLink(link).pipe(
							mergeMap((linkObject: { deeplink: string }) => {
								return this.handleLink(linkObject.deeplink, onScanProcessed);
							}),
							catchError(() => {
								return this.handleCertificateLinkService
									.tryHandlePartnerLink(link)
									.pipe(
										mergeMap((linkObject: DeepLinkObject) => {
											return this.handleLink(
												linkObject.deeplink,
												onScanProcessed,
											);
										}),
									);
							}),
							catchError((e) => {
								const errorMessageTranslated =
									this.translateService.instant(e.message) ||
									toastMessageType.notReadable;
								this.loaderService.dismiss('productDetail');
								// this.toasterService.toastMessage(errorMessageTranslated as any);
								throw new Error(toastMessageType.notReadable);
							}),
						);
					}),
				);
		}
	};

	public getCertificateAndVerifyValidity(
		wallet: ArianeeWallet,
		linkObject: DeepLinkObject,
	): Observable<DeepLinkObject> {
		return of(wallet.methods).pipe(
			mergeMap((methods) =>
				methods.getCertificate(
					linkObject.certificateId,
					linkObject.passphrase,
					{
						content: true,
						owner: true,
						issuer: true,
					},
				),
			),
			mergeMap((certificate) => {
				const isCertificateExist = certificate.owner !== undefined;

				const isCertificateReachable = certificate.content !== undefined;

				const authorizedAddress: string[] = environment.authorizedIssuers;
				const issuerAddress = certificate.issuer.identity.address;
				if (authorizedAddress && authorizedAddress.length > 0) {
					const isIncluded = authorizedAddress
						.map((d) => d.toLowerCase())
						.includes(issuerAddress.toLowerCase());
					// the issuer is not authorized by this app
					if (!isIncluded) {
						throw new Error('Scan.notReadableInThisApp');
					}
				}

				const externalContents: undefined | { type: string; url: string }[] =
					get(certificate, 'content.data.externalContents');

				if (externalContents) {
					const authRedirectTo = externalContents.find(
						(d) => d.type === 'authRedirectTo',
					);
					if (authRedirectTo) {
						return this.handleLink(authRedirectTo.url);
					}
				}

				if (!isCertificateExist) {
					this.loaderService.dismiss();
					throw new Error(toastMessageType.notExist);
				} else if (!isCertificateReachable) {
					this.loaderService.dismiss();
					throw new Error(toastMessageType.notAuthorized);
				}
				this.loaderService.dismiss();
				return of(linkObject);
			}),
		);
	}

	/**
	 * Create a first (hidden) navigation to tab/brand-list to allow back navigation
	 * @param url
	 */
	public async redirectWithFirstNavigation(url: string) {
		window.history.pushState({ page_id: 1 }, '', '/tab/brand-list/');
		this.navCtrl.navigateForward(url);
	}

	/**
	 * Handle native link shaped like: com.arianee.wallet:// for notification
	 * @param link
	 */
	public handleNativeLink(link) {
		if (link.startsWith('com.arianee.wallet://')) {
			const [method, certificateId] = link.substring(21).split('/');

			if (method === 'item') {
				const linkObject = {
					certificateId: certificateId,
					method: 'notification',
				};
				return linkObject;
			}
		}
	}
}
