import { Injectable } from '@angular/core';
import { BiometricProxyService } from './biometric-auth-service';
import { from, Observable, of } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class BiometricService {
  private fingerPrint;
  constructor (
    private fingerprintProvider: BiometricProxyService
  ) {
    this.fingerPrint = fingerprintProvider.fingerPrintService;
  }

  public showAuth = (): Observable<boolean> => {
    return this.isFingerPrintAvailable()
      .pipe(
        mergeMap((isAvailable):Observable<boolean> => {
          if (isAvailable) {
            return this.fingerPrint.show({
              disableBackup: true
            })
              .then(() => true)
              .catch(() => false);
          } else {
            return of(false);
          }
        })
      );
  }

  public isFingerPrintAvailable () {
    return from(this.fingerPrint.isAvailable().catch(() => false))
      .pipe(
        map((result) => (result === 'finger' || result === 'face' || result === 'biometric'))
      );
  }
}
