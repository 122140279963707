import { Component, Renderer2, ViewChild, ViewEncapsulation } from '@angular/core';
import { IonSlides, ModalController, NavController } from '@ionic/angular';
import { UserService } from '../../providers/user-service/user.service';

import { EventLoggerService } from '../../providers/event-logger/event-logger-service';
import { LoaderService } from '@arianeeprivate/wallet-shared-components';

@Component({
  selector: 'app-intro',
  templateUrl: './intro.page.html',
  styleUrls: ['./intro.page.scss'],
  encapsulation: ViewEncapsulation.None
})
export class IntroPage {
    @ViewChild(IonSlides, { static: false }) introSlide: IonSlides;
    viewEntered: boolean;

    arrSlides = [
      {
        title: 'OnBoarding.slide1.title',
        description: 'OnBoarding.slide1.description',
        imgurl: 'assets/imgs/onboarding-screen-9.png'
      },
      {
        title: 'OnBoarding.slide2.title',
        description: 'OnBoarding.slide2.description',
        imgurl: 'assets/imgs/onboarding-screen-6.png'
      },
      {
        title: 'OnBoarding.slide3.title',
        description: 'OnBoarding.slide3.description',
        imgurl: 'assets/imgs/onboarding-screen-7.png'
      },
      {
        title: 'OnBoarding.slide4.title',
        description: 'OnBoarding.slide4.description',
        imgurl: 'assets/imgs/onboarding-screen-8.png'
      }
    ];

    isLastSlide = false;

    constructor (
        private navCtrl: NavController,
        private userService: UserService,
        private modalCtrl:ModalController,
        private lodaer:LoaderService,
        private renderer: Renderer2,
        private eventLogger: EventLoggerService
    ) {
    }

    ngAfterViewInit () {
      this.viewEntered = true;
    }

    ionViewDidEnter () {
      this.renderer.addClass(document.body, 'body--without--app-loader');
      this.eventLogger.logScreen('intro', {});
    }

    onClickSlideSkip () {
      this.redirect();
    }

    async onClickSlideNext () {
      await this.introSlide.slideNext();
      await this.checkIfLastSlide();
    }

    async onSlideChange () {
      await this.checkIfLastSlide();
      this.eventLogger.logEvent('intro-slide-change', {});
    }

    async redirect () {
      this.eventLogger.logEvent('intro-end', {});

      this.userService.setOnBoardingStatus();
      this.renderer.removeClass(document.body, 'body--without--app-loader');
      await this.modalCtrl.dismiss();
    }

    onClickGetStarted () {
      this.redirect();
    }

    async checkIfLastSlide () {
      this.isLastSlide = await this.introSlide.isEnd();
    }
}
