import { Injectable } from '@angular/core';
import {
	AbstractControl,
	AsyncValidator,
	ValidationErrors,
} from '@angular/forms';
import { BehaviorSubject, Observable } from 'rxjs';
import {
	catchError,
	debounceTime,
	distinctUntilChanged,
	switchMap,
	take,
} from 'rxjs/operators';

import { EnsService } from '../providers/ens-service/ens.service';
import { LensService } from '../providers/lens-service/lens.service';

@Injectable({ providedIn: 'root' })
export class CustomValidator implements AsyncValidator {
	constructor(
		private lensService: LensService,
		private ensService: EnsService,
	) {}

	validate(control: AbstractControl): Observable<ValidationErrors | null> {
		const subject = new BehaviorSubject<AbstractControl>(null);
		const obs = subject.pipe(
			distinctUntilChanged(),
			switchMap(async (control) => {
				if (control.pristine) {
					return null;
				}
				const URL_REGEXP = /^0x[a-fA-F0-9]{40}$/;
				control.markAsTouched();
				const handleLens = await this.lensService.getProfilesByHandle(
					control.value,
				);
				const ens = await this.ensService.resolveENS(control.value);
				const addressFromLensOrEns = handleLens || ens;
				if (URL_REGEXP.test(control.value) || addressFromLensOrEns) {
					return null;
				}
				return {
					invalidPublicKey: true,
				};
			}),
			take(1),
		);
		subject.next(control);
		return obs;
	}
}
