import { Component, Input } from '@angular/core';

import { NftStatus } from './models/nft-status.model';

@Component({
	selector: 'app-nft-status',
	templateUrl: './nft-status.component.html',
	styleUrls: ['./nft-status.component.scss'],
})
export class NftStatusComponent {
	@Input() item: NftStatus;
}
