/**
 * Check if linkObject has all needed property
 * @param linkObject
 */
import { DeepLinkObject } from '../../../../models/deepLinkObject';

export const isLinkValid = (linkObject:DeepLinkObject):boolean => {
  const { method, certificateId, passphrase } = linkObject;
  return method !== undefined && certificateId !== undefined && passphrase !== undefined;
};
