import { parseUri } from '../../../wallet-connect-v2-service/utils';

export const getWcLinkFromDeeplink = (link: string): string => {
	if (!link) return null;

	const uriQueryParamMatches = link.split(/wc\?uri=/);
	let extractedUri =
		Array.isArray(uriQueryParamMatches) && uriQueryParamMatches.length === 2
			? decodeURIComponent(uriQueryParamMatches[1])
			: link;

	if (!extractedUri) return null;

	const wcUriMatches = extractedUri.match(/wc:[a-zA-z0-9-]*@[1-2].*/);
	if (Array.isArray(wcUriMatches) && wcUriMatches.length > 0) {
		extractedUri = wcUriMatches[0];
	}

	try {
		const { topic, version } = parseUri(extractedUri);
		if (!topic || !version) return null;
		return extractedUri;
	} catch (e) {
		console.error(e);
		return null;
	}
};

const DUMMY_LINK = 'https://arianee.net/wc';
// https://arianee.net/wc

// WalletConnect "dummy" link are used on dapps side to open the wallet when a user interaction is needed
export const isWcDummyLink = (link: string): boolean => {
	return link === DUMMY_LINK;
};
