import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { MediaItemCarousel } from '../../models/media-item-carousel.model';

@Component({
  selector: 'app-ar-media',
  templateUrl: './ar-media.component.html',
  styleUrls: ['./ar-media.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ArMediaComponent {
    @Input() media: MediaItemCarousel;

    setImageDimensions () {
      const container = document.getElementById('image-container');
      const image = document.getElementById('image');
      const containerWidth = container.offsetWidth;
      const containerHeight = container.offsetHeight;
      const imageWidth = image.offsetWidth;
      const imageHeight = image.offsetHeight;
      if (imageWidth > containerWidth || imageHeight > containerHeight) {
        if (imageWidth / containerWidth > imageHeight / containerHeight) {
          // scale down based on width
          image.style.width = '100%';
          image.style.height = 'auto';
        } else {
          image.style.width = 'auto';
          image.style.height = '100%';
        }
      } else {
        // if the image is small enough, set its dimensions to fill all the background
        image.style['min-width'] = '100%';
        image.style['min-height'] = '100%';
      }
    }
}
