import {
	AfterViewInit,
	Component,
	ElementRef,
	EventEmitter,
	Input,
	OnChanges,
	OnDestroy,
	OnInit,
	Output,
	SimpleChanges,
	ViewChild,
	ViewEncapsulation,
} from '@angular/core';
import { ToasterService } from '@arianeeprivate/wallet-shared-components';
import { Clipboard } from '@ionic-native/clipboard/ngx';
import { ethers } from 'ethers';
import { Subject } from 'rxjs/internal/Subject';
import { Subscription } from 'rxjs/internal/Subscription';

import { EventLoggerService } from '../../../providers/event-logger/event-logger-service';

type InputEvent = {
	readonly data: string | null;
	readonly dataTransfer: DataTransfer | null;
	readonly inputType: string;
};

type Mnemonic = string[];

type MnemonicTextAreaProps = {
	bottomRightAction: 'none' | 'copy' | 'paste';
	canPaste?: boolean;
	initialValue: string;
	readonly?: boolean;
	onChangeCurrentWord: EventEmitter<string>; // emits when the current word that is not yet blockified changes
	onChange: EventEmitter<{ valid: boolean; mnemonic: Mnemonic }>; // emits when the current word is blockified
	onFocusChange: EventEmitter<{ focused: boolean }>;
	$suggestion: Subject<string>;
	dictionary: string[];
};
@Component({
	selector: 'app-mnemonic-textarea',
	templateUrl: './mnemonic-textarea.component.html',
	styleUrls: ['./mnemonic-textarea.component.scss'],
	encapsulation: ViewEncapsulation.None,
})
export class MnemonicTextareaComponent
	implements MnemonicTextAreaProps, OnInit, AfterViewInit, OnChanges, OnDestroy
{
	private static ZERO_WIDTH_SPACE = String.fromCharCode(8203);

	@Input() bottomRightAction: 'none' | 'copy' | 'paste';
	@Input() canPaste?: boolean = true;
	@Input() initialValue: string;
	@Input() readonly?: boolean = false;
	@Input() $suggestion: Subject<string>;
	@Input() dictionary: string[];

	@Output() onChange: EventEmitter<{ valid: boolean; mnemonic: Mnemonic }> =
		new EventEmitter();

	@Output() onChangeCurrentWord: EventEmitter<string> = new EventEmitter();

	@Output() onFocusChange: EventEmitter<{ focused: boolean }> =
		new EventEmitter();

	private currentWord: string = '';
	private mnemonic: Mnemonic = [];

	private _dictionary: Set<string> = new Set<string>(); // use a set to improve performance
	private subscription: Subscription;

	@ViewChild('textarea', { static: false }) textarea: ElementRef<HTMLElement>;

	private eventListeners: {
		target: EventTarget;
		name: string;
		function: EventListenerOrEventListenerObject;
	}[] = [];

	constructor(
		private toasterService: ToasterService,
		private clipboard: Clipboard,
		private eventLoggerService: EventLoggerService,
	) {}

	ngOnInit() {
		this.subscription = this.$suggestion.subscribe((suggestion) => {
			this.onNewSuggestion(suggestion);
		});

		this._dictionary = new Set(this.dictionary);
		this.subscribeToKeyboardEvents();
	}

	ngOnChanges(changes: SimpleChanges): void {
		if (changes.dictionary) {
			this._dictionary = new Set(changes.dictionary.currentValue);
		}

		if (changes.initialValue && !changes.initialValue.firstChange) {
			this.setMnemonicFrom(changes.initialValue.currentValue);
		}
	}

	ngAfterViewInit() {
		this.setMnemonicFrom(this.initialValue);
	}

	ngOnDestroy() {
		if (this.subscription) this.subscription.unsubscribe();

		this.eventListeners.forEach(({ target, name, function: fn }) => {
			target.removeEventListener(name, fn);
		});
	}

	private subscribeToKeyboardEvents() {
		const keyboardDidShow = (event: Event) => {
			this.textarea.nativeElement.scrollIntoView({
				behavior: 'smooth',
			});
		};

		window.addEventListener('keyboardDidShow', keyboardDidShow);

		this.eventListeners.push({
			target: window,
			name: 'keyboardDidShow',
			function: keyboardDidShow,
		});
	}

	private setMnemonicFrom(str: string) {
		this.setMnemonic(
			str
				.split(' ')
				.map((word) => this.sanitizeWord(word))
				.filter((word) => !!word),
		);

		this.updateTextareaHTML();
	}

	private onNewSuggestion(suggestion: string) {
		this.setMnemonic([...this.mnemonic, suggestion]);
		this.setCurrentWord('');
		this.updateTextareaHTML();
	}

	public onTextareaChange(event: InputEvent) {
		const { data: key, inputType } = event;

		if (inputType === 'deleteContentBackward') {
			if (this.currentWord.length > 0)
				this.setCurrentWord(this.currentWord.slice(0, -1));
			else {
				this.setMnemonic(this.mnemonic.slice(0, -1));
			}
		}

		if (key === ' ') {
			if (this.currentWord.length > 0) {
				this.setMnemonic([...this.mnemonic, this.currentWord.trim()]);
				this.setCurrentWord('');
			}
		} else if (key && key.match(/^[a-zA-Z]$/)) {
			this.setCurrentWord(this.currentWord + key);
		}

		this.updateTextareaHTML();
	}

	private setCurrentWord(word: string) {
		this.currentWord = word;
		this.onChangeCurrentWord.emit(word);
	}

	private setMnemonic(mnemonic: Mnemonic) {
		this.mnemonic = mnemonic;
		const valid =
			mnemonic.every((word) => this._dictionary.has(word.toLowerCase())) &&
			mnemonic.length === 12 &&
			ethers.utils.isValidMnemonic(mnemonic.join(' '));

		const lowerCasedMnemonic = this.mnemonic.map((word) => word.toLowerCase());
		this.onChange.emit({ valid, mnemonic: lowerCasedMnemonic });

		setTimeout(
			() =>
				this.textarea.nativeElement.scrollIntoView({
					behavior: 'smooth',
				}),
			10,
		);
	}

	public onTextareaPaste(event: ClipboardEvent) {
		if (!this.canPaste) {
			this.toasterService.showRedesigned({
				title: 'Backup.pasteForbidden',
				position: 'top',
				duration: 2500,
				color: 'danger',
				icon: 'cancel',
			});

			return event.preventDefault();
		}

		const data = event.clipboardData.getData('text/plain');
		const words = data
			.split(' ')
			.map((word) => this.sanitizeWord(word))
			.filter((word) => !!word);

		this.setMnemonic([...this.mnemonic, ...words]);

		this.updateTextareaHTML();
	}

	private updateTextareaHTML() {
		this.textarea.nativeElement.innerHTML =
			this.mnemonic.map((word) => this.getWordHTML(word)).join('') +
			MnemonicTextareaComponent.ZERO_WIDTH_SPACE +
			this.currentWord;

		this.placeCursorAtEndOfTextarea();
	}

	private getWordHTML(word: string) {
		if (!this._dictionary.has(word.toLowerCase()))
			return `<div class="word invalid">${word}</div>`;

		return `<div class="word">${word}</div>`;
	}

	private placeCursorAtEndOfTextarea() {
		const selection = window.getSelection();
		const range = document.createRange();

		selection.removeAllRanges();
		range.selectNodeContents(this.textarea.nativeElement);
		range.collapse(false);
		selection.addRange(range);

		this.textarea.nativeElement.focus();
	}

	private sanitizeWord(word: string) {
		return word.replace(/[^a-zA-Z]/g, '');
	}

	public async copyMnemonicToClipboard() {
		const _mnemonic = this.mnemonic.join(' ');
		let copied = false;

		try {
			await this.clipboard.copy(_mnemonic);
			copied = true;
		} catch {
			await navigator.clipboard.writeText(_mnemonic);
			copied = true;
		} finally {
			if (copied) {
				this.toasterService.showRedesigned({
					title: 'Backup.copied',
					position: 'top',
					duration: 2500,
					color: 'info',
					icon: 'information',
				});

				this.eventLoggerService.logEvent('mnemonic-textarea_copy_cta');
			} else {
				this.toasterService.showRedesigned({
					title: 'Backup.copyError',
					position: 'top',
					duration: 2500,
					color: 'danger',
					icon: 'cancel',
				});
			}
		}
	}

	public async pasteMnemonicFromClipboard() {
		try {
			const text = await this.clipboard.paste();
			this.setMnemonicFrom(text);
			this.textarea.nativeElement.focus();

			this.eventLoggerService.logEvent('mnemonic-textarea_paste_cta');
		} catch {
			const text = await navigator.clipboard.readText();
			this.setMnemonicFrom(text);
		}
	}
}
