import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { from, Observable, of } from 'rxjs';
import { mergeMap } from 'rxjs/operators';

import { UserService } from '../providers/user-service/user.service';
import { ModalController } from '@ionic/angular';
import { IntroPage } from '../pages/intro/intro.page';

@Injectable({
  providedIn: 'root'
})
export class HasOnboardedGuard implements CanActivate {
  private onBoardedModal;

  constructor (protected userService: UserService, protected router: Router, protected modal:ModalController) {
  }

  public hasOnboarded (state: RouterStateSnapshot): Observable<boolean> {
    return this.userService.hasOnboarded()
      .pipe(
        mergeMap(hasOnBoarded => {
          if (hasOnBoarded) {
            return of(true);
          } else {
            return from(this.presentModalAndWait());
          }
        }));
  }

  private async presentModalAndWait () {
    if (this.onBoardedModal === undefined) {
      this.onBoardedModal = await this.modal.create({
        component: IntroPage,
        cssClass: 'modal--full_screen'
      });
      await this.onBoardedModal.present();
      await this.onBoardedModal.onWillDismiss();

      this.onBoardedModal = undefined;
    } else {
      await this.onBoardedModal.onWillDismiss();
    }
    return true;
  }

  public canActivate (
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.hasOnboarded(state);
  }
}
