import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Location } from '@angular/common';
import { Header } from './models/header.model';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  @Input() header: Header;
  @Input() isWeb: boolean = false;
  @Output() headerClick: EventEmitter<null> = new EventEmitter<null>();
  @Output() brandAuthenticityClick: EventEmitter<null> = new EventEmitter<null>();

  constructor (private location: Location) { }

  ngOnInit () {}

  goBack () {
    this.location.back();
  }
}
