import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-page-header-for-brand',
  templateUrl: './page-header-for-brand.component.html',
  styleUrls: ['./page-header-for-brand.component.scss']
})
export class PageHeaderForBrandComponent {
  @Input() public title!: string;
  @Output() public backClick = new EventEmitter();
  @Output() public titleClick = new EventEmitter();
}
