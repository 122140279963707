import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { Icon } from './models/icon.model';

@Component({
	selector: 'app-icon',
	templateUrl: './icon.component.html',
	styleUrls: ['./icon.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IconComponent {
	@Input() icon: Icon;
}
