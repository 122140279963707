import { Injectable } from '@angular/core';
import Web3 from 'web3';

@Injectable({
	providedIn: 'root',
})
export class EnsService {
	public async resolveENS(ens: string): Promise<string | null> {
		if (!ens.endsWith('.eth')) {
			return null;
		}
		const web3 = new Web3('https://ethereum.arianee.net');
		try {
			return await web3.eth.ens.getAddress(ens);
		} catch (e) {
			return null;
		}
	}
}
