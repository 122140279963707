import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { WhatPlatformService } from '../providers/what-platform-service/whatPlatformService';
import { ArianeeService } from '../providers/arianee-service/arianee.service';
import { take } from 'rxjs/operators';
import { getChainTypeFromNetwork } from '../providers/wallet-connect-service/chain-utils/chain-utils';

@Injectable({
  providedIn: 'root'
})
export class IsPWAAndSwitchNetworkGuard implements CanActivate {
  constructor (private router: Router,
              private whatPlatformService: WhatPlatformService,
              private arianeeService: ArianeeService
  ) {
  }

  public async canActivate (
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Promise<boolean> {
    if (this.whatPlatformService.isPWA()) {
      try {
        const network = await this.arianeeService.$walletInitialize
          .pipe(take(1))
          .toPromise();

        const wallet = await this.arianeeService.getWalletInstance(network);

        wallet.utils.readLink(window.location.href);
      } catch (e) {
        if (e.chain) {
          console.warn('chain', e.chain);

          // if no chain, let it go! It makes it work in localhost
          if (e.chain) {
            console.warn('handleLink ##  Switching chain');
            this.arianeeService.switchChainType(getChainTypeFromNetwork(e.chain));
          }
        }
      }
      return true;
    } else {
      return false;
    }
  }
}
