import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { MediaItemCarousel } from '../../models/media-item-carousel.model';

@Component({
	selector: 'app-picture-media',
	templateUrl: './picture-media.component.html',
	styleUrls: ['./picture-media.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PictureMediaComponent {
	@Input() media: MediaItemCarousel;
}
