import { CertificateSummary } from '@arianee/arianeejs/dist/src/core/wallet/certificateSummary';
import { ExternalContent, TransparencyItem } from '../../../models/mapped-certificate.model';

export const mapCertificateSummaryToTransparencyItems = async (
  certificate: CertificateSummary
): Promise<TransparencyItem[]> => {

  const hasData = certificate.content.data;
  const externalContents : ExternalContent[] = hasData ? [...(certificate.content.data.externalContents || [])] : [];
  const transparencyV1Requests = externalContents
    .filter((externalContent: ExternalContent) => externalContent.type === 'transparency')
    .map((externalContent: ExternalContent) => fetch(externalContent.url.replace(/http:\/\//, 'https://')));

  try {
    const transparencyV1Responses = await Promise.all(transparencyV1Requests);
    const transparencyV1Items: TransparencyItem[][] = (await Promise.all(
      transparencyV1Responses.map((response) => response.json())
    ));

    const flattenedTransparencyV1Items:TransparencyItem[] = transparencyV1Items
      .reduce((arr, items) => [...arr, ...items], []);

    flattenedTransparencyV1Items.forEach((item: TransparencyItem) => { item.transparencyType = 'v1'; });

    const transparencyV2Items: TransparencyItem[] = hasData ? [...certificate.content.data.transparencyItems || []] : [];
    transparencyV2Items
      .filter((item) => !!item)
      .forEach((item: TransparencyItem) => { item.transparencyType = 'v2'; });

    return [...flattenedTransparencyV1Items, ...transparencyV2Items];
  } catch (e) {
    console.error('Error while loading transparency items', e);
    return [];
  }
};
