import { MediaItemCarousel } from '../../../../components/redesign/media-carousel/models/media-item-carousel.model';
import { MappedCertificate } from '../../product-detail/models/mapped-certificate.model';

export function getMediaCarouselItemFromPoapCertificate (certificate: MappedCertificate): MediaItemCarousel[] {
  return certificate.pictures.map((picture, index) => {
    return {
      type: picture.type,
      url: picture.url,
      order: index
    };
  });
}
