import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { NETWORK } from '@arianee/arianeejs';
import { ModalController } from '@ionic/angular';

import { ArianeeService } from '../../../providers/arianee-service/arianee.service';
import { MediaItemCarousel } from '../media-carousel/models/media-item-carousel.model';
import { SenderMessage } from './models/sender-message.model';

@Component({
	selector: 'app-opt-in-out-modal',
	templateUrl: './opt-in-out-modal.component.html',
	styleUrls: ['./opt-in-out-modal.component.scss'],
})
export class OptInOutModalComponent implements OnInit {
	@Input() data: { title: string; media: MediaItemCarousel };
	@Input() certificateId: string;
	@Input() network: NETWORK;
	sender: SenderMessage = null;

	mediaUrl: string;

	constructor(
		private arianeeService: ArianeeService,
		private modalCtrl: ModalController,
		private cdr: ChangeDetectorRef,
	) {}

	async ngOnInit() {
		this.mediaUrl =
			this.data.media.type === 'picture'
				? this.data.media.url
				: this.data.media.preview;
		await this.initSender();
	}

	private async initSender() {
		this.arianeeService.getWalletInstance(this.network).then(async (wallet) => {
			await wallet.methods
				.getCertificate(this.certificateId, '', {
					messageSenders: true,
					issuer: true,
				})
				.then((certificateSummary) => {
					const issuerAddress = certificateSummary.issuer.identity.address;
					const issuerName = certificateSummary.issuer.identity.data.name;

					this.sender = {
						name: issuerName,
						address: issuerAddress,
						isAllowed: certificateSummary.messageSenders[issuerAddress],
						modifying: false,
					};
				});
		});
	}

	async onToggle() {
		if (!this.sender) throw new Error('Sender not found');

		try {
			this.sender.modifying = true;

			const wallet = await this.arianeeService.getWalletInstance(this.network);
			await wallet.methods.setMessageAuthorizationFor(
				this.certificateId,
				this.sender.address,
				!this.sender.isAllowed,
			);

			this.sender.isAllowed = !this.sender.isAllowed;
		} catch (e) {
			console.error('Error while changing message authorization', e);
			this.sender.modifying = false;
		} finally {
			this.sender.modifying = false;
			this.cdr.detectChanges();
		}
	}

	onCloseModal(): void {
		this.modalCtrl.dismiss({
			dismissed: true,
		});
	}
}
