import { Injectable } from '@angular/core';
import { NETWORK } from '@arianee/arianeejs/dist/src';
import {
	DisplayMapperService,
	EnrichedNotification,
	Notification,
	NotificationProvider,
	ToasterService,
} from '@arianeeprivate/wallet-shared-components';
import { get } from 'lodash';
import moment from 'moment';
import { Observable, ReplaySubject, from, of } from 'rxjs';
import { map, mergeMap, switchMap, take, tap } from 'rxjs/operators';

import { environment } from '../../../environments/environment';
import { EnrichedNotificationNetwork } from '../../pages/main/noti/models/enriched-notification-network.model';
import { ArianeeBlockchainProxyService } from '../arianee-blockchain-proxy-service/arianee-blockchain-proxy-service';
import { ArianeeService } from '../arianee-service/arianee.service';
import { SentryErrorHandler } from '../sentry-service/sentry-service';
import { UserService } from '../user-service/user.service';

@Injectable({
	providedIn: 'root',
})
export class DecentralizedMessageService implements NotificationProvider {
	public messageList: EnrichedNotification[];
	public $message = new ReplaySubject<any[]>(1);
	private network: NETWORK;
	private previousMessageList: EnrichedNotification[] = [];

	constructor(
		private arianeeService: ArianeeService,
		private toasterService: ToasterService,
		private userService: UserService,
		private arianeeBlockchainProxyService: ArianeeBlockchainProxyService,
		private displayMapperService: DisplayMapperService,
		private sentryService: SentryErrorHandler,
	) {}

	public init() {
		if (environment.appType === 'web') return;
		this.getNotifications().subscribe();
	}

	/**
	 * Update read status if not already true
	 * @param tokenId
	 * @param messageId
	 */
	public markAsRead(tokenId, messageId): Observable<boolean> {
		let network: NETWORK;
		const { isRead } = this.messageList.find(
			(message: EnrichedNotificationNetwork) => {
				network = message.network;
				return message.tokenId === tokenId && message.id === messageId;
			},
		);

		if (isRead === false) {
			this.messageList.find((d) => {
				return d.tokenId === tokenId && d.id === messageId;
			}).isRead = true;

			this.pushNotifications(this.messageList);

			return from(this.arianeeService.getWalletInstance(network)).pipe(
				mergeMap(async (wallet) => wallet.methods.markAsRead(messageId)),
			);
		}

		return of(false);
	}

	public pushNotifications(notifications: EnrichedNotification[]) {
		this.$message.next([...notifications]);
	}

	async refreshNotifications() {
		try {
			this.messageList = []; // erase to not display previous message waiting new one to be loaded
			const chainType = await this.userService.$chainType
				.get()
				.pipe(take(1))
				.toPromise();
			const address = await this.arianeeService.$address
				.pipe(take(1))
				.toPromise();
			const messages = await this.arianeeBlockchainProxyService.getMessages(
				chainType,
				address,
			);
			const newMessages: EnrichedNotification[] = await Promise.all(
				messages.map(async (message) => {
					try {
						return await this.getMessage(message);
					} catch (error) {
						this.sentryService.handleError(error);
						return null;
					}
				}),
			);
			this.messageList = newMessages.filter(
				(message) => message !== undefined && message !== null,
			);
			if (this.messageList.length > this.previousMessageList.length) {
				this.pushNotifications(this.messageList);
			}
			this.previousMessageList = this.messageList;
		} catch (error) {
			this.sentryService.handleError(error);
		}
	}

	getNotifications(): Observable<EnrichedNotification[]> {
		return this.arianeeService.$walletInitialize.pipe(
			switchMap(() => this.userService.$chainType.get()),
			mergeMap(async (chainType) => {
				this.messageList = []; // erase to not display previous message waiting new one to be loaded
				const address = await this.arianeeService.$address
					.pipe(take(1))
					.toPromise();
				return this.arianeeBlockchainProxyService.getMessages(
					chainType,
					address,
				);
			}),
			mergeMap(async (messages) => {
				return Promise.all(
					messages.map((message) =>
						this.getMessage(message).catch((error) =>
							this.sentryService.handleError(error),
						),
					),
				);
			}),
			map((messages) =>
				messages.filter((message) => message !== undefined && message !== null),
			),
			tap((messages: any) => {
				this.messageList = messages.filter(
					(message) => message !== undefined && message !== null,
				);
				this.pushNotifications(this.messageList);
			}),
		);
	}

	public async getMessage(message): Promise<EnrichedNotification> {
		const wallet = await this.arianeeService.getWalletInstance(message.network);
		const messageContent = await wallet.methods.getMessage({
			messageId: message.messageId,
			query: {
				advanced: {
					languages: [...navigator.languages],
				},
			},
		});

		if (messageContent === undefined || messageContent === null) {
			throw new Error('Message is not reachable');
		}

		const messageContentMapped = this.mapDecentralizedMessage({
			...messageContent,
			network: message.network,
		});

		const certificate = await wallet.methods.getCertificate(
			message.tokenId,
			undefined,
			{
				content: true,
				issuer: true,
			},
		);
		return this.displayMapperService.mapEnrichedNotification(
			messageContentMapped,
			certificate,
		);
	}

	public addMessage(message) {
		return from(this.getMessage(message)).pipe(
			tap((notification: EnrichedNotification) => {
				this.messageList.push(notification);
				this.pushNotifications(this.messageList);
			}),
		);
	}

	public messageToaster(message: EnrichedNotification) {
		this.toasterService.showRedesigned({
			title: 'Notification.newMessageToaster',
			position: 'top',
			duration: 2500,
			color: 'info',
			icon: 'information',
		});
	}

	private mapDecentralizedMessage(message): Notification & {
		network: NETWORK;
	} & {
		medias: { mediaType: 'picture' | 'youtube'; url: string }[];
	} {
		return {
			tokenId: message.certificateId,
			time: moment(message.timestamp).toISOString(),
			message: get(message.content, 'data.content'),
			title: get(message.content, 'data.title'),
			isRead: message.isRead,
			id: message.messageId,
			network: message.network,
			issuer: message.issuer,
			externalContents: get(message.content, 'data.externalContents'),
			medias: get(message.content, 'data.pictures'),
		};
	}

	baiduPushNotificationHandler(): any {}

	batchPushNotificationHandler(batchEvent: any): any {}
}
