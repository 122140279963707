import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';

import { Button } from '../../redesign/button/models/button.model';
import { IModalHeaderConfig } from '../components/modal-header/modal-header.component';

@Component({
	selector: 'app-wallet-success-modal',
	templateUrl: './wallet-success-modal.component.html',
	styleUrls: ['./wallet-success-modal.component.scss'],
})
export class WalletSuccessModalComponent implements OnInit {
	@Input() website: string;
	@Input() isVerified: boolean;

	@Input() customTitle: string;
	@Input() customMsg: string;

	headerConfig: IModalHeaderConfig = {
		logo: '',
		title: '',
		subtitle: {
			label: '',
			verified: false,
			logo: '',
		},
		description: '',
	};

	doneButton: Button;

	constructor(
		public modalController: ModalController,
		public translateService: TranslateService,
	) {}

	ngOnInit() {
		this.headerConfig.logo = './assets/imgs/green-checkmark.png';
		this.headerConfig.subtitle.label = this.website;
		this.headerConfig.subtitle.verified = this.isVerified;

		if (this.customTitle && this.customTitle != '')
			this.headerConfig.title = this.customTitle;
		else
			this.headerConfig.title = this.translateService.instant(
				'WalletConnect.success.title',
			);

		if (this.customMsg && this.customMsg != '')
			this.headerConfig.description = this.customMsg;
		else
			this.headerConfig.description = this.translateService.instant(
				'WalletConnect.success.description',
			);

		this.initButtons();
	}

	private initButtons() {
		this.doneButton = {
			title: {
				text: this.translateService.instant('WalletConnect.error.done'),
			},
			buttonType: 'primary',
		};
	}
}
