import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'ellipsis'
})
export class EllipsisPipe implements PipeTransform {
  transform (value: string, limit: number, showMore: boolean = false): string {
    if (limit === undefined || !value) {
      return value;
    }

    if (value.length > limit && !showMore) {
      return value.substring(0, limit) + '...';
    } else if (value.length > limit && showMore) {
      return value.substring(0, limit) + '...' + '<i class="icon-plus"></i>';
    } else {
      return value;
    }
  }
}
