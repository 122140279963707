import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { EMPTY } from 'rxjs';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SlackService {
  constructor (private http:HttpClient) {

  }

  public sendMessage (text:string, channel = '#alerts') {
    const options = {
      headers: new HttpHeaders(
        { 'Content-Type': 'application/x-www-form-urlencoded' }
      )
    };

    const body = {
      text,
      channel: '#alerts'
    };

    this.http.post(`https://hooks.slack.com/services/T7USWV3QX/BSQRVQ37F/${environment.slack.apikey}`, body, options)
      .pipe(catchError(d => EMPTY))
      .subscribe();
  }
}
