import { ChangeDetectorRef, Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { IonSlides } from '@ionic/angular';
import { CertificateSummary } from '@arianee/arianeejs/dist/src/core/wallet/certificateSummary';
import { AssetType } from '@arianeeprivate/wallet-shared-components/lib/models/AssetType';

@Component({
  selector: 'app-onboarding-slider',
  templateUrl: './onboarding-slider.component.html',
  styleUrls: ['./onboarding-slider.component.scss']
})
export class OnboardingSliderComponent {
  @ViewChild('slider', { static: false }) slider: IonSlides;
  @Output() skipOnBoarding = new EventEmitter();
  @Input() assetType:AssetType ;
  @Output() currentStep = new EventEmitter<number>();
  @Input() currentCertificate: CertificateSummary;
  /**
   * OnBoarding Slider Options
   */
  slideOpts = {
    initialSlide: 0,
    speed: 400
  };

  lastSlideOpts = {
    initialSlide: 0,
    speed: 400,
    allowTouchMove: false
  };

  /**
   * get ion-slides based Js Library
   * to access core methods
   */
  swiper: any;
  showFinalStep = false;

  /**
   * get all steps container Heights
   */
  stepContainerHeights: number[] = [];
  maxHeight: number;

  constructor (private dc: ChangeDetectorRef) {
  }

  get rootTranslation ():string {
    return `OnBoardingInPassport.${this.assetType}`;
  }

  /**
   * Select the Max Container Height value
   * @param height
   */
  setContainerHeight (height: number) {
    this.stepContainerHeights.push(height);
    this.maxHeight = Math.max(...this.stepContainerHeights);
    this.dc.detectChanges();
  }

  async ngAfterViewInit (): Promise<void> {
    this.swiper = await this.slider.getSwiper();
  }

  /**
   * increment step index
   */
  async sliderNextHandler (): Promise<void> {
    const swiper: any = await this.slider.getSwiper();
    this.currentStep.emit(swiper.activeIndex);
  }

  /**
   * decrement step index
   */
  async sliderPrevHandler (): Promise<void> {
    const swiper: any = await this.slider.getSwiper();
    this.currentStep.emit(swiper.activeIndex);
  }

  /**
   * Hide steps and show final slider
   * Emit step index 5
   */
  finalStep (): void {
    this.currentStep.emit(this.swiper.slides.length);
    if (!this.currentCertificate.isRequestable) {
      this.skipFinalStep();
    } else {
      this.showFinalStep = true;
    }
  }

  /**
   * Swipe to Previous slide on click
   */
  async swipePrevHandler (): Promise<void> {
    const swiper: any = await this.slider.getSwiper();
    swiper.slidePrev();
  }

  /**
   * Swipe to Next slide on click
   */
  async swipeNxtHandler (): Promise<void> {
    const swiper: any = await this.slider.getSwiper();
    swiper.slideNext();
  }

  /**
   * Close OnBoarding without Claim
   */
  skipFinalStep (): void {
    this.skipOnBoarding.emit();
  }
}
