import { Injectable } from '@angular/core';
import { ChinaService } from '../china-service/china-service';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ProxyService {
    private _authorizedProxiedURL: Promise<string[]>;

    constructor (private chinaService: ChinaService, private httpClient: HttpClient) {
    }

    public requestInterceptor = async (url:string, config): Promise<{ url, config }> => {
      const newUrl = await this.proxify(url);

      return { url: newUrl, config };
    }

    /**
     * Proxify url if needed
     * @param url
     */
   public proxify=async (url:string):Promise<string> => {
     const isChina = await this.chinaService.isChina();
     if (isChina) {
       const proxied = await this.getProxifyAuthorizedDomain();
       if (proxied.includes(new URL(url, 'http://localhost.arianee.org').hostname)) {
         const urlWithoutProtocol = url.replace('https://', '');
         return `https://proxy.arianee.net/${urlWithoutProtocol}`;
       }
     }
     return url;
   };

    private getProxifyAuthorizedDomain = (): Promise<string[]> => {
      if (this._authorizedProxiedURL === undefined) {
        const chinaUrl = environment.countryUrl;
        this._authorizedProxiedURL = this.httpClient.get<string[]>(environment.proxyAuthorizedDomain)
          .toPromise();
      }

      return this._authorizedProxiedURL;
    };
}
