import ArianeeWallet from '@arianee/arianeejs/dist/src/core/wallet';
import { CertificateSummary } from '@arianee/arianeejs/dist/src/core/wallet/certificateSummary';
import { get } from 'lodash';

type CreateCertificateProofLinkParams = Parameters<
  ArianeeWallet['methods']['createCertificateProofLink']
>;

const getDeepLinkDomain = (certificate: CertificateSummary): string | null => {
  const externalContents = get(certificate, 'issuer.identity.data.externalContents');

  if (!externalContents) {
    return null;
  }

  return get(
    externalContents.find((externalContent) => externalContent.type === 'deepLinkDomain'),
    'url',
    null
  );
};

export const createCertificateProofLink =
  (
    wallet: ArianeeWallet,
    certificate: CertificateSummary,
    passphrase?: CreateCertificateProofLinkParams[1]
  ) =>
    async () => {
      const deepLinkDomain = getDeepLinkDomain(certificate);

      return wallet.methods.createCertificateProofLink(
        +certificate.certificateId,
        passphrase,
        deepLinkDomain
      );
    };

export const createCertificateRequestOwnershipLink =
  (
    wallet: ArianeeWallet,
    certificate: CertificateSummary,
    passphrase?: CreateCertificateProofLinkParams[1]
  ) =>
    async () => {
      const deepLinkDomain = getDeepLinkDomain(certificate);

      return wallet.methods.createCertificateRequestOwnershipLink(
        +certificate.certificateId,
        passphrase,
        deepLinkDomain
      );
    };

export const transferCertificate =
  (
    wallet: ArianeeWallet,
    certificate: CertificateSummary,
    to: string
  ) =>
    async () => {

      return wallet.methods.transfer(
        +certificate.certificateId,
        to
      );
    };
