import { Location } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import {
	Component,
	ElementRef,
	EventEmitter,
	OnDestroy,
	OnInit,
	Output,
	ViewChild,
} from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { NETWORK } from '@arianee/arianeejs/dist/src';
import { ArianeeWallet } from '@arianee/arianeejs/dist/src/core/wallet';
import { CertificateSummary } from '@arianee/arianeejs/dist/src/core/wallet/certificateSummary';
import { CertificateEvents } from '@arianee/arianeejs/dist/src/core/wallet/certificateSummary/certificateSummary';
import { IdentitySummary } from '@arianee/arianeejs/dist/src/models';
import {
	BrandDisplay,
	CertificateDisplay,
	CongratOnClaimModalComponent,
	DeclareLostComponent,
	Events,
	LabelModalComponent,
	LoaderService,
	ProdPopoverComponent,
	ProductCertVerifiedComponent,
	ProductInfoCertComponent,
	ProductInfoHistoryComponent,
	ProductInfoPGeneveComponent,
	ProductInfoResellComponent,
	ProductInfoSwissMadeComponent,
	ProductInfoTransparencyComponent,
	ProductInfoTransparencyItemsComponent,
	ProductInfoYoutubeComponent,
	ProductViewComponent,
	StyleService,
	ToasterService,
	TransferCertComponent,
	TransparencyItemModalComponent,
	brandDisplayMapper,
	brandImageLogo,
	brandName,
	certificateDisplayMapper,
} from '@arianeeprivate/wallet-shared-components';
import { AssetType } from '@arianeeprivate/wallet-shared-components/lib/models/AssetType';
import { ArianeeWalletStyleConfig } from '@arianeeprivate/wallet-shared-components/lib/models/arianee-wallet-style-config';
// import { PhotoViewer } from '@ionic-native/photo-viewer/ngx';
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';
import { PreviewAnyFile } from '@ionic-native/preview-any-file/ngx';
import {
	ModalController,
	NavController,
	PopoverController,
	ToastController,
	ToastOptions,
} from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import BN from 'bn.js';
import { get } from 'lodash';
import { Observable, from } from 'rxjs';
import {
	distinctUntilChanged,
	filter,
	first,
	map,
	shareReplay,
	take,
	tap,
} from 'rxjs/operators';

import { environment } from '../../../../../../environments/environment';
import { DeletePassportComponent } from '../../../../../components/delete-passport/delete-passport.component';
import { ExportMnemonicComponent } from '../../../../../components/export-mnemonic/export-mnemonic.component';
import { IsAuthGuard } from '../../../../../guards/isAuth.guard';
import {
	createCertificateProofLink,
	createCertificateRequestOwnershipLink,
} from '../../../../../helpers/wallet-methods';
import { ArianeeBlockchainActionService } from '../../../../../providers/arianee-blockchain-action-service/arianee-blockchain-action.service';
import { ArianeeBlockchainProxyService } from '../../../../../providers/arianee-blockchain-proxy-service/arianee-blockchain-proxy-service';
import { ArianeeEventWatcherService } from '../../../../../providers/arianee-event-watcher.service.ts/arianee-event-watcher.service';
import { ArianeeService } from '../../../../../providers/arianee-service/arianee.service';
import { ArianeeEventService } from '../../../../../providers/arianeeEvent-service/arianeeEvent.service';
import { BackupCheckService } from '../../../../../providers/backup-check-service/backup-check.service';
import { BackupService } from '../../../../../providers/backup-service/backup.service';
import { BranchService } from '../../../../../providers/branch-service/branch-service';
import { ChinaService } from '../../../../../providers/china-service/china-service';
import { EventLoggerService } from '../../../../../providers/event-logger/event-logger-service';
import { FeatureFlipService } from '../../../../../providers/feature-flip-service/feature-flip.service';
import { GenericCustodialService } from '../../../../../providers/generic-custodial-service/generic-custodial.service';
import { BrowserService } from '../../../../../providers/inapp-browser/inapp-browser-service';
import { LabelService } from '../../../../../providers/label-service/label-service';
import { MissingStolenService } from '../../../../../providers/missing-stolen-service';
import { NotificationService } from '../../../../../providers/notification-service/notification.service';
import {
	PendingNft,
	PendingNftService,
} from '../../../../../providers/pending-nft-service/pending-nft.service';
import { HandleLinkService } from '../../../../../providers/scan-service/handle-link.service/handle-link.service';
import { SharingService } from '../../../../../providers/sharing-service/sharing.service';
import { SPKZService } from '../../../../../providers/spkz-service.ts/SPKZService';
import { TabsService } from '../../../../../providers/tabs/tabs.service';
import { UserService } from '../../../../../providers/user-service/user.service';
import { getChainTypeFromNetwork } from '../../../../../providers/wallet-connect-service/chain-utils/chain-utils';
import { WhatPlatformService } from '../../../../../providers/what-platform-service/whatPlatformService';
import { ChainType } from '../../../../../types/multichain';
import { AuthPage } from '../../../../auth/auth.page';
import { footerDisplay } from '../../helpers/footer/footer.helper';

export interface ActionItem {
	title: string;
	component?: any;
	e2e: string;
	url?: string;
	type?: string;
	featureName: string;
	route?: string;
}

@Component({
	selector: 'app-product-detail-legacy',
	templateUrl: './product-detail-legacy.component.html',
	styleUrls: ['./product-detail-legacy.component.scss'],
})
export class ProductDetailLegacyComponent implements OnInit, OnDestroy {
	public assetType: AssetType;
	@ViewChild('productView', { static: false, read: ElementRef })
	productViewHtml: ElementRef;

	@ViewChild('productView', { static: false })
	productView: ProductViewComponent;

	public certificateId: string;
	public passphrase: string;
	public method: string;
	public proof: any;
	public brandId: string;
	public labels: any;
	public pendingEventsLength: any;
	public isMissing = false;
	public isStolen = false;
	public certificate: CertificateSummary;
	public certificateDisplay: CertificateDisplay;
	public identity: IdentitySummary;
	public identityDisplay: BrandDisplay;
	public displayHistoryBtn: boolean = true;
	public currentUrlWithSkipOption: URL;

	private pendingCertificateEndpoint: {
		url: string;
		label: string;
	} | null = null;

	public isClaimed: boolean = false;

	public events: CertificateEvents = {
		transfer: [],
		arianeeEvents: [],
		all: [],
	};

	public showProof = false;
	private deepLinkRedirection$;
	public isChina: boolean;
	private isOwner: boolean;
	private brandNameTranslation: { brandname: string };
	private actionMenuItemDynamic = [];
	private subscriptions = [];

	public CTAButtonLabel: string;
	public linkButtonLabel: string;
	public isRequestableButton: boolean;
	public proofFooter: boolean;
	public requestableFooter: boolean;
	private specialExternalContentType = ['resellLink', 'repairLink', 'lostLink'];

	featureFlippedItems: Array<ActionItem> = [
		{
			title: 'CertificateInfos.resell',
			component: ProductInfoResellComponent,
			e2e: 'resell',
			featureName: 'resell',
		},
		{
			title: 'CertificateInfos.toggleMissingStatus',
			component: DeclareLostComponent,
			e2e: 'lost',
			featureName: 'lost',
		},
		{
			title: 'CertificateInfos.deletePassport',
			component: DeletePassportComponent,
			e2e: 'delete',
			featureName: 'deletePassport',
		},
		{
			title: 'ExportMnemonics.fromWallet.title',
			component: ExportMnemonicComponent,
			e2e: 'export-mnemonic',
			featureName: 'export-mnemonic',
		},
	];

	private readonly actionMenuItemStatic: Array<ActionItem> = [
		{
			title: 'CertificateInfos.shareCertificate',
			component: ProductInfoCertComponent,
			e2e: 'share',
			featureName: 'share',
		},
		{
			title: 'CertificateInfos.transferCertificate',
			component: TransferCertComponent,
			e2e: 'transfer',
			featureName: 'transfer',
		},
		{
			title: 'CertificateInfos.showMyCertificate',
			e2e: 'showMyCertificate',
			featureName: 'showMyCertificate',
			route: 'myCertificate',
		},
	];

	showOnBoarding = false;
	showBackdrop = false;
	errorToaster;

	private network: NETWORK | null = null;
	private wallet: ArianeeWallet | null = null;
	private chainType: ChainType;

	constructor(
		private modalCtrl: ModalController,
		private popOverCtrl: PopoverController,
		private location: Location,
		private route: ActivatedRoute,
		private router: Router,
		private arianeeService: ArianeeService,
		private loaderService: LoaderService,
		private toasterService: ToasterService,
		// private photoViewer: PhotoViewer,
		private inAppBrowser: InAppBrowser,
		private navCtrl: NavController,
		private notificationService: NotificationService,
		private labelService: LabelService,
		private chinaService: ChinaService,
		private eventLogger: EventLoggerService,
		private arianeeEventService: ArianeeEventService,
		private browserService: BrowserService,
		public platformService: WhatPlatformService,
		private branchService: BranchService,
		private previewAnyFile: PreviewAnyFile,
		private featureFlipService: FeatureFlipService,
		private isAuthGuard: IsAuthGuard,
		private sharingService: SharingService,
		private backupService: BackupService,
		private backupCheckService: BackupCheckService,
		private handleLinkService: HandleLinkService,
		private userService: UserService,
		private tabsService: TabsService,
		private sharedStyleService: StyleService,
		private arianeeBlockchainActionService: ArianeeBlockchainActionService,
		private toastController: ToastController,
		private translateService: TranslateService,
		private spkzService: SPKZService,
		private missingAndStolenService: MissingStolenService,
		private genericCustodialWallet: GenericCustodialService,
		private pendingNftService: PendingNftService,
		private arianeeEventWatcherService: ArianeeEventWatcherService,
		private arianeeBlockchainProxyService: ArianeeBlockchainProxyService,
		private applicationEvents: Events,
	) {
		this.currentUrlWithSkipOption = new URL(window.location.href);
		this.currentUrlWithSkipOption.searchParams.append('s', '');
	}

	ionViewWillLeave() {
		this.closeCertificateNotExistToaster();
	}

	get isWeb(): boolean {
		return this.platformService.isPWA();
	}

	/**
	 * Display OnBoarding
	 * more than 1 Certificate, wallet is considered as already on-Boarded
	 */
	onBoardingCheck(): void {
		const hasOnboardedSub = this.userService
			.hasOnboarded()
			.subscribe(async (hasOnboarded) => {
				if (hasOnboarded) return;
				if (this.method !== 'requestOwnership') return;

				const [mainnetCertificates, testnetCertificates] = await Promise.all([
					this.arianeeBlockchainProxyService.getCertificates(
						ChainType.mainnet,
						this.wallet.address,
					),
					this.arianeeBlockchainProxyService.getCertificates(
						ChainType.testnet,
						this.wallet.address,
					),
				]);

				const totalCertificatesCount =
					mainnetCertificates.length + testnetCertificates.length;

				if (!this.isWeb && totalCertificatesCount <= 1) {
					this.displayOnBoarding();
					this.userService.setOnBoardingStatus();
				}
			});

		this.subscriptions.push(hasOnboardedSub);
	}

	setChainType() {
		const chainTypeSub = this.userService.$chainType
			.get()
			.pipe(distinctUntilChanged())
			.subscribe((chainType) => {
				this.chainType = chainType;
			});

		this.subscriptions.push(chainTypeSub);
	}

	/**
	 * Customize Shared Modal Style
	 */
	setSharedModalsCustomStyle(): void {
		const secondary = getComputedStyle(
			document.documentElement,
		).getPropertyValue('--color-bg-secondary');
		const actionColor = getComputedStyle(
			document.documentElement,
		).getPropertyValue('--color-action-primary');
		const font = getComputedStyle(document.documentElement).getPropertyValue(
			'--font-text',
		);
		const modalTitleColor = getComputedStyle(
			document.documentElement,
		).getPropertyValue('--color-modal-title');

		const color: ArianeeWalletStyleConfig = {
			fontFamily: font,
			buttons: {
				color: '#fff',
				bgColor: actionColor,
				bgHoverColor: actionColor,
			},
			modal: {
				borderColor: secondary,
				titleColor: modalTitleColor || secondary,
			},
		};
		this.sharedStyleService.initStyle(color);
	}

	get actionMenuItems(): Array<ActionItem> {
		return [...this.actionMenuItemStatic, ...this.actionMenuItemDynamic];
	}

	getPendingCertificateIdOrUrlFromRoute(): string {
		return this.route.snapshot.paramMap.get('pendingCertificateIdOrUrl');
	}

	isPendingCertificate(certificate: CertificateSummary): boolean {
		return (
			!!this.getPendingCertificateIdOrUrlFromRoute() ||
			this.pendingNftService.supportsDeferredClaim(certificate)
		);
	}

	ionViewWillEnter() {
		this.loaderService.showLoader();
	}

	async init() {
		if (environment.appType === 'web') {
			await this.setWalletInstanceFromEnv();
		} else {
			await this.setWalletInstanceFromRoute();
		}

		this.setSharedModalsCustomStyle();

		const certificateIdAndpassphrase = this.route.snapshot.paramMap.get(
			'certificateIdAndpassphrase',
		);
		this.method = this.route.snapshot.paramMap.get('method');

		this.onBoardingCheck();

		const pendingCertificateIdOrUrl: string =
			this.getPendingCertificateIdOrUrlFromRoute();
		if (pendingCertificateIdOrUrl) {
			return await this.fetchPendingCertificate(pendingCertificateIdOrUrl);
		}

		if (certificateIdAndpassphrase) {
			[this.certificateId, this.passphrase] =
				certificateIdAndpassphrase.split(',');
			this.eventLogger.logScreen('PWA', {
				certificateId: this.certificateId,
				method: this.method,
			});

			this.eventLogger.logEvent('deeplinkFront_Start', {
				certificateId: this.certificateId,
				method: this.method,
			});

			this.deepLinkRedirection$ = this.branchService
				.fetchDeepLinkForPassport(window.location.href, this.network)
				.pipe(shareReplay(1));

			// For caching
			this.deepLinkRedirection$.subscribe();
		} else {
			this.passphrase = this.route.snapshot.paramMap.get('passphrase');
			this.certificateId = this.route.snapshot.paramMap.get('certificateId');
		}
		this.subscriptions.push(
			this.arianeeEventWatcherService.$transferToReceived.subscribe((event) => {
				const certificateId: number | null = get(
					event,
					'[0].returnValues._tokenId',
					null,
				);
				if (
					certificateId &&
					this.certificateId &&
					+this.certificateId === +certificateId
				) {
					this.fetchCertificate().subscribe();
				}
			}),
		);

		this.fetchCertificate().subscribe(
			(certificate) => {
				this.loaderService.dismiss('productDetail');
				if (!certificate || !certificate.content) {
					this.openCertificateNotExistToaster();
				}
			},
			(error) => {
				this.loaderService.dismiss('productDetail');
			},
		);

		const $pendingEvents = this.getPendingEvents()
			.pipe(
				tap(async (_pendingEventsLength) => {
					await this.updatePendingEventsLength(_pendingEventsLength, true);
				}),
			)
			.subscribe();

		this.subscriptions.push($pendingEvents);

		if (this.method === 'proof') {
			this.proof = await this.wallet.methods.isCertificateProofValid(
				parseInt(this.certificateId),
				this.passphrase,
			);
		}

		this.missingAndStolenService
			.isLostOrStolen(this.certificateId, this.network)
			.subscribe(({ isMissing, isStolen }) => {
				this.isMissing = isMissing;
				this.isStolen = isStolen;
			});
	}

	private async setWalletInstanceFromRoute() {
		this.network = this.route.snapshot.paramMap.get('network') as NETWORK;
		this.wallet = await this.arianeeService.getWalletInstance(this.network);
	}

	private async setWalletInstanceFromEnv() {
		this.network = environment.network;
		this.wallet = await this.arianeeService.getWalletInstance(this.network);
	}

	private async refreshEvents() {
		await this.arianeeEventService.refreshEvents(
			this.wallet,
			this.certificateId,
			this.passphrase,
		);
		this.events = await this.arianeeEventService
			.getCachedEvents(this.certificateId)
			.pipe(take(1))
			.toPromise();
	}

	private async updatePendingEventsLength(
		_pendingEventsLength: BN,
		refreshEventsIfNeeded: boolean = false,
	) {
		this.pendingEventsLength = _pendingEventsLength;

		const $cachedEvents = this.arianeeEventService.getCachedEvents(
			this.certificateId,
		);
		const cachedEvents = $cachedEvents
			? await $cachedEvents.pipe(take(1)).toPromise()
			: {};
		const arianeeEvents = get(cachedEvents, 'arianeeEvents', []);
		const cachedPendingCount = arianeeEvents.filter(
			(arianeeEvent) => arianeeEvent.pending,
		).length;

		if (
			new BN(_pendingEventsLength).toNumber() !== cachedPendingCount &&
			refreshEventsIfNeeded
		) {
			await this.refreshEvents();
		}
	}

	private async fetchPendingEventsLength(
		refreshEventsIfNeeded: boolean = true,
	) {
		const pendingEventsLength = await this.getPendingEvents()
			.pipe(take(1))
			.toPromise();
		await this.updatePendingEventsLength(
			pendingEventsLength,
			refreshEventsIfNeeded,
		);
	}

	private async fetchPendingCertificate(certificateIdOrUrl: string) {
		this.loaderService.showLoader();

		const isCertificateId: boolean = /^[0-9]+$/.test(certificateIdOrUrl);
		let certificate: PendingNft;

		if (isCertificateId) {
			certificate = this.pendingNftService.getPendingNft(
				this.chainType,
				+certificateIdOrUrl,
			);
			if (!certificate) {
				this.loaderService.dismiss();
				return this.openCertificateNotExistToaster();
			}
		} else {
			const decodedUrl = decodeURIComponent(certificateIdOrUrl);
			const url = new URL(decodedUrl);
			this.pendingCertificateEndpoint = {
				url: url.origin,
				label: url.searchParams.get('label'),
			};

			const authUrl = await this.wallet.methods.createAuthURL({
				type: 'arianeeAccessToken',
				url: decodedUrl,
				certificateId: null,
			});

			try {
				const request = await fetch(authUrl);
				if (request.status === 400) {
					throw new Error('No certificate');
				}
				certificate = (await request.json()) as PendingNft;
			} catch {
				this.loaderService.dismiss();
				return this.openCertificateNotExistToaster();
			}
		}

		if (certificate.network) {
			this.arianeeService.switchChainType(
				getChainTypeFromNetwork(certificate.network),
			);
		}

		this.certificate = this.cleanCertificate(certificate);
		this.certificateId = this.certificate.certificateId;
		this.certificateDisplay = certificateDisplayMapper(certificate);
		this.assetType = this.certificateDisplay.assetType;

		const { CTAButtonLabel, linkButtonLabel } = footerDisplay({
			productDisplay: this.certificateDisplay,
			isPWA: this.platformService.isPWA(),
			method: this.method,
		});

		const isActivePendingNft = this.pendingNftService.isPendingNft(
			+this.certificate.certificateId,
			this.chainType,
		);
		const inQueueForTransfer = get(certificate, 'inQueueForTransfer', false);
		const isRequestable = get(certificate, 'isRequestable', true);

		this.CTAButtonLabel = CTAButtonLabel;
		this.linkButtonLabel = linkButtonLabel;
		this.isClaimed = isActivePendingNft;
		this.CTAButtonLabel = CTAButtonLabel;
		this.isRequestableButton =
			!isActivePendingNft && !inQueueForTransfer && isRequestable;
		this.proofFooter = false;
		this.requestableFooter = true;
		this.brandId = certificate.issuer.identity.address;
		this.identity = certificate.issuer.identity;
		this.identityDisplay = brandDisplayMapper(this.identity);
		this.isOwner = isActivePendingNft;
		this.brandNameTranslation = {
			brandname: brandName(certificate.issuer.identity),
		};
		this.displayHistoryBtn = false;
		this.loaderService.dismiss();

		// Redirect to real certificate if it no longer is a pending certificate
		if (!isActivePendingNft && inQueueForTransfer && isRequestable) {
			this.navigateToNonPendingCertificate(
				+this.certificate.certificateId,
				certificate.network,
			);
		}
	}

	async openCertificateNotExistToaster() {
		this.showBackdrop = true;
		const toastOptions: ToastOptions = {
			message: this.translateService.instant('CertificateInfos.notExist'),
			color: 'danger',
			position: 'middle',
			duration: 5000,
			cssClass: 'e2e-toaster',
		};
		// open Toast
		this.errorToaster = await this.toastController.create(toastOptions);
		this.errorToaster.present();
	}

	closeCertificateNotExistToaster() {
		if (this.errorToaster) {
			this.errorToaster.dismiss();
		}

		this.showBackdrop = false;
	}

	onClickItem = async (index) => {
		const isAuth = await this.isAuthGuard.isAuth();
		if (isAuth) {
			if (this.actionMenuItems[index].component) {
				switch (this.actionMenuItems[index].component) {
					case ProductInfoResellComponent:
						if (this.wallet.contracts.userActionContract) {
							this.loaderService.showLoader();

							// eslint-disable-next-line no-case-declarations
							const proofLink = await this.wallet.methods.createAuthURL({
								url: environment.resellWatchfinder,
								certificateId: this.certificateId,
								type: 'arianeeAccessToken',
							});

							this.wallet.contracts.userActionContract.methods
								.addAddressToWhitelist(
									this.certificateId,
									environment.watchFinderAddress,
								)
								.send()
								.then(() => {
									this.browserService.openBrowser(proofLink);
								})
								.catch(() => {
									this.toasterService.showRedesigned({
										title: 'Oh noo',
										color: 'danger',
										icon: 'cancel',
										duration: 2500,
										position: 'top',
									});
								})
								.finally(() => {
									this.loaderService.dismiss();
								});
						}

						this.popOverCtrl.dismiss();
						break;
					case DeclareLostComponent:
						this.modalCtrl
							.create({
								component: this.actionMenuItems[index].component,
								cssClass: 'middle light-round auto-height',
								componentProps: {
									certificateId: this.certificateId,
									certificate: this.certificate,
									onToggleLost: this.onToggleMissing,
									isLost: this.isMissing,
									logScreen: this.eventLogger.logScreen,
								},
							})
							.then((detailModal) => {
								detailModal.present().then((data) => {
									this.popOverCtrl.dismiss();
								});
							});

						break;
					case TransferCertComponent:
						this.modalCtrl
							.create({
								component: this.actionMenuItems[index].component,
								cssClass: 'middle light-round auto-height',
								componentProps: {
									certificateId: this.certificateId,
									certificate: this.certificate,
									logScreen: this.eventLogger.logScreen,
									createCertificateRequestOwnershipLink:
										createCertificateRequestOwnershipLink(
											this.wallet,
											this.certificate,
										),
									shareLink: this.sharingService.shareLink,
								},
							})
							.then((detailModal) => {
								detailModal.present().then((data) => {
									this.popOverCtrl.dismiss();
								});
							});
						break;
					case ProductInfoCertComponent:
						this.showProofModal().then(() => {
							this.popOverCtrl.dismiss();
						});
						break;

					default:
						this.modalCtrl
							.create({
								component: this.actionMenuItems[index].component,
								cssClass: 'middle light-round auto-height',
								componentProps: {
									certificateId: this.certificateId,
									certificate: this.certificate,
									logScreen: this.eventLogger.logScreen,
								},
							})
							.then((detailModal) => {
								detailModal.present().then((data) => {
									this.popOverCtrl.dismiss();
								});
							});
				}
			} else if (this.actionMenuItems[index].route) {
				this.navCtrl.navigateForward(
					`tab/brand-list/product-detail/${this.network}/${this.certificateId}/myCertificate`,
				);
				this.popOverCtrl.dismiss();
			} else if (this.actionMenuItems[index].type) {
				this.spkzService.navigateToSPKZLink(this.actionMenuItems[index].url);
			} else if (this.actionMenuItems[index].url) {
				this.loaderService.showLoader();

				const authUrl = await this.wallet.methods.createAuthURL({
					url: this.actionMenuItems[index].url,
					certificateId: this.certificateId,
					type: 'arianeeAccessToken',
				});

				this.loaderService.dismiss();
				this.browserService.openBrowser(authUrl);
			}
		}
	};

	async featureActivated(item): Promise<boolean> {
		if (item.featureName === 'export-mnemonic') {
			return await this.genericCustodialWallet
				.hasBrandCustodialWalletProvider(this.brandId, this.network)
				.toPromise();
		}
		return this.featureFlipService
			.$isFeatureFlipOnce(item.featureName)
			.toPromise();
	}

	public fetchCertificate(): Observable<any> {
		return from(
			this.wallet.methods.getCertificate(this.certificateId, this.passphrase, {
				content: true,
				issuer: true,
				owner: true,
				isRequestable: true,
			}),
		).pipe(
			tap(async (certificate) => {
				this.certificate = this.cleanCertificate(certificate);
				this.certificateDisplay = certificateDisplayMapper(certificate);

				this.assetType = this.certificateDisplay.assetType;
				const {
					requestableFooter,
					proofFooter,
					CTAButtonLabel,
					swipe,
					linkButtonLabel,
				} = footerDisplay({
					productDisplay: this.certificateDisplay,
					isPWA: this.platformService.isPWA(),
					method: this.method,
				});

				this.CTAButtonLabel = CTAButtonLabel;
				this.linkButtonLabel = linkButtonLabel;
				this.isRequestableButton = swipe;
				this.proofFooter = proofFooter;
				this.requestableFooter = requestableFooter;
				this.brandId = certificate.issuer.identity.address;
				this.identity = certificate.issuer.identity;
				this.identityDisplay = brandDisplayMapper(this.identity);
				this.isOwner = certificate.owner.isOwner;
				this.brandNameTranslation = {
					brandname: brandName(certificate.issuer.identity),
				};
				this.generateActionMenuItems();

				const labels = this.labelService.getLabels(certificate.issuer.identity);
				const labelsDetail = labels.map(async (label) =>
					this.labelService.getLabelDetail(label.title),
				);

				this.labels = await Promise.all(labelsDetail);

				const isPendingNft = this.pendingNftService.isPendingNft(
					this.certificate.certificateId,
					this.chainType,
				);
				if (isPendingNft) {
					this.isClaimed = true;
				}

				this.eventLogger.logScreen('product-detail', {
					certificateId: this.certificateId,
					method: this.method,
					issuer: certificate.issuer.identity.address,
					isRequestable: certificate.isRequestable,
					isOwner: certificate.owner.isOwner,
				});
			}),
		);
	}

	cleanCertificate(certificate) {
		const medias = [];

		if (get(certificate, 'content.data.medias')) {
			certificate.content.data.medias.map((media) => {
				// Remove AR Content if not IOS
				if (media.mediaType === 'AR') {
					if (
						this.platformService.isIOS() &&
						environment.appType === 'cordova'
					) {
						medias.push(media);
					}
				} else {
					medias.push(media);
				}
			});

			certificate.content.data.medias = medias;
		}

		return certificate;
	}

	get $getUnreadNotifications() {
		return this.notificationService
			.$notificationUnreadFilterByCertificateId(this.certificateId)
			.pipe(map((notif) => notif.length));
	}

	async ngOnInit() {
		this.setChainType();
		this.subscriptions.push(
			this.applicationEvents.subscribe('TransferFrom', (event) => {
				if (
					event.returnValues._tokenId === this.certificateId &&
					this.network === event.network
				) {
					this.navCtrl.navigateRoot('/tab/brand-list');
				}
			}),
		);

		await this.init();

		this.isChina = await this.chinaService.showChinaFooter();

		if (this.getPendingCertificateIdOrUrlFromRoute()) return;

		// display directly proof of ownership if queryparam say so from blockhain action service
		this.route.queryParams
			.pipe(
				take(1),
				filter((queryParams) => queryParams.showProof !== undefined),
				tap(() => {
					this.router.navigate(['.'], {
						relativeTo: this.route,
						queryParams: {},
						replaceUrl: true,
					});
					this.showProofModal();
				}),
			)
			.subscribe();

		this.subscriptions.push(
			this.arianeeEventService
				.fetchAndCacheEvents(this.wallet, this.certificateId, this.passphrase)
				.subscribe((data) => {
					this.events = data;
				}),
		);

		this.router.events
			.pipe(filter((r) => r instanceof NavigationEnd))
			.subscribe((r) => {
				this.route.queryParams
					.pipe(
						take(1),
						filter((queryParams) => queryParams.showProof !== undefined),
						tap(() => {
							this.router.navigate(['.'], {
								relativeTo: this.route,
								queryParams: {},
								replaceUrl: true,
							});
							this.showProofModal();
						}),
					)
					.subscribe();
			});
	}

	getPendingEvents() {
		return from(
			this.wallet.contracts.eventContract.methods
				.pendingEventsLength(this.certificateId)
				.call(),
		);
	}

	displayCongratModal = async () => {
		const isPendingCertificate = this.isPendingCertificate(this.certificate);

		const detailModal = await this.modalCtrl.create({
			component: CongratOnClaimModalComponent,
			cssClass: 'auto-height middle light-round',
			componentProps: {
				certificateId: this.certificateId,
				certificate: this.certificate,
				logScreen: this.eventLogger.logScreen,
				logEvent: this.eventLogger.logEvent,
				descriptionKey: isPendingCertificate
					? 'CertificateInfos.claimCongrat.descriptionPending'
					: null,
				buttonKey: isPendingCertificate
					? 'CertificateInfos.claimCongrat.buttonLabelPending'
					: null,
			},
		});

		detailModal.onDidDismiss().then(() => {
			this.displayPinCodeModal();
		});

		await detailModal.present();
	};

	/**
	 * Check IF Pin code already Set otherwise display Auth page
	 *
	 */
	displayPinCodeModal() {
		this.userService.hasPinCode
			.pipe(first())
			.subscribe(async (data: boolean) => {
				if (data === false) {
					const pinCodeModal = await this.modalCtrl.create({
						component: AuthPage,
						cssClass: 'modal--full_screen',
					});
					pinCodeModal.onDidDismiss().then(() => {
						this.backupCheckService.displayIfNeededBackUpModal().then();
					});
					await pinCodeModal.present();
				} else {
					this.backupCheckService.displayIfNeededBackUpModal().then();
				}
			});
	}

	public onDownloadAppClick = (
		initiator: 'ctaButton' | 'appStoreButton' | 'googlePlayButton',
	) => {
		this.eventLogger.logEvent(`deeplinkFront_${initiator}_Click`, {
			certificateId: this.certificateId,
			method: this.method,
		});

		this.loaderService.showLoader();

		if (initiator !== 'ctaButton') {
			const storeUrlFromInitiator: Partial<
				Record<
					Parameters<ProductDetailLegacyComponent['onDownloadAppClick']>[0],
					string
				>
			> = {
				appStoreButton:
					'https://apps.apple.com/en/app/arianee-wallet/id1435782507',
				googlePlayButton:
					'https://play.google.com/store/apps/details?id=com.arianee.wallet',
			};

			this.eventLogger.logEvent(`deeplinkFront_${initiator}_End`, {
				certificateId: this.certificateId,
				method: this.method,
			});

			window.location.href = storeUrlFromInitiator[initiator];
		} else {
			this.deepLinkRedirection$.pipe(take(1)).subscribe(
				(response) => {
					this.eventLogger.logEvent(`deeplinkFront_${initiator}_End`, {
						certificateId: this.certificateId,
						method: this.method,
					});

					window.location.replace(response.url);
				},
				(err) => {
					this.loaderService.dismiss();
				},
			);
		}
	};

	async onSwipeClick() {
		this.eventLogger.logEvent('onSwipeComplete', {
			certificateId: this.certificateId,
			method: this.method,
		});

		if (this.isPendingCertificate(this.certificate)) {
			return await this.onPendingNftSwipe();
		}

		this.loaderService.showLoaderWithBlockchain();

		try {
			await this.wallet.methods.requestCertificateOwnership(
				+this.certificateId,
				this.passphrase,
			);
			this.isClaimed = false;
			this.isRequestableButton = false;
			this.isOwner = true;
			this.loaderService.dismiss();
			this.eventLogger.logEvent('onSwipeComplete_success', {
				certificateId: this.certificateId,
				method: this.method,
			});
			this.displayCongratModal();
		} catch (e) {
			this.loaderService.dismiss();
			this.eventLogger.logEvent('onSwipeComplete_error', {
				certificateId: this.certificateId,
				method: this.method,
			});
			this.toasterService.alert({ message: 'Error.GenericMessage' });
			this.fetchCertificate().toPromise();
			e.cause = { certificateId: this.certificateId, method: this.method };
			throw e;
		}
	}

	async onPendingNftSwipe() {
		const isNewProductDetail = await this.featureFlipService
			.$isFeatureFlipOnce('newProductDetail')
			.toPromise();

		const useDeferredClaim = this.pendingNftService.supportsDeferredClaim(
			this.certificate,
		);

		if (
			!useDeferredClaim &&
			(!this.pendingCertificateEndpoint ||
				!(
					'url' in this.pendingCertificateEndpoint &&
					'label' in this.pendingCertificateEndpoint
				))
		) {
			throw new Error(
				'Swiped on a pending nft that has not a valid pendingNftEndpoint',
			);
		}

		if (!isNewProductDetail) {
			this.loaderService.showLoaderWithBlockchain();
		}

		try {
			if (useDeferredClaim) {
				const address = this.wallet.address;
				const res = await this.pendingNftService.deferredClaim(
					this.certificate,
					this.passphrase,
					address,
				);

				// Don't use !res.success or typescript wont be able to infer that res.nmpError is defined
				if (res.success === false)
					throw new Error(
						this.translateService.instant('Error.GenericMessageWithDetails', {
							details: res.nmpError,
						}),
					);

				this.pendingNftService.addPendingNft(this.chainType, {
					...this.certificate,
					network: this.network,
				} as PendingNft);
			} else {
				const transferUrl = `${this.pendingCertificateEndpoint.url}/certificate/one2Many/v2/transfer?label=${this.pendingCertificateEndpoint.label}`;

				const authUrl = await this.wallet.methods.createAuthURL({
					type: 'arianeeAccessToken',
					url: transferUrl,
					certificateId: null,
				});

				const request = await fetch(authUrl);
				const certificate = (await request.json()) as PendingNft;

				this.pendingNftService.addPendingNft(this.chainType, certificate);
			}

			this.displayCongratModal();

			this.isClaimed = true;
			this.isRequestableButton = false;
			this.isOwner = true;

			if (useDeferredClaim) {
				await this.eventLogger.logEvent('onPendingSwipeComplete_success', {
					certificateId: this.certificateId,
					type: 'deferredClaim',
				});
			} else {
				await this.eventLogger.logEvent('onPendingSwipeComplete_success', {
					certificateId: this.certificateId,
					type: 'pendingOne2ManyClaim',
					endpoint: this.pendingCertificateEndpoint.url,
					label: this.pendingCertificateEndpoint.label,
				});
			}
		} catch (e) {
			if (useDeferredClaim) {
				this.toasterService.alert({
					message: e.message,
				});

				await this.eventLogger.logEvent('onPendingSwipeComplete_error', {
					certificateId: this.certificateId,
					type: 'deferredClaim',
				});
			} else {
				this.toasterService.alert({ message: 'Error.GenericMessage' });

				await this.eventLogger.logEvent('onPendingSwipeComplete_error', {
					certificateId: this.certificateId,
					type: 'pendingOne2ManyClaim',
					endpoint: this.pendingCertificateEndpoint.url,
					label: this.pendingCertificateEndpoint.label,
				});
			}
		}
		if (!isNewProductDetail) {
			this.loaderService.dismiss();
		}
	}

	navigateToMessage() {
		this.router.navigate(
			[
				`/tab/brand-list/product-detail/notification/${this.network}/${this.certificateId}`,
			],
			{ skipLocationChange: true },
		);
	}

	navigateToNonPendingCertificate(certificateId: number, network: NETWORK) {
		setTimeout(() => {
			this.navCtrl.navigateForward(
				`/tab/brand-list/product-detail/${network}/${this.certificateId}`,
			);
		}, 500);
	}

	onClickNavBack() {
		this.navCtrl.back();
	}

	actionProofLink = async (link: {
		type: string;
		url: string;
		title: string;
	}) => {
		if (this.isOwner) {
			this.loaderService.showLoader();

			try {
				const proofLink = await this.wallet.methods.createAuthURL({
					url: link.url,
					certificateId: +this.certificateId,
					type:
						link.type === 'proofLinkAction' ? 'proof' : 'arianeeAccessToken',
				});

				this.browserService.openBrowser(proofLink);
			} catch (e) {
				console.error(e);
				this.toasterService.showRedesigned({
					title: 'Oh noo',
					color: 'danger',
					icon: 'cancel',
					duration: 2500,
					position: 'top',
				});
			} finally {
				this.loaderService.dismiss();
			}
		} else {
			this.toasterService.showRedesigned({
				title: 'CertificateInfos.actionLink.notTheOwner',
				color: 'danger',
				icon: 'cancel',
				duration: 2500,
				position: 'top',
			});
		}
	};

	async onLinkClick(link: { type: string; url: string; title: string }) {
		if (
			link.type === 'proofLinkAction' ||
			link.type === 'arianeeAccessTokenAuthLink'
		) {
			if (!this.isOwner && this.isPendingCertificate(this.certificate)) {
				this.toasterService.showRedesigned({
					title: 'CertificateInfos.actionLink.notTheOwner',
					color: 'danger',
					icon: 'cancel',
					duration: 2500,
					position: 'top',
				});
			}

			return this.actionProofLink(link);
		} else if (link.type === 'youtube') {
			const historyModal = await this.modalCtrl.create({
				component: ProductInfoYoutubeComponent,
				cssClass: 'auto-height middle light-round',
				componentProps: {
					link: link,
					logScreen: this.eventLogger.logScreen,
				},
			});
			await historyModal.present();
		} else if (link.type === 'system') {
			this.loaderService.showLoader();
			this.previewAnyFile.preview(link.url).then(() => {
				setTimeout(() => {
					this.loaderService.dismiss();
				}, 5000);
			});
		} else if (link.type === 'external') {
			this.browserService.openBrowserSystem(link.url);
		} else if (link.type === 'spkzLink') {
			this.spkzService.navigateToSPKZLink(link.url);
		} else {
			if (link.url.startsWith('arianee:')) {
				if (this.isOwner) {
					link.url = link.url.replace('%certificateId%', this.certificateId);
					this.arianeeBlockchainActionService.handleLink(
						link.url,
						this.network,
					);
				} else {
					this.toasterService.showRedesigned({
						title: 'CertificateInfos.actionLink.notTheOwner',
						color: 'danger',
						icon: 'cancel',
						duration: 2500,
						position: 'top',
					});
				}
			} else {
				this.browserService.openBrowser(link.url);
			}
		}
	}

	public onBrandClick() {
		this.navCtrl.navigateForward(`/tab/brand-page/${this.brandId}`);
	}

	ngOnDestroy() {
		this.subscriptions.forEach((sub) => sub.unsubscribe());
	}

	public onToggleMissing = async (isMissing) => {
		if (this.isMissing !== isMissing) {
			this.isMissing = isMissing;
			this.loaderService.showLoaderWithBlockchain();
			await this.missingAndStolenService.onToggleMissingStatus(
				isMissing,
				this.certificateId,
			);
			this.loaderService.dismiss();
			this.missingAndStolenService.openLostOrStollenIfNeeded(
				this.certificateId,
				isMissing,
				this.network,
			);
		}
	};

	showProofModal = async (): Promise<HTMLIonModalElement> => {
		const modal = await this.modalCtrl.create({
			component: ProductInfoCertComponent,
			cssClass: 'middle light-round auto-height',
			componentProps: {
				certificateId: this.certificateId,
				certificate: this.certificate,
				logScreen: this.eventLogger.logScreen,
				shareLink: this.sharingService.shareLink,
				createCertificateProofLink: createCertificateProofLink(
					this.wallet,
					this.certificate,
				),
				network: this.network,
			},
		});

		modal.present();

		return modal;
	};

	onClickViewCert = async () => {
		const verifyModal = await this.modalCtrl.create({
			component: ProductCertVerifiedComponent,
			cssClass: 'auto-height bottom transparent cert',
			showBackdrop: false,
			componentProps: {
				certificateId: this.certificateId,
				certificate: this.certificate,
				logScreen: this.eventLogger.logScreen,
				network: this.network,
			},
		});
		await verifyModal.present();
	};

	acceptEvent = async ($event) => {
		const eventId = $event[0];

		this.loaderService.showLoaderWithBlockchain();

		try {
			await this.wallet.methods.acceptArianeeEvent(eventId);
		} catch (e) {
			console.error(e);
		} finally {
			this.loaderService.dismiss();
			this.toasterService.showRedesigned({
				title: 'CertificateEvents.accepted',
				color: 'success',
				icon: 'check',
				duration: 2500,
				position: 'top',
			});
			await this.afterEventAction(eventId);
		}
	};

	declineEvent = async ($event) => {
		const eventId = $event[0];

		this.loaderService.showLoaderWithBlockchain();

		try {
			await this.wallet.methods.refuseArianeeEvent(eventId);
		} catch (e) {
			console.error(e);
		} finally {
			this.loaderService.dismiss();
			this.toasterService.showRedesigned({
				title: 'CertificateEvents.refused',
				color: 'success',
				icon: 'check',
				duration: 2500,
				position: 'top',
			});
			await this.afterEventAction(eventId);
		}
	};

	afterEventAction = async (eventId: string) => {
		this.arianeeEventService.markAsNonPendingInCache(
			this.certificateId,
			eventId,
		);
		await this.refreshEvents();
		await this.fetchPendingEventsLength(false);
	};

	onClickHistory = async () => {
		const brandLogoHeader =
			this.route.snapshot.paramMap.get('brandLogoHeader') ||
			brandImageLogo(this.certificate.issuer.identity, ['brandLogoHeader']);
		await this.arianeeEventService
			.getCachedEvents(this.certificateId)
			.pipe(take(1))
			.toPromise();
		const address = await this.arianeeService.$address
			.pipe(first())
			.toPromise();

		const historyModal = await this.modalCtrl.create({
			component: ProductInfoHistoryComponent,
			cssClass: 'auto-height middle light-round',
			componentProps: {
				events: this.events,
				certificateDisplay: this.certificateDisplay,
				identityBase: this.certificate.issuer.identity,
				certificateId: this.certificateId,
				address,
				logScreen: this.eventLogger.logScreen,
				onAcceptEvent: this.acceptEvent,
				onDeclineEvent: this.declineEvent,
				brandLogoHeader,
				network: this.network,
			},
		});
		await historyModal.present();
	};

	onClickTransparency = async (url) => {
		const historyModal = await this.modalCtrl.create({
			component: ProductInfoTransparencyComponent,
			cssClass: 'auto-height middle light-round',
			componentProps: {
				jsonUrl: url,
				logScreen: this.eventLogger.logScreen,
				onClickViewMore: this.openLabelModal,
				certificate: this.certificate,
			},
		});
		await historyModal.present();
	};

	openTransparencyItemModal = async (transparencyItem) => {
		const detailModal = await this.modalCtrl.create({
			component: TransparencyItemModalComponent,
			cssClass: 'auto-height middle light-round',
			componentProps: {
				transparencyItem: transparencyItem,
				openBrowser: this.browserService.openBrowser,
			},
		});
		await detailModal.present();
	};

	onClickTransparencyItems = async () => {
		const historyModal = await this.modalCtrl.create({
			component: ProductInfoTransparencyItemsComponent,
			cssClass: 'auto-height middle light-round',
			componentProps: {
				certificate: this.certificate,
				onTransparencyItemClick: this.openTransparencyItemModal,
			},
		});
		await historyModal.present();
	};

	openLabelModal = async (props) => {
		const detailModal = await this.modalCtrl.create({
			component: LabelModalComponent,
			cssClass: 'auto-height middle light-round',
			componentProps: props,
		});
		await detailModal.present();
	};

	openPGeneve = async () => {
		const detailModal = await this.modalCtrl.create({
			component: ProductInfoPGeneveComponent,
			cssClass: 'auto-height middle light-round',
			componentProps: {
				certificate: this.certificate,
				logScreen: this.eventLogger.logScreen,
				openBrowser: this.browserService.openBrowser,
				onClickViewMore: this.nothing,
				pgeneveUrl: environment.pgeneveUrl,
			},
		});
		await detailModal.present();
	};

	nothing() {}

	openSwissMade = async () => {
		const detailModal = await this.modalCtrl.create({
			component: ProductInfoSwissMadeComponent,
			cssClass: 'auto-height middle light-round',
			componentProps: {
				certificate: this.certificate,
				logScreen: this.eventLogger.logScreen,
				openBrowser: this.browserService.openBrowser,
				onClickViewMore: this.openLabelModal,
			},
		});
		await detailModal.present();
	};

	onClickMore = async (ev: any) => {
		const prodPopover = await this.popOverCtrl.create({
			component: ProdPopoverComponent,
			cssClass: 'popoverMore',

			event: ev,
			translucent: true,
			componentProps: {
				actionMenuItems: this.actionMenuItems,
				onClickItem: this.onClickItem,
			},
		});

		await prodPopover.present();
	};

	private async generateActionMenuItems() {
		const actionMenuItemDynamic = [];
		const features = await Promise.all(
			this.featureFlippedItems.map((feature) => this.featureActivated(feature)),
		);

		features.forEach(async (feature, index) => {
			if (feature) {
				actionMenuItemDynamic.push(this.featureFlippedItems[index]);
			}
		});

		let externalContentLinks = [];
		if (this.certificate.content.data) {
			externalContentLinks = (
				this.certificate.content.data.externalContents || []
			).filter((link) => this.specialExternalContentType.includes(link.type));
		}

		this.actionMenuItemDynamic = [
			...actionMenuItemDynamic,
			...externalContentLinks,
		];
	}

	public onClickCtaButton = async () => {
		if (this.certificateDisplay.fallbackClaim) {
			const objectLink = await this.handleLinkService
				.handleLink(this.certificateDisplay.fallbackClaim.url)
				.toPromise()
				.catch((e) => false);
			if (objectLink) {
				this.handleLinkService.redirectWithFirstNavigation(
					`/tab/brand-list/product-detail/${this.network}/${objectLink.certificateId}/${objectLink.passphrase}/${objectLink.method}`,
				);
			} else {
				this.browserService.openBrowser(
					this.certificateDisplay.fallbackClaim.url,
				);
			}
		} else {
			return this.onDownloadAppClick('ctaButton');
		}
	};

	/**
	 * Hide on-Boarding
	 */
	displayOnBoarding(): void {
		this.tabsService.hideTabsMenu.next(true);
		this.showOnBoarding = true;
	}

	/**
	 * Hide on-Boarding
	 */
	closeOnBoarding(): void {
		this.tabsService.hideTabsMenu.next(false);
		this.showOnBoarding = false;
	}

	/**
	 * Handle Scroll for each on-boarding step
	 * @param stepIndex
	 */
	onBoardingStepHandler(stepIndex: number) {
		// get Elements position
		if (
			this.productView.productMainImg !== undefined &&
			this.productView.walletDescription !== undefined &&
			this.productView.productLinks !== undefined
		) {
			const imagesPos = this.productView.productMainImg.nativeElement.offsetTop;
			const descriptionPos =
				this.productView.walletDescription.nativeElement.offsetTop;
			const linksPos = this.productView.productLinks.nativeElement.offsetTop;
			switch (stepIndex) {
				case 0:
					this.productView.scrollElement.scrollToPoint(0, 0, 300).then();
					break;
				case 1:
					this.productView.scrollElement
						.scrollToPoint(0, imagesPos - 200, 300)
						.then();
					break;
				case 2:
					this.productView.scrollElement
						.scrollToPoint(0, descriptionPos - 100, 300)
						.then();
					break;
				case 3:
					this.productView.scrollElement
						.scrollToPoint(0, linksPos - 100, 300)
						.then();
					break;
				case 4:
					this.productView.scrollElement.scrollToPoint(0, 0, 300).then();
					break;
			}
		}
	}

	onClickShareBtn(overlayImg: string) {
		if (this.isOwner) {
			this.router.navigate(['/share-photo'], { queryParams: { overlayImg } });
		} else {
			this.toasterService.showRedesigned({
				title: 'CertificateInfos.actionLink.notTheOwner',
				color: 'danger',
				icon: 'cancel',
				duration: 2500,
				position: 'top',
			});
		}
	}
}
