import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { IdentitySummary } from '@arianee/arianeejs/dist/src/models';
import { get } from 'lodash';

@Injectable({
  providedIn: 'root'
})
export class LabelService {
  constructor (private http:HttpClient) {

  }

  public async getLabelDetail (labelName:string):Promise<any> {
    const labelUrl = environment.arianeeshareUrl + '/labels/' + labelName.toLowerCase() + '/descriptions.json';
    return this.http.get(labelUrl).toPromise();
  }

  public getLabels (identity: IdentitySummary): any[] {
    const externalContents = get(identity, 'data.externalContents');
    if (externalContents) {
      return identity.data.externalContents.filter((externalContent) => externalContent.type === 'label');
    } else {
      return [];
    }
  };
}
