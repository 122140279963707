import { Component } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { ArianeeService } from '../../providers/arianee-service/arianee.service';
import { CertificateSummary } from '@arianee/arianeejs/dist/src/core/wallet/certificateSummary';
import { take } from 'rxjs/operators';
import { LoaderService } from '@arianeeprivate/wallet-shared-components';

@Component({
  templateUrl: './delete-passport.component.html',
  styleUrls: ['./delete-passport.component.scss']
})
export class DeletePassportComponent {
  public certificateId: string;
  public certificate:CertificateSummary;
  constructor (
      private modalCtrl: ModalController,
      private loaderService:LoaderService,
      private arianeeService:ArianeeService
  ) {
  }

  async onCancel () {
    await this.modalCtrl.dismiss();
  }

  async onConfirm () {
    this.loaderService.showLoaderWithBlockchain();

    try {
      const network = await this.arianeeService.$walletInitialize.pipe(take(1)).toPromise();
      const wallet = await this.arianeeService.getWalletInstance(network);
      await wallet.methods.destroyCertificate(this.certificateId);
      this.loaderService.dismiss();
    } catch (e) {
      this.loaderService.dismiss();
    }

    await this.modalCtrl.dismiss();
  }
}
