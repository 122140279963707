import { Component, Input } from '@angular/core';

import { FeatureFlipService } from '../../providers/feature-flip-service/feature-flip.service';

@Component({
	selector: 'app-auth',
	templateUrl: './auth.page.html',
	styleUrls: ['./auth.page.scss'],
})
export class AuthPage {
	@Input() changePinCode = false;

	constructor(public featureFlipService: FeatureFlipService) {}
}
