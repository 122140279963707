import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-wallet-connection-loader',
  templateUrl: './wallet-connection-loader.component.html',
  styleUrls: ['./wallet-connection-loader.component.scss']
})
export class WalletConnectionLoaderComponent {
  @Input() message: string = 'WalletConnect.connection.connecting';
}
