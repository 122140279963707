import { Location } from '@angular/common';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NETWORK } from '@arianee/arianeejs/dist/src';
import {
	DisplayMapperService,
	EnrichedNotification,
	LoaderService,
	Message,
	Notification,
	ToasterService,
} from '@arianeeprivate/wallet-shared-components';
import { ModalController, NavController } from '@ionic/angular';
import { get } from 'lodash';
import moment from 'moment';
import { map, take } from 'rxjs/operators';

import { NotiOptionComponent } from '../../../../../components/noti-option/noti-option.component';
import { ArianeeBlockchainActionService } from '../../../../../providers/arianee-blockchain-action-service/arianee-blockchain-action.service';
import { ArianeeService } from '../../../../../providers/arianee-service/arianee.service';
import { EventLoggerService } from '../../../../../providers/event-logger/event-logger-service';
import { BrowserService } from '../../../../../providers/inapp-browser/inapp-browser-service';
import { NotificationService } from '../../../../../providers/notification-service/notification.service';

type AugmentedMessage = Message & {
	id: Notification['id'];
	issuer: Notification['issuer'];
};

@Component({
	selector: 'app-chat-legacy',
	templateUrl: './chat-legacy.component.html',
	styleUrls: ['./chat-legacy.component.scss'],
})
export class ChatLegacyComponent implements OnInit {
	public messages: Array<AugmentedMessage> = [];
	public headerImage: string;
	private height: number;
	@Input() certificateId: string;
	@Input() messageId: string;
	@ViewChild('messagesContent', { static: false }) messagesContent;
	private network: NETWORK;

	constructor(
		private modalCtrl: ModalController,
		private location: Location,
		private route: ActivatedRoute,
		private navCtrl: NavController,
		private arianeeService: ArianeeService,
		private eventLogger: EventLoggerService,
		private loaderService: LoaderService,
		private notificationService: NotificationService,
		private browserService: BrowserService,
		private arianeeBlockchainActionService: ArianeeBlockchainActionService,
		private displayMapperService: DisplayMapperService,
		private toasterService: ToasterService,
	) {}

	ngOnInit() {
		this.certificateId = this.route.snapshot.paramMap.get('certificateId');
		this.network = this.route.snapshot.paramMap.get('network') as NETWORK;
		this.eventLogger.logScreen('chat', {
			certificateId: this.certificateId,
			network: this.network,
		});

		this.loaderService.showLoader();

		this.markAsReadAllMessage();
		this.arianeeService.getWalletInstance(this.network).then(async (wallet) => {
			await wallet.methods
				.getCertificate(this.certificateId, '', {
					messageSenders: true,
					issuer: true,
					content: true,
				})
				.then((certificate) => {
					const certificateDisplay =
						this.displayMapperService.certificateDisplayMapper(certificate);
					this.headerImage = certificateDisplay.brandLogoHeader;

					this.notificationService
						.$notificationFilterByCertificateId(this.certificateId)
						.subscribe((notifications: EnrichedNotification[]) => {
							this.eventLogger.logEvent('chat_displayedMessages', {
								certificateId: this.certificateId,
								messageIds: notifications.map((d) => d.id),
								network: this.network,
								issuer: certificate.issuer.identity.address,
							});

							this.messages = notifications
								.map((notif) => {
									return {
										id: notif.id,
										issuer: notif.issuer,
										issuerName: get(notif, 'issuer.identity.data.name'),
										avatarurl: notif.smallBrandImage,
										issender: false,
										message: notif.message,
										time: notif.time,
										title: notif.title,
										brandHeader: notif.bigBrandImage,
										externalContents: notif.externalContents,
									};
								})
								.sort((left, right) =>
									moment(left.time).diff(moment(right.time)),
								);

							this.loaderService.dismiss();
						});
				});
		});
	}

	ngAfterViewChecked() {
		const height = this.messagesContent.nativeElement.scrollHeight;

		if (this.height !== height) {
			this.height = height;
			this.messagesContent.nativeElement.scrollTo({
				behavior: 'smooth',
				top: height,
			});
		}
	}

	markAsReadAllMessage(): void {
		this.notificationService
			.$notificationFilterByCertificateId(this.certificateId)
			.pipe(
				take(1),
				map((allMessages) => allMessages.map((message) => message.id)),
			)
			.subscribe((messageIds) => {
				messageIds.forEach((messageId) =>
					this.notificationService.markAsRead(this.certificateId, messageId),
				);
			});
	}

	async onClickShowOption() {
		const notiOptionModal = await this.modalCtrl.create({
			component: NotiOptionComponent,
			componentProps: {
				certificateId: this.certificateId,
				network: this.network,
			},
			cssClass: 'middle auto-height hard-round',
		});
		await notiOptionModal.present();
	}

	onClickNavBack() {
		// this.location.back();
		this.navCtrl.pop();
	}

	actionProofLink = async (externalContent: {
		type: string;
		url: string;
		title: string;
	}) => {
		this.arianeeService.getWalletInstance(this.network).then(async (wallet) => {
			const { isOwner } = await wallet.methods.ownerOf(this.certificateId);

			if (isOwner) {
				this.loaderService.showLoader();
				wallet.methods
					.createAuthURL({
						url: externalContent.url,
						certificateId: +this.certificateId,
						type:
							externalContent.type === 'proofLinkAction'
								? 'proof'
								: 'arianeeAccessToken',
					})
					.then((authURL) => {
						this.loaderService.dismiss();
						this.browserService.openBrowser(authURL);
					})
					.catch((e) => {
						this.toasterService.showRedesigned({
							title: 'Oh noo',
							color: 'danger',
							icon: 'cancel',
							duration: 2500,
							position: 'top',
						});
					});
			} else {
				this.toasterService.showRedesigned({
					title: 'CertificateInfos.actionLink.notTheOwner',
					color: 'danger',
					icon: 'cancel',
					duration: 2500,
					position: 'top',
				});
			}
		});
	};

	async navigateTo(
		message: AugmentedMessage,
		externalContent: { url: string; type: string; title: string },
	) {
		await this.eventLogger.logEvent('chat_ctaClicked', {
			certificateId: this.certificateId,
			messageId: message.id,
			issuer: get(message.issuer, 'identity.address'),
			network: this.network,
		});

		const urlObject = new URL(externalContent.url);

		if (urlObject.protocol === 'arianee:') {
			if (urlObject.searchParams.get('certificateId') === null) {
				urlObject.searchParams.append(
					'certificateId',
					this.certificateId.toString(),
				);
			}

			this.arianeeBlockchainActionService.handleLink(
				urlObject.href,
				this.network,
			);
		} else {
			if (
				['arianeeAccessTokenAuthLink', 'proofLinkAction'].includes(
					externalContent.type,
				)
			) {
				return this.actionProofLink(externalContent);
			} else {
				this.browserService.openBrowser(urlObject.toString());
			}
		}
	}
}
