import { Component, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';

type Awaited<T> = T extends PromiseLike<infer U> ? U : T

interface ModalScrollableContentProps {
    modal: HTMLIonModalElement;
}

/**
 * This component should be used for scrollable content inside a modal.
 * It prevents the modal from closing (when the behaviour swipeToClose is eabled) when the user is scrolling inside a scrollable content.
 */
@Component({
  selector: 'app-modal-scrollable-content',
  template: `
        <div (touchstart)="touchStart()" (touchend)="touchEnd()" [style.justify-content]="justifyContent">
            <ng-content></ng-content>
        </div>`,
  styles: [`div {
        overflow-y: visible;
        display: flex;
        align-items: center;
        width: 100%;
        flex-direction: column;
    }`]
})
export class ModalScrollableContentComponent implements ModalScrollableContentProps {
    @Input() modal: Awaited<ReturnType<ModalController['create']>>;
    @Input() justifyContent: 'center' | 'flex-start' | 'flex-end' = 'flex-start';

    public touchStart () {
      this.modal.swipeToClose = false;
    }

    public touchEnd () {
      this.modal.swipeToClose = true;
    }
}
