import { Pipe, PipeTransform } from '@angular/core';
import moment from 'moment';

@Pipe({ name: 'timeSinceMessage' })
export class TimeSinceMessagePipe implements PipeTransform {
	transform(value: string): string {
		const now = moment();
		const diff = moment.duration(now.diff(value)).asSeconds(); // in seconds

		if (diff < 60) {
			return 'now';
		} else if (diff < 3600) {
			const minutes = Math.floor(diff / 60);
			return `${minutes}m`;
		} else if (diff < 86400) {
			const hours = Math.floor(diff / 3600);
			return `${hours}h`;
		} else {
			const date = moment(value, 'YYYY-MM-DDTHH:mm:ss').locale(
				navigator.language,
			);
			const lang = navigator.language;
			const format = lang.startsWith('fr') ? 'D/M' : 'M/D';
			return date.format(format);
		}
	}
}
