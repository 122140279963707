import { Component, Input, OnInit } from '@angular/core';
import { ArianeeEvent } from '@arianee/arianeejs/dist/src/core/wallet/certificateSummary/certificateSummary';
import { ModalController } from '@ionic/angular';

import { Icon } from '../../../../../components/redesign/icon/models/icon.model';
import { ListItem } from '../../../../../components/redesign/list/models/list-item.model';
import { HistoryDetailsModalComponent } from './components/history-details-modal/history-details-modal.component';

@Component({
	selector: 'app-history-modal',
	templateUrl: './history-modal.component.html',
	styleUrls: ['./history-modal.component.scss'],
})
export class HistoryModalComponent implements OnInit {
	@Input() header: { title: string; icon: Icon };
	@Input() historyItems: ListItem[] = [];
	@Input() acceptEvent: (eventId: string) => Promise<boolean>;
	@Input() declineEvent: (eventId: string) => Promise<boolean>;

	@Input() isWeb: boolean;
	public modal: HTMLIonModalElement;

	constructor(private modalCtrl: ModalController) {}

	async ngOnInit() {
		this.modal = await this.modalCtrl.getTop();
	}

	async openHistoryDetailsModal(item: ListItem) {
		const historyItemDetails: Partial<ArianeeEvent> = item.original;
		if (historyItemDetails) {
			this.onCloseModal();
			const modal = await this.modalCtrl.create({
				component: HistoryDetailsModalComponent,
				swipeToClose: true,
				cssClass: 'modal--bottom',
				componentProps: {
					header: {
						title: item.title + ' ' + item.highlightText,
						icon: {
							image: {
								url: historyItemDetails.issuer.identity.data.pictures
									? historyItemDetails.issuer.identity.data.pictures.find(
											(picture) => picture.type === 'brandLogoSquare',
									  ).url
									: undefined,
								height: '100%',
								width: '100%',
							},
							borderRadius: '16px',
							border: '1px solid rgba(0, 0, 0, 0.06)',
						},
					},
					arianeeEvent: historyItemDetails,
					isWeb: this.isWeb,
					acceptEvent: this.acceptEvent,
					declineEvent: this.declineEvent,
				},
			});

			await modal.present();
		}
	}

	onCloseModal(): void {
		this.modalCtrl.dismiss({
			dismissed: true,
		});
	}
}
