import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import Web3 from 'web3';

import { Button } from '../../redesign/button/models/button.model';
import { IModalHeaderConfig } from '../components/modal-header/modal-header.component';
import { SignMessageModalProps } from '../types/wallet-connect-modal';

@Component({
	selector: 'app-wallet-sign-message-modal',
	templateUrl: './wallet-sign-message-modal.component.html',
	styleUrls: ['./wallet-sign-message-modal.component.scss'],
})
export class WalletSignMessageModalComponent
	implements OnInit, SignMessageModalProps
{
	@Input() logo: string;
	@Input() website: string;
	@Input() isVerified: boolean;

	@Input() message: string;
	@Input() modalTitle?: SignMessageModalProps['modalTitle'];

	headerConfig: IModalHeaderConfig = {
		logo: '',
		title: 'Sign this message',
		subtitle: {
			label: '',
			verified: false,
		},
		description: '',
	};

	signButton: Button;
	refuseButton: Button;

	constructor(
		public modalController: ModalController,
		public translateService: TranslateService,
	) {}

	ngOnInit() {
		this.headerConfig.title = this.translateService.instant(
			this.modalTitle || 'WalletConnect.signMessage.signMessage',
		);

		this.headerConfig.logo = this.logo;
		this.headerConfig.subtitle.label = this.website;
		this.headerConfig.subtitle.verified = this.isVerified;

		this.message = this.message.startsWith('0x')
			? new Web3().utils.hexToUtf8(this.message)
			: this.message;

		this.initButtons();
	}

	private initButtons() {
		this.signButton = {
			title: {
				text: this.translateService.instant('WalletConnect.signMessage.sign'),
			},
			buttonType: 'primary',
		};

		this.refuseButton = {
			title: {
				text: this.translateService.instant('WalletConnect.signMessage.refuse'),
			},
			buttonType: 'text',
		};
	}
}
