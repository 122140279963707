import { Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';

import { environment } from '../../../environments/environment';

@Injectable({
	providedIn: 'root',
})
export class WhatPlatformService {
	constructor(private platform: Platform) {}

	public isIOS = () => {
		return this.platform.is('ios');
	};

	public isPWA = () => {
		return environment.appType === 'web';
	};

	public shouldBeMock = () => {
		return !this.platform.is('cordova');
	};
}
