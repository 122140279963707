import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';

import { Icon } from '../../../../../components/redesign/icon/models/icon.model';
import { ListItem } from '../../../../../components/redesign/list/models/list-item.model';
import { TransparencyItem } from '../../models/mapped-certificate.model';
import { NftLinkInfo } from '../../utils/externalContentsHandler';
import { TransparencyDetailsModalComponent } from './components/transparency-details-modal/transparency-details-modal.component';

interface TransparencyModalProps {
	header: { title: string; icon: Icon };
	transparencyItems: ListItem[];
}

@Component({
	selector: 'app-transparency-modal',
	templateUrl: './transparency-modal.component.html',
	styleUrls: ['./transparency-modal.component.scss'],
})
export class TransparencyModalComponent
	implements TransparencyModalProps, OnInit
{
	@Input() header: { title: string; icon: Icon };
	@Input() transparencyItems: ListItem<TransparencyItem>[] = [];
	@Input() brandLogoSquare: string;
	@Input() isWeb: boolean;
	@Input() nftLinkInfo: NftLinkInfo;

	public modal: HTMLIonModalElement;

	constructor(private modalCtrl: ModalController) {}

	async ngOnInit() {
		this.modal = await this.modalCtrl.getTop();
	}

	onCloseModal(): void {
		this.modalCtrl.dismiss({
			dismissed: true,
		});
	}

	async openTransparencyDetailsModal(item: ListItem) {
		const transparencyItemDetails: TransparencyItem =
			item.original as TransparencyItem;
		if (transparencyItemDetails) {
			this.onCloseModal();
			const modal = await this.modalCtrl.create({
				component: TransparencyDetailsModalComponent,
				swipeToClose: true,
				cssClass: 'modal--bottom',
				componentProps: {
					icon: {
						image: {
							url: item.icon.image.url,
							height: '100%',
							width: '100%',
						} as Icon,
						borderRadius: '16px',
					},
					transparencyItem: transparencyItemDetails,
					isWeb: this.isWeb,
					nftLinkInfo: this.nftLinkInfo,
				},
			});

			await modal.present();
		}
	}
}
