import {
	Component,
	ElementRef,
	OnDestroy,
	OnInit,
	ViewChild,
} from '@angular/core';
import { Router } from '@angular/router';
import { IdentityBase } from '@arianee/arianeejs/dist/src/models';
import { POAPNFT } from '@arianeeprivate/wallet-shared-components';
import { ModalController, Platform } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, Observable, Subject, combineLatest } from 'rxjs';
import { map, takeUntil, tap } from 'rxjs/operators';

import { Button } from '../../../../../components/redesign/button/models/button.model';
import { ImportChoiceModalComponent } from '../../../../../components/redesign/import-choice-modal/import-choice-modal.component';
import { ArianeeBlockchainSyncService } from '../../../../../providers/arianee-blockchain-sync-service/arianee-blockchain-sync.service';
import { BackupCheckService } from '../../../../../providers/backup-check-service/backup-check.service';
import { BackupService } from '../../../../../providers/backup-service/backup.service';
import { EventLoggerService } from '../../../../../providers/event-logger/event-logger-service';
import { FeatureFlipService } from '../../../../../providers/feature-flip-service/feature-flip.service';
import { MyCertificatesService } from '../../../../../providers/my-certificates/my-certificates.service';
import { PoapService } from '../../../../../providers/poap/poap.service';
import { UserService } from '../../../../../providers/user-service/user.service';
import {
	CollectionItem,
	collectionListMapper,
} from '../../mappers/collection-list-mapper';

@Component({
	selector: 'app-brand-list-new',
	templateUrl: './brand-list-new.component.html',
	styleUrls: ['./brand-list-new.component.scss'],
	providers: [BackupCheckService],
})
export class BrandListNewComponent implements OnInit, OnDestroy {
	@ViewChild('header', {
		read: ElementRef,
		static: false,
	})
	header: ElementRef<HTMLElement>;

	mappedCollections$: Observable<CollectionItem[]> = undefined;
	isIos = false;
	isPoapRefreshing: boolean;
	hasBackup: boolean;

	scanButton: Button;
	importWalletButton: Button;
	private poapCount: BehaviorSubject<number> = new BehaviorSubject(0);
	private poapIdentity: IdentityBase;
	private destroy$ = new Subject();
	public headerHeight: number = 0;
	constructor(
		private platform: Platform,
		private modalCtrl: ModalController,
		private featureFlipService: FeatureFlipService,
		private poapService: PoapService,
		private backupService: BackupService,
		private arianeeBlockchainSync: ArianeeBlockchainSyncService,
		private eventLogger: EventLoggerService,
		private translate: TranslateService,
		private backupCheckService: BackupCheckService,
		private myCertificatesService: MyCertificatesService,
		private router: Router,
		private userService: UserService,
	) {}

	async ngOnInit() {
		this.isIos = this.platform.is('ios');
		this.initPoap();
		this.initCertificatesGroupedByIssuer();
		this.eventLogger.logScreen('brand-list', {});
		this.userService.getHasBackup().subscribe((hasBackup) => {
			this.hasBackup = hasBackup;
		});
		this.initButtons();
	}

	ngAfterViewInit() {
		this.setHeaderHeight();
	}

	private mapCertificatesToIssuers(certificatesGroupedByIssuer) {
		return certificatesGroupedByIssuer
			.map((issuerCertificates) => ({
				issuer: issuerCertificates[0].issuer.identity,
				certificatesCount: issuerCertificates.length,
			}))
			.filter((issuer) => issuer.issuer !== undefined);
	}

	goToCollection(brandName: string, brandAddress: string) {
		this.eventLogger.logEvent('brand-list_clickOnBrand', {
			address: brandAddress,
		});

		switch (brandName) {
			case 'POAP':
				return this.router.navigate(['/tab/brand-list/poap-nft-list']);
			default:
				return this.router.navigate([
					'/tab/brand-list/brand-product-list/' + brandAddress,
				]);
		}
	}

	onClickScanFirstProduct() {
		this.router.navigate(['tab/scan-full-page']);
	}

	async onClickBackupViewer() {
		await this.eventLogger.logEvent('brand-list_clickBackupViewer');
		await this.openImportChoiceModal();
	}

	private async openImportChoiceModal() {
		const modal = await this.modalCtrl.create({
			component: ImportChoiceModalComponent,
			cssClass: 'modal--bottom',
			swipeToClose: true,
			componentProps: {
				header: {
					title: this.translate.instant('Settings.importBackup'),
					icon: {
						name: 'icon-download',
						fontSize: 42,
						height: '72px',
						width: '72px',
						borderRadius: '16px',
					},
				},
			},
		});
		return modal.present();
	}

	private initButtons() {
		this.scanButton = {
			title: {
				text: this.translate.instant('BrandList.scanFirstProduct'),
			},
			buttonType: 'primary',
			disabled: false,
		};

		this.importWalletButton = {
			className: 'import-button',
			title: {
				text: this.translate.instant('BrandList.recoverYourWallet'),
			},
			buttonType: 'text',
			disabled: false,
		};
	}

	private initPoap() {
		combineLatest([
			this.poapService.getMyPoapNfts(),
			this.poapService.getPoapIdentity(),
			this.poapService.$isFetchingPoapNfts,
		])
			.pipe(takeUntil(this.destroy$))
			.subscribe(
				([poapList, identity, isFetching]: [
					POAPNFT[],
					IdentityBase,
					boolean,
				]) => {
					this.poapCount.next(poapList.length);
					this.isPoapRefreshing = isFetching;
					this.poapIdentity = identity;
				},
			);
	}

	private mapIssuersToCollections(
		issuers: { issuer: IdentityBase; certificatesCount: number }[],
		poapCount: number,
	): CollectionItem[] {
		if (poapCount > 0) {
			issuers = [
				...issuers,
				{
					issuer: this.poapIdentity,
					certificatesCount: poapCount,
				},
			];
		}
		return collectionListMapper(issuers);
	}

	private initCertificatesGroupedByIssuer() {
		const certificatesGroupedByIssuer$ = this.myCertificatesService
			.getAllMyCertificatesGroupedByIssuer()
			.pipe(
				map((certificatesGroupedByIssuer) => {
					const issuers = Object.keys(certificatesGroupedByIssuer || {});
					return issuers.map((issuerName) => {
						return certificatesGroupedByIssuer[issuerName] || [];
					});
				}),
				tap((certificatesGroupedByIssuer) => {
					this.arianeeBlockchainSync.syncWithBlockchain();
				}),
			);

		const issuers$ = certificatesGroupedByIssuer$.pipe(
			map((certificatesGroupedByIssuer) =>
				this.mapCertificatesToIssuers(certificatesGroupedByIssuer),
			),
		);

		this.mappedCollections$ = combineLatest([issuers$, this.poapCount]).pipe(
			map(([issuers, poapCount]) => {
				return this.mapIssuersToCollections(issuers, poapCount);
			}),
		);
	}

	private setHeaderHeight() {
		this.headerHeight = (
			this.header.nativeElement.firstElementChild as HTMLElement
		).offsetHeight;

		// Retry if css not yet loaded
		if (this.headerHeight === 0)
			setTimeout(this.ngAfterViewInit.bind(this), 10);
	}

	ngOnDestroy() {
		this.destroy$.next();
		this.destroy$.complete();
	}
}
