import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'urlEncode',
})
export class UrlEncodePipe implements PipeTransform {
	transform(value: string): string {
		return value
			.replace(/[()]/g, function (c) {
				return '%' + c.charCodeAt(0).toString(16);
			})
			.replace(/ /g, '%20');
	}
}
