import { CertificateDisplay } from '@arianeeprivate/wallet-shared-components';

export const footerDisplay = (data: {
	productDisplay: CertificateDisplay;
	isPWA?: boolean;
	method?: string;
}): {
	requestableFooter: boolean;
	CTAButtonLabel: string;
	swipe: boolean;
	proofFooter: boolean;
	linkButtonLabel: string;
} => {
	const result = {
		CTAButtonLabel: undefined,
		linkButtonLabel: undefined,
		proofFooter: false,
		swipe: false,
		requestableFooter: false,
	};

	const { productDisplay, isPWA, method } = data;

	if (method === 'proof') {
		result.proofFooter = true;
		return result;
	}

	if (isPWA) {
		if (productDisplay.isRequestable) {
			result.CTAButtonLabel = 'CertificateInfos.nftClaimButton';
			result.linkButtonLabel = 'CertificateInfos.nonArianeeWallet';
			return result;
		} else if (productDisplay.fallbackClaim) {
			result.CTAButtonLabel = productDisplay.fallbackClaim.title;
			result.linkButtonLabel = 'CertificateInfos.nonArianeeWallet';
			return result;
		} else {
			result.CTAButtonLabel = 'CertificateInfos.GetApp';
			result.linkButtonLabel = 'CertificateInfos.nonArianeeWallet';
		}
	} else {
		if (productDisplay.isOwner) {
			result.requestableFooter = true;
		} else if (productDisplay.isRequestable) {
			result.swipe = true;
		} else if (productDisplay.fallbackClaim) {
			result.CTAButtonLabel = productDisplay.fallbackClaim.title;
			result.linkButtonLabel = 'CertificateInfos.nonArianeeWallet';
		} else {
			result.requestableFooter = true;
		}
	}

	return result;
};
