import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { WhatPlatformService } from '../what-platform-service/whatPlatformService';
import { GoogleDriveAPIMocks } from './google-drive-api.mockResponse';
import { GoogleDriveCreateFileBody, GoogleDriveResponse } from '../../models/googleDrive';
import { SentryErrorHandler } from '../sentry-service/sentry-service';

declare let window: any;
@Injectable({
  providedIn: 'root'
})

export class GoogleDriveApiService {
  constructor (
      private httpClient: HttpClient,
      private whatPlatform: WhatPlatformService,
      private sentryService:SentryErrorHandler
  ) {}

  public async requestFiles (accessToken:string) {
    if (this.whatPlatform.shouldBeMock()) {
      return GoogleDriveAPIMocks.requestFiles();
    }

    return this.httpClient.request(
      'GET',
      'https://www.googleapis.com/drive/v3/files',
      {
        headers: {
          authorization: `Bearer ${accessToken}`
        }
      }
    ).toPromise();
  }

  public async requestFilesWithQuery (accessToken:string, query:string):Promise<GoogleDriveResponse> {
    if (this.whatPlatform.shouldBeMock()) {
      return GoogleDriveAPIMocks.requestFilesWithQuery();
    }

    return await this.httpClient.request(
      'GET',
      'https://www.googleapis.com/drive/v3/files',
      {
        params: {
          q: query,
          spaces: 'drive',
          corpora: 'user'
        },
        headers: {
          authorization: `Bearer ${accessToken}`
        }
      }
    ).toPromise() as GoogleDriveResponse;
  }

  public async searchFileInAppFolder (accessToken:string, query:string) {
    if (this.whatPlatform.shouldBeMock()) {
      return GoogleDriveAPIMocks.searchFileInAppFolder();
    }

    return this.httpClient.request(
      'GET',
      'https://www.googleapis.com/drive/v3/files',
      {
        params: {
          q: query,
          spaces: 'appDataFolder',
          corpora: 'user'
        },
        headers: {
          authorization: `Bearer ${accessToken}`
        }
      }
    ).toPromise().catch(e => {
      this.sentryService.handleError(e);
      throw e;
    });
  }

  public async createFolder (accessToken:string, name:string, parents?:string[]):Promise<any> {
    const body:GoogleDriveCreateFileBody = {
      name: name,
      mimeType: 'application/vnd.google-apps.folder'
    };

    if (parents) {
      body.parents = parents;
    }

    return this.httpClient.post(
      'https://www.googleapis.com/drive/v3/files/',
      body,
      {
        headers: {
          'Content-Type': 'application/json',
          authorization: `Bearer ${accessToken}`
        }
      }).toPromise();
  }

  public async copyFile (accessToken:string, fileId:string, destinationId:string[]) {
    return this.httpClient.post(
      'https://www.googleapis.com/drive/v3/files/' + fileId + '/copy',
      {
        parents: destinationId
      },
      {
        headers: {
          'Content-Type': 'application/json',
          authorization: `Bearer ${accessToken}`
        }
      }).toPromise();
  }

  public async deleteFile (accessToken:string, fileId:string) {
    return this.httpClient.delete(
      'https://www.googleapis.com/drive/v3/files/' + fileId,
      {
        headers: {
          'Content-Type': 'application/json',
          authorization: `Bearer ${accessToken}`
        }
      }).toPromise();
  }

  public uploadFile (blobFile:Blob, name:string, parentFolder:any, accessToken:string) {
    const formData = new FormData();
    formData.append('Metadata',
      new Blob([JSON.stringify({
        name: name,
        parents: [parentFolder]
      })], {
        type: 'application/json'
      }));
    formData.append('Media', blobFile, 'exportArianee');

    return this.httpClient.post(
      'https://www.googleapis.com/upload/drive/v3/files?uploadType=multipart',
      formData,
      {
        headers: {
          contentType: 'multipart/form-data',
          authorization: `Bearer ${accessToken}`
        }
      }).toPromise();
  }

    public logout=async () => {
      try {
        await this.trySilentDriveAccess();
        window.plugins.googleplus.logout(
          function (msg) {
            console.info(msg);
          }, (e) => {
            console.error(e);
          }
        );
      } catch (e) {
        console.info('user is not logged in on google drive');
      };
    }

  public login=async (): Promise<{accessToken:string, email:string}> => {
    if (this.whatPlatform.shouldBeMock()) {
      return Promise.resolve({ accessToken: 'string', email: 'string' });
    }
    let token;
    try {
      token = await this.trySilentDriveAccess();
    } catch (e) {
      token = await this.getDriveAccessToken();
    }

    return token;
  };

  private getDriveAccessToken (): Promise<any> {
    return new Promise((resolve, reject) => {
      window.plugins.googleplus.login(
        {
          scopes: 'https://www.googleapis.com/auth/drive.file https://www.googleapis.com/auth/drive.appdata',
          webClientId: environment.webIdGoogleDrive
        },
        async function (obj) {
          resolve(obj);
        },
        function (e) {
          console.error('getDriveAccessToken error', e);
          reject(new Error('can\'t get the drive access token'));
        });
    });
  }

  private trySilentDriveAccess (): Promise<any> {
    return new Promise((resolve, reject) => {
      window.plugins.googleplus.trySilentLogin(
        {
          scopes: 'https://www.googleapis.com/auth/drive.file https://www.googleapis.com/auth/drive.appdata',
          webClientId: environment.webIdGoogleDrive
        },
        function (obj) {
          resolve(obj);
        },
        function (e) {
          console.error('trySilentDriveAccess error', e);
          reject(new Error('can\'t get the drive access token from silent login'));
        }
      );
    });
  }

  public getFileContent (fileId, accessToken) {
    if (this.whatPlatform.shouldBeMock()) {
      return GoogleDriveAPIMocks.getFileContent(fileId);
    };

    return this.httpClient.request(
      'GET',
      'https://www.googleapis.com/drive/v3/files/' + fileId + '?alt=media',
      {
        headers: {
          authorization: `Bearer ${accessToken}`
        }
      }
    ).toPromise();
  }
}
