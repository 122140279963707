import {
	AfterViewInit,
	Component,
	EventEmitter,
	Input,
	Output,
} from '@angular/core';

import { StatusBarService } from '../../../providers/statusbar/statusbar-service';
import { Icon } from '../icon/models/icon.model';

interface HeaderAltProps {
	showBackButton: boolean;
	backButtonStyle?: {
		color?: string;
		background?: string;
	};
	title: string;
	cropTitle?: boolean;
	imageUrl?: string;
	actionItem: {
		icon: Icon;
		action: () => void;
	};
}

@Component({
	selector: 'app-header-alt',
	templateUrl: './header-alt.component.html',
	styleUrls: ['./header-alt.component.scss'],
})
export class HeaderAltComponent implements HeaderAltProps {
	@Input() showBackButton: boolean;
	@Input() backButtonStyle?: HeaderAltProps['backButtonStyle'];
	@Input() title: string;
	@Input() cropTitle?: boolean = true;
	@Input() imageUrl?: string;
	@Input() actionItem: {
		icon: Icon;
		action: () => void;
	} = undefined;

	@Output() public backButtonClick = new EventEmitter();
}
