import { Component, Input, Renderer2 } from '@angular/core';
import { ModalController, NavController, ToastController } from '@ionic/angular';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { delay, filter, map, mergeMap, tap } from 'rxjs/operators';
import { from, iif, of, Subject, Subscription } from 'rxjs';
import { SessionService } from '../../providers/session-service/session.service';
import { UserService } from '../../providers/user-service/user.service';
import { BiometricService } from '../../providers/fingerprint-service/biometric.service';
import { EventLoggerService } from 'src/app/providers/event-logger/event-logger-service';

@Component({
  selector: 'app-auth',
  templateUrl: './fakeLogin.page.html',
  styleUrls: ['./fakeLogin.page.scss']
})
export class FakeLoginPage {
    @Input() changePinCode=false
    constructor (
        private navCtrl: NavController,
        public userService: UserService,
        private router: Router,
        private toastController: ToastController,
        private fingerPrint: BiometricService,
        private modalCtrl:ModalController,
        private renderer: Renderer2,
        private eventLogger: EventLoggerService

    ) {

    }

    ionViewWillEnter () {
      this.eventLogger.logScreen('login', {});

      this.renderer.addClass(document.body, 'body--without--app-loader');
    }

    public login () {
      this.redirect();
    }

    redirect = async () => {
      this.renderer.removeClass(document.body, 'body--without--app-loader');
      await this.modalCtrl.dismiss();
    };
}
