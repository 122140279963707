import {Component, Input, OnInit} from '@angular/core';
import {ModalController} from '@ionic/angular';
import {TranslateService} from '@ngx-translate/core';

import {environment} from '../../../../../../environments/environment';
import {Button} from '../../../../../components/redesign/button/models/button.model';
import {Icon} from '../../../../../components/redesign/icon/models/icon.model';
import {IsAuthGuard} from '../../../../../guards/isAuth.guard';
import {SharingService} from '../../../../../providers/sharing-service/sharing.service';

@Component({
	selector: 'app-transfer-choice-modal',
	templateUrl: './transfer-choice-modal.component.html',
	styleUrls: ['./transfer-choice-modal.component.scss'],
})
export class TransferChoiceModalComponent implements OnInit {
	@Input() type: 'transfer' | 'proof';
	@Input() header: { title: string; icon: Icon };
	@Input() isWeb: boolean;

	modal: HTMLIonModalElement;

	transferOwnershipButton: Button;
	transferOwnershipWithArianeeButton: Button;

	appDisplayName = environment.appDisplayName;

	constructor(
		private sharingService: SharingService,
		private translateService: TranslateService,
		protected modalCtrl: ModalController,
		protected authGuard: IsAuthGuard,
	) {}

	async ngOnInit() {
		this.modal = await this.modalCtrl.getTop();

		const authenticated = await this.authGuard.isAuth();
		if (!authenticated) {
			await this.modal.dismiss();
			return;
		}

		this.initButtons();
	}

	onCloseModal(): void {
		this.modalCtrl
			.dismiss({
				dismissed: true,
			})
			.then((r) => {});
	}

	async onTransferOwnership(
		type: 'transferWithArianee' | 'transferAnyAddress',
	): Promise<void> {
		await this.modalCtrl.dismiss({
			dismissed: true,
			transfer: type,
		});
	}

	private initButtons() {
		this.transferOwnershipWithArianeeButton = {
			title: {
				text: this.translateService
					.instant('TransferCertificate.transferWithArianee')
					.replace('{{appName}}', environment.appDisplayName),
			},
			buttonType: 'primary',
			loadingText: null,
			loading: false,
			disabled: false,
			actionFinished: false,
		};

		this.transferOwnershipButton = {
			title: {
				text: this.translateService.instant(
					'TransferCertificate.transferToAnyAddress',
				),
			},
			buttonType: 'primary',
			loadingText: null,
			loading: false,
			disabled: false,
			actionFinished: false,
		};
	}
}
