import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NavController } from '@ionic/angular';
import { environment } from '../../../environments/environment';
import { EventLoggerService } from '../event-logger/event-logger-service';
import { HandleLinkService } from '../scan-service/handle-link.service/handle-link.service';
import { take } from 'rxjs/operators';
import { ArianeeService } from '../arianee-service/arianee.service';

@Injectable({
  providedIn: 'root'
})

export class DemoPassportService {
  constructor (
    private navCtrl: NavController,
    private http:HttpClient,
    private handleLinkService:HandleLinkService,
    private eventLogger:EventLoggerService,
    private arianeeService: ArianeeService
  ) {
  }

  async getDemoPassport () {
    this.eventLogger.logEvent('DemoPassportRequest');

    try {
      const demoPassportApiResult:{method?:string, certificateId?:number, passphrase?:string, deeplink?:string, message?:string} = await this.http.get(environment.demoPassportEndpoint).toPromise();
      const link = await this.handleLinkService.handleLink(demoPassportApiResult.deeplink).pipe(take(1)).toPromise();
      const network = await this.arianeeService.$walletInitialize.pipe(take(1)).toPromise();

      this.eventLogger.logEvent('DemoPassportSuccess');
      this.navCtrl.navigateForward(`/tab/brand-list/product-detail/${network}/${link.certificateId}/${link.passphrase}/${link.method}`);
    } catch (e) {
      alert('No more demo passport available');
      this.eventLogger.logEvent('DemoPassportFail');
    }
  }
}
