import { Injectable } from '@angular/core';
import { from, Observable, of, ReplaySubject } from 'rxjs';
import { distinctUntilChanged, mergeMap, take, tap } from 'rxjs/operators';
import { StorageService } from '../storage-service/storage.service';
import { environment } from '../../../environments/environment';
import { Platform } from '@ionic/angular';
import { SecureStorage } from '@ionic-native/secure-storage/ngx';
import { NativeStorage } from '@ionic-native/native-storage/ngx';
import { ArianeeService } from '../arianee-service/arianee.service';
import { CertificateEvents } from '@arianee/arianeejs/dist/src/core/wallet/certificateSummary/certificateSummary';
import { isEqual } from 'lodash';
import ArianeeWallet from '@arianee/arianeejs/dist/src/core/wallet';

@Injectable({
  providedIn: 'root'
})
export class ArianeeEventService {
  private secureStorage: SecureStorage;
  private nativeStorage: NativeStorage;

  public events:{
    [key:string]: ReplaySubject<CertificateEvents>
  }={};

  constructor (private storageService: StorageService, private platform: Platform) {
    this.platform.ready().then(() => {
      this.secureStorage = this.storageService.secureStorage;
      this.nativeStorage = this.storageService.nativeStorage;
    });
  }

  public getCachedEvents = (certificateId) => {
    return this.events[certificateId];
  }

  public fetchAndCacheEvents (wallet: ArianeeWallet, certificateId:string, passphrase?:string):Observable<CertificateEvents> {
    if (this.events[certificateId] === undefined) {
      this.events[certificateId] = new ReplaySubject<CertificateEvents>(1);
      wallet.methods.getCertificate(certificateId, passphrase,
        {
          events: true,
          arianeeEvents: environment.features.arianeeEvent,
          owner: true
        })
        .then(certificate => {
          this.events[certificateId].next(certificate.events);
        });
    }

    return this.events[certificateId]
      .pipe(distinctUntilChanged((obj1, obj2) => isEqual(obj1, obj2)));
  }

  public refreshEvents = async (wallet: ArianeeWallet, certificateId:string, passphrase?: string): Promise<void> => {
    if (this.events[certificateId] === undefined) return;

    const certificate = await wallet.methods.getCertificate(certificateId, passphrase,
      {
        events: true,
        arianeeEvents: environment.features.arianeeEvent,
        owner: true
      });

    this.events[certificateId].next(certificate.events);
  }

  public markAsNonPendingInCache = async (certificateId, arianeeEventId: string | number) => {
    const $cachedEvents = this.getCachedEvents(certificateId);
    if (!$cachedEvents) return;
    const events = await this.getCachedEvents(certificateId).pipe(take(1)).toPromise();
    const event = events.arianeeEvents.find((event) => event.arianeeEventId.toString() === arianeeEventId.toString());
    event.pending = false;
  }
}
