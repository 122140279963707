import { ChangeDetectionStrategy, Component, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-modal-footer',
  templateUrl: './modal-footer.component.html',
  styleUrls: ['./modal-footer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ModalFooterComponent {
    @Output() closeModal: EventEmitter<void> = new EventEmitter<void>();

    onCloseModal () {
      this.closeModal.emit();
    }
}
