import { Injectable } from '@angular/core';
import { FeatureFlipService } from '../feature-flip-service/feature-flip.service';

@Injectable({
  providedIn: 'root'
})
export class ThemeService {
  constructor(private ffService: FeatureFlipService) {
  }

  async checkDarkMode() {
    this.ffService.$isFeatureFlip('darkMode').subscribe((active: boolean) => {
      if (active === true) {
        document.body.classList.add('dark-mode');
      } else {
        document.body.classList.remove('dark-mode');
      }
    });
  }
}
