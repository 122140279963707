import { Injectable } from '@angular/core';
import { AppVersion } from '@ionic-native/app-version/ngx';
import { Platform } from '@ionic/angular';
import CryptoJS from 'crypto-js';
import { Observable, ObservableInput, combineLatest, from, of } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';

import { environment } from '../../../environments/environment';
import { ArianeeService } from '../arianee-service/arianee.service';
import { UserService } from '../user-service/user.service';

var pjson = require('../../../../package.json');

@Injectable({
	providedIn: 'root',
})
export class ProfileService {
	private getVersionNumber;
	private getVersionCode;

	constructor(
		public userService: UserService,
		private appVersion: AppVersion,
		private arianeeJSService: ArianeeService,
		private platform: Platform,
	) {}

	public getProfile = () => {
		if (!this.getVersionNumber) {
			if (this.platform.is('cordova')) {
				this.getVersionNumber = this.appVersion.getVersionNumber();
				this.getVersionCode = this.appVersion.getVersionCode();
			} else {
				this.getVersionNumber = Promise.resolve(pjson.version);
				this.getVersionCode = Promise.resolve(pjson.version);
			}
		}
		return combineLatest([
			this.userService.hasPinCode,
			this.userService.hasOnboarded(),
			this.userService.$backup
				.get()
				.pipe(
					map(
						(items) =>
							items.filter(
								(item) => item.type === 'google' || item.type === 'icloud',
							).length > 0,
					),
				),
			this.userService.$backup
				.get()
				.pipe(
					map(
						(items) =>
							items.filter((item) => item.type === 'manual').length > 0,
					),
				),
			this.userService.$devToolStorage.get(),
			this.userService.$advancedToolStorage.get(),
			from(this.getVersionNumber),
			from(this.getVersionCode),
			this.arianeeJSService.$address.pipe(
				map((pubKey) => CryptoJS.SHA256(pubKey).toString()),
			),
			this.userService.$numberOfAppLaunch.get(),
			this.arianeeJSService.$walletInitialize,
			this.getNumberOfCertificate(),
			this.getNumberOfMessages(),
		]).pipe(
			map((values) => {
				const [
					pincode,
					onboarded,
					hasCloudBackup,
					hasManualBackup,
					hasDevTool,
					hasAdvancedTool,
					buildNb,
					versionCode,
					publicKey,
					numberOfAppLaunch,
					network,
					numberOfCertificates,
					numberOfMessages,
				] = values;

				return {
					baidu: environment.isBaidu,
					appType: environment.appType,
					network,
					name: environment.name,
					production: environment.production,
					environment: environment.environment,
					hasPinCode: pincode,
					hasOnboarded: JSON.parse(onboarded as string),
					hasDevTool: JSON.parse(hasDevTool as string),
					hasAdvancedTool: JSON.parse(hasAdvancedTool as string),
					backupCloud: hasCloudBackup,
					backupManual: hasManualBackup,
					buildNb,
					versionCode,
					numberOfAppLaunch,
					publicKey,
					numberOfMessages,
					numberOfCertificates,
				};
			}),
		);
	};

	private getNumberOfCertificate = (): Observable<number> => {
		if (environment.appType === 'web') {
			return of(0);
		}
		return this.arianeeJSService.$walletInitialize.pipe(
			mergeMap(async (network) =>
				this.arianeeJSService.getWalletInstance(network),
			),
			mergeMap((wallet) => wallet.methods.getCertificatesId()),
			map((certificates) => (certificates ? certificates.length : 0)),
		);
	};

	private getNumberOfMessages = (): Observable<number> => {
		if (environment.appType === 'web') {
			return of(0);
		}
		return this.arianeeJSService.$walletInitialize.pipe(
			mergeMap(async (network) =>
				this.arianeeJSService.getWalletInstance(network),
			),
			mergeMap(
				(wallet) =>
					wallet.methods.getAllMyMessageIds() as unknown as ObservableInput<
						any[]
					>,
			),
			map((wallet) => wallet.length),
		);
	};
}
