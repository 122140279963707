import { Directive, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { environment } from '../../../environments/environment';

@Directive({
  selector: '[appIfNotWhitelabel]'
})
export class IfNotWhitelabelDirective implements OnInit {
  constructor (private templateRef: TemplateRef<any>,
              private container: ViewContainerRef) { }

  ngOnInit (): void {
    const isWhitelabel = environment.name !== 'arianee';

    if (isWhitelabel) {
      this.container.clear();
    } else {
      this.container.createEmbeddedView(this.templateRef);
    }
  }
}
