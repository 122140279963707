export class MockBrowserSecureStorage {
  clear (): Promise<any> {
    return undefined;
  }

  get (key: string): Promise<string> {
    return Promise.resolve(localStorage.getItem(key));
  }

  keys (): Promise<string[]> {
    return Promise.resolve(Object.keys(localStorage));
  }

  remove (key: string): Promise<string> {
    localStorage.removeItem(key);
    return Promise.resolve(key);
  }

  secureDevice (): Promise<any> {
    return undefined;
  }

  set (key: string, value: string): Promise<any> {
    localStorage.setItem(key, value);
    return Promise.resolve();
  }
}
