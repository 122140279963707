import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import {
	LoaderService,
	ToasterService,
} from '@arianeeprivate/wallet-shared-components';
import { Platform, PopoverController } from '@ionic/angular';
import { ethers } from 'ethers';
import { from } from 'rxjs';
import { mergeMap, take, tap } from 'rxjs/operators';

import { ArianeeService } from '../../providers/arianee-service/arianee.service';
import { BackupService } from '../../providers/backup-service/backup.service';
import { EventLoggerService } from '../../providers/event-logger/event-logger-service';
import { SentryErrorHandler } from '../../providers/sentry-service/sentry-service';
import { UserService } from '../../providers/user-service/user.service';

@Component({
	selector: 'app-import-popover',
	templateUrl: './import-popover.component.html',
	styleUrls: ['./import-popover.component.scss'],
})
export class ImportPopoverComponent implements OnInit {
	@Input() backups;
	constructor(
		private popoverController: PopoverController,
		private arianeeService: ArianeeService,
		private router: Router,
		private loaderService: LoaderService,
		private backupService: BackupService,
		private platform: Platform,
		private loggerService: EventLoggerService,
		private sentryService: SentryErrorHandler,
		private toasterService: ToasterService,
		private userService: UserService,
	) {}

	backupChoosed: string;
	public isIos: boolean;

	ngOnInit() {
		this.isIos = this.platform.is('ios');
		this.updateCheck();
		this.loggerService.logEvent('backup_import_modal_display');
	}

	dismissPopover() {
		return this.popoverController.dismiss();
	}

	async importWallet() {
		this.loggerService.logEvent('backup_import_modal_start_import_backup');

		this.loaderService.showLoader();
		const shouldContinue =
			await this.backupService.alertIfImportBackupNotSafe();

		if (shouldContinue) {
			this.loaderService.showLoader();
			const mnemonic = await this.backupService.getMnemonic(this.backupChoosed);
			const isValidMnemonic = ethers.utils.isValidMnemonic(mnemonic);

			if (!isValidMnemonic) {
				this.loggerService.logEvent('backup_import_modal_invalid_mnemonic');
				this.sentryService.sentry.captureMessage(
					'backup_import_modal_invalid_mnemonic',
					{
						tags: {
							invalidMnemonic: this.backupChoosed,
						},
					},
				);
				await this.dismissPopover();
				this.loaderService.dismiss();
				await this.toasterService.alert({
					message: 'Backup.corruptedBackup',
				});
			} else {
				this.arianeeService
					.switchWallet(mnemonic)
					.pipe(
						tap(() =>
							this.loggerService.logEvent(
								'backup_import_modal_switchWallet_success',
							),
						),
						mergeMap(() =>
							from(
								this.router
									.navigate(['tab/brand-list'])
									.then(() => window.location.reload()),
							),
						),
						tap(async () => {
							await this.backupService.checkBackup();

							this.dismissPopover();
							this.loaderService.dismiss();
						}),
						take(1),
					)
					.subscribe();
				this.userService.setHasBackup(true);
			}
		}
	}

	updateCheck() {
		if (this.backups && this.backups.length === 1) {
			this.backupChoosed = this.backups[0].id;
		}
	}
}
