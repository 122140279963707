import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of, ReplaySubject } from 'rxjs';
import { filter, map, mergeMap, shareReplay, take, tap } from 'rxjs/operators';
import { FeatureFlipService } from '../feature-flip-service/feature-flip.service';
import { DecentralizedMessageService } from '../decentralized-message/decentralizedMessage.service';
import { ArianeeService } from '../arianee-service/arianee.service';
import { environment } from '../../../environments/environment';
import { EnrichedNotification } from '@arianeeprivate/wallet-shared-components';
import { ArianeeBlockchainProxyService } from '../arianee-blockchain-proxy-service/arianee-blockchain-proxy-service';
import { UserService } from '../user-service/user.service';

declare var batch;

@Injectable({
  providedIn: 'root'
})

export class NotificationService {
    private _$notifications: ReplaySubject<EnrichedNotification[]>;
    private $isInit = new BehaviorSubject(false);
    public $notifications: Observable<EnrichedNotification[]> = this.$isInit.pipe(
      filter((isInit: boolean) => isInit === true),
      mergeMap((isInit: boolean) => this._$notifications),
      map((notifications: EnrichedNotification[]) => notifications.filter(notification => notification !== null && notification !== undefined)),
      mergeMap((notifications: EnrichedNotification[]) => this.filterNotificationByCertificate(notifications)),
      shareReplay(1)
    );

    constructor (
        private featureFlipService: FeatureFlipService,
        private decentralizedMessage: DecentralizedMessageService,
        private arianeeService: ArianeeService,
        private arianeeBlockchainProxyService: ArianeeBlockchainProxyService,
        private userService: UserService
    ) {
    }

    async init () {
      this._$notifications = this.decentralizedMessage.$message;
      await this.initBatch();
      this.decentralizedMessage.init();
      this.$isInit.next(true);
    }

    public $notificationFilterByCertificateId (certificateId): Observable<EnrichedNotification[]> {
      return this.$notifications.pipe(
        map((notifications) => notifications.filter(notifs => notifs.tokenId === certificateId))
      );
    }

    public $notificationUnreadFilterByCertificateId (certificateId): Observable<EnrichedNotification[]> {
      return this.$notifications.pipe(
        map((notifications) => notifications.filter(notifs => (notifs.tokenId === certificateId && !notifs.isRead)))
      );
    }

    public $getUnreadNotification (): Observable<EnrichedNotification[]> {
      return this.$notifications.pipe(
        map((notifications) => notifications.filter(notifs => !notifs.isRead))
      );
    }

    public markAsRead (tokenId, messageId) {
      const update = () =>
        this.$getUnreadNotification()
          .pipe(
            tap((unreadNotification: EnrichedNotification[]) => {
              if (unreadNotification.length === 0) {
                // batch.push.clearBadge();
              }
            }),
            mergeMap(() => this.decentralizedMessage.getNotifications()),
            tap((notifications: EnrichedNotification[]) => this.decentralizedMessage.pushNotifications(notifications))
          );

      this.decentralizedMessage.markAsRead(tokenId, messageId)
        .pipe(
          mergeMap(hasBeenModified => {
            if (hasBeenModified === true) {
              return update();
            } else {
              return of([]);
            }
          }),
          take(1)
        )
        .subscribe((notifications) => {

        });
    }

    private async initBatch (): Promise<void> {
      try {
        batch.setConfig(environment.batchAPI);
        batch.start();

        batch.push.refreshToken();
        batch.push.requestNotificationAuthorization();

        const publicKey = await this.arianeeService
          .$address
          .pipe(take(1))
          .toPromise();

        await batch.user.getEditor()
          .setIdentifier(publicKey)
          .save();

        document.addEventListener('batchPushReceived', (e: any) => {
        });
      } catch (e) {

      }
    }

    private filterNotificationByCertificate (notifications: EnrichedNotification[]): Observable<EnrichedNotification[]> {
      return this.arianeeService.$address.pipe(
        take(1),
        mergeMap(async address =>
          this.arianeeBlockchainProxyService.getCertificates(await this.getCurrentChainType(), address)
        ),
        map(certificates => certificates.map(certificate => certificate.certificateId.toString())),
        map(certificatedIds => notifications.filter(notification => certificatedIds.includes(notification.tokenId.toString()))));
    }

    public addMessage (messageId) {
      return this.decentralizedMessage.addMessage(messageId)
        .pipe(take(1))
        .toPromise();
    }

    private async getCurrentChainType () {
      return await this.userService.$chainType.getOnce().toPromise();
    }
}
