import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { NETWORK } from '@arianee/arianeejs';
import { CertificateSummary } from '@arianee/arianeejs/dist/src/core/wallet/certificateSummary';
import { IdentitySummary } from '@arianee/arianeejs/dist/src/models';
import { LoaderService } from '@arianeeprivate/wallet-shared-components';
import { AlertController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { from } from 'rxjs';
import { distinctUntilChanged, take } from 'rxjs/operators';

import { IsAuthGuard } from '../../guards/isAuth.guard';
import { ArianeeService } from '../arianee-service/arianee.service';
import { BrowserService } from '../inapp-browser/inapp-browser-service';

@Injectable({
	providedIn: 'root',
})
export class ArianeeBlockchainActionService {
	network: NETWORK;
	constructor(
		private arianeeService: ArianeeService,
		private alertController: AlertController,
		private browserService: BrowserService,
		private loaderService: LoaderService,
		private translateService: TranslateService,
		private router: Router,
		private isAuthGuard: IsAuthGuard,
	) {
		this.arianeeService.$walletInitialize
			.pipe(distinctUntilChanged())
			.subscribe((network) => {
				this.network = network;
			});
	}

	public async handleLink(url, network: NETWORK) {
		const urlObject = new URL(url);

		const wallet = await from(this.arianeeService.getWalletInstance(network))
			.pipe(take(1))
			.toPromise();

		// for legacy arianee://path instead of arianee:path
		const action = urlObject.pathname.replace('//', '');

		switch (action) {
			case 'certificate/proof/createCertificateProofLink': {
				const certificateId = urlObject.searchParams.get('certificateId');
				const isAuth = await this.isAuthGuard.isAuth();
				if (isAuth) {
					await this.router.navigate(
						[`tab/brand-list/product-detail/${this.network}/${certificateId}`],
						{
							queryParams: {
								showProof: true,
							},
						},
					);
				}

				return '';
			}
			// example arianee:certificate/proof/createCertificateProofLink;
			case 'certificate/proof/createAuthURL': {
				const urlCallback = urlObject.searchParams.get('url');

				const actionMethod = async () => {
					const certificateId = urlObject.searchParams.get('certificateId');

					const authenticatedURL = await wallet.methods.createAuthURL({
						certificateId,
						type: 'arianeeAccessToken',
						url: urlCallback,
					});
					this.browserService.openBrowser(authenticatedURL);
				};
				const message = await this.translateService
					.get('ArianeeAction.Connect', { url: urlCallback })
					.toPromise();

				return this.confirmAction({ message, actionMethod });
			}
			case 'transferFrom': {
				// legacy method for watchfinder POC
				const transferI18n = await this.translateService
					.get('ArianeeAction.Transfer')
					.toPromise();
				const params = urlObject.searchParams.get('params');
				const [certificateId, receiver] = params.split(',');
				const certificate: CertificateSummary =
					await wallet.methods.getCertificate(certificateId);
				const identity: IdentitySummary = await wallet.methods.getIdentity(
					receiver,
				);
				const message = await this.translateService
					.get('ArianeeAction.ConfirmTransfer', {
						certificateName: certificate.content.data.name,
						identityName: identity.data.name,
					})
					.toPromise();

				const actionMethod = async () => {
					wallet.contracts.smartAssetContract.methods
						.transferFrom(wallet.address, receiver, certificateId)
						.send();
					this.browserService.openBrowser(
						urlObject.searchParams.get('callback'),
					);
				};
				return this.confirmAction({
					message,
					actionMethod,
					validateLabel: transferI18n,
				});
			}
		}
	}

	/**
	 * Pass
	 * @param parameters:
	 * actionMethod: method to be called on user confirm
	 * message: description message in alert box to display
	 * validateLabel: label of validate if needed
	 */
	private async confirmAction(parameters: {
		actionMethod: () => Promise<any>;
		message: string;
		validateLabel?: string;
	}): Promise<void> {
		// eslint-disable-next-line no-async-promise-executor
		return new Promise(async (resolve, reject) => {
			const { actionMethod, message, validateLabel } = parameters;
			const okI18n = await this.translateService
				.get('ArianeeAction.Ok')
				.toPromise();

			const cancelI18n = await this.translateService
				.get('ArianeeAction.Cancel')
				.toPromise();

			const alert = await this.alertController.create({
				cssClass: 'alertCtrl',
				message: message,
				buttons: [
					{
						text: cancelI18n,
						role: 'cancel',
						cssClass: 'alertCtrl',
					},
					{
						text: validateLabel || okI18n,
						cssClass: 'alertCtrl',
						handler: async () => {
							await actionMethod();
							resolve();
						},
					},
				],
			});

			alert.present();
		});
	}
}
