import { Injectable } from '@angular/core';
import { SocialSharing } from '@ionic-native/social-sharing/ngx';
import { TranslateService } from '@ngx-translate/core';
import { forkJoin, of } from 'rxjs';
import { take } from 'rxjs/operators';

@Injectable({
	providedIn: 'root',
})
export class SharingService {
	constructor(
		private socialSharing: SocialSharing,
		private translateService: TranslateService,
	) {}

	/**
	 *
	 * @param subject: translation key
	 * @param message: translation key
	 * @param url: url to share
	 */
	public shareLink = (subject: string, message: string, url: string) => {
		// this is the complete list of currently supported params you can pass to the plugin (all optional)

		const translateSubject =
			subject !== '' ? this.translateService.get(subject) : of('');
		const translateMessage =
			message !== '' ? this.translateService.get(message) : of('');

		forkJoin({
			subject: translateSubject,
			message: translateMessage,
		})
			.pipe(take(1))
			.subscribe((data) => {
				const options = {
					subject: data.subject, // fi. for email
					message: data.message, // not supported on some apps (Facebook, Instagram)
					url: url,
				};

				this.socialSharing.shareWithOptions(options);
			});
	};
}
