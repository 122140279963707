import { Component, Input, OnInit } from '@angular/core';
import { ToasterService } from '@arianeeprivate/wallet-shared-components';
import { Clipboard } from '@ionic-native/clipboard/ngx';

@Component({
	selector: 'app-copy-icon',
	templateUrl: './copy-icon.component.html',
	styleUrls: ['./copy-icon.component.scss'],
})
export class CopyIconComponent implements OnInit {
	@Input() text: string;
	@Input() toasterKey: string = 'Settings.copyToClipboard';
	constructor(
		private toasterService: ToasterService,
		private clipBoard: Clipboard,
	) {}

	ngOnInit() {}

	async copyContent() {
		await this.clipBoard.copy(this.text);
		this.toasterService.showRedesigned({
			title: this.toasterKey,
			color: 'info',
			icon: 'information',
			duration: 2000,
			position: 'top',
		});
	}
}
