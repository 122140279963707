import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { NativeStorageObservableCache } from '../../helpers/observable-cache-storage/native-storage-observable-cache';
import { StorageService } from '../storage-service/storage.service';
import { Platform } from '@ionic/angular';
import { storageKeys } from '../../../config/storageKeys';
import { map, take } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class FeatureFlipService {
    public features:NativeStorageObservableCache<Array<{name:string, activated:boolean, description:string, platforms?:string[]}>>;

    constructor (private storageService: StorageService, private platform: Platform) {
      this.platform.ready()
        .then(() => {
          this.features = new NativeStorageObservableCache(
            this.storageService.nativeStorage,
            storageKeys.featureFlips,
            environment.featureFlips);
        });
    }

    public async switchFeatureFlip (featureFlipName: string) {
      const features = await this.features.getOnce().toPromise();
      const featureToFlip = features.find(d => d.name === featureFlipName);
      if (featureToFlip) {
        featureToFlip.activated = !featureToFlip.activated;
        this.features.set(features);
      }
      return this.features;
    }

    public $features =() => {
      return this.features.get()
        .pipe(map(features => {
          return features.map(feature => {
            let isPlatformConcerned = true;
            if (feature && feature.platforms) {
              isPlatformConcerned = feature.platforms.filter(platform => {
                return this.platform.is(platform as any);
              }).length > 0;
            }

            const isActivated = feature === undefined ? false : feature.activated;

            feature.activated = isPlatformConcerned && isActivated;
            return feature;
          });
        }));
    }

    public $isFeatureFlipOnce = (featureFlipName: string): Observable<boolean> => {
      return this.$isFeatureFlip(featureFlipName)
        .pipe(
          take(1)
        );
    };

    public $isFeatureFlip =(featureFlipName: string):Observable<boolean> => {
      return this.$features()
        .pipe(
          map(features => features.find(feat => feat.name === featureFlipName)),
          map(feature => !!feature && feature.activated)
        );
    }
}
