import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';

@Component({
  selector: 'app-onboarding-slider-item',
  templateUrl: './onboarding-slider-item.component.html',
  styleUrls: ['./onboarding-slider-item.component.scss']
})
export class OnboardingSliderItemComponent implements AfterViewInit, OnChanges {
  @ViewChild('stepItem', { static: false }) stepItem: ElementRef<HTMLElement>;
  @Input() backward = true;
  @Input() backwardDisplay = true;
  @Input() forward = true;
  @Input() forwardDisplay = true;
  @Input() skipBtn = true;
  @Input() gotItBtn = false;

  @Input() title = '';
  @Input() description = '';
  @Input() maxHeight: number;

  @Output() skipClicked = new EventEmitter();
  @Output() goItClicked = new EventEmitter();
  @Output() swipeNxt = new EventEmitter();
  @Output() swipePrev = new EventEmitter();
  @Output() containerHeight = new EventEmitter<number>();

  height: number;

  ngAfterViewInit () {
    // SetTimeout for Height precision issue
    setTimeout(() => {
      // Emit container Height
      this.containerHeight.emit(this.stepItem.nativeElement.clientHeight);
    }, 200);
  }

  ngOnChanges (changes: SimpleChanges) {
    if (changes.maxHeight) {
      this.height = changes.maxHeight.currentValue;
    }
  }
}
