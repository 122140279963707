import { NETWORK } from '@arianee/arianeejs';

import { defaultCommonEnvironment } from './environment.default.common';

export const defaultProdEnvironment = {
	...defaultCommonEnvironment,
	environment: 'prod',
	production: true,
	network: NETWORK.mainnet,
	pgeneveUrl: 'https://www.poincondegeneve.ch/secure?',
	swissmadeUrl: 'https://www.poincondegeneve.ch/secure?',
	blockchainProvider: {
		mainnet: 'https://poa.arianee.net',
		testnet: 'https://sokol.arianee.net',
	},
	proxyAuthorizedDomain: 'https://share.arianee.net/proxifier/authorized.json',
	countryUrl: 'https://country.arianee.net',
	arianeeshareUrl: 'https://arianeeshare.arianee.net',
	partnersLinks: 'https://arianeeshare.arianee.net/partnersLinks/',
	deeplinkURL: 'https://arianeeshare.arianee.net/deeplink',
	resellWatchfinder: 'https://resell.arianee.org/watchfinder',
	watchFinderAddress: '0xa2C4e69a9e4d85551ee7414301c34351738a533A',
	uwrUrl: 'https://services.arianee.com/uwr/',
	walletConnectVerified:
		'https://arianeeshare.arianee.net/public/wallet-connect-verified-website.json',
	blockchainProxy: {
		enable: true,
		host: 'https://api.arianee.com/report',
		base: 'https://api.arianee.com',
	},
};
