import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';

import { BackupChoiceModalComponent } from '../backup-choice-modal/backup-choice-modal.component';
import { Button } from '../button/models/button.model';

@Component({
	templateUrl: './backup-warn-modal.component.html',
	styleUrls: ['./backup-warn-modal.component.scss'],
})
export class BackUpWarnModalComponent implements OnInit {
	@Input() logScreen;

	backupButton: Button;
	closeButton: Button = {
		title: {
			text: this.translate.instant('BackupWarnModal.later'),
			color: '#9799A3',
		},
		buttonType: 'text'
	};

	constructor(
		private modalCtrl: ModalController,
		private translate: TranslateService,
	) {}

	ngOnInit() {
		this.backupButton = {
			title: {
				text: this.translate.instant('BackupWarnModal.backupCurrentWallet'),
			},
			buttonType: 'primary'
		};
		this.logScreen('BackUpWarnModal');
	}

	public openBackup = async () => {
		await this.closeModal();
		await this.openBackupChoiceModal();
	};

	private async openBackupChoiceModal() {
		const modal = await this.modalCtrl.create({
			component: BackupChoiceModalComponent,
			cssClass: 'modal--bottom',
			swipeToClose: true,
			componentProps: {
				header: {
					title: this.translate.instant('Settings.backupCurrentWallet'),
					icon: {
						name: 'icon-backup-wallet',
						fontSize: 42,
						height: '72px',
						width: '72px',
						borderRadius: '16px',
					},
				},
			},
		});
		return modal.present();
	}

	public closeModal = () => {
		return this.modalCtrl.dismiss();
	};
}
