import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { MediaItemCarousel } from '../../models/media-item-carousel.model';

@Component({
	selector: 'app-picture-media-fullscreen',
	templateUrl: './picture-media-fullscreen.component.html',
	styleUrls: ['./picture-media-fullscreen.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PictureMediaFullscreenComponent {
	@Input() media: MediaItemCarousel;
}
