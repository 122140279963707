import { Component, OnInit } from '@angular/core';
import { IdentityBase } from '@arianee/arianeejs/dist/src/models';
import {
	DisplayMapperService,
	POAPNFT,
	ProductListItem,
} from '@arianeeprivate/wallet-shared-components';
import { NavController } from '@ionic/angular';
import { Subscription, combineLatest, forkJoin } from 'rxjs';

import { EventLoggerService } from '../../../../../providers/event-logger/event-logger-service';
import { PoapService } from '../../../../../providers/poap/poap.service';

@Component({
	selector: 'app-poap-nft-list-legacy',
	templateUrl: './poap-nft-list-legacy.component.html',
	styleUrls: ['./poap-nft-list-legacy.component.scss'],
})
export class PoapNftListLegacyComponent implements OnInit {
	brandId: string;
	brandsCount = 0;
	brandName: string;
	brandLogo: string;
	isLoading = true;
	subProducts: Array<ProductListItem> = [undefined, undefined];
	poapSubscription = Subscription.EMPTY;

	constructor(
		private navCtrl: NavController,
		private eventLogger: EventLoggerService,
		private poapService: PoapService,
		private displayMapperService: DisplayMapperService,
	) {}

	ngOnInit() {
		this.getPoapNfts();
	}

	async ionViewDidEnter() {
		this.eventLogger.logScreen('poap-nft-list', { brandId: this.brandId });

		this.getPoapNfts();
	}

	async ionViewDidLeave() {
		this.poapSubscription.unsubscribe();
	}

	getPoapNfts() {
		this.isLoading = true;
		this.poapSubscription = combineLatest([
			this.poapService.getMyPoapNfts(),
			this.poapService.getPoapIdentity(),
		]).subscribe(([poapList, identity]: [POAPNFT[], IdentityBase]) => {
			this.subProducts = poapList.map((data: POAPNFT) =>
				this.displayMapperService.poapListCertificateDisplayMapper({
					poapNFT: data,
					identity: identity,
				}),
			);
			if (this.subProducts.length > 0) {
				this.brandLogo = this.subProducts[0].brandLogo;
				this.brandName = this.subProducts[0].brandName;
				this.brandId = this.subProducts[0].brandName;
			}
			this.isLoading = false;
		});
	}

	onBrandClick() {
		this.navCtrl.navigateForward(
			'/tab/brand-page/' + this.poapService.poapAddress,
		);
	}

	onClickProduct(tokenId) {
		this.navCtrl.navigateForward(`/tab/brand-list/poap-detail/${tokenId}`);
	}

	onClickNavBack() {
		this.navCtrl.navigateBack('/tab/brand-list');
	}
}
