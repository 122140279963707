import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { from, iif, Observable, of } from 'rxjs';
import { mergeMap, take } from 'rxjs/operators';
import { ModalController } from '@ionic/angular';

import { UserService } from '../providers/user-service/user.service';
import { HasOnboardedGuard } from './has-onboarded-guard.service';
import { AuthPage } from '../pages/auth/auth.page';
import { environment } from '../../environments/environment';
import { PrivacyPolicyPopover } from '../components/privacy-policy-popover/privacy-policy-popover.component';

@Injectable({
  providedIn: 'root'
})
export class UserAgreedAndOnboardedGuard extends HasOnboardedGuard {
    private pinCodeModal:HTMLIonModalElement;
    private privacyModal:HTMLIonModalElement;

    constructor (protected userService: UserService, protected router: Router, protected modal:ModalController) {
      super(userService, router, modal);
    }

    private hasAgreed (): Observable<boolean> {
      if (environment.isBaidu) {
        return this.userService.hasAgreePrivacyPolicy.getOnce()
          .pipe(
            mergeMap(hasAgreed => {
              if (hasAgreed) {
                return of(true);
              } else {
                return from(this.presentUserAgreementModal());
              }
            }));
      } else {
        return of(true);
      }
    }

    private async presentUserAgreementModal ():Promise<boolean> {
      if (this.privacyModal === undefined) {
        this.privacyModal = await this.modal.create({
          component: PrivacyPolicyPopover,
          cssClass: 'auto-height middle light-round',
          backdropDismiss: false
        });

        await this.privacyModal.present();
        await this.privacyModal.onWillDismiss();
        this.privacyModal = undefined;
      }

      return true;
    }

    public canActivate (
      route: ActivatedRouteSnapshot,
      state: RouterStateSnapshot
    ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
      return this.hasAgreed();
    }
}
