import { Component, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';

import { getChainName } from '../../../providers/wallet-connect-service/chain-utils/chain-utils';
import { Button } from '../../redesign/button/models/button.model';
import { IModalHeaderConfig } from '../components/modal-header/modal-header.component';
import { IModalList } from '../components/modal-list-with-icon/modal-list-with-icon.component';
import { SessionProposalModalProps } from '../types/wallet-connect-modal';

@Component({
	selector: 'app-session-proposal-modal',
	templateUrl: './session-proposal-modal.component.html',
	styleUrls: ['./session-proposal-modal.component.scss'],
})
export class SessionProposalModalComponent
	implements SessionProposalModalProps
{
	@Input() logo: SessionProposalModalProps['logo'];
	@Input() website: SessionProposalModalProps['website'];
	@Input() isVerified: SessionProposalModalProps['isVerified'];
	@Input() methods: SessionProposalModalProps['methods'];
	@Input() chains: SessionProposalModalProps['chains'];
	@Input() unsupportedMethods: SessionProposalModalProps['unsupportedMethods'];
	@Input() unsupportedChains: SessionProposalModalProps['unsupportedChains'];
	@Input() canApprove: SessionProposalModalProps['canApprove'];

	public modalList: IModalList[] = [];
	public headerConfig: IModalHeaderConfig;

	acceptButton: Button;
	rejectButton: Button;

	constructor(
		public modalController: ModalController,
		private translate: TranslateService,
	) {}

	ngOnInit() {
		this.populateHeaderConfig();
		this.populateModalList();
		this.initButtons();
	}

	private populateHeaderConfig() {
		this.headerConfig = {
			logo: this.logo,
			title: this.translate.instant('WalletConnect.v2.titles.sessionProposal'),
			subtitle: {
				label: this.website,
				verified: this.isVerified,
			},
		};
	}

	private populateModalList() {
		const permissions = new Set<string>();

		const supportedMethods = this.getSupportedMethods();

		for (const method of supportedMethods) {
			const permission = this.getPermissionLabelFromMethod(method);
			permissions.add(permission);
		}

		this.modalList = Array.from(permissions).map((permission) => ({
			icon: {
				name: 'icon-check',
				backgroundColor: 'transparent',
				color: '#000000',
				fontSize: 24,
			},
			label: permission,
		}));
	}

	private getSupportedMethods(): string[] {
		return this.methods.filter(
			(method) => !this.unsupportedMethods.includes(method),
		);
	}

	private getUnsupportedChainsNames(): string[] {
		return this.unsupportedChains.map((chain) => {
			if (!chain.startsWith('eip155:')) return chain;
			return getChainName(parseInt(chain.replace('eip155:', '')));
		});
	}

	private getPermissionLabelFromMethod(method: string): string {
		switch (method) {
			case 'eth_sign':
			case 'personal_sign':
			case 'eth_signTypedData':
			case 'eth_signTypedData_v3':
			case 'eth_signTypedData_v4':
				return this.translate.instant(
					'WalletConnect.v2.permissions.signMessage',
				);
			case 'eth_sendTransaction':
				return this.translate.instant(
					'WalletConnect.v2.permissions.sendTransaction',
				);
			case 'eth_signTransaction':
				return this.translate.instant(
					'WalletConnect.v2.permissions.signTransaction',
				);
			default:
				return method;
		}
	}

	private initButtons() {
		this.acceptButton = {
			title: {
				text: this.translate.instant('WalletConnect.v2.actions.authorise'),
			},
			buttonType: 'primary',
			disabled: !this.canApprove,
		};

		this.rejectButton = {
			title: {
				text: this.translate.instant('WalletConnect.v2.actions.reject'),
			},
			buttonType: 'text',
		};
	}
}
