import { Component, Input, OnInit } from '@angular/core';

import { NotificationGroup } from '../../../pages/main/noti/mappers/notification-mapper';

@Component({
	selector: 'app-notification-item',
	templateUrl: './notification-item.component.html',
	styleUrls: ['./notification-item.component.scss'],
})
export class NotificationItemComponent implements OnInit {
	@Input() notification: NotificationGroup;
	@Input() isLast: boolean;

	constructor() {}

	ngOnInit() {}
}
