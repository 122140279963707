import { Injectable } from '@angular/core';
import { catchError, map, mergeMap, take } from 'rxjs/operators';
import { ArianeeService } from './arianee-service/arianee.service';
import { BrowserService } from './inapp-browser/inapp-browser-service';
import { from, Observable, of } from 'rxjs';
import get from 'lodash/get';
import { NETWORK } from '@arianee/arianeejs/dist/src';

@Injectable({
  providedIn: 'root'
})
export class MissingStolenService {
  constructor (private arianeeService:ArianeeService, private browserService:BrowserService) {

  }

  public isLostOrStolen=(certificateId:string, network: NETWORK):Observable<{isMissing:boolean, isStolen:boolean }> => {
    return from(this.arianeeService.getWalletInstance(network))
      .pipe(
        mergeMap(wallet => {
          const promises = Promise.all([
            wallet.methods.isMissing(certificateId),
            wallet.methods.isStolen(certificateId)
          ]);
          return from(promises);
        }),
        catchError(e => {
          // @todo remove the catch once we have finished setting up the smart contract
          console.info('Error isMissing isStolen ', e);
          return of([false, false]);
        }),
        map(([isMissing, isStolen]) => ({ isMissing, isStolen }))
        , take(1));
  }

  private async getLostStolenProvider (certificateId, network: NETWORK) {
    const certificate = await (from(this.arianeeService.getWalletInstance(network))
      .pipe(
        mergeMap(wallet => {
          return from(wallet.methods.getCertificate(certificateId, null, { issuer: true }));
        })
        , take(1)).toPromise());

    return get(certificate, 'issuer.identity.data.providers', []).find(p => p.type === 'missing');
  }

  public openLostOrStollenIfNeeded (certificateId, isLost, network: NETWORK) {
    if (isLost) {
      this.openLostOrStollenDeclareMissingModal(certificateId, network);
    } else {
      this.openLostOrStollenDeclareUnMissingModal(certificateId, network);
    }
  }

  public async openLostOrStollenDeclareMissingModal (certificateId, network: NETWORK) {
    const providerMissing = await this.getLostStolenProvider(certificateId, network);
    const providerMissingUrl = get(providerMissing, 'url');
    if (providerMissingUrl) {
      from(this.arianeeService.getWalletInstance(network))
        .pipe(
          take(1),
          mergeMap(async wallet => {
            if (providerMissing.address) {
              return from(wallet.contracts.userActionContract.methods.addAddressToWhitelist(certificateId, providerMissing.address)
                .send({ from: wallet.address }));
            }
            return of(true);
          })
        )
        .subscribe();

      this.arianeeService.$walletInitialize.pipe(
        take(1),
        mergeMap(network=>this.arianeeService.getWalletInstance(network)),
        mergeMap(wallet => wallet.methods.createAuthURL({
          url: providerMissingUrl,
          certificateId: certificateId,
          type: 'arianeeAccessToken'
        }))
      ).subscribe(proofLink => {
        this.browserService.openBrowser(proofLink);
      });
    }
  }

  private generateUnMissingUrl (providerMissingUrl) {
    let newUrl = providerMissingUrl;
    if (!newUrl.endsWith('/')) {
      newUrl += '/';
    }
    return `${newUrl}unmissing`;
  }

  public async openLostOrStollenDeclareUnMissingModal (certificateId, network: NETWORK) {
    const providerMissing = await this.getLostStolenProvider(certificateId, network);
    const providerMissingUrl = get(providerMissing, 'url');

    if (get(providerMissing, 'url')) {
      this.arianeeService.$walletInitialize.pipe(
        take(1),
        mergeMap(network=>this.arianeeService.getWalletInstance(network)),
        mergeMap(wallet => wallet.methods.createAuthURL({
          url: this.generateUnMissingUrl(providerMissingUrl),
          certificateId: certificateId,
          type: 'arianeeAccessToken'
        }))
      ).subscribe(proofLink => {
        this.browserService.openBrowser(proofLink);
      });
    }
  }

  /**
   * Toggle missing status
   * @param isLost
   * @param certificateId
   */
  public onToggleMissingStatus = async (isLost:boolean, certificateId:string):Promise<boolean> => {
    const network = await this.arianeeService.$walletInitialize.pipe(take(1)).toPromise();
    return from(this.arianeeService.getWalletInstance(network))
      .pipe(
        mergeMap(wallet => {
          if (!isLost) {
            return wallet.methods.unsetMissingStatus(certificateId);
          } else {
            return wallet.methods.setMissingStatus(certificateId);
          }
        }),
        take(1),
        map(d => isLost)
      ).toPromise();
  }
}
