import {
	ChangeDetectionStrategy,
	Component,
	Input,
	OnInit,
} from '@angular/core';

import { TextAlignment } from './models/text-alignment.enum';

@Component({
	selector: 'app-title',
	templateUrl: './title.component.html',
	styleUrls: ['./title.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TitleComponent implements OnInit {
	@Input() title: string;
	@Input() textAlignment: TextAlignment;
	@Input() margin: string = '8px 0 4px 0';

	@Input() e2eTag?: string = '';

	ngOnInit() {}
}
