const storageKeys = {
	onBoardingStatus: 'onBoardingStatus',
	privacyPolicyStatus: 'privacyPolicyStatus',
	mnemonic: 'mnemonic',
	mnemonicV1: 'encryptedKey',
	pinCode: 'pinCode',
	hasDevTools: 'hasswitchselectinsettings',
	hasAdvancedTools: 'hasAdvancedTools',
	hasBackupStatusKey: 'hasBackupStatusKey',
	autoBackup: 'autoBackup',
	userNetwork: 'userNetwork',
	userChainType: 'userChainType',
	featureFlips: 'featureFlips',
	numberOfAppLaunch: 'numOfAppLaunch',
	hasBackup: 'hasBackup',
};

export { storageKeys };
