import { GoogleDriveResponse } from '../../models/googleDrive';

export const GoogleDriveAPIMocks = {
  requestFiles: () => Promise.resolve({
    files: [
      {
        kind: 'drive#file',
        id: '1NDl11Tn7fU-wFnd6AqtEGgMr9EM0SEN7fUd5yQmDjgg',
        name: 'arianee',
        mimeType: 'application/vnd.google-apps.document'
      }]
  }),
  searchFileInAppFolder: () => Promise.resolve({
    files: [
      {
        kind: 'drive#file',
        id: '1DNzymO9eq0HTkX49zxF4sg2zOB5TJwGe',
        name: 'backup-0x95FaBc0b22519374a8715C5Ef2381411B492d511-1601889415675',
        mimeType: 'application/json'
      },
      {
        kind: 'drive#file',
        id: 'corruptedMnemonicId',
        name: 'backup-0x13d1B30f6c2DF95d57FBbBC9cd476CdC5F43a854-16015424124393',
        mimeType: 'application/json'
      },
      {
        kind: 'drive#file',
        id: '12PmaQ9xP4PBfcHXRpXaPzmXQxtIgbGfR',
        name: 'backup-0x13d1B30f6c2DF95d57FBbBC9cd476CdC5F43a854-1601542413993',
        mimeType: 'application/json'
      }
    ]
  }),
  getFileContent: (fileId?:string) => {
    if (fileId === 'corruptedMnemonicId') {
      return Promise.resolve({
        mnemonic: 'U2FsdGVkX1/+H+TrRdWPz4kPk3JI2HEQtvySeTS/KiRzyW3/yOjxc9pmyfEWkVEto0GsEcfMfoN6v2yhK/K7FfresGBP2jSqm7BvFc8FxD+3+ARlsAq+g6'
      });
    }
    return Promise.resolve({
      mnemonic: 'U2FsdGVkX1/+H+TrRdWPz4kPk3JI2HEQtvySeTS/KiRzyW3/yOjxc9pmyfEWkVEto0GgECeBnQqz8sEcfMfoN6v2yhK/K7FfresGBP2jSqm7BvFc8FxD+3+ARlsAq+g6'
    });
  },
  requestFilesWithQuery: ():Promise<GoogleDriveResponse> => Promise.resolve({ kind: '', imcompleteSearch: false, files: [] })
};
