import {
	ChangeDetectionStrategy,
	Component,
	Input,
	OnInit,
} from '@angular/core';

import { MediaItemCarousel } from '../../models/media-item-carousel.model';

@Component({
	selector: 'app-youtube-media',
	templateUrl: './youtube-media.component.html',
	styleUrls: ['./youtube-media.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class YoutubeMediaComponent implements OnInit {
	@Input() media: MediaItemCarousel;

	showPreview: boolean = true;

	ngOnInit() {
		const swiperContainer = document.querySelector(
			'.swiper-container',
		) as HTMLElement;
		if (swiperContainer) {
			swiperContainer.style.backgroundColor = '#000';
		}
	}
}
