import { CertificateEvents } from '@arianee/arianeejs/dist/src/core/wallet/certificateSummary/certificateSummary';

export interface EventContent {
    returnValues: {
        _from?: string;
        _to?: string;
    }
    timestamp: number;
}

export const findMostRecentTransferEventTo = (events: CertificateEvents, to: string) : EventContent | null => {
  let mostRecent: EventContent | null = null;

  for (const event of events.transfer || []) {
    const { _to } = (event as EventContent).returnValues;
    const { timestamp } = event;

    if (_to !== to) continue;
    if (!mostRecent || mostRecent.timestamp < timestamp) { mostRecent = event; }
  }

  return mostRecent;
};
