import { Component } from '@angular/core';
import { IdentityBase } from '@arianee/arianeejs/dist/src/models';
import { PoapClaimResponse, PoapQueueStatusResponse, PoapService } from '../../../providers/poap/poap.service';
import { ActivatedRoute } from '@angular/router';
import {
  brandImageLogo,
  CertificateDisplay,
  CongratOnClaimModalComponent,
  DisplayMapperService,
  EventDisplay,
  LoaderService,
  POAPNFT,
  ProductInfoHistoryComponent
} from '@arianeeprivate/wallet-shared-components';
import { filter, first, map, mergeMap, take, tap } from 'rxjs/operators';
import { ModalController, NavController, ToastController, ToastOptions } from '@ionic/angular';
import { NotificationService } from '../../../providers/notification-service/notification.service';
import { HandleLinkService } from '../../../providers/scan-service/handle-link.service/handle-link.service';
import { BrowserService } from '../../../providers/inapp-browser/inapp-browser-service';
import { EventLoggerService } from '../../../providers/event-logger/event-logger-service';
import { combineLatest, Observable, Subscription, timer } from 'rxjs';
import { ArianeeEventService } from '../../../providers/arianeeEvent-service/arianeeEvent.service';
import { ArianeeService } from '../../../providers/arianee-service/arianee.service';
import { TranslateService } from '@ngx-translate/core';
import { FeatureFlipService } from '../../../providers/feature-flip-service/feature-flip.service';

@Component({
  selector: 'app-poap-detail',
  templateUrl: './poap-detail.page.html',
  styleUrls: ['./poap-detail.page.scss', '../product-detail/product-detail.page.scss']
})
export class PoapDetailPage {
    poapTokenId: string;
    identity: IdentityBase;
    currentWalletAddress: string;
    passphrase: string;
    method: string;
    certificateDisplay: CertificateDisplay;
    isRequestableButton: boolean;
    showProof = false;

    events: EventDisplay[] = [];
    showBackdrop = false;
    brandId: string;
    errorToaster;
    public poapQRHash: string;
    public poapNFTSecret: string;
    public showFooter: boolean;
    poapSubscription = Subscription.EMPTY;
    loading = false;
    certificate: { poapNft: POAPNFT; identity: IdentityBase };

    constructor (
        private navCtrl: NavController,
        private notificationService: NotificationService,
        private handleLinkService: HandleLinkService,
        private browserService: BrowserService,
        private eventLogger: EventLoggerService,
        private loaderService: LoaderService,
        private displayMapperService: DisplayMapperService,
        private poapService: PoapService,
        private route: ActivatedRoute,
        private arianeeEventService: ArianeeEventService,
        private modalCtrl: ModalController,
        private arianeeService: ArianeeService,
        private translateService: TranslateService,
        private toastController: ToastController,
        public featureFlipService: FeatureFlipService) {
    }

    async ionViewDidEnter () {
      this.eventLogger.logScreen('poap-details', { certificateId: this.poapTokenId, type: 'poap' });

      this.poapTokenId = this.route.snapshot.paramMap.get('tokenId');
      this.poapQRHash = this.route.snapshot.paramMap.get('qrHash');

      this.getPoap();
    }

    async ionViewDidLeave () {
      this.poapSubscription.unsubscribe();
    }

    startLoading () {
      this.loading = true;
      this.loaderService.showLoader();
    }

    stopLoading () {
      this.loading = false;
      this.loaderService.dismiss();
    }

    async checkIfPoapAlreadyOwnedByAddressAndRedirect (eventId: string) {
      const alreadyOwnedPoapNFTOfThisEvent = await this.poapService.findTokenIdOfEventIdOfAddress({
        eventId: eventId
      });

      if (alreadyOwnedPoapNFTOfThisEvent) {
        this.navCtrl.navigateForward(`/tab/brand-list/poap-detail/${alreadyOwnedPoapNFTOfThisEvent}`, {
          animated: false,
          replaceUrl: true
        });
      }

      return alreadyOwnedPoapNFTOfThisEvent;
    }

    getPoap () {
      this.startLoading();

      this.poapSubscription =
            combineLatest([
              this.poapService.getMyPoapNfts(),
              this.poapService.getPoapIdentity(),
              this.arianeeService.$address.pipe(take(1))
            ])
              .subscribe(([poapList, identity, currentWalletAddress]: [POAPNFT[], IdentityBase, string]) => {
                const poapNFT: POAPNFT = poapList.find((poap: POAPNFT) => {
                  if (this.poapQRHash) {
                    return poap.qr_hash === this.poapQRHash;
                  }
                  return poap.tokenId.toString() === this.poapTokenId;
                });

                this.currentWalletAddress = currentWalletAddress;
                this.identity = identity;
                if (poapNFT) {
                  this.initPoapDisplay(poapNFT);
                } else {
                  this.fetchPoap();
                }
              });
    }

    async openPOAPNotExistToaster () {
      this.showBackdrop = true;
      const toastOptions: ToastOptions = {
        message: this.translateService.instant('CertificateInfos.notExist'),
        color: 'danger',
        position: 'middle',
        duration: 5000,
        cssClass: 'e2e-toaster'
      };
        // open Toast
      this.errorToaster = await this.toastController.create(toastOptions);
      await this.errorToaster.present();
    }

    async initPoapDisplay (poapNFT: POAPNFT) {
      // nft is not a poap nft => error.
      if (!poapNFT.event) {
        this.stopLoading();
        return this.openPOAPNotExistToaster();
      }

      if (this.poapQRHash) {
        this.isRequestableButton = !poapNFT.claimed;
        this.poapNFTSecret = poapNFT.secret;
        this.poapTokenId = poapNFT.id.toString();
        await this.checkIfPoapAlreadyOwnedByAddressAndRedirect(poapNFT.event.id.toString());
      }

      this.stopLoading();

      this.events = this.displayMapperService.poapToEventDisplayMapper({ poapNFT, identity: this.identity });
      this.certificateDisplay = this.displayMapperService
        .poapCertificateDisplayMapper({ poapNFT, identity: this.identity });
      this.certificate = { poapNft: poapNFT, identity: this.identity };

      // depending how you fetch data, return is not the same.
      const ownerOrBenificiary = poapNFT.beneficiary || poapNFT.owner;
      this.certificateDisplay.isOwner = ownerOrBenificiary
        ? ownerOrBenificiary.toLowerCase() === this.currentWalletAddress.toLowerCase()
        : false;
    }

    fetchPoap (): void {
      const request: Observable<POAPNFT> = this.poapQRHash ? this.poapService.getNFTFromPoapQrHash(this.poapQRHash) : this.poapService.getPOAPByTokenId(this.poapTokenId);
      request.subscribe({
        next: (poap: POAPNFT) => this.initPoapDisplay(poap),
        error: async () => {
          const response = await this.poapService.openMaxRetryAlert();
          if (response.retry) {
            this.fetchPoap();
          }
        }
      });
    }

    onClickNavBack () {
      this.navCtrl.back();
    }

    onBrandClick () {
      this.navCtrl.navigateForward('/tab/brand-page/' + this.poapService.poapAddress);
    }

    async onLinkClick (link: { type: string, url: string, title: string }) {
      this.browserService.openBrowserSystem(link.url);
    }

    get $getUnreadNotifications () {
      return this.notificationService
        .$notificationUnreadFilterByCertificateId(this.poapTokenId)
        .pipe(map(notif => notif.length));
    }

    async onSwipeClick () {
      this.startLoading();
      this.eventLogger.logEvent('onSwipeComplete', { certificateId: this.poapTokenId, type: 'poap' });
      return this.poapService.claimPOAP({
        qr_hash: this.poapQRHash,
        secret: this.poapNFTSecret
      })
        .pipe(
          take(1),
          tap(() => this.loaderService.showLoaderWithBlockchain()),
          tap(() => {
            this.isRequestableButton = false;
            this.stopLoading();
          })
        ).subscribe((data: PoapClaimResponse) => {
          this.stopLoading();
          this.verifyPoapQueueBeforeRedirect(data.queue_uid);
        });
    }

    verifyPoapQueueBeforeRedirect (queueId: string) {
      if (queueId) {
        this.startLoading();
        timer(0, 3000)
          .pipe(
            mergeMap(() => {
              return this.poapService.getQueueStatus(queueId);
            }),
            filter((data: PoapQueueStatusResponse) => {
              const status = data.status.toLowerCase();
              return status === 'finish';
            }),
            first()
          )
          .subscribe((data: PoapQueueStatusResponse) => {
            this.fetchPoap();
            this.displayCongratModal();
          });
      }
    }

    onClickHistory = async () => {
      const brandLogoHeader = this.route.snapshot.paramMap.get('brandLogoHeader') || brandImageLogo(this.identity, ['brandLogoHeader']);
      const address = await this.arianeeService.$address.pipe(first()).toPromise();
      const historyModal = await this.modalCtrl.create({
        component: ProductInfoHistoryComponent,
        cssClass: 'auto-height middle light-round',
        componentProps: {
          displayEvents: this.events,
          certificateDisplay: this.certificateDisplay,
          identityBase: { data: this.identity, address: this.poapService.poapAddress },
          certificateId: this.poapTokenId,
          address,
          logScreen: this.eventLogger.logScreen,
          brandLogoHeader
        }
      });
      await historyModal.present();
    }

    closeCertificateNotExistToaster () {
      if (this.errorToaster) {
        this.errorToaster.dismiss();
      }

      this.showBackdrop = false;
    }

    displayCongratModal = async () => {
      const detailModal = await this.modalCtrl.create({
        component: CongratOnClaimModalComponent,
        cssClass: 'auto-height middle light-round',
        componentProps: {
          certificateId: this.poapTokenId,
          certificateDisplay: this.certificateDisplay,
          logScreen: this.eventLogger.logScreen,
          logEvent: this.eventLogger.logEvent,
          descriptionKey: null,
          buttonKey: null
        }
      });

      await detailModal.present();
    };
}
