import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';

import { Icon } from '../../../../../components/redesign/icon/models/icon.model';
import { ListItem } from '../../../../../components/redesign/list/models/list-item.model';

interface BrandModalProps {
	header: { title: string; icon: Icon };
	content: string;
	externalContents: ListItem[];
	socialNetworks: ListItem[];
}

@Component({
	selector: 'app-brand-modal',
	templateUrl: './brand-modal.component.html',
	styleUrls: ['./brand-modal.component.scss'],
})
export class BrandModalComponent implements BrandModalProps, OnInit {
	@Input() header: { title: string; icon: Icon };
	@Input() content: string;
	@Input() externalContents: ListItem[] = [];
	@Input() socialNetworks: ListItem[] = [];
	@Input() isWeb: boolean;

	public modal: HTMLIonModalElement;

	constructor(private modalCtrl: ModalController) {}

	async ngOnInit() {
		this.modal = await this.modalCtrl.getTop();
	}

	onCloseModal(): void {
		this.modalCtrl.dismiss({
			dismissed: true,
		});
	}
}
