import { Injectable } from '@angular/core';
import {
	Events,
	ToasterService,
} from '@arianeeprivate/wallet-shared-components';
import { NavController } from '@ionic/angular';
import { ethers } from 'ethers';
import { Subject } from 'rxjs';
import { take } from 'rxjs/operators';

import { environment } from '../../../environments/environment';
import { ArianeeBlockchainProxyService } from '../arianee-blockchain-proxy-service/arianee-blockchain-proxy-service';
import { ArianeeBlockchainSyncService } from '../arianee-blockchain-sync-service/arianee-blockchain-sync.service';
import { ArianeeService } from '../arianee-service/arianee.service';
import { DecentralizedMessageService } from '../decentralized-message/decentralizedMessage.service';
import { EventLoggerService } from '../event-logger/event-logger-service';
import { StorageService } from '../storage-service/storage.service';
import { UserService } from '../user-service/user.service';
import { VibrationService } from '../vibration-service/vibration.service';

@Injectable({
	providedIn: 'root',
})
export class ArianeeEventWatcherService {
	public $transferToReceived: Subject<any> = new Subject();
	private fetchEventsInterval: ReturnType<typeof setInterval>;

	private static readonly TRANSFER_FROM_CURSOR = 'transferFromDate';
	private static readonly TRANSFER_TO_CURSOR = 'transferToDate';
	private static readonly MESSAGE_SENT_CURSOR = 'messageSentDate';

	constructor(
		private arianeeBlockchainProxyService: ArianeeBlockchainProxyService,
		private userService: UserService,
		private storageService: StorageService,
		private toasterService: ToasterService,
		private navController: NavController,
		private arianeeBlockchainSyncService: ArianeeBlockchainSyncService,
		private events: Events,
		private vibrationService: VibrationService,
		private loggerService: EventLoggerService,
		private arianeeService: ArianeeService,
		private decentralizedMessageService: DecentralizedMessageService,
	) {}

	public async initWatch() {
		if (environment.appType === 'web') return;

		this.fetchEventsInterval = setInterval(async () => {
			await this.fetchEvents();
		}, 5000);
	}

	private async fetchEvents(): Promise<void> {
		const chainType = await this.userService.$chainType.getOnce().toPromise();

		const [transferFromCursor, transferToCursor, messageSentCursor] =
			await Promise.all([
				this.getDateCursor(ArianeeEventWatcherService.TRANSFER_FROM_CURSOR),
				this.getDateCursor(ArianeeEventWatcherService.TRANSFER_TO_CURSOR),
				this.getDateCursor(ArianeeEventWatcherService.MESSAGE_SENT_CURSOR),
			]);

		const address = ethers.utils.getAddress(
			await this.arianeeService.$address.pipe(take(1)).toPromise(),
		);

		const [transferEventsFrom, transferEventsTo, messageSentEvents] =
			await Promise.all([
				this.arianeeBlockchainProxyService.getEvents(
					chainType,
					'smartAssetContract',
					'Transfer',
					`returnValues._from=${address}&createdAfter=${transferFromCursor}`,
				),
				this.arianeeBlockchainProxyService.getEvents(
					chainType,
					'smartAssetContract',
					'Transfer',
					`returnValues._to=${address}&createdAfter=${transferToCursor}`,
				),
				this.arianeeBlockchainProxyService.getEvents(
					chainType,
					'messageContract',
					'MessageSent',
					`returnValues._receiver=${address}&createdAfter=${messageSentCursor}`,
				),
			]);

		this.handleMessageSentEvents(messageSentEvents);
		this.handleTransferEvents(
			transferEventsTo,
			ArianeeEventWatcherService.TRANSFER_TO_CURSOR,
		);
		this.handleTransferEvents(
			transferEventsFrom,
			ArianeeEventWatcherService.TRANSFER_FROM_CURSOR,
		);
	}

	private async getDateCursor(cursor: string): Promise<string> {
		try {
			return await this.storageService.nativeStorage.getItem(cursor);
		} catch (e) {
			await this.upsertDateCursor(cursor);
			return await this.getDateCursor(cursor);
		}
	}

	private async upsertDateCursor(cursor: string): Promise<void> {
		const now = new Date().toISOString();

		await this.storageService.nativeStorage.setItem(cursor, now);
	}

	private async handleTransferEvents(
		transferEvents: { blockNumber: number }[],
		cursor: string,
	) {
		if (!transferEvents || transferEvents.length === 0) return;
		this.decentralizedMessageService.refreshNotifications();

		await this.upsertDateCursor(cursor);

		const mustNavigateRoot = false;

		for (const event of transferEvents) {
			let logEventValue: string;
			let toasterMessage: string;
			if (cursor === ArianeeEventWatcherService.TRANSFER_FROM_CURSOR) {
				this.events.publish('TransferFrom', event);

				logEventValue = 'watcher_TransferFrom';
				toasterMessage = 'BlockChainEventToaster.TransferFrom';
			} else {
				this.$transferToReceived.next(event);

				logEventValue = 'watcher_TransferTo';
				toasterMessage = 'BlockChainEventToaster.TransferTo';
			}

			this.loggerService.logEvent(logEventValue);

			this.vibrationService.vibrateRepeat();

			this.toasterService.showRedesigned({
				title: toasterMessage,
				position: 'top',
				duration: 2500,
				color: 'info',
				icon: 'information',
			});
		}

		this.arianeeBlockchainSyncService.syncAllCertificate({});

		if (mustNavigateRoot) this.navController.navigateRoot('/tab/brand-list');
	}

	private async handleMessageSentEvents(
		messageSentEvents: { blockNumber: number }[],
	) {
		if (!messageSentEvents || messageSentEvents.length === 0) return;
		this.decentralizedMessageService.refreshNotifications();

		await this.upsertDateCursor(ArianeeEventWatcherService.MESSAGE_SENT_CURSOR);

		for (let i = 0; i < messageSentEvents.length; i++) {
			this.loggerService.logEvent('watcher_MessageReceive');

			setTimeout(() => {
				this.toasterService.showRedesigned({
					title: 'Notification.newMessageToaster',
					position: 'top',
					duration: 2500,
					color: 'info',
					icon: 'information',
				});
			}, 2500 * (i + 1));
		}
	}
}
