import { Injectable } from '@angular/core';
import { CameraPreview } from '@ionic-native/camera-preview/ngx';
import { Platform } from '@ionic/angular';

@Injectable({
	providedIn: 'root',
})
export class CameraService {
	constructor(
		private cameraPreviewPlugin: CameraPreview,
		private platform: Platform,
	) {}

	private shouldBeMock() {
		return !this.platform.is('cordova');
	}

	public get cameraPreview(): CameraPreview {
		if (!this.shouldBeMock()) {
			return this.cameraPreviewPlugin;
		} else {
			console.warn('camera preview is mocked for browser');
			return {
				startCamera(): Promise<void> {
					return new Promise((resolve) => {
						return resolve();
					});
				},
				stopCamera() {
					return true;
				},
				getSupportedFlashModes() {
					return ['off'];
				},
				async takeSnapshot() {
					const { image } = await import('./pictureb64');
					return [image];
				},
			} as any;
		}
	}
}
