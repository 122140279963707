import { Injectable } from '@angular/core';
import { Route, Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class HandleInappLinkService {
  public allPaths:string[] ;
  constructor (
        private router: Router
  ) {

  }

  public getAllParentPath (): string[] {
    if (!this.allPaths) {
      this.allPaths = [];
      this.findRecursively(this.router.config);
    }
    return this.allPaths;
  }

  public extractInAppLink (link:string):string {
    const url = new URL(link);
    return url.pathname + url.search;
  }

  public isInAppLink (link: string): boolean {
    const paths = this.getAllParentPath();

    for (var path of paths) {
      if (link.includes(path)) {
        return true;
      }
    }
    return false;
  }

  private findRecursively = (routes: Route[], parentPath = '') => {
    routes.forEach(route => {
      const currentPath = route.path ? parentPath + '/' + route.path : parentPath;
      if (route.children) {
        return this.findRecursively(route.children, currentPath);
      } else {
        if (currentPath.length > 0) {
          this.allPaths.push(currentPath);
        }
      }
    });
  }
}
